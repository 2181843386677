// global vars
var popSearchPopular;
var popSearchRecent = [];
var dataStoreSearch = [];
var limitRecentSearch = 5;
var searchInputDOM = document.querySelector('#search_input_wrapper');
var popSearchWrapper = '<div class="popsearch xs-p-0" style="display:none;"></div>';
var GA_CLICK = "clicked";
var CLICK_POPULAR_SEARCH = "Click Popular Search";
var CLICK_RECENT_SEARCH = "Click Recent Search";
var CLICK_POPULAR_CITY = "Click Popular City";

var citysearchDom = document.querySelector('#search_city_wrapper');
var citySearchWrapper = '<div class="citysearch popsearch xs-p-0" style="display:none;"></div>';




var platform = txt_platform;

switch (platform) {
  case 1:
    themeColor = "#e7f8f4";
    textColor ="#04c6a1";
    clearColor= "#00b481";
    break;
  case 2:
    themeColor = "#fff0f3";
    textColor ="#9d6464";
    clearColor= "#be0043";
    break;
  case 5:
    themeColor = "#d1fcff";
    textColor ="#04c0cf";
    clearColor= "#04c0cf";
    break;
  case 6:
    themeColor = "#fff0f3";
    textColor ="#9d6464";
    clearColor= "#00b481";
    break;
  case 7:
    themeColor = "#11385A";
    textColor ="#04c6a1";
    clearColor= "#00b481";
    break;
  case 8:
    themeColor = "#CB0C13";
    textColor ="#04c6a1";
    clearColor= "#00b481";
    break;
  default:
    themeColor = "#e7f8f4";
    textColor ="#04c6a1";
    clearColor= "#00b481";
    break;
}


popSearchRecent = JSON.parse(localStorage.getItem('storeRecentSearch')) || popSearchRecent;
// console.log(popSearchRecent);

if (searchInputDOM) {
  searchInputDOM.innerHTML += popSearchWrapper;
  var psw = document.querySelector('.popsearch');
  var search_keyword = searchInputDOM.querySelector('#search_keyword');
  var suggestion = searchInputDOM.querySelector('#suggestion');
  var dataPopler = generatePopularSearch();

  search_keyword.addEventListener('click', function (e) {
    e.stopPropagation();
    dataStoreSearch = generateStoreSearch(popSearchRecent);
    appendData(dataStoreSearch, dataPopler, psw);
    popSearchState(true);
  });

  document.body.addEventListener('click', function () {
    popsearchDisplay = psw.style.display;
    if (popsearchDisplay === 'block') {
      psw.style.display = 'none';
    }
  });
}

//popular city
if(citysearchDom)
{
  citysearchDom.innerHTML += citySearchWrapper;
  var cws = document.querySelector('.citysearch');
  var search_keyword = citysearchDom.querySelector('#search_location_2');
  var cityPopuler = generateCity();

  search_keyword.addEventListener('click', function (e) {
    e.stopPropagation();
    appendDataCity(cityPopuler, cws);
    searchCityView(true);
  });

  document.body.addEventListener('click', function () {
    citysearchDisplay = cws.style.display;
    if (citysearchDisplay === 'block') {
      cws.style.display = 'none';
    }
  });

}

function appendData(dataStore, dataPopler, el) {
  el.innerHTML = '';

  var popSearchInner = '<div class="popsearch__inner"><ul class="popsearch__list">' + dataStore + ' ' + dataPopler + '</ul></div>';
  el.insertAdjacentHTML('beforeend', popSearchInner);

  var lists = document.querySelectorAll('.popsearch__list-item-link');
  setStoreSearch(lists);
}

function appendDataCity(cityPopuler, el) {
  el.innerHTML = '';

  var popSearchInner = '<div class="popsearch__inner"><ul class="popsearch__list"> ' + cityPopuler + '</ul></div>';
  el.insertAdjacentHTML('beforeend', popSearchInner);
}


function setPopularSearch(p) {
  switch (p) {
    case "MK":
      popSearchPopular = [
        { "name":"Clerk", "type": "job" },
        { "name": "Teacher", "type": "job" },
        { "name": "Admin Assistant", "type": "job" }
      ];
      break;
    case "RB":
      popSearchPopular = [
        { "name": "Account Executive", "type":"job" },
        { "name": "Admin", "type":"job" },
        { "name": "Sales Executive", "type":"job" }
      ];
      break;
    default:
      popSearchPopular = [
        { "name":"Clerk", "type": "job" },
        { "name": "Teacher", "type": "job" },
        { "name": "Admin Assistant", "type": "job" }
      ];
      break;
  }

  return popSearchPopular;
}

// generatePopularSearch  
function generatePopularSearch() {
  var list = '<li class="popsearch__title">' + txt_popular_search + '</li>';
  var jsonData = {
    platform : platform,
    '_token': $('meta[name="csrf-token"]').attr('content'),
    '_method': "GET"
  };

  var request = $.ajax({
    url: canvas_url + 'api/getpopularjobsearch',
    data: jsonData,
    async: false,
    type: 'GET'
  })
  request.done(function (data) {
    var lastLoc = GetUrlParms('loc');
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      var keyword = data[i].replace('/(\s+&\s+|\s+)/g','-');
        list += '<li class="popsearch__list-item" style="width: auto"><a href="'+canvas_url+'?q='+escape(keyword)+'&loc='+lastLoc+'" data-text="'+data[i]+'" class="text-dark popsearch__list-item-link" style="background-color:'+themeColor+'; display: inline-block; color: '+textColor+'; border-radius: 5px; margin: 5px; padding: 5px; float: left;" onclick="sendGaEvent(\''+ CLICK_POPULAR_SEARCH + '\', \'' + GA_CLICK +  '\',\'' + keyword + '\');pushSocialCredit(\'2\',\''+data[i]+'\');setPopularCookies(\'' + data[i] + '\');">' + data[i] + '</a></li>';
    }
  });
  return list; 
}

// setStoreSearch
// push item to storeRecentSearch
function setStoreSearch(suggestion_el) {
  if (suggestion_el != null || typeof(suggestion_el) != "undefined") {
    suggestion_el.forEach(function (suggestion_job) {
      suggestion_job.addEventListener("click", function () {
        popSearchRecent.push(suggestion_job.dataset.text);
      });
    });
  } else {
    searchVal = search_keyword.value;
    searchId = search_keyword.id;
    if (searchVal != "" && searchId !='search_location_2' ) {
      popSearchRecent.push(searchVal);
    }
  }
  popSearchRecent.reverse();
  popSearchRecent = removeDuplicates(popSearchRecent);
  popSearchRecent.reverse();
  localStorage.setItem('storeRecentSearch', JSON.stringify(popSearchRecent)); 
  // console.log(popSearchRecent);
}

// getStoreSearch
function generateStoreSearch(popSearchRecentValue) {
  var list = '';
  var limit = (popSearchRecentValue.length <= limitRecentSearch) ? popSearchRecentValue.length : limitRecentSearch;
  
  if (popSearchRecentValue.length) {
    list += '<li class="popsearch__title">' + txt_recent_search + '</li>';

    var items = (popSearchRecentValue.length < 2) ? popSearchRecentValue : popSearchRecentValue.slice(popSearchRecentValue.length - limit).reverse();
    
    var i = 0;
    var jLimit = limit;
    if (limit >= 2) {
      jLimit = 2;
    }
    var lastLoc = GetUrlParms('loc');
    for (var j = 0 ; j < jLimit; j++) {
      var keyword = items[i].replace(/(\s+&\s+|\s+)/g,'-');
      list += '<li class="popsearch__list-item item-'+j+'"><a href="' + canvas_url + '?q=' + keyword + '&loc='+lastLoc+'" onclick="sendGaEvent(\'' +CLICK_RECENT_SEARCH  + '\', \'' + GA_CLICK + '\', \'' + keyword +'\');pushSocialCredit(\'1\',\''+items[i]+'\');" class="text-dark" style="background-color:'+themeColor+'; display: inline-block; color: '+textColor+'; border-radius: 5px; margin: 5px; padding: 5px; float: left">' + items[i] + '</a></li>';
      i++;
    }

    list += '<li class="popsearch__button"><a href="#" class="btn btn-danger float-left" onclick="clearStoreSearch()" style="background-color:'+clearColor+'; border: #fff">' + txt_clear_search + '</a></li>'
  } else {}
  
  return list;
}

// clearStoreSearch
// remove items from storeRecentSearch
function clearStoreSearch() {
  popSearchRecent = [];
  sendSCLoginEvent(SEARCH,EVENT_SEARCH_SCREEN_CLICK_RECENT_CLEAR);
  localStorage.removeItem('storeRecentSearch');
}

function pushSocialCredit(type,keyword)
{
  jsonObj = [];
  item = {}
  item["keyword"] = keyword;
  jsonObj.push(item);
  var event_type ='';

  if(type == 1)
  {
    event_type = EVENT_SEARCH_SCREEN_CLICK_RECENT_SEARCH;
  }
  else
  {
    event_type = EVENT_SEACH_SCREEN_CLICK_POPULAR_SEARCH;
  }

  sendSCLoginEvent(SEARCH,event_type,jsonObj);


}

// popSearchState
// show/hide popsearch
function popSearchState(cond) {
  psw.style.display = (cond === true) ? 'block' : 'none';
}

function searchCityView(cond) {
  cws.style.display = (cond === true) ? 'block' : 'none';
}



// function to generate city
function generateCity() {
  var list = '<li class="popsearch__title">' + txt_popular_city + '</li>';
  var jsonData = {
    platform : platform,
    '_token': $('meta[name="csrf-token"]').attr('content'),
    '_method': "GET"
  };


  var request = $.ajax({
    url: canvas_url + 'api/getpopularcity',
    data: jsonData,
    async: false,
    type: 'GET'
  })

  var lastKeyword = GetUrlParms('q');
  
  request.done(function (data) {  
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      var keyword = data[i].replace('/(\s+&\s+|\s+)/g','-');
        list += '<li class="popsearch__list-item" style="width: auto"><a href="'+canvas_url+'?q='+escape(lastKeyword)+'&loc='+escape(keyword)+'" data-text="'+data[i]+'" class="text-dark popsearch__list-item-link" style="background-color:'+themeColor+'; display: inline-block; color: '+textColor+'; border-radius: 5px; margin: 5px; padding: 5px; float: left;" onclick="sendGaEvent(\''+ CLICK_POPULAR_CITY + '\', \'' + GA_CLICK +  '\',\'' + keyword + '\');setPopularCookies(\'' + data[i] + '\');">' + data[i] + '</a></li>';
    }
  });
  return list; 
}
