const ERROR_FINGERPRINT_UPLOAD_RESUME="upload-resume-failed"
const ERROR_FINGERPRINT_APPLY_JOB="apply-job-failed"
const ERROR_FINGERPRINT_GA="google-analytic-event-failed"

const ERROR_LEVEL_WARNING="warning"
const ERROR_LEVEL_ERROR="error"

const ERROR_TYPE_ENCODING="EncodingError"

const ERROR_MESSAGE_IMAGE_CANNOT_DECODED="The source image cannot be decoded."
const ERROR_MESSAGE_GA_NOT_DEFINED="ga is not defined"
const ERROR_MESSAGE_INVALID_IMAGE_TYPE="Invalid image type."
