var server = window.location.hostname;
var canvas_url = "";
var platform;

if (server == 'localhost')
    canvas_url = location.protocol + "//localhost:8000/", platform = "RB";
else if (server == 'dev.ricebowl.my')
    canvas_url = location.protocol + "//dev.ricebowl.my/"; //54.169.110.239
else if (server == 'job.ricebowl.my')
    canvas_url = location.protocol + "//job.ricebowl.my/";
else if (server == 'job.maukerja.my')
    canvas_url = location.protocol + "//job.maukerja.my/";
else if (server == 'www.ajobthing.com')
    canvas_url = location.protocol + "//www.ajobthing.com/";
else if (server == 'www.ajobthing.my')
    canvas_url = location.protocol + "//www.ajobthing.my/";
else if (server == 'dev.maukerja.my')
    canvas_url = location.protocol + "//dev.maukerja.my/", platform = "MK";
else if (server == 'test.maukerja.my')
    canvas_url = location.protocol + "//test.maukerja.my/", platform = "MK";
else if (server == 'test.ricebowl.my')
    canvas_url = location.protocol + "//test.ricebowl.my/", platform = "RB";
else if (server == 'test.internsheeps.com')
    canvas_url = location.protocol + "//test.internsheeps.com/";
else if (server == 'www.internsheeps.com')
    canvas_url = location.protocol + "//www.internsheeps.com/";
else if (server == 'dev.internsheeps.com')
    canvas_url = location.protocol + "//dev.internsheeps.com/";
else if ((server.indexOf('maukerja.my') !== -1))
    canvas_url = location.protocol + "//"+server+"/", platform = "MK";
else if (server == 'ricebowljobs.orientaldaily.com.my')
    canvas_url = location.protocol + "//ricebowljobs.orientaldaily.com.my/";
else if (server.indexOf('pocketmoneys.com') !== -1)
    canvas_url = location.protocol + "//"+ server +"/";
else if (server.indexOf('pocketmonies.com') !== -1)
    canvas_url = location.protocol + "//"+ server +"/";
else if (server.indexOf('maukerja.id') !== -1)
    canvas_url = location.protocol + "//"+ server +"/";
else if (server == 'showme.my')
    canvas_url = location.protocol + "//showme.my/";
else if (server == 'www.harikerjayamcd.my')
    canvas_url = location.protocol + "//www.harikerjayamcd.my/";
else if ( (server.indexOf('dev') !== -1) || (server.indexOf('test') !== -1) )
    canvas_url = location.protocol + "//"+ server + "/" ;
else
    canvas_url = location.protocol + "//"+server+"/", platform = "RB";

var API_URL = canvas_url + "api/";
var ADMIN_URL = canvas_url + "admin/";
var ADMIN_API_URL = ADMIN_URL + "api/";

var cUrl = window.location;
var sPath = window.location.pathname;
var sPage = sPath.substring(sPath.lastIndexOf('/') + 1);
var emptc = 3;
var searchFilterCheck = false;
var applyjoblink = '';
var issearchlink = false;
var searchseolinkdata = new Array();

var abuseReport = {
    "other_type" :  "others",
    "type" : {
        "company"   : "company",
        "job"       : "job"
    }
}

var errrorTypes = {
    '302' : 'not_found',
    '401' : 'unauthorized',
    '500' : 'internal_server_error',
    'other': 'other'
}


var modalIDs = {
    'login' : "login_modal",

}

var scEvents = {
    'share_job': "share_job",
    'exit': "exit"
}


// view job contact and apply job
var userActionStatus = {
    'success' : "success",
    'warning' : "warning",
    'suspended' : "suspended",
}


var suspendTimerID = "timer-suspended";


var timeout_redirect = 3000; //redirect timeout after showing a success or error message

//var azure_api = {id:'ricebowl-web', secret:'dA/VMy4MLSrNCRnes75/MSP42TTOO2wcnJQObG2V9AU='};
var azure_api = {id: 'RiceBowl2015', secret: 'ICc+5CQ75uk9VRWb9Hq5xUrVRq1OtPWYqtFwle7SLzA='};
var guided_search_stuff = {active_filters: {}};
var video_stuff = {};
var job_sorting_stuff = {sort: 'Date', direction: 'desc', sorting: false};

// regular expression to check url
var matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

var storageKey = {
    'browseHistory' : 'browseHistory'
}
//default validator
$.validator.setDefaults({
    errorElement: 'span',
    errorClass: 'help-block',
    ignore: ":hidden:not(.important)",
    errorPlacement: function(error, element) {

        if (element.attr("type") == "checkbox" || element.attr("type") == "radio") {
            $(element).parents('.form-group').find('label').first().after(error);
        }else{
            $(element).parents('.form-group').append(error);
        }

    },
    highlight: function(element) {
        $(element).parents('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).parents('.form-group').removeClass('has-error');
        $(element).parents('.form-group').find('.help-block').remove();
    }
});

//init date
var d = new Date();
var n = d.getFullYear();

//init loader template
NProgress.configure({
  template: '<div class="loader-bar" role="bar"></div></div>'
});

//init notify
$.notifyDefaults({
    // settings
    type: "danger",
    newest_on_top: true,
    delay: 5000,
    timer: 1000,
    placement: {
        from: "top",
        align: "center"
    },
    animate: {
       enter: 'animated fadeInDown',
       exit: 'animated fadeOutUp'
   },
   offset: 10,
   z_index: 2000,
});

//init sweetalert
swal.setDefaults({
  width: 450,
  buttonsStyling: false,
  showCancelButton: false,
  reverseButtons : true,
  confirmButtonClass: 'btn btn-primary w-100p-xs',
  cancelButtonClass: 'btn btn-default  w-100p-xs sm-mr-20 xs-mr-0 sm-mb-0 xs-mb-10',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Ok'
});

//init utiljs for intTelInput
$.fn.intlTelInput.loadUtils(canvas_url+"assets/js/utils.js");

function getQueryParameter(index) {

    var value = "";
    try {
        var params = new URLSearchParams(window.location.search);
        value = params.get(index);
    }catch (ex) {
        console.log(ex)
    }

    return value;
}
function checkResume(obj){
    if(typeof obj !== 'undefined'){

        var file_type = '';
        var str = $(obj).val();

        $('#resume-preview-wrap').show();
        $('#resume-upload').hide();
        $('#resume_title').parent().show();

        if (getResumeType(obj) == 'application/pdf') {
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-pdf.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }else if(getResumeType(obj) == 'application/msword'){
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-doc.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }else if(getResumeType(obj) == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-docx.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }else if(getResumeType(obj) == 'image/jpeg'){
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-jpg.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }
        else if(getResumeType(obj) == 'image/jpg'){ //temp fix for phone upload issue when digit name
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-jpg.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }
        else if(getResumeType(obj) == 'image/png'){ //temp fix for phone upload issue when digit name
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-jpg.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }
        else if(getResumeType(obj) == ''){ //temp fix for phone upload issue when digit name
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-pdf.png" class="center-block" height="40">';
            $('#resume_title').val(getResumeTitle(str)).focus();
        }
        else{
            file_type = '<img src="'+canvas_url+'assets/img/resume/file-broken.png" class="center-block" height="40"> <h4 class="xs-mt-10 xs-mb-0 text-danger text-bold resume-error-text">'+$('.resume-error-message').text()+'<br><span class="text-small text-normal d-block xs-mt-5">('+txt_resume_format+')</span></h4>';
            $('#resume_title').val('').parent().hide();
        }

        $('#resume-preview-type').html(file_type);
        if( document.getElementById("resume_file").files.length != 0 ){
            $('.upload_resume').prop('disabled', false);
        }

    }else{
        return;
    }
}

function getResumeTitle(str){
    if(typeof str != 'undefined'){
        var title = new String(str).substring(str.lastIndexOf('\\') + 1);
        if(title.lastIndexOf(".") != -1)   {
            title = title.substring(0, title.lastIndexOf("."));
        }
        return title;
    }else{
        return;
    }
}

function getResumeType(obj){
    if(typeof obj !== 'undefined'){
        var id = $(obj).attr('id');
		if(typeof id !== 'undefined' && typeof document.getElementById(id).files[0] !== 'undefined' &&  document.getElementById(id).files[0] !== null){
			var filetype = document.getElementById(id).files[0].type;
			return filetype;
		}else{
			return;
		}
    }else{
        return;
    }
}

function cleanPhone(s) {

    if (typeof s == 'undefined')
    {
        return;
    }
    var selectedDialCode = '';
    if ($('.selected-dial-code').length > 0)
    {
        selectedDialCode = $(".selected-dial-code").html();
        selectedDialCode = selectedDialCode.replace("+","");
    }

    if (s[0] != '6' && s[0] != '0' && s[0] != '1') {
        return selectedDialCode+s;
    }

    if (selectedDialCode=='') selectedDialCode = '60';
    s = s.replace(/^\+60+/, '');
    s = s.replace(/^60+/, '');
    s = s.replace(/^\+0+/, '');
    s = s.replace(/^0+/, '');
    s = s.replace(/^\++/, '');
    var cleanhp = selectedDialCode + s;
    return cleanhp;
}

function validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
}

function validatePhone(phone) {
    //var min = 11;//nurul - change to 8
    //var max = 12;
    var min = 9;
    max = 15;
    var digits_only = phone.replace(/[^0-9]/g, '');
    var a = (phone.match(/^(?=.*[0-9])[- +()0-9]+$/) != null && digits_only.toString().length >= min && digits_only.toString().length <= max);
    return a;
}

function validateInt(n) {
    var regex = /([^0-9])/g;
    return !regex.test(n);
}

function validatePassword(password) {
    var min = 5;
    var max = -1;
    return (password.toString().length >= min && (max < 0 || password.toString().length <= max));
}

function validateDate(d) {
    // validate date in this format dd/mm/yyyy
    pdate = d.split('/');
    var dd = parseInt(pdate[0]);
    var mm = parseInt(pdate[1]);
    var yyyy = parseInt(pdate[2]);
    if (yyyy < 1900 || mm > 12 || dd > 31)
        return false;
    var objDate = new Date(mm + '/' + dd + '/' + yyyy);
    if (Object.prototype.toString.call(objDate) !== "[object Date]")
        return false;
    return !isNaN(objDate.getTime());
}

function validateForm(parentID) {
    var arrValues = [];
    var valid = true;
    $('#' + parentID).find('*:visible').each(function () {
        var obj = $(this);
        if (obj.attr('data-validation')) {
            var rules = obj.data('validation').rules;
            var objID = obj.attr('id');
            var objIdSelector = '#' + objID;
            // ***** check for value to not be empty
            if (rules.indexOf('required') !== -1) {
                if (obj.val() === '' || obj.val() === '0') {
                    obj.parents('.form-group').addClass("has-error");
                    //go(objIdSelector, 150, 500);
                    $.notify(obj.data('validation').msg1);
                    valid = false;
                    return false;
                } else {
                    if (!(objID in arrValues)) {
                        arrValues[objID] = obj.val();
                    }
                }
            }
            // ***** check for value to be a number
            if (rules.indexOf('number') !== -1) {
                if (!$.isNumeric(obj.val())) {
                    obj.parents('.form-group').addClass("has-error");
                    //go(objIdSelector, 150, 500);
                    $.notify(obj.data('validation').msg2);
                    valid = false;
                    return false;
                } else {
                    if (!(objID in arrValues)) {
                        arrValues[objID] = obj.val();
                    }
                }
            }
            // ***** check for value to be a valid date
            if (rules.indexOf('date') !== -1) {
                if (!validateDate(obj.val())) {
                    obj.parents('.form-group').addClass("has-error");
                    //go(objIdSelector, 150, 500);
                    $.notify(obj.data('validation').msg2);
                    valid = false;
                    return false;
                } else {
                    if (!(objID in arrValues)) {
                        arrValues[objID] = obj.val();
                    }
                }
            }
            // ***** check for value to be a valid email address
            if (rules.indexOf('email') !== -1) {
                // ***** Only validate if not empty
                if (obj.val()) {
                    if (!validateEmail(obj.val())) {
                        obj.parents('.form-group').addClass("has-error");
                        //go(objIdSelector, 150, 500);
                        $.notify(obj.data('validation').msg2);
                        valid = false;
                        return false;
                    }
                }
                if (!(objID in arrValues)) {
                    arrValues[objID] = obj.val();
                }
            }
            // ***** check for value to be a valid phone number
            if (rules.indexOf('phone') !== -1) {
                if (!validatePhone((obj.val()))) {
                    obj.parents('.form-group').addClass("has-error");
                    //go(objIdSelector, 150, 500);
                    $.notify(obj.data('validation').msg2);
                    valid = false;
                    return false;
                } else {
                    if (!(objID in arrValues)) {
                        arrValues[objID] = obj.val();
                    }
                }
            }
            // ***** check to see gender has been selected
            if (rules.indexOf('gender') !== -1) {
                var gender = $(objIdSelector + ' input[type=radio]:checked').val();
                if (gender === undefined) {
                    obj.parents('.form-group').addClass("has-error");
                    //go(objIdSelector, 150, 500);
                    $.notify(obj.data('validation').msg1);
                    valid = false;
                    return false;
                } else {
                    if (!(objID in arrValues)) {
                        arrValues[objID] = gender;
                    }
                }
            }
            // ***** just get value
            if (rules.indexOf('value') !== -1) {
                if (!(objID in arrValues)) {
                    arrValues[objID] = obj.val();
                }
            }
        }
    });
    if (valid) {
        return arrValues;
    } else {
        return false;
    }
}

function GetUrlParms(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.href);
    if (results == null)
        return "";
    else
        return results[1];
}

var showLoginModal = function (event) {
    if (typeof event != 'undefined') {
        event.stopPropagation();
        event.preventDefault();
    }
    $('#login-for-salary-modal .apply-login > div').hide();
    $('#m-login').show();
    $('#hr-with-or-hehe').show();
    $('#show-signup-thing').show();
    $('#m-login').show();
    $('#m-signup').hide();
    $('#m-forget-password').hide();
    $('#login_modal').modal('toggle');
    return false;
}

function CheckResumeApplyJob() {
    var resume_id = $("#resume_id").val();
    if (resume_id == 'na') {
        $("#noresume-checkbox").fadeIn();
    } else if(resume_id == 'upload') {
        $('#upload-resume').modal('show');
    }else{
        $("#noresume-checkbox").fadeOut();
    }


    if (!$("#login-first-alert").length) {
        //only show view resume button for logged in users
        var btn = '<a class="d-block text-left xs-mt-10" id="view-uploaded-resume" target="_blank" href="' + canvas_url + 'jobseeker/view-resume?id=' + resume_id + '" onclick="trackEvent({ event:\'Click\', key:\'View Resume\', value:\'Apply Job\' })"><i class="fa fa-eye"></i> ' + txt_view + ' ' + $("#resume_id option:selected").text() + '</a>';
        if (resume_id != 0 && resume_id != 'na' && resume_id != 'upload') {
            if ($("#view-uploaded-resume").length) {
                $("#view-uploaded-resume").fadeOut(400, 'swing', function () {
                    $("#view-uploaded-resume").remove();
                    $("#resume_id").parent().parent().append(btn);
                    $("#view-uploaded-resume").fadeIn();
                });
            } else {
                $("#resume_id").parent().parent().append(btn);
                $("#view-uploaded-resume").fadeIn();
            }
        } else {
            $("#view-uploaded-resume").fadeOut(400, 'swing', function () {
                $("#view-uploaded-resume").remove();
            });
        }
    }
}

function sendSCEvent(type, job_id) {
    if(typeof type === 'undefined'){
        return false
    }

    var job_data = {};

    if(type === scEvents.share_job){
        job_data.job_id = job_id;
    }
    else{
        job_data = job_id;
    }

    $.post(
        canvas_url + "api/sc/event",
        {
            'type': type,
            'data': job_data,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "POST"
        }, function (data) {
        }, "json"
    );
}


function sendJobIdSocialCredit(type,value,category)
{
    jsonObj = [];
    item = {}
  
    if(category == 'job')
    {
        item["job_id"] = value;      
    }
    else if(category == 'company')
    {
        item["company_id"] = value;
        console.log('2');
    }
    else if(category == 'category_job')
    {
        item["category"] = value;
    } 
    else if (category == 'type_job') 
    {
        item["job_type"] = value;
    }
    else 
    {
        item["location"] = value;
    }

  jsonObj.push(item);

  sendSCLoginEvent(SC_APPLY_JOB,type,jsonObj);

}

var sc_array = ["onboarding","top_menus","profile","Profiling Question","Profile Details","Search","Side Page","apply_job"]
function sendSCLoginEvent(type,action_type,value,status) {
    if(typeof type === 'undefined'){
        return false
    }

    var postData = {};
    if(type=='login')
    {
        postData['login_method'] = value;
        type = 'event_login_with_' + action_type;
    } 
    else if (jQuery.inArray(type, sc_array) !== -1 ) 
    {
        if(typeof value =='object')
        {
            postData = value[0];   
            type = action_type;
        }
        else
        {
            postData = "{}";        
            type = action_type;
        }
    }
    else
    {
        postData['register_method'] = value;
        type = 'event_register_' + action_type;
    }

    if(typeof status !== 'undefined' && status != '')
    {
        postData['reason'] = 'fail';
    }

    $.post(
        canvas_url + "api/sc/loginEvent",
        {
            'type': type,
            'data': postData,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "POST"
        }, function (data) {
        }, "json"
    );
}









function ClickBanner(id) {
    $.post(canvas_url + "api/banner/click", {'id': id, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
    }, "json");
}


function FilterURL(string) {
   if (typeof string!='undefined' && string!=null && string!='')
   {
    string = string.toLowerCase();
    string = string.replace(" ", "-");
    string = string.replace(".", "-");
    string = string.replace(/[&\/\\#,@+()$~%.'":*?<>{}]/g, '');
    string = string.replace(/ /g, '-');
   }
    return string;
}

function countChar(val) {
    var len = val.value.length;
    if (len >= 140) {
        val.value = val.value.substring(0, 140);
    } else {
        $('.charNum').text(140 - len);
    }
}

function SetLanguage(lang,platformID) {
    var current_url = cUrl.href;
    var newurl = current_url.split('#')[0];

    if (newurl.indexOf("/job/") != -1 || newurl.indexOf("/state/") != -1 || newurl.indexOf("/category/") != -1 || newurl.indexOf("/company/") != -1 || newurl.indexOf("/contact") != -1 || newurl.indexOf("/pricing") != -1 || newurl.indexOf("/aboutus") != -1 || newurl.indexOf("/terms-condition") != -1 || newurl.indexOf("/aboutus") != -1 || newurl.indexOf("/help") != -1 || newurl.indexOf("/interviewaccepted") != -1 || newurl.indexOf("/fastresponse") != -1 || cUrl.pathname == '/' || cUrl.pathname == '/en/' || cUrl.pathname == '/zh/' || cUrl.pathname == '/ms/' || cUrl.pathname == '/en' || cUrl.pathname == '/zh' || cUrl.pathname == '/ms' || newurl.indexOf("/jobsearch/") != -1)
    {
        if (newurl.indexOf("/en") != -1)
        {
            newurl = newurl.replace("/en", "/" + lang);
            var newurlss = newurl;
        } else if (newurl.indexOf("/zh") != -1)
        {
            newurl = newurl.replace("/zh", "/" + lang);
            var newurlss = newurl;
        } else if (newurl.indexOf("/ms") != -1)
        {
            newurl = newurl.replace("/ms", "/" + lang);
            var newurlss = newurl;
        } else
        {
            var old = canvas_url;
            newurl = newurl.replace("/zh", "");
            newurl = newurl.replace("/en", "");
            newurl = newurl.replace("/ms", "");
            var new_url = old + lang + '/';
            var newurlss = newurl.replace(old, new_url);
            //console.log(cUrl.href);
            //console.log(newurl);
            //console.log(old);
            //console.log(new_url);
            //console.log(newurlss);

        }
        if(typeof platformID !== 'undefined' && platformID  == 1){
            setCookie("RicebowlLang","yes",365);
        }
    } else
    {
        var newurlss = newurl;
    }

    //console.log(newurlss);

    $.post(canvas_url + "api/setlang", {'lang': lang, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {

        setCookie("userLanguage",lang,365);

        //location.reload();
        window.location = newurlss;
    }, "json");

    $(document).ajaxError(function (e, xhr, settings, exception) {
        if (xhr.status == 404) {
            console.log("The page you are lookin for not found.");
        }
        if (xhr.status == 500) {
            console.log("An error occured while processing your request, please try again later.");

        }
        console.log(xhr.responseText);
    });
}


function setPreferredLanguage(language) {

    sendGaEvent(ACTION_SET_LANGUAGE_PRREFERENCE, GA_CATEGORY_CLICK, language);

    $.post(canvas_url + "api/preference/language", {'language': language, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {

        if(data.success == true) {
            setCookie("RicebowlLang",language,365);
            setCookie("CloseLangModal",2,365);
            SetLanguage(language);
        }
    }, "json");


}

function setClosingCounter()
{
    var value = 0;
    if(getCookie("CloseLangModal") == "")
    {
        value = 1;
    }
    else
    {
        value = parseInt(getCookie("CloseLangModal"))+1;
    }


    setCookie("CloseLangModal",value,365);
}

function SetLanguageHome(lang) {
    if ($(".btn").hasClass("disabled")) {
        return false;
    } else {
        //$(".btn-"+lang).html('<img src="'+canvas_url+'image/loading.gif" style="width:22px;">');
        $(".btn-" + lang).button("loading");
        //$(".btn").addClass("disabled");
        $.post(canvas_url + "api/setlang", {'lang': lang, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {

            if (server != 'localhost')
                canvas_url = canvas_url.replace("http:", "https:");
            window.location = canvas_url + lang + '/';
            setTimeout(function () {
                $(".btn-" + lang).button('reset');
            }, 3000);
        }, "json");
    }
}

function OnKeyPressJobseekerLogin(e) {
    if (window.event) {
        e = window.event;
    }
    if ((e.keyCode == 13)) {
        $(".submit_jobseekerlogin").trigger("click");
    }
}

function Paging(listing_type, start_limit, page, total_records, limit) {
    var midNumbers = 3; //midNumbers number left right from the page , lets   1 2 3   4    1 2 3
    var pagging = new Array();
    pagging['pagging'] = "";
    pagging['t'] = "";
    pagging['sql'] = "";

    pagging['Start'] = "";
    pagging['Preveious'] = "";
    pagging['Mid'] = "";
    pagging['MidTemp'] = "";
    pagging['Next'] = "";
    pagging['End'] = "";

    var currentURL = window.location.href;

    if (listing_type == 'BlogPostListing') {
        txt_next = '';
        txt_previous = '';
    }

    $("#pagehrefprev").remove();
    $("#pagehrefnext").remove();

    if (total_records > 0)
    {
        var aNextImage = '<i class="fa fa-chevron-right"></i>';
        var aPrevImage = '<i class="fa fa-chevron-left"></i>';
        //var NextImage = '' + txt_next + ' <i class="fa fa-check"></i>';
        //var PrevImage = '<i class="fa fa-check"></i> ' + txt_previous + '';

        limitvalue = (page - 1) * limit;
        numofpages = Math.ceil(total_records / limit);
        if (page == 0)
            page = 1;
        pagging['t'] = '<ul class="pagination xs-mt-0 xs-mb-0">';
        if (page > 1)
        {
            var pageprev = page - 1;
            pagging['Preveious'] += "<li onclick=\"LoadPaging('" + listing_type + "'," + pageprev + ");\" aria-label='Previous'><a>" + aPrevImage + "</a></li>";
            if (listing_type=='JobNewListing')
            {
                var prevPageURL = currentURL.replace("page="+page,"page="+pageprev);
                $('head').append('<link id="pagehrefprev" rel="prev" href="'+prevPageURL+'">');
            }
        } else
        {
            left_link = "&nbsp;";
            pagging['Preveious'] += '<li class="disabled"><a>' + aPrevImage + '</a></li>';
            if (txt_previous == '')
                pagging['Preveious'] = '';
        }


        pagging['pagging'] += '';
        for (i = 1; i <= numofpages; i++)
        {
            if (i > page - midNumbers && i < page + midNumbers)
            {

                if (page == i)
                {
                    pagging['MidTemp'] = '<li class="active"><a>' + (i + "") + '</a></li>';
                    pagging['Mid'] += pagging['MidTemp'];
                } else
                {
                    pagging['MidTemp'] = "<li onclick=\"LoadPaging('" + listing_type + "'," + i + ");\" style='cursor:pointer'><a>" + i + "</a></li>";
                    pagging['Mid'] += pagging['MidTemp'];
                }
            }
        }
        pagging['pagging'] += '';


        if (page < numofpages)
        {
            var pagenext = (page + 1);
            pagging['Next'] += "<li onclick=\"LoadPaging('" + listing_type + "'," + pagenext + ");\" aria-label='Next'><a>" + aNextImage + "</a></li>";
            // console.log(listing_type+' '+numofpages)
            if (listing_type=='JobNewListing' && numofpages > 1)
            {
                var nextPageURL = currentURL.replace("page="+page,"page="+pagenext);

                var url = new URL(currentURL);
                var c = url.searchParams.get("page");
                if(c == null){
                    nextPageURL = currentURL+'?page='+pagenext
                }

                $('head').append('<link id="pagehrefnext" rel="next" href="'+nextPageURL+'">');
            }

        } else
        {
            pagging['Next'] += '<li class="disabled"><a>' + aNextImage + '</a></li>';
            if (txt_next == '')
                pagging['Next'] = '';
        }

        pagging['pagging'] += pagging['t'];
        pagging['pagging'] += pagging['Preveious'];
        pagging['pagging'] += '' + pagging['Mid'] + '';
        pagging['pagging'] += pagging['Next'];
    }

    if (total_records < limit)
    {
        //document.getElementById("Paging").style.display = "none";
    }

    return pagging['pagging'];
}

function LoadPaging(listing_type, page) {
    // if (listing_type == 'JobListing') {
    //     $("#page").val(page);
    //     $.getJobs(page);
    // }
    // if (listing_type == 'BlogPostListing') {
    //     $.getBlogPosts(page);
    // }

    if (listing_type == 'SavedJobListing') {
         $.getSavedJobsDashboard(page);
    }

    if (listing_type == 'AppliedJobListing') {
         $.getAppliedJobsDashboard(page);
    }

    if (listing_type == 'JobNewListing') {
        $("#page").val(page);
        $.getJobsListing(page);
    }

	if (listing_type == 'FeaturedJobListing') {
        $('body').scrollTop(0);
        $("#page").val(page);
        $.getFeaturedJobsListing(page);
    }

	if (listing_type == 'JobNewListing')
	{
		var current_url = cUrl.href;

		var current_page = setGetParameter("page",page);
		history.pushState('data', '', current_page);
        $('html, body').animate({
            scrollTop: $("#header").offset().top
        }, 2000);
        /*
		if (current_url.indexOf("?") < 0)
			url += "?" + paramName + "=" + paramValue;
		else
			url += "&" + paramName + "=" + paramValue;

		var q = GetUrlParms('q');
		var ref = GetUrlParms('ref');
		var viewcpa = GetUrlParms('viewcpa');
		if (q=='' && ref=='' && viewcpa=='') // Means nothing on url then just add page
		{
			var current_page = current_url+'?page=' + page;
		}
		else
		{
			var current_page = current_url+'&page=' + page;
		}
		*/

	}

    //var current_host = window.location.host;
    if (listing_type == 'JobListing') {
        var current_hash = window.location.hash; //filters and stuff
        var current_page = '?page=' + page;
        var viewcpa = GetUrlParms('viewcpa');
        if (viewcpa!='')
        current_page += '&viewcpa=yes';
        history.pushState('data', '', current_page + current_hash);
    }
}

function ShowAllStatesNew(country, tag_prefix){
    $("." + tag_prefix + "-states").each(function (index) {
        $(this).hide();
    });
    $(".states-"+tag_prefix).hide();
    if (country == '0' || country == 'Other')
    {
        $("#" + tag_prefix + "-Other-state").show();

    } else
    {
        var countryName = country.toLowerCase();
        countryName = countryName.replace(" ","-");
        var userState = $("#user_state-"+tag_prefix).val();
        var request = $.ajax({
           type: 'GET',
           url:  canvas_url+'allstates/'+countryName+'.json',
           dataType: 'json'
        });
        request.done(function(data) {

            var states = data;
            var selected = '';
            var statelist = '<span class="caret"></span><select class="'+tag_prefix+'_state form-control" name="'+tag_prefix+'_state" id="jobseeker_alert_location_state_'+tag_prefix+'"><option value="">--Select One--</option>';
            for (var i=0;i<states.length;i++) {
                selected = '';
                if (userState==states[i].name) selected = 'selected';
                statelist +='<option value="'+states[i].name+'" '+selected+'>'+states[i].name+'</option>';
            }
            statelist +='</select>';
            $("#statelist-" + tag_prefix + "").html(statelist);
            $("#statelist-" + tag_prefix + "").show();
        });

        request.fail(function (jqXHR, textStatus) {
            console.log('error in loading');
            $("#" + tag_prefix + "-Other-state").show();
        });
        //$("#" + country + "-state").show();
    }
}

function ShowStates(country){
    $(".states").each(function (index) {
        $(this).hide();
    });

    var elem = $("#states-div").find('.help-block').remove();

    if ( country == 'Other')
    {
        $("#Other-state").parent().show();
        $("#Other-state").show();

    } else if(country == ''){
        $("#Other-state").hide();

        var statelist = '<div class="select"><span class="caret"></span><select class="states form-control required" name="new_state_id" id="new_state_id"><option value="">'+txt_nl_selectstate+'</option></div>';
        statelist +='</select>';
        $("#statelist").html(statelist);
        $("#statelist").show();


    }else{

        $("#Other-state").parent().show();
        var countryName = country.toLowerCase();
        countryName = countryName.replace(/\s+/g, '-').toLowerCase();
        var request = $.ajax({
           type: 'GET',
           url:  canvas_url+'allstates/'+countryName+'.json',
           dataType: 'json'
        });


        request.done(function(data) {

            var states = data;
            var statelist = '<div class="select"><span class="caret"></span><select class="states form-control required" name="new_state_id" id="new_state_id"><option value="">'+txt_nl_selectstate+'</option></div>';
            for (var i=0;i<states.length;i++) {
                statelist +='<option value="'+states[i].name+'">'+states[i].name+'</option>';
            }
            statelist +='</select>';
            $("#statelist").html(statelist);
            $("#statelist").show();
        });

        request.fail(function (jqXHR, textStatus) {
            console.log('error in loading');

        });

    }
}

function ShowStatesNew(country, tag_prefix){
    $("." + tag_prefix + "-states").each(function (index) {
        $(this).hide();
        $(this).attr('id', $(this).data('id'));
        if ((country === '0' || country === 'Other') && $(this).attr('id') === $("#" + tag_prefix + "-Other-state")) {
            $(this).show();
            $(this).attr('id', tag_prefix + "-state");
        } else if ($(this).attr('id') == tag_prefix + "-" + country + "-state") {
            $(this).show();
            $(this).attr('id', tag_prefix + "-state");
        }
    });
}

function pad(number, length) {

        var str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }

        return str;
}

function setGetParameter(paramName, paramValue) {
	var url = window.location.href;
	if (url.indexOf(paramName + "=") >= 0) {
		var prefix = url.substring(0, url.indexOf(paramName));
		var suffix = url.substring(url.indexOf(paramName));
		suffix = suffix.substring(suffix.indexOf("=") + 1);
		suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
		url = prefix + paramName + "=" + paramValue + suffix;
	} else {
		if (url.indexOf("?") < 0)
			url += "?" + paramName + "=" + paramValue;
		else
			url += "&" + paramName + "=" + paramValue;
	}
	return url;
	//window.location.href = url;
}

//notify the programmers
function errorAlert(o) {
    if (typeof o.key == 'undefined')
        return false;
    //o.key = o.key.replace(/(!?[_=0-9 ])/ig, '');
    o['_token'] = $('meta[name="csrf-token"]').attr('content');
    o['_method'] = 'POST';
    $.post(canvas_url + "error-alert", o, function (data) {}, "json");
}

var getQueryString = function (field, url) {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
};


// ***** fake mixpanel function
var mixpanel = {track: function (nothing) {
    //console.log('mixpanel has been deprecated');
    return false;
}};

//our little implementation of mixpanel
//we could just put the code into the mixpanel.track above so that all current
//events tracked with mixpanel would automatically work, but i dont want to
function trackEvent(o) {
    var defaults = {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'event': '',
        'key': '',
        'value': ''
    }
    o = $.extend({}, defaults, o);

    var url = location.protocol + '//' + window.location.hostname + '/api/track-event';
    $.post(url, o, function (data) {}, "json");
}

function makeUrlFriendly(term, type) {
    type = (typeof type !== 'undefined') ? type : 'normal';
    var result = '';
    term = term.trim();
    if (type == 'location') {
        var commaPos = term.indexOf(',');
        var linePos = term.indexOf('|');
        if (commaPos === -1 && linePos === -1) {
            term = term;
        } else if (commaPos !== -1 && linePos !== -1) {
            if (commaPos < linePos) {
                term = term.substr(0, term.indexOf(','));
            } else {
                term = term.substr(0, term.indexOf('|'));
            }
        } else if (commaPos === -1 && linePos !== -1) {
            term = term.substr(0, term.indexOf('|'));
        } else {
            term = term.substr(0, term.indexOf(','));
        }
    }
    term = term.replace(/ /g, '-');
    term = term.replace(/\//g, '-');
    term = term.replace(/&/g, '-');
    term = term.replace(/,/g, '-');
    term = term.replace(/:/g, '-');
    term = term.replace(/"/g, '-');
    term = term.replace(/\(/g, '-');
    term = term.replace(/\)/g, '-');
    term = term.replace(/---/g, '-');
    term = term.replace(/--/g, '-');
    if (term.slice(-1) === '-') {
        term = term.substr(0, term.length - 1);
    }
    result = term.toLowerCase();
    return result;
}

//openurl
function openURL(url,action,event){
    action = (typeof action !== 'undefined') ? action : '';

    if (typeof url != 'undefined' &&  typeof event != 'undefined'){

        if(event.target.tagName.toLowerCase() === 'a' || event.target.tagName.toLowerCase() === 'img' || event.target.tagName.toLowerCase() === 'font'){
            event.target.href;
        }else{
            window.open(url, action)
        }

    }else{
        console.log('openURL missing parameter')
    }
}

//top alert
function top_alert(action,element) {

    action = (typeof action !== 'undefined') ? action : '';
    element = (typeof element !== 'undefined') ? element : '';

    var _navigation = $('#navigation.fixed');
    var nav  = $('#navigation > .navigation-group');


    if ($.cookie(element) == undefined) {
        $('#'+element).show();
        _navigation.css({'padding-top' :  nav.height()});
    }

    if (action == 'close'){
        _navigation.css({'padding-top' : nav.height() - $('#'+element).innerHeight()});
        $('#'+element).hide();
        if(element != ''){
            $.cookie(element, 'yes', { expires: 2, path: '/' });
        }
    }

}

//scrollTop
function go(to, offset, speed) {
    if (typeof to != 'undefined' && (to == '' || $(to).length > 0)) {
        if (typeof offset == 'undefined')
            offset = 0;
        if (typeof speed == 'undefined')
            speed = 0;
        if (to == '')
            to = 0;
        else
            to = $(to).offset().top - offset;
        if (to !== '') {
            $('body, html').animate({
                scrollTop: to
            }, speed);
        }
    } else {
        console.log('scroll error: "' + to + '" does not exist');
    }
}

//notify
function notify(message, type) {
    type = (typeof type !== 'undefined') ? type : 'danger';
    if(typeof message != 'undefined'){
        $.notify({message: message}, {type: type});
    }else{
        console.log('missing parameter')
    }
}

//custom dropdown
function dropdown(action){
    if(typeof action != 'undefined'){
        if (action == 'close'){
            $('.dropdown').removeClass('open')
        }
    }else{
        console.log('missing parameter')
    }
}

//x scroll area
function scroll_X(id){

    if (typeof id != 'undefined') {
        element = document.getElementById(id);
        var el = $('#'+id).find('.x-scroll, .x-scroll-md, x-scroll-sm, .x-scroll-xs'); //init id
        var nav_left = $('#'+id).find('.x-left-nav'); //init id
        var nav_right = $('#'+id).find('.x-right-nav'); //init id
        var windW = $(window).width(); //widow width
        var elW = el[0].scrollWidth; // scroll width

        //check scroll
        if (elW > el.width()) {
            //init position
            el.parent().addClass('left');
            el.on('scroll', function() {

                var scroll = el.scrollLeft();

                if ((scroll) < 10){
                    el.parent().removeClass('scrolling right').addClass('left');
                }else if((scroll + windW)  > (elW - 10)){
                    el.parent().removeClass('scrolling left').addClass('right');
                }else{
                    el.parent().addClass('scrolling').removeClass('right left');
                }
            });
        }else{
            el.parent().removeClass('scrolling left right')
        }

        //action
        var current_ = '';
        $(nav_left).on('click',function(){
            current_ = $(el).scrollLeft();
            //$(el).scrollLeft(current_ - 420);
            $(el).animate({scrollLeft: current_ - 420},100);
        });
        $(nav_right).on('click',function(){
            current_ = $(el).scrollLeft();
            //$(el).scrollLeft(current_ + 420);
            $(el).animate({scrollLeft: current_ + 420},100);
        });
    }else{
        console.log('missing parameter');
    }
}

// affix jobtitle
function jobtitleaffix(action){

    if (typeof action == 'undefined') {
        var job_title_float = $('#job-detail-title');
        var header = $('#header').innerHeight();
        var navigation = $('#navigation').innerHeight();
        var job_detail = $('#job-detail').innerHeight();
        var offset = 40;

        if ($(window).width() < 768) {
            offset = 20;
        }

        //init position on load
        job_title_float.css({'top': navigation});

        //fixed
        job_title_float.on('affixed.bs.affix', function(){
           $(this).css({'top': navigation });
        });

        //top
        job_title_float.on('affixed-top.bs.affix', function(){
           $(this).css({'top': 'auto' });
        });
    }else{
        console.log('undefined missing parameter');
    }
}

// is fb app
function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

// is fb app
function isInstagramApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return  (ua.indexOf('Instagram') > -1) ? true : false;
}

$(document).ready(function(){
    $('#send-report').on('show.bs.modal', function(){
      // Contoh clear bagian report message
      $('#_report_problem_type').prop('selectedIndex','Cannot upload photo');
      $('#_report_message').val('');
      $('.all_reason input:checkbox').prop('checked', false);
      $('#issue-preview-wrap').hide();
      $('#issue_filename').val('');
      $('#issue-upload').show();
      $('id-img').attr('src', '');
    });
  });

//submit report
function sendReportIssue(btn) {
    var $btn = $(btn);
    var validator = $('#_form_send_report');
    validator.validate({
        rules: {
            problem_type: {required:true},
            message: {required:true},
            reason: {required:true},
            name: {required:true},
            phone: {required:true}
        },
        messages: {
            problem_type: {required:'Choose problem type'},
            message: {required:'Enter what was the problem that your encounter'},
            reason: {required:'Choose the reason, You can choose multiple reason'},
            name: {required:'Enter your name'},
            phone: {required:'Enter your phone'}
        }
    });

    //validator.form();

    if (validator.valid()){

        $btn.button("loading");

        //get message
        var messages = '';
        var name = $('#_report_name').val();
        var phone = $('#_report_phone').val();
        var reason = $('.all_reason input[type=checkbox]:checked').map(function() {return this.value;}).get().join(',');
        var message = $('#_report_message').val();

        if (reason === '' || reason === undefined){
            reason = '';
        }else{
            reason = "\n\n" + 'Reason: ' + reason;
        }

        if (name === '' || name === undefined){
            name = '';
        }else{
            name = "\n\n" + 'Name: ' + name;
        }

        if (phone === '' || phone === undefined){
            phone = '';
        }else{
            phone = "\n\n" + 'Phone: ' + phone;
        }

        messages = message + reason + name + phone;

        var jsonData = new FormData($('#_form_send_report')[0]);
        jsonData.append('message', messages);

        // for (var pair of jsonData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }

        var request = $.ajax({
            url: canvas_url + "api/issues/create",
            data: jsonData,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            processData: false,
            contentType: false,
        });

        request.done(function (data) {
            if(data.status == 'success'){
                $('#send-report').modal('toggle');
                $.notify('Report Submitted!', {type: "success"});
                $btn.button("reset");
            }else{
                $.notify(data.message, {type: 'danger'});
                $btn.button("reset");
            }
        });

        request.fail(function (jqXHR, textStatus) {
            event.preventDefault();
            $btn.button("reset");
            showErrorNotification(jqXHR.status)
        });

    }
}

//send message
$(".send-message").bind("click", function () {

    var full_name = $("#full_name").val();
    var register_phone = $("#register_phone").intlTelInput("getNumber");
    register_phone = register_phone.replace("+", '');
    var email_address = $("#email").val();
    var personal_description = $("#personal_description").val();
    var btn = $(".send-message");
    var _this = $(this);
    var filter = /^([\w-.]+)@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(([\w-]+.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/;
    var isRegisterPhoneValidNumber = $("#register_phone").intlTelInput("isValidNumber");

    if (full_name == '') {
        _this.parents('.modal-body').find('#full_name').addClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-fullname').show();
    return;
    } else {
        _this.parents('.modal-body').find('#full_name').removeClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-fullname').hide();
    }

    if (register_phone == '' || !isRegisterPhoneValidNumber) {
        _this.parents('.modal-body').find('#register_phone').addClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-phone').show();
    return;
    } else {
        _this.parents('.modal-body').find('#register_phone').removeClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-phone').hide();
    }

    if (email_address == '' || !filter.test(email_address)) {
        _this.parents('.modal-body').find('#email').addClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-email').show();
    return;
    } else {
        _this.parents('.modal-body').find('#email').removeClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-email').hide();
    }

    if (personal_description == '') {
        _this.parents('.modal-body').find('#personal_description').addClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-desc').show();
    return;
    } else {
        _this.parents('.modal-body').find('#personal_description').removeClass('input-error');
        _this.parents('.modal-body').find('.alert.alert-desc').hide();
    }

    btn.button("loading");
    $(".send-message").addClass("disabled");

        var jsonData = {
        full_name: full_name,
        register_phone: register_phone,
        email_address: email_address,
        personal_description: personal_description,
        '_token': $('meta[name="csrf-token"]').attr('content'),
        '_method': "POST"
        };

        var request = $.ajax({
        url: canvas_url + 'api/submitfeedback',
        data: jsonData,
        type: 'POST',
        crossDomain: true,
        dataType: 'json'
        });

    request.done(function (data) {
    if (data.status == 'success') {
        $('#customer_modal').modal('hide');
        $('#feedback-message').modal('show');
    } else {
        btn.button('reset');
        $(".send-message").removeClass("disabled");
        // _this.parents('.modal-body').find('.alert').html('Something Wrong');
        // _this.parents('.modal-body').find('.alert').show();
    }
    });

    btn.button('reset');

    request.fail(function (jqXHR, textStatus, errorThrown) {
    btn.button('reset');
        $(".send-message").removeClass("disabled");
        // _this.parents('.modal-body').find('.alert').html('Something Wrong');
        // _this.parents('.modal-body').find('.alert').show();
    });
});

//submit report
function sendReportJob(btn) {
    var $btn = $(btn);
    var validator = $('#_form_send_report_job');

    validator.validate({
        rules: {
            message: {
                required: true,
                minlength:10,
                maxlength:500
            },
            reason_id: {required:true},
            job_id: {
                required:  function() {
                    return  type == abuseReport.type.job
                }
            },
            type: {required:true},
            company_id: {
                required:  function() {
                    return  type == abuseReport.type.company
                }
            },
        },
        messages: {
            message: {required:'Please add some words', minlength: "Please add more words", maxlength: "Your description is too long"},
            reason_id: {required:''},
            job_id: {required:'Missing parameter'},
            company_id: {required:'Missing parameter'},
            type: {required:'Missing parameter'},
        }
    });

    //validator.form();

    if (validator.valid()){

        $btn.button("loading");

        //get message
        var type = $('#report_type').val();
        var jobID = $('#report_job_id').val();
        var companyID = $('#report_company_id').val();
        var reportedID = type == abuseReport.type.job ? jobID : companyID;
        var jsonData = new FormData($('#_form_send_report_job')[0]);
        jsonData.append('reported_id', reportedID);
        jsonData.delete('job_id');
        jsonData.delete('company_id');

        var request = $.ajax({
            url: canvas_url + "api/abuse-report/store",
            data: jsonData,
            type: 'POST',
            crossDomain: true,
            dataType: 'json',
            processData: false,
            contentType: false,
        });

        request.done(function (data) {
            if(data.success === true){
                sendJobIdSocialCredit(SC_JOB_DETAIL_REPORT, jobID , 'job');
                $btn.button("reset");
                reportJobNotification('success');

                var $element = $("#report-job-" + jobID)
                $element.addClass("reported-job-button");
                $element.addClass("disabled");
                $element.removeClass("report-job-btn");
                $element.innerText = txt_report_job.reported

            }else{
                reportJobNotification('error');
                $btn.button("reset");
            }
        });

        request.fail(function (jqXHR, textStatus) {
            event.preventDefault();
            reportJobNotification('error');
            $btn.button("reset");
        });

    }
}


function reportJobNotification(type){

    $('.modal').modal('hide');

    var title = txt_report_job.notifications.failed
    var text  = txt_report_job.notifications.failed_description

    if(typeof type !== 'undefined' && type == 'success'){
        title = txt_report_job.notifications.success
        text = txt_report_job.notifications.success_description
    }

    swal({
        title: title,
        text: text,
        type: type,
        width: 450,
        confirmButtonText: 'OK',
        confirmButtonClass: 'btn btn-primary',
        buttonsStyling: false
    });
}


function checkReportIssue(reason){
    var validator = $('#_form_send_report').validate().resetForm(); //reset validation
    $('#_report_message').val(''); // resert input
    $('.all_reason input[type=checkbox]').attr('checked', false); //reset checkbox
    $('.all_reason').hide(); // hide all reason

    if( reason === 'Cannot upload resume'){
        $('#resume_reason').show();
    }
}


function showErrorNotification(type) {

    type =  errrorTypes[type];
    if(typeof type === 'undefined'){
        type = errrorTypes.other
    }

    if(type == errrorTypes["401"]){
        $('.modal').modal('hide');
        $('#' + modalIDs.login).modal();
    }else{
        $.notify(txt_got_error+" Or you have Internet Connection Issue.", {type: 'danger'});
    }

}

// function to escape quote
// reference: http://locutus.io/php/strings/addslashes/
function addslashes (str) {
    return (str + '')
        .replace(/[\\"']/g, '\\$&')
        .replace(/\u0000/g, '\\0')
}


function isUrl(string) {
    return matcher.test(string);
}

//set cookie
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//get cookie
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function deleteCookie(cname) {
    var d = new Date();
    d.setTime(d.getTime() - (1000*60*60*24));
    var expires = "expires=" + d.toGMTString();
    window.document.cookie = cname+"="+"; "+expires;

}

function redirectOnboarding()
{
  var urlpage = getCookie('OnboardingRequest');
  if(urlpage != '' || urlpage != '/')
  {
      deleteCookie('OnboardingRequest');
      window.location = canvas_url + urlpage;
  }
  else
  {
      window.location = canvas_url;
  }
}


/**
 *
 * @param endtime : timestamp
 * @returns {{total: number, days: number, hours: number, minutes: number, seconds: number}}
 */
function getTimeRemaining(endtime){
    var now = (Date.parse(new Date())) / 1000
    var t = endtime - now;
    var seconds = Math.floor( (t) % 60 );
    var minutes = Math.floor( (t/60) % 60 );
    var hours = Math.floor((t/(60*60)));
    var days = Math.floor( t/(60*60*24));
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}


function startTimer( elementID, endTime) {
    if(typeof endTime === 'undefined'){
        endTime = getTomorrow();
    }

    var clock = document.getElementById(elementID);
    var timeinterval = setInterval(function(){
        var t = getTimeRemaining(endTime);
        clock.style.display = 'block';;
        clock.innerHTML =  t.hours + ' hours '+  t.minutes + ' minutes';

        if(t.total<=0){
            clearInterval(timeinterval);
        }
    },1000);
}

function getTomorrow() {

    var today = new Date();
    today.setHours(0,0,0,0);
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate()+1)
    tomorrow.setHours(0,0,0,0);

    return tomorrow.getTime()/1000;
}

function goToLatestHistory(){
    var latestHistory = localStorage.getItem(storageKey.browseHistory);
    
    if(typeof latestHistory === 'undefined' || latestHistory== null){
        latestHistory =  canvas_url;
    }else{
        latestHistory = canvas_url + latestHistory
    }

    window.open (latestHistory);
}

// remove duplicate array
function removeDuplicates(arr){
    return arr.filter(function(item, index){
        return arr.indexOf(item) >= index;
    });
}

//on ready
$(document).ready(function () {

	$('body').on('click', '.login-for-salary', function (e) {
        trackEvent({event: 'click', key: 'Login for salary', value: 'Login for salary'});
        showLoginModal(e);
    });

	$(".fb-share-button").bind("click", function () {
        var id = $(this).attr('data-id');
        var url = $(this).attr('data-url');
        FB.ui({
            method: 'share',
            href: url, //'http://54.169.110.239/job/464-social-media-customer-service-specialist',
        }, function (response) {});

        sendSCEvent(scEvents.share_job, id);
    });

    $(".twitter-share-button").bind("click", function () {
        var id = $(this).attr('data-id');
        var url = $(this).attr('data-url');
        var text = $(this).attr('data-title');

        window.open("http://twitter.com/intent/tweet?text=" + text + " via @ricebowl&url=" + url, "twittershare", "location=1,status=0,scrollbars=0,resizable=yes,width=626,height=436");

        $.get(canvas_url + "api/admin/blogpost/sharelink", {'seo_link': url, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "GET"}, function (data) {
            if (data.status == 'success')
            {
                var short_url = data.data;
                window.open("http://twitter.com/intent/tweet?text=" + text + " via @ricebowl&url=" + short_url, "twittershare");
            }
        }, "json");
    });

    $(".miniicon-btn").click(function (e) {
        var type  = $(this).attr("data-type");
        sendGaEvent(ACTION_MINI_ICON, GA_CATEGORY_CLICK, type);
    });

	$('.create_newresume').click(function (e) {
        e.preventDefault(); //prevent the page from jolting up to the top
        var $btn = $(this);
        var apply_id = $("#cp").val();
        var ic_number = $("#ic_number").val();
        var id_type = $("#id_type option:selected").val();
        var gender = $("#gender").val();
        var nationality = $("#nationality option:selected").text();
        var base_country = $("#base_country").val();
        var highest_education = $("#highest_education").val();
        var expected_salary_curr = $("#expected_salary_curr").val();
        var expected_salary = $("#expected_salary").val();
        var first_time_working = $("#first_time_working").val();
        var currently_working = $("#currently_working").val();
        var current_company_name = $("#current_company_name").val();
        var current_title = $("#current_title").val();
        var current_salary_curr = $("#current_salary_curr").val();
        var current_salary = $("#current_salary").val();
        var previous_company_name = $("#previous_company_name").val();
        var previous_title = $("#previous_title").val();
        var previous_salary_curr = $("#previous_salary_curr").val();
        var previous_salary = $("#previous_salary").val();
        var english_level = $('input[name="english_level"]:checked').val();
        var bs_level = $('input[name="bs_level"]:checked').val();
        var mandarin_level = $('input[name="mandarin_level"]:checked').val();
        var tamil_level = $('input[name="tamil_level"]:checked').val();
        var photo_filename = $("#photo_filename").val();
        var resume_filename = $("#resume_filename").val();

        $("#ic_number").parent().removeClass('error');
        $("#highest_education").parent().removeClass('error');
        $("#nationality").parent().removeClass('error');
        $("#expected_salary").parent().removeClass('error');
        $("#first_time_working").parent().removeClass('error');
        $("#currently_working").parent().removeClass('error');
        $("#current_company_name").parent().removeClass('error');
        $("#current_title").parent().removeClass('error');
        $("#current_salary").parent().removeClass('error');
        $("#previous_company_name").parent().removeClass('error');
        $("#previous_title").parent().removeClass('error');
        $("#previous_salary").parent().removeClass('error');

        if (ic_number == '') {
            $("#ic_number").parent().addClass('error');
            //$("#ic_number").focus();
            go('#ic_number', 150, 500);
            $.notify(notify_msg.required.ic_number);
            return false;
        } else if (nationality == '0' || nationality == txt_select_one) {
            $("#nationality").parent().addClass('error');
            //$("#nationality").focus();
            go('#nationality', 150, 500);
            $.notify(notify_msg.required.nationality);
            return false;
        } else if (highest_education == '') {
            $("#highest_education").parent().addClass('error');
            //$("#nationality").focus();
            go('#highest_education', 150, 500);
            $.notify(notify_msg.required.highest_education);
            return false;
        } else if (expected_salary == '') {
            $("#expected_salary").parent().addClass('error');
            //$("#expected_salary").focus();
            go('#expected_salary', 150, 500);
            $.notify(notify_msg.required.expected_salary);
            return false;
        } else if (first_time_working == '') {
            $("#first_time_working").parent().addClass('error');
            //$("#current_company_name").focus();
            go('#first_time_working', 150, 500);
            $.notify(notify_msg.required.first_time_working);
            return false;
        } else if (first_time_working == 'No' && currently_working == '') {
            $("#currently_working").parent().addClass('error');
            //$("#current_company_name").focus();
            go('#currently_working', 150, 500);
            $.notify(notify_msg.required.currently_working);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'Yes' && current_company_name == '') {
            $("#current_company_name").parent().addClass('error');
            //$("#current_company_name").focus();
            go('#current_company_name', 150, 500);
            $.notify(notify_msg.required.company_name);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'Yes' && current_title == '') {
            $("#current_title").parent().addClass('error');
            //$("#current_title").focus();
            go('#current_title', 150, 500);
            $.notify(notify_msg.required.job_title);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'Yes' && current_salary == '') {
            $("#current_salary").parent().addClass('error');
            //$("#current_salary").focus();
            go('#current_salary', 150, 500);
            $.notify(notify_msg.required.salary);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'No' && previous_company_name == '') {
            $("#previous_company_name").parent().addClass('error');
            //$("#previous_company_name").focus();
            go('#previous_company_name', 150, 500);
            $.notify(notify_msg.required.company_name);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'No' && previous_title == '') {
            $("#previous_title").parent().addClass('error');
            //$("#previous_title").focus();
            go('#previous_title', 150, 500);
            $.notify(notify_msg.required.job_title);
            return false;
        } else if (first_time_working == 'No' && currently_working == 'No' && previous_salary == '') {
            $("#previous_salary").parent().addClass('error');
            //$("#previous_salary").focus();
            go('#previous_salary', 150, 500);
            $.notify(notify_msg.required.salary);
            return false;
        } else {
            $btn.button("loading");
            $.post(canvas_url + "api/jobseeker/createnewresume", {
                'apply_id': apply_id,
                'ic_number': ic_number,
                'id_type': id_type,
                'nationality': nationality,
                'base_country': base_country,
                'gender': gender,
                'highest_education': highest_education,
                'expected_salary_curr': expected_salary_curr,
                'expected_salary': expected_salary,
                'first_time_working': first_time_working,
                'currently_working': currently_working,
                'current_company_name': current_company_name,
                'current_title': current_title,
                'current_salary_curr': current_salary_curr,
                'current_salary': current_salary,
                'previous_company_name': previous_company_name,
                'previous_title': previous_title,
                'previous_salary_curr': previous_salary_curr,
                'previous_salary': previous_salary,
                'photo_filename': photo_filename,
                'english_level': english_level,
                'bs_level': bs_level,
                'mandarin_level': mandarin_level,
                'tamil_level': tamil_level,
                'resume_filename': resume_filename,
                '_token': $('meta[name="csrf-token"]').attr('content'),
                '_method': "POST"
            },
                    function (data) {
                        if (data.status == 'success') {
                            /*swal({
                             title: txt_done_label,
                             text: txt_resume_upload_successfully,
                             type: "success",
                             html: true,
                             showConfirmButton: true
                             }, function(isConfirm){
                             window.location = canvas_url+"jobseeker/account?resumeuploaded=yes";
                             });*/
                            $.notify(txt_resume_upload_successfully, {type: 'success'});
                            setTimeout(function () {
                                $btn.button('reset');
                                window.location = canvas_url + "jobseeker/resumes?resumeuploaded=yes";
                            }, timeout_redirect);
                        } else {
                            //swal("Opps!", data.msg, "warning");
                            $.notify(data.msg);
                            $btn.button('reset');
                        }
                    }, "json");
        }
    });

    $(".submit_replyemployer").bind("click", function () {

        var vc_code = $("#vc_code").val();
        var replymessage = $("#replymessage").val();
        var interested_check = $("#interested_check").val();
        var interested = $(".interested").is(':checked');
        if (interested)
            interested = 'yes';
        else
            interested = 'no';
        var $btn = $(this);

        $("#replymessage").parent().removeClass("error");

        if (interested == 'no' && interested_check == 'no')
        {
            $('#jobseekernointerested').modal('show');
            return false;
        } else
        {
            $('#jobseekernointerested').modal('hide');
        }

        if (replymessage == '') {
            $("#replymessage").parent().addClass("error");
            $("#replymessage").focus();
            $.notify({message: 'Please enter your message'}, {type: "danger"});
            return false;
        } else {

            $("#replymessage").parent().removeClass("error");
            $("#spinner").show();
            $btn.button("loading");
            $.post(canvas_url + "api/jobseeker/replyemployer",
                    {'replymessage': replymessage, 'vc_code': vc_code, 'interested': interested, '_token': $('meta[name="csrf-token"]').attr('content')}, function (data) {
                if (data.status == 'success') {
                    $.notify({message: 'Your message has been sent to employer. Thank you.'}, {type: 'success'});
                    setTimeout(function () {
                        window.location = canvas_url;
                    }, timeout_redirect);
                } else {
                    $("#spinner").hide();
                    $btn.button('reset');
                    $.notify({message: 'There is some error'}, {type: 'danger'});
                }
            }, "json");
        }
    });

	$('.resendverifyemail').click(function () {
        $.post(canvas_url + "api/jobseeker/resendverifyemail", {'_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
            if (data.status == 'success') {
                $(".verify-msg").fadeIn()
                $(".verify-msg").html(data.msg);
            } else
            {
                $(".verify-msg").fadeOut()
            }
        }, "json");
    });

	$(".submit_replyemployersayhi").bind("click", function () {

        var vc_code = $("#vc_code").val();
        var expected_salary_cur = $("#expected_salary_cur").val();
        var expected_salary = $("#expected_salary").val();
        var resume_id = $("#resume_id").val();
        var resume_check = $('input[name=resume_check]:checked').val();
        var cover_letter = $("#cover_letter").val();
        var cover_letter_english = $("#cover_letter_english").val();

        var $btn = $(this);

        if ($(this).hasClass("disabled")) {
            return false;
        } else {
            if (expected_salary == '')
            {
                $("#expected_salary").parent().addClass('error');
                go('#expected_salary', 150, 500);
                $.notify(notify_msg.required.expected_salary);
                return false;
            } else if (!$.isNumeric(expected_salary)) {
                $("#expected_salary").parent().addClass('error');
                go('#expected_salary', 150, 500);
                $.notify(notify_msg.invalid.salary);
                return false;
            } else if (resume_id == '0') {
                $("#resume_id").parent().addClass('error');
                //$("#resume_id").focus();
                go('#resume_id', 150, 500);
                $.notify(txt_plz_upload_resume);
                return false;
            } else if (resume_id == 'na' && typeof resume_check == 'undefined') {
                $("#resume_id").parent().addClass('error');
                go('#resume_id', 150, 500);
                $.notify(notify_msg.required.no_resume_reason);
                return false;
            } else if (cover_letter == '') {
                $("#cover_letter").parent().addClass('error');
                go('#cover_letter', 150, 500);
                $.notify(notify_msg.required.coverletter);
                return false;
            } else
            {
                $("#spinner").show();
                $btn.button("loading");
                $.post(canvas_url + "api/jobseeker/replyemployersayhi",
                        {'vc_code': vc_code, 'expected_salary_cur': expected_salary_cur, 'expected_salary': expected_salary, 'resume_id': resume_id, 'resume_check': resume_check, 'cover_letter': cover_letter, 'cover_letter_english': cover_letter_english, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                    if (data.status == 'success') {
                        $.notify({message: 'Your message has been sent to employer. Thank you.'}, {type: 'success'});
                        setTimeout(function () {
                            window.location = canvas_url;
                        }, timeout_redirect);
                    } else {
                        $("#spinner").hide();
                        $btn.button('reset');
                        $.notify({message: 'There is some error'}, {type: 'danger'});
                    }
                }, "json");
            }
        }
    });

	$(".request_resumebtn").bind("click", function () {
        $(".request_resumebtn").fadeOut();
        var cp = $("#cp").val();
        $.post(canvas_url + "api/jobseeker/resumerequest", {'cp': cp, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
            if (data.status == 'success') {
                $.notify($(".request_success_msg").text(), {type: 'success'});
            } else {
                $.notify(data.msg);
            }
        }, "json");
    });

	$(".submit_referchecked").bind("click", function () {

        var vc_code = $("#vc_code").val();
		var know_person = $('input[name="know_person"]:checked').val();
		var info_correct = $('input[name="info_correct"]:checked').val();
		var recommended = $('input[name="recommended"]:checked').val();
        var details = $("#details").val();

        var $btn = $(this);

        $("#know_person").parent().removeClass("error");
		$("#info_correct").parent().removeClass("error");
		$("#recommended").parent().removeClass("error");
		$("#details").parent().removeClass("error");

		if (typeof know_person=='undefined')
		{
			$("#know_person").parent().addClass("error");
            $("#know_person").focus();
            $.notify({message: 'Please let us know if you know this person'}, {type: "danger"});
            return false;
		}
		else if (typeof info_correct=='undefined')
		{
			$("#info_correct").parent().addClass("error");
            $("#info_correct").focus();
            $.notify({message: 'Please let us know if this information correct or not'}, {type: "danger"});
            return false;
		}
		else if (typeof recommended=='undefined')
		{
			$("#recommended").parent().addClass("error");
            $("#recommended").focus();
            $.notify({message: 'Please let us know if you recommend us to this person'}, {type: "danger"});
            return false;
		}
		else if (details=='')
		{
			$("#details").parent().addClass("error");
            $("#details").focus();
            $.notify({message: 'Please let us know something about this person'}, {type: "danger"});
            return false;
        } else {

            $("#know_person").parent().removeClass("error");
			$("#info_correct").parent().removeClass("error");
			$("#recommended").parent().removeClass("error");
			$("#details").parent().removeClass("error");

			$("#spinner").show();
            $btn.button("loading");
            $.post(canvas_url + "api/jobseeker/referencechecked",
                    {'know_person': know_person, 'vc': vc_code, 'info_correct': info_correct, 'recommended': recommended, 'details': details, '_token': $('meta[name="csrf-token"]').attr('content')}, function (data) {
                if (data.status == 'success') {
                    $.notify({message: 'Thank you for your time. Really Appreciated. This will help applicant to get better jobs. Thanks.'}, {type: 'success'});
                    setTimeout(function () {
                        window.location = canvas_url;
                    }, timeout_redirect);
                } else {
                    $("#spinner").hide();
                    $btn.button('reset');
                    $.notify({message: 'There is some error: '+data.msg}, {type: 'danger'});
                }
            }, "json");
        }
    });

	$(".send_email").bind("click", function () {
        var email_address = $("#contact_email_address").val();
        var contact_no = cleanPhone($("#contact_no").val());
        var msg = $("#msg").val();
        var name = $("#name").val();
        var package_id = $("#package_id").val();
        var $btn = $(this);

        if (name == '') {
            $("#name").parent().addClass("error");
            go('#name', 150, 500);
            $.notify(txt_plz_enter_name);
            return false;
        } else if (email_address == '') {
            $("#contact_email_address").parent().addClass("error");
            go('#name', 150, 500);
            $.notify(txt_email_address_error);
            return false;
        } else if (contact_no == '') {
            $("#contact_no").parent().addClass("error");
            go('#name', 150, 500);
            $.notify(txt_enter_phone_number);
            return false;
        } else if (msg == '') {
            $("#msg").parent().addClass("error");
            go('#msg', 150, 500);
            $.notify(txt_enter_message);
            return false;
        } else {
            $btn.button("loading");
            $.post(canvas_url + "api/emailcontact", {'email_address': email_address, 'contact_no': contact_no, 'msg': msg, 'name': name, 'package_id': package_id, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                if (data.status == 'success') {
                   $.notify(txt_sent_msg_success, {type: 'success'});
                    $btn.button('reset');
                } else {
                    //swal("Opps!", txt_got_error+" "+data.msg, "warning");
                    $.notify(data.msg, {type: 'danger'});
                    $btn.button('reset');
                }
            }, "json");
        }
    });

    $(".unsubscribe-btn").bind("click", function () {
        var email_address = $("#email_address").val();
        var contact_no = $("#contact_no").val();
        var $btn = $(this);

        if (email_address == '') {
            $.notify(txt_email_address_error);
            return false;
        } else {
            $btn.button("loading");
            $.post(canvas_url + "api/jobseeker/jobalert-unsubscribe", {'email_address': email_address, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                if (data.status == 'success') {
                    $.notify("You have successfully unsubscribed job alert", {type: 'success'});
                    $btn.button('reset');
                    window.location = canvas_url;
                } else {
                    //swal("Opps!", txt_got_error+" "+data.msg, "warning");
                    $.notify(data.msg, {type: 'danger'});
                    $btn.button('reset');
                }
            }, "json");
        }
    });

    $.getRecommendedJobs = function ()
    {
        var pagename = $("#pagename").val();
        var job_id = $("#job_id").val();

        var resultDiv = '';
        var jsonData = {
            'pagename': pagename,
            'job_id': job_id,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/recommendedjobs",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        request.done(function (data) {

            if (data.status === 'success')
            {
                var jobs = data.data;
                var count = jobs.length;
                for (var i = 0; i < count; i++)
                {
                    resultDiv += '<a href="' + jobs[i].joburl + '" target="_blank" onclick="ga(\'send\',\'event\',\'Recommendations\',\'' + pagename + '\');">\
                                                        <div class="xs-pt-10 xs-pb-10 b-top">\
                                    <p class="text-bold text-dark xs-mb-0">' + jobs[i].job_title + '</p>\
                                    <p class="text-grey xs-mb-0 text-ellipsis">' + jobs[i].company_name + '</p>\
                                    <p class="xs-mb-0 text-primary"><i class="fa fa-usd"></i> ' + jobs[i].salary + '</p>\
                                    <p class="xs-mb-0 text-grey"><i class="fa fa-map-marker"></i> ' + jobs[i].location + '</p>\
                                </div></a>';
                }

                $("#recommendedjobs").html(resultDiv);
                $("#recommendedjobs_div .recommendedjobs_head").html(data.title);
                $("#recommendedjobs_div").show();

            } else
            {
                $("#recommendedjobs_div").hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {
            $("#recommendedjobs_div").hide();
        });
    };

    $.getRecommendedJobsList = function (callback)
    {
        // console.log('ola');
        var pagename = $("#pagename").val();
        var job_id = $("#job_id").val();
        var xstate = $("#state-filter").val();

        var _isLogin  = typeof isLogin  !== 'undefined' ? isLogin :  0;

        var resultDiv = '';
        var jsonData = {
            'pagename': pagename,
            'job_id': job_id,
            'isLogin': _isLogin,
            'state': xstate,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/recommendedjobs",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        request.done(function (data) {
            // console.log(data);

            var result = false
            if (data.status === 'success')
            {
                result = data;
            }
            callback(result);
        });

        request.fail(function (jqXHR, textStatus) {
            return false
        });
    };

    $.getSimilarJobsByCompany = function (companyID, jobID)
    {
        var pagename = $("#pagename").val();

        var resultDiv = '';
        var jsonData = {
            'companyID': companyID,
            'jobID': jobID,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "POST"
        };

        var request = $.ajax({
            url: canvas_url + "api/recommendations/company-jobs",
            data: jsonData,
            type: 'POST',
            crossDomain: true,
            dataType: 'json'
        });

        var recommendationsEl = $("#recommended-companyjobs");
        var recommendationsDiv = $("#recommended-companyjobs-div");
        var recommendationsTotal = $("#companyjobs-total");

        request.done(function (data) {
            if (data.success == true)
            {
                if(data.data.total == 0){
                    recommendationsDiv.hide();
                    return false;
                }
                var jobs = data.data.jobs;

                var count = jobs.length;
                for (var i = 0; i < count; i++)
                {
                    resultDiv += '<a href="' + jobs[i].url + '" target="_blank" onclick="ga(\'send\',\'event\',\'Recommendations\',\'' + pagename + '\'); sendJobIdSocialCredit(\SC_JOB_DETAIL_COMPANY_JOB_CLICK\,\'' + jobs[i].job_id + '\' , \'job\');">\
                                                        <div class="xs-pt-10 xs-pb-10 b-top">\
                                    <p class="text-bold text-dark xs-mb-0">' + jobs[i].job_title + '</p>\
                                    <p class="text-grey xs-mb-0 text-ellipsis">' + jobs[i].company_name + '</p>\
                                    <p class="xs-mb-0 text-primary"><i class="fa fa-usd"></i> ' + jobs[i].salary + '</p>\
                                    <p class="xs-mb-0 text-grey"><i class="fa fa-map-marker"></i> ' + jobs[i].location + '</p>\
                                </div></a>';
                }

                recommendationsTotal.html('(' + data.data.total + ')');
                recommendationsEl.html(resultDiv);
                recommendationsDiv.show();

            } else
            {
                recommendationsDiv.hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {
            recommendationsDiv.hide();
        });
    };

    //load page
    $('#page-load').hide();
    $('#page-ready').show();
    top_alert('','app-banner','app-banner');//show app promo
    top_alert('','profile-alert','profile-alert'); //show profile alert

    //intialize button loading state
    $('button').button({loadingText: '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i> Loading...'});
    $('label').button({loadingText: '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i> Loading...'});

    //init default tooltip and popover
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    //dropdown-static toggle
    $('[data-toggle="dropdown-static"]').on('click', function(e){
        if ($(this).parent().hasClass('open')) {
            $(this).parent().removeClass('open');
        }else{
            $('[data-toggle="dropdown-static"]').parent().removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    //dropdown-static close
    $('body').on('click', function (e) {

        if( !e ) e = window.event;

        if (!$('[data-toggle="dropdown-static"]').is(e.target||e.srcElement)
            && $('[data-toggle="dropdown-static"]').has(e.target||e.srcElement).length === 0
            && $('.open').has(e.target||e.srcElement).length === 0
        ) {
            $('[data-toggle="dropdown-static"]').parent().removeClass('open');
        }
    });


	/**
	 * Resume
	 */
	$('.upload_resume').click(function(e){

		try{
			var $btn =  $(this);
			$btn.button("loading");

      $('.upload-resume-loading-title').html('Please Wait...');
      $(".progress-bar").css("width", "0%").attr("aria-valuenow", 0);
			// validate file size
            if(!$.validFileSize("resume_file", 5)){
				$btn.button("reset");
				$.notify(txt_resume_file_max, {type: 'danger'});
				return false;
			}

			$('#upload-resume-ui').addClass('load');
			var myFile = $('#resume_file').prop('files')[0];
			var element = $(this).parents('.upload-resume-container').find('.form_submit_jsresume');
			var formData = new FormData(element[0]);
			var token = $('meta[name="csrf-token"]').attr('content');
			formData.append('_token', token);

            var request = $.ajax({
				url: canvas_url + "api/jobseeker/upload-resume",
				data: formData,
				type: 'POST',
				crossDomain: true,
				dataType: 'json',
				processData: false,
				contentType: false,
        xhr: function() {
          var jqXHR = null;
          if (window.ActiveXObject) {
            jqXHR = new window.ActiveXObject("Microsoft.XMLHTTP");
          } else {
            jqXHR = new window.XMLHttpRequest();
          }
          jqXHR.upload.addEventListener("progress", function(evt) {
              if (evt.lengthComputable) {
                var percentComplete = Math.round((evt.loaded * 100) / evt.total);
                $(".progress-bar").css("width", percentComplete + "%").attr("aria-valuenow", percentComplete);
                if (percentComplete == 100) {
                  $('.upload-resume-loading-title').html('Processing Document...');
                }
              }
            }, false);
          return jqXHR;
        }
			});

			var isRequestResume  = $('.jobseeker_uploadresume').length > 0;

			request.done(function (data) {
				if (data.success == true)
				{
					if(!isRequestResume){
						$.notify(txt_success, {type: 'success'});
					}

					$(".modal").modal("hide"); //hide modal
                    $('#resume-preview-wrap').hide(); //reset form
                    $('#resume-upload').show(); //reset form
                    $('#resume_file,#resume_title').val(''); //reset form

                    var pageName = $('#pagename').val();

                    var homePages = ["joblisting", "home"];
					if(pageName && homePages.indexOf(pageName) !== -1) {
                        location.reload();
                    }if($('.jobseeker-timeline-page').length > 0){//timeline page
                        getAllResumes(); //update on timeline page
                    }else if($('.jobseeker-resumes-page').length > 0 || $('.jobseeker-profile-page').length > 0){
                        getJobseekerResumes(); //update on resume page
						//window.location = canvas_url + 'jobseeker/resumes';//resume page
					}else if (isRequestResume){// request resume
						var cp = $("#cp").val();
						$.updateApplyJobResume(cp, data.resumeID);
					}else{
						$.getJobseekerUploadedResumes(data.resumeID.toString()); //apply job  page
					}

					$btn.button("reset");
                    $('#upload-resume-ui').removeClass('load');
                    $('#resume-preview-wrap').hide(); //reset form
                    $('#resume-upload').show(); //reset form
                    $('#resume_file,#resume_title').val(''); //reset form
					return true
				}else{
					$.notify("Oh no, we can't read your file. Please change your file setting or upload resume from another document.", {type: 'danger'});
					$btn.button("reset");
                    $('#upload-resume-ui').removeClass('load');
                    $('#resume-preview-wrap').hide(); //reset form
                    $('#resume-upload').show(); //reset form
                    $('#resume_file,#resume_title').val(''); //reset form
				}

				return false

			});

			request.fail(function (jqXHR, textStatus,errorThrown) {
			    $btn.button("reset");
                $('#upload-resume-ui').removeClass('load');

				sendErrorEvent(errorThrown, [ERROR_FINGERPRINT_UPLOAD_RESUME]);

				showErrorNotification(jqXHR.status)
				return false;
			});
		}catch(ex){
            sendErrorEvent(ex, [ERROR_FINGERPRINT_UPLOAD_RESUME]);
		}

	});

	/**
	 *
	 * @param elementID => id of input file
	 * @param maxSize => maximum size in MB
	 * @returns {boolean}
	 */
	$.validFileSize = function (elementID, maxSize)
	{
		if(typeof elementID === 'undefined'){
			return true;
		}

		if(typeof maxSize === 'undefined'){
			var maxSize = 0;
		}

		try{
			var el = document.getElementById(elementID);
			if ( !el || !el.files || !el.files[0]) {
				return true;
			}
			return el.files[0].size <=  (1024 * 1024 * maxSize);
		}catch (ex){

		}
		return true;
	}
	$.getJobseekerUploadedResumes = function (resumeID)
    {
        var jsonData = {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/jobseeker/resumes",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });


		request.done(function (data) {
			if(data.success === true){
				var resumes = data.data.resumes;
                var options = "";
				for( i = 0; i< resumes.length; i++){
					var selected = "";
					if(resumeID == resumes[i].id){
                        selected =  'selected="selected"';
					}
					options += '<option '+selected+' value="'+resumes[i].id +'" data-content="<i class=\'fa fa-file-text-o xs-mr-5 \'></i> <span>'+ resumes[i].title +' ( '+resumes[i].uploaded_date+')</span>"></option>';
				}

                $("#resume_id").find('optgroup#recent-resume-group').html(options);

                $("#view-all-resume-option").text(resumeText.view_all_resume + " ("+ data.data.count + ")" ) ;

				// $('#resume_id').html(options);
                $('#resume_id').selectpicker('refresh');

                $('#resume_id').val(resumeID);
				$('#resume_id')
    			.val(resumeID)
    			.trigger('change');


                // set resume type to existing resume
				$('#resume_type').val("existing_resume");
			}
		});
	}

	$.updateApplyJobResume = function (cp, resumeID ){
		var jsonData = {
			cp: cp,
			resume_id: resumeID,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "POST"
        };

        var request = $.ajax({
            url: canvas_url + "api/jobseeker/save-request-resume",
            data: jsonData,
            type: 'POST',
            crossDomain: true,
            dataType: 'json'
        });


		request.done(function (data) {
			if(data.status == 'success'){
				$.notify(txt_success, {type: 'success'});
                var redirect = $("#red").val();
                if(redirect == 'timeline'){
                    window.location = canvas_url + 'jobseeker/timeline/'+cp;
                }else{
                    window.location = canvas_url + 'jobseeker/mypage';
                }
			}else{
				$.notify(txt_got_error, {type: 'danger'});
			}
		});

		request.fail(function (jqXHR, textStatus) {
			$.notify(txt_got_error, {type: 'danger'});
			return false;
		});
	}
	if (isLogin=='0') // User not login
    {
        // do nothing
    } else {
        $.sendFeedback = function(){
            if(Raven.isSetup()){
                try{
                    Raven.showReportDialog();
                }catch(ex){
                    $('#send-report').modal('show');
                }
            }else{
                $('#send-report').modal('show');
            }
        };
    }


    //-------- BANNER ---------//
    var platform_id = $('#imp_platform_id').val();
    var banner_type = $('#banner-type').val();
    var request = $.ajax({
        url: canvas_url+"api/banners?platform="+platform_id+"&type="+banner_type,
        dataType: 'json',
        type: 'GET'
    });

    request.done(function (data) {

        if (data.status == 'success') {

            var banner = $.map(data.data, function(value, index) {
                return [value];
            });

            // descending
            banner.sort(function(a, b){
                return a.id + b.id;
            });

            var template = '';
            var status = '';

            for (var i = 0; i < banner.length; i++) {

                if (banner[i].status == 'active') {
                    status = '';
                }else{
                    status = 'hide'
                }

                /*template = '<a href=" '+banner[i].link_url+' " target="_blank" title=" '+banner[i].alternate+' "  class="'+status+'">\
                        <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="'+banner[i].image_path+'" alt="'+banner[i].alternate+'" width="300px" class="center-block lazy">\
                        </a>';*/

                template = '<div class="banner-carousel w-100p xs-mb-0">\
                                    <a v-for="banner in banners" href="'+ banner[i].link_url + '" target="_blank" class="dashboardpromoclick d-block" " >\
                                        <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + banner[i].image_path + '" alt="' + banner[i].alternate + '" class="center-block lazy w-100p baner height-img"/>\
                                    </a>\
                                </div>\
                            </div>';

                $('#promo_banner').append(template);
            }


            //init promo banner
            $('#promo_banner').slick({
                appendArrows: $('#promo_action'),
                prevArrow: '<a class="promo-action-left"><i class="fa fa-chevron-left"></i></a>',
                nextArrow:'<a class="promo-action-right"><i class="fa fa-chevron-right"></i></a>',
                accessibility: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 8000,
                infinite: true,speed: 500,
                fade: true,
                cssEase: 'linear'
            });

            //check if promo empty then hide
            if($('#promo_banner > .slick-list > .slick-track').children().length < 1){
                $('#promo_banner').parent().hide();
            }else{
                $('#promo_banner').parent().show();
            }

        }else{
            console.log('no banner');
        }
    });


    //-------- JOB TODAY ---------//
    var jsonData = {
        'platform_id' : $('#imp_platform_id').val(),
        '_token': $('meta[name="csrf-token"]').attr('content'),
        '_method': "GET"
    };

    var request = $.ajax({
        url: canvas_url + "api/job/jobToday",
        data: jsonData,
        type: 'GET',
        crossDomain: true,
        dataType: 'json'
    });

    request.done(function (data) {
        sendGaEvent(GA_CATEGORY_VIEW, APPLIED_JOB_TODAY, SHOWN_JOB_TODAY);
        var job = data.data;
        var clogo = '';
        var applied_btn = '';
        var resultDiv = '';

        const jobId = job.job_id;

        if (job.logo != null && job.logo != '') {
            clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job.logo + '" alt="' + job.company_name + ' Logo" class="lazy">';
        } else {
            clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job.category_pic + '" alt="' + job.jobCategory + ' Category" class="lazy">';
        }

        if (job.company_privacy == 'private') {
            clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg" alt="Private Company" class="lazy">';
        }

        applied_btn  = '<a href="' +job.apply_url + '" target="_blank" class="btn btn-primary btn-outline sm-ml-10 xs-ml-0 sm-mt-0 xs-mt-10 w-320-xs w-100p-xs" onclick="sendGaEvent(GA_CATEGORY_CLICK, APPLIED_JOB_TODAY, CLOSE_JOB_TODAY);saveBrowsingHistory(); sendJobIdSocialCredit(\SC_RECOMMENDED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';

        resultDiv ='<div class="container sm-pl-20 sm-pr-20 xs-pl-0 xs-pr-0 xs-pt-10 xs-pb-10">\
                          <div class="group xs-pl-10 xs-pr-10">\
                                 <p class="text-dark text-bold xs-mb-0">'+txt_job_today+'<a class="close pointer" data-dismiss="alert" aria-label="close" onclick="$(\'#job-today\').hide();sendGaEvent(GA_CATEGORY_CLICK, GA_CATEGORY_JOB_TODAY, CLOSE_JOB_TODAY); sendJobIdSocialCredit(\SC_RECOMMENDED_JOB_CLICK\,\'' + jobId + '\' , \'\job\'\);">&times;</a></p>\
                          </div>\
                         <div class="input-group-custom group">\
                            <div class="group md-pr-20 xs-pr-10 xs-pt-5 xs-pb-5">\
                                 <a target="_blank" class="img-thumb img-center" href="' + job.joburl + '">' + clogo + '</a>\
                         </div>\
                         <div class="group auto">\
                            <p class="text-black xs-mb-0"><h4 class=" xs-mt-0 xs-mb-0 text-capitalize text-bold"><a class="text-dark" onclick="sendJobIdSocialCredit(\SC_RECOMMENDED_JOB_CLICK\,\'' + jobId + '\' , \'\job\'\);" target="_blank" href="' +  job.joburl + '"> '+job.job_title+'</a></h4></p>\
                            <p class="text-nowrap pointer text-primary xs-mb-0"><i class="fa fa-usd w-15"></i>'+job.salary+'</p>\
                            <p class="xs-mb-0"><i class="fa fa-map-marker w-15"></i>'+job.region+'</p>\
                         </div>\
                         <div class="group md-mt-0 xs-mt-10 md-pr-0 sm-pl-20 sm-pr-20 xs-pl-0 xs-pr-0 text-center-xs" style="display: flex;">\
                            <div class="row">\
                                <div class="col-lg-reset col-md-reset d-inline-block col-sm-reset col-xs-12 sm-pr-0 xs-pl-10 xs-pr-20 display-force-block apply-job-today ">\
                                         '+ applied_btn +'\
                                </div>\
                         </div>\
                           </div>\
                        </div>\
                    </div>';

        $('#job-today').html(resultDiv);
        $('#job-today').show();
    });

    $.reasonChanged = function (type) {
        var div  = $("#report_message_form_group")

        if (type.trim() == abuseReport.other_type) {
            div.show()
        }
        else  {
            div.hide()
        }
    };



    $.getReportReasonsDropDown = function(group) {
        var resultDiv = '';

        if(typeof group == 'undefined'){
            group = abuseReport.type.job
        }

        var jsonData = {
            'group': group,
            '_token':$('meta[name="csrf-token"]').attr('content'),
            '_method':"GET"
        };

        var request = $.ajax({
            url: "/api/report-reasons",
            data: jsonData,
            type: 'GET',
            crossDomain:true,
            dataType:'json'
        });

        request.done(function(data){
            if (data.success == true)
            {
                var reasons = data.data;
                var current = reasons.length;
                resultDiv+='<div>';
                for (var i=0;i<current;i++)
                {
                    // resultDiv+='<option value="'+platform[i].id+'">'+platform[i].title+'</option>';
                    resultDiv+='<div class="radio xs-mt-0"> ' +
                        '<label><input type="radio" name="reason_id" value="'+ reasons[i].id+'" data-type="' + reasons[i].slug + '">' + reasons[i].reason +'</label> ' +
                        '</div>';
                }
                resultDiv+='</div>';

                $("#report_reason").html(resultDiv);
                $("#report_message_form_group").show();

            }
            else
            {
                $("#report_reason").html('<div></div>');
            }
        });

        request.fail(function(){
            $("#report_reason").html('<div></div>');
        });
    };

    var pageName = $("#pagename").val();
    var reportPages = ["companypage", "jobdetail"];
    if (reportPages.includes(pageName)) {
        $.getReportReasonsDropDown();
    }

    $(".report-job-btn").on("click", function (e){

        e.preventDefault();
        e.stopPropagation();

        if ($(this).hasClass("reported-job-button")){
            return false;
        }

        if (isLogin=='0') // User not login
        {
            showLoginModal(event);
        }
        else{
            var id = $(this).attr('data-id');
            var type = $(this).attr('data-type');
            if(typeof type == 'undefined'){
                type = abuseReport.type.job
            }

            $("#_report_job_message").val("");
            $("#report_type").val(type);
            $("#report_job_id").val(id);
            $("#report_company_id").val(id);
            $('#report-job-modal').modal('show');
        }

    });


    $('#upload-resume').on('shown.bs.modal', function () {
        if( document.getElementById("resume_file").files.length == 0 ){
            $('.upload_resume').prop('disabled', true);
        }
    })


    $('.download-app-button').bind("click", function (){
        sendGaEvent(ACTION_DOWNLOAD_APP, GA_CATEGORY_CLICK, "");
    });

    $('.btn_logout').click(function(e) {
        e.preventDefault();
        swal({
            title: txt_are_u_sure,
            text: txt_want_to_logout,
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: txt_cancel,
            confirmButtonText: txt_confirm_logout,
        }).then(function () {
            sendGaEvent(ACTION_LOGOUT, GA_CATEGORY_CLICK, "");
            localStorage.removeItem('mcuserId');
            localStorage.removeItem('mcnickname');
            localStorage.removeItem('mcchannelUrl');
            fbSignOut();
            window.location = canvas_url + "jobseeker/logout";
        }, function (dismiss) { if(dismiss === 'cancel' || dismiss === 'close'){} });
    });

    addFBBrowserAlert();

});

function addFBBrowserAlert() {
    var isFbApp = isFacebookApp();


    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;

    var msgEl = document.getElementById("fbapp_msg");

    if(msgEl) {
        var msg = msgEl.value
        var _divMsg = '';

        _divMsg += '<div class="alert-info xs-p-5" id="modalInfo_inApp">\
		<div class="alert-info__inner">' + msg + '</div>\
		</div><div class="alert-info__backdrop"></div>';

        if (isAndroid && isFbApp) {
            document.getElementById("resume_file").setAttribute("multiple", true);
            document.getElementById("fb-browser-warning").innerHTML = _divMsg;
        }
    }


}

function resendEmailVerification(){
    var submitBtn = $(".resend-verification-button");
    submitBtn.button("loading");
    submitBtn.addClass('disabled');
    submitBtn.removeAttr("onclick");
    var jsonData = {
        '_token': $('meta[name="csrf-token"]').attr('content'),
    };

    var request = $.ajax({
        url: canvas_url+"api/jobseeker/resendverifyemail",
        data: jsonData,
        type: 'POST',
        crossDomain: true,
        dataType: 'json'
    });

    request.done(function (data) {
        submitBtn.button("reset");
        submitBtn.removeClass('disabled');
        submitBtn.attr('onclick','resendEmailVerification()');

        $("#verify-email-modal").modal("hide");
        $("#verify-email-modal-success").modal("show");

    });

    request.fail(function (xhr) {
        submitBtn.button("reset");
        submitBtn.removeClass('disabled');
        showErrorNotification(xhr.status);
        submitBtn.attr('onclick','resendEmailVerification()');
        $("#verify-email-modal").modal("hide");
        $("#verify-email-modal-success").modal("show");
    });
}

function resendEmail(){
    var submitBtn = $(".resend-verification-link");
    submitBtn.html("Loading ...");
    submitBtn.addClass('disabled');
    submitBtn.removeAttr("onclick");
    var jsonData = {
        '_token': $('meta[name="csrf-token"]').attr('content'),
    };

    var request = $.ajax({
        url: canvas_url+"api/jobseeker/resendverifyemail",
        data: jsonData,
        type: 'POST',
        crossDomain: true,
        dataType: 'json'
    });

    request.done(function (data) {
        $(".resend-verification-2-area").html(txt_verif_email_sent);
        submitBtn.removeClass('disabled');
    });

    request.fail(function (xhr) {
        submitBtn.html("<b>Resend Email</b>");
        submitBtn.removeClass('disabled');
        showErrorNotification(xhr.status);
        submitBtn.attr('onclick','resendEmail()');
    });
}

window.onbeforeunload = function () {
    var activity = scEvents.exit;

    var data = {};

    var last_url = window.location.href;

    data.url = last_url;

    sendSCEvent(activity, data);
}

$.netcoreTracking = function (id, payload, needID ){

    try {
        console.log("Tracking something....")
        // set userID

        if (typeof needID !== 'undefined' && needID == true){
            if (sessionStorage.hasOwnProperty(NC_SS_KEY)) {
                payload["pk^customerid"] = sessionStorage.getItem(NC_SS_KEY);
            }
        }
        console.log(id)
        console.log(payload)
        // smartech('contact', NC_ACTIVITY_ID, payload);
        smartech('dispatch', id, payload)

    } catch(e){
        console.log("Exception in tracking something....")
        console.log(e)
    }
}



$.netcoreAddContact = function (platformId, userId, email, mobile, name) {

    var platform = "Maukerja";
    var contactId = "126";
    if(platformId == 1){
        platform = "Ricebowl";
        contactId = "127";
    }

    smartech('contact', contactId, {
        'pk^customerid': userId,
        'email': email,
        'mobile': mobile,
        'NAME': name
    },
    function() {
        smartech('identify', userId);
        smartech('dispatch', platform + ' Register', {
        });
    });

}



$.decodeToken = function (token) {

    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }

}
function loadSidebarVideo()
{
    var current_page = parseInt($("#page").val());
    var resultDiv = '';
    var v_html = '';

    var jsonData = {
        id: 0,
        type: 'all',
        count: 3,
        page : current_page,
        start: 1,
        random: true,
        '_token': $('meta[name="csrf-token"]').attr('content'),
        '_method': 'GET'
    };

    var request = $.ajax({
        url: canvas_url+"get-video",
        data: jsonData,
        type: 'GET',
        crossDomain: true,
        async : false,
        dataType: 'json'
    });

    request.done(function (data) {
        if(data.status == 'error')
        {
            $(".sidebar-video").hide();
        }
        else
        {
            var videos = data.data;
            var count = videos.length;

            for (var i = 0; i < count; i++) {
               v_html += ' <div data-toggle="modal" onClick="\$(\'#promo-video-modal .modal-video\').html(video_stuff[\'video_' + i + '\']);" data-target="#promo-video-modal";>\
                                            <div class="xs-pt-10 xs-pb-10 b-top">\
                                                <div class="img-thumb img-center pull-left xs-mr-10">\
                                                        <img src="' + videos[i].thumbnail.replace('http://', 'https://') + '" class="img-responsive lazy">\
                                                </div>\
                                                <div>\
                                                    <p class="xs-mb-0 text-bold text-dark text-ellipsis text-capitalize">'+videos[i].title+'</p>\
                                                    <p class="xs-mb-0 text-truncate text-dark">'+videos[i].description+'</p>\
                                                </div>\
                                                <div class="clearfix"></div>\
                                            </div>\
                                        </div> ';
                video_stuff['video_' + i] = '<iframe id="video-iframe-' + i + '" class="embed-responsive-item" src="https://www.youtube.com/embed/' + videos[i].y_id + '?showinfo=0&rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>';
            }
            resultDiv = v_html;
            $('#rightside-video').append(resultDiv);
        }
    });

    request.fail(function (xhr) {
        $(".sidebar-video").hide();
    });
}


function sendGaEvent(action, category, label, value) {

    try {
        if(typeof value === 'undefined'){
            value = "";
        }

        if(typeof label === 'undefined'){
            label = "";
        }

        ga('send', 'event', category, action, label, value);


        gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    }catch (ex){
        console.log(ex)
        sendErrorEvent(ex, [ERROR_FINGERPRINT_GA]);
    }

}

function sendPageView(title, path, location) {

    try {

        ga('send', 'pageview', title);

        var trackingID  = "";

        ga.getAll().forEach(function (tracker) {
            trackingID = tracker.get('trackingId');

        });

        gtag('config', trackingID, {
            'page_title' : title,
            'page_path': path,
            'page_location': location
        });


    }catch (ex){
        console.log(ex);
    }
}

function sendErrorEvent(error, fingerprints, message, level) {
    console.log(error)
    console.log(fingerprints)
    console.log(level)
    if(typeof fingerprints === 'undefined'){
        fingerprints = [];
    }

    if(typeof level === 'undefined'){
        level = ERROR_LEVEL_ERROR
    }

    if(typeof message === 'undefined'){
        message = ""
    }


    try{
        Sentry.withScope(function (scope) {
            scope.setLevel(level);
            Sentry.captureMessage(message);
            scope.setFingerprint(fingerprints);
            Sentry.captureException(error);

        });

    }catch(ex){
        console.log(ex)
    }
}

function getHighSalary()
{
    var page = parseInt($("#page").val());
    if (page == 0) page = 1;
    var popSearchRecent = [];
    popSearchRecent = JSON.parse(localStorage.getItem('storeRecentSearch')) || popSearchRecent;
    var value = "1|RM 3500|"+page;
    if(typeof popSearchRecent !== 'undefined' && popSearchRecent.length > 0)
    {
        clearAllSearchFilters();
        $("#search_keyword").val(popSearchRecent.slice(-1));
        $("#search_salary").val("3500");
        $.SetJobSearch();

        setCookie("MiniIconAction",value,365);

    }
    else
    {
        clearAllSearchFilters();
        $.SetSearchSalary(3500);
        setCookie("MiniIconAction",value,365);
    }
}


function getUserLocation(type)
{
    $('#spinner').show();
    if (getCookie("geoLocation") == "")
    {

        var jsonData = {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/jobseeker/getLocation",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        request.done(function (data) {
            if(data.status == 'success')
            {
                var response = data.msg;
                redirectLocation(type,response);
            }
        });


        request.fail(function (jqXHR) {
            window.location.href = canvas_url;
        });
    }
    else
    {
        redirectLocation(type,JSON.parse(getCookie("geoLocation")));
    }
}

function redirectLocation(type,data)
{
    var page = parseInt($("#page").val());
    if (page == 0) page = 1;
    if(type == 'city')
    {
        $('#spinner').hide();
        var popSearchRecent = [];
        popSearchRecent = JSON.parse(localStorage.getItem('storeRecentSearch')) || popSearchRecent;
        clearAllSearchFilters();
        var city = data.city.replace('+',' ');
        var value = '2|'+city+'|'+page;
        if(typeof popSearchRecent !== 'undefined' && popSearchRecent.length > 0)
        {
            $("#search_keyword").val(popSearchRecent.slice(-1));
            $("#search_location_2").val(city);
        }
        else
        {

            $("#search_location_2").val(city);
        }
        setCookie("MiniIconAction",value,365);
        $.SetJobSearch();

    }
    else
    {
        $('#spinner').hide();
        var stateSlug = data.state.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'');
        var value = '3|'+stateSlug+'|'+page;
        if(stateSlug.indexOf("wilayahpersekutuan") != -1 || data.country != 'Malaysia' )
        {
            setCookie("MiniIconAction",value,365);
            window.location.href = canvas_url + 'kualalumpur';
        }
        else
        {
            setCookie("MiniIconAction",value,365);
            window.location.href = canvas_url + stateSlug;
        }
   }
}

function setPopularCookies(keyword)
{
    var page = parseInt($("#page").val());
    if (page == 0) page = 1;
    var value = keyword + '|' + page;
    setCookie("PopularSearchAction",value,365);
}


function script(url) {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var x = document.getElementsByTagName('head')[0];
    x.appendChild(s);
}

function disableButton(key) {
    if (key == 'fbLoginButton') {
        $('.googleLogin').attr('disabled', 'disabled');
        $('.phone-login').attr('disabled', 'disabled');
        $('.email-login').attr('disabled', 'disabled');
        $('.password-login').attr('disabled', 'disabled');
        $('.button-login').attr('disabled', 'disabled');
    } else if (key == 'fbLoginReset') {
        $('.googleLogin').removeAttr('disabled');
        $('.phone-login').removeAttr('disabled');
        $('.email-login').removeAttr('disabled');
        $('.password-login').removeAttr('disabled'); 
        $('.button-login').removeAttr('disabled'); 
    } else if (key == 'emailLoginButton') {
        $('.googleLogin').attr('disabled', 'disabled');
        $('.phone-login').attr('disabled', 'disabled');
        $('.fbregisternew').attr('disabled', 'disabled');
    } else if(key == 'googleLoginReset') {
        $('.fbregisternew').removeAttr('disabled');
        $('.phone-login').removeAttr('disabled');
        $('.email-login').removeAttr('disabled');
        $('.password-login').removeAttr('disabled'); 
        $('.button-login').removeAttr('disabled'); 
    } else if (key == 'googleLoginButton') {
        $('.fbregisternew').attr('disabled', 'disabled');
        $('.phone-login').attr('disabled', 'disabled');  
        $('.email-login').attr('disabled', 'disabled');
        $('.password-login').attr('disabled', 'disabled');
        $('.button-login').attr('disabled', 'disabled');
    }
    else {
        $('.googleLogin').removeAttr('disabled');
        $('.phone-login').removeAttr('disabled');
        $('.fbregisternew').button('reset');
        $('.fbregisternew').removeAttr('disabled');
        $('.email-login').removeAttr('disabled');
        $('.password-login').removeAttr('disabled'); 
        $('.button-login').removeAttr('disabled'); 
    }
}


//----------jobseeker intro --------------//
$('#form_submit_question').validate({
    rules: {
        questions: {required:true},
    },
    messages: {
        questions: {required:'This field is required'},
    },
    submitHandler: function() {
        //using default submit function below
        return false;
    }
});

$("#form_submit_question").on('submit', function(){

    if ($('#form_submit_question').valid()) {

        var submitBtn = $(this).parent().next().find('label');
        var _this = $(this);
        submitBtn.button("loading");

        var jobId = $("#jobId").val();
        var questions = $("#questions").val();
        var type = $("#type").val();
        var patt = new RegExp("/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;");
        var res = patt.test(questions);

        if(questions.length == 0 || questions.length < 3)
        {
            _this.parents('.modal-body').find('.alert').html('Please Input more than 3 character');
        }
        else if (res)
        {
            _this.parents('.modal-body').find('.alert').html('we dont support emoji now :)');
        }
        else
        {
            $.post(canvas_url + "api/engagement", {'jobId': jobId,'questions':questions,'type':type,'_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {

                if (data.status == 'success')
                {
                    submitBtn.button("reset");
                    swal({
                        title: "Success",
                        text: "You Question Has Been Posted",
                        type: "success"
                    }).catch(swal.noop);

                    setTimeout(function () {
                        location.reload();
                    }, timeout_redirect);
                }
                else
                {

                    var text_message = '';
                    for (i = 0; i < data.msg.length; i++) {
                        if (data.msg[i]) {
                            text_message += data.msg[i];
                        }
                    }

                    submitBtn.button("reset");
                    $.notify(text_message, {type: 'danger'});
                }

            }, "json")
                .fail( function(xhr, textStatus, errorThrown) {
                    showErrorNotification(xhr.status)
                });
        }





    }
});

$.viewImpressionsTracking = function (type, jobId, source){

    var jsonData = {
        type: type,
        jobId: jobId,
        source: source,
        '_token': $('meta[name="csrf-token"]').attr('content'),
        '_method': "POST"
    };

    var request = $.ajax({
        url: "/api/jobs/views",
        data: jsonData,
        type: 'POST',
        crossDomain:true,
        dataType:'json'
    });

    request.done(function(data){
        console.log("success to update impressions")
    });

    request.fail(function(e){
        console.log(e)
        console.log("failed to update impressions")
    });


};


function sendScApply(jobId) {
    var type = getCookie('ApplyJob');
    var scType = '';

    switch(type) {
        case GA_LABEL_HOME_PAGE:
            scType = SC_APPLY_JOB_HOMEPAGE;
            break;
        case GA_LABEL_MY_PAGE:
            scType = SC_APPLY_JOB_MY_PAGE;
            break;
        case GA_LABEL_JOB_DETAIL:
            scType = SC_APPLY_JOB_JOB_DETAIL;
            break;
        case GA_LABEL_COMPANY_PAGE:
            scType = SC_APPLY_JOB_JOB_DETAIL;
            break;
        case GA_LABEL_FEATURED_JOBS:
            scType = SC_APPLY_JOB_JOB_DETAIL;
            break;
    }

    sendJobIdSocialCredit(scType, jobId , 'job');
    deleteCookie('ApplyJob');
}

