function afterChangeEmail() {
    var alert_title = 'Thank you!';
    var alert_content = '<p>We’ve send to you an email for verification. Please <strong>verify</strong> and you will received latest and suitable jobs based on your preference. </p>';
  
    var jsonData = {
      '_token': $('meta[name="csrf-token"]').attr('content'),
    }
  
    var sendVerification = $.ajax({
      url: canvas_url+"api/jobseeker/resendverifyemail",
      data: jsonData,
      type: 'POST',
      crossDomain: true,
      dataType: 'json'
    });
  
    sendVerification.done(function (data) {
      if (data.status == 'success') {
        $(".alert-content__title").html(alert_title);
        $(".alert-content__msg").html(alert_content);
        $(".alert-checked").fadeOut(1000);
        setTimeout(function () {
          location.reload();
        }, timeout_redirect);
      }
    });
  }
  
  $(document).ready(function () {
    $(document).bind("click", function(){
      if ($("#search-filters").hasClass("hide")) {
        $(".alert-container, .alert-content").hide();
      }
    });
    
    $(".submit-change").bind("click", function () {
      var valid_email_alert  = $("#alert_text_valid").text()
      var txt_field_required  = $("#alert_text_valid2d").text()
      $.validator.setDefaults( {
        submitHandler: function () {
          alert( "submitted!" );
        }
      });
      
      $("#jobalert_jobseeker_email").validate({
        rules: {
          jobseeker_email: {
            required: true,
            email: true
          }
        },
        messages: {
          jobseeker_email: { email:valid_email_alert,required:txt_field_required },
        },
    
        submitHandler: function () {
          var emailInputElement  = $("#jobseeker_email")
          var emailInputErrorElement  = $("#account_login_email_error")
          var loginEmail = emailInputElement.val();
          var submitBtn = $(".submit-change");
  
          if (!validateEmail(loginEmail)) {
            emailInputElement.parent().addClass("error");
            emailInputErrorElement.html(txt_valid_email_error);
            emailInputErrorElement.show();
            return false;
          }
          else {
            submitBtn.button("loading");
            submitBtn.addClass('disabled');
            
            var jsonData = {
              'email_address': loginEmail,
              '_token': $('meta[name="csrf-token"]').attr('content'),
              '_method': "PUT"
            };
  
            var changeEmailRequest = $.ajax({
                url: canvas_url+"api/jobseeker/changeemail",
                data: jsonData,
                type: 'PUT',
                crossDomain: true,
                dataType: 'json'
            });
  
            changeEmailRequest.done(function (data) {
              if (data.success == true) {
                submitBtn.button("reset");
                $(".alert-checked").show();
                afterChangeEmail();
              } else {
                emailInputErrorElement.html(data.message);
                emailInputErrorElement.show();
                submitBtn.button('reset');
              }
            });
          }
        }
      }).form();
    });
  });