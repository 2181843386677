const GA_CATEGORY_CLICK = 'Clicked';
const GA_CATEGORY_SEARCH_HIT = 'Search Hit';
const GA_CATEGORY_VIEW = 'View';
const GA_CATEGORY_USER_REGISTER = 'Register';
const GA_CATEGORY_BULK_APPLY = 'Bulk Apply Job';
const GA_CLOSE_OPT_IN = 'Close Opt In';
const GA_CATEGORY_APPLY_JOB = 'Apply Job';
const GA_CATEGORY_RESUME = 'Resume';
const GA_CATEGORY_WALKIN = 'Walkin Interview Interest';
const GA_CATEGORY_JOB_DETAIL_APPLY_BUTTON = 'job apply button job detail';
const GA_CATEGORY_JOB_QNA = 'qna button on job detail';
const GA_CATEGORY_COMPANY_QNA = 'qna button on company detail';
const GA_CATEGORY_SAVE_JOB = 'Save Job';
const GA_CATEGORY_SHARE_JOB = 'Share Job';
const GA_CATEGORY_ADD_SKILL_RECOMMENDATION = 'Add Skills recommendation';
const GA_CATEGORY_JSRE = 'JSRE';
const GA_CATEGORY_RECOMMENDATIONS = 'Recommendations';
const GA_CATEGORY_SHOW_CONTACT = 'show employer contact';
const GA_CATEGORY_SHOW_VIDEO = 'Show Video';
const GA_CATEGORY_SHOW_BLOG = 'Show Blog';
const GA_CATEGORY_MARKETING_JOBS = 'Marketing Jobs';
const GA_CATEGORY_ALERT = "Job Alert Prompt";
const GA_CATEGORY_ONBOARDING = "Onboarding";
const GA_CATEGORY_PROFILING_QUESTION = "Profiling Question";
const GA_CATEGORY_NEW_HOMEFEED = "New Homefeed";
const GA_CATEGORY_ANCHOR_PROFILE = "Anchor Profile";
const GA_CATEGORY_TAC = "TAC";
const GA_CATEGORY_GODEEP_APPLICATION = "GoDeep Application";
const GA_CATEGORY_FACEBOOK = "Facebook";
const GA_CATEGORY_PROFILE = "Profile";
const GA_CATEGORY_QUICK_APPLY = "Quick Apply";
const GA_CATEGORY_REGISTER = "Register";
const GA_CATEGORY_LOGIN = "Login";
const GA_CATEGORY_GOOGLE = "Google";
const GA_CATEGORY_JOB_TODAY = "Job Today";
const GA_CATEGORY_NEW_APPLY_FLOW = "New Apply Flow";
const GA_CATEGORY_ASKPRO_SUCCESS_APPLY = "askpro_success_apply";
const GA_CATEGORY_USER_LIMITATION = "User Apply Limitation";

const ACTION_APPLY_JOB_FROM_LIST = 'Apply Job From List';
const ACTION_APPLY_JOB_SUCCESSFUL = 'Job Applied Successfully';
const ACTION_APPLY_JOB_FROM_DETAILS = 'Apply Job From Details Page';
const ACTION_APPLY_JOB_MISSING_INFO = 'Apply Job Missing Info';
const ACTION_APPLY_JOB_FROM_PROFILE_RECOMMENDATION = 'Apply Job From Profile Recommendation';
const ACTION_VIEW_JOB_LIST = 'Job Loaded In Listing';
const ACTION_VIEW_JOB_DETAILS = 'View Job Details';
const ACTION_SAVE_JOB_FROM_JOB_LIST = 'Save Job From List';
const ACTION_SHARE_FROM_JOB_LIST = "Share Job From List";
const ACTION_SHARE_FROM_JOB_DETAILS = "Share Job From Details Page";
const ACTION_REGISTER_SUCCESS = "success";
const ACTION_REGISTER_FAILED = "failed";
const ACTION_DOWNLOAD_APP = "Download App";
const ACTION_SET_LANGUAGE_PRREFERENCE = "Language Preference";
const ACTION_MINI_ICON = "Mini Icon";
const CHANGE_LIST_VIEW = "Change List View";
const ACTION_OPT_IN_CLOSE_NOTIFICATION = "Close Opt In Push Notification";
const ACTION_CANCEL_INTERESTED = "cancel interested";
const ACTION_CLICKED_INTERESTED = "cancel interested";
const ACTION_APPLY_BUTTON = "apply button";
const ACTION_APPLY_DOWNLOAD_APP_BUTTON = "download app from apply button";
const ACTION_APPLY_DOWNLOAD_APP_JOB_LISTING = "download app from job listing";
const ACTION_QUICK_APPLY = "quick apply";
const ACTION_VIEW_APPLY_PAGE = "View Apply Page";
const ACTION_JOB_DETAIL_SAVE_JOB = "save from job detail page";
const ACTION_ADD_SKILL_RECOMMENDATION = "Add Skills recommendation";
const ACTION_APPLY_HIGH_MATCH_PERCENTAGE = "high_match_percentage";
const ACTION_APPLY_LOW_MATCH_PERCENTAGE = "low_match_percentage";
const ACTION_VIEW_PROFILE_RECOMMENDATION = "view_profile_recommendation";
const ACTION_LOAD_JOB_LISTING = "Load Job Listing";
const ACTION_LOAD_JOB_FEATURED = "Load Job Featured";
const ACTION_SCROLL_JOB_LISTING = "Scroll Job Listing";
const ACTION_BROWSE_MORE_JOBS = "Browse More Jobs";
const ACTION_BROWSE_BLOGS_VIDEOS = "Browse Blogs and Videos";
const ACTION_VIEW_COMPANY = "View Company Info";
const ACTION_VIEW_COMPANY_FEATURED = "View Company Info in Featured Jobs ";
const SHARE_JOB_FROM_NEW_HOMEFEED = "Share Job Listing From New Homefeed";
const SAVE_JOB_FROM_NEW_HOMEFEED = "Save Job From Job Listing New Homefeed";
const APPLY_JOB_FROM_NEW_HOMEFEED = "Apply Job From Job Listing New Homefeed";
const SHARE_JOB_FROM_NEW_HOMEFEED_FEATURED = "Share Job Featured From New Homefeed";
const SAVE_JOB_FROM_NEW_HOMEFEED_FEATURED = "Save Job From Job Featured New Homefeed";
const APPLY_JOB_FROM_NEW_HOMEFEED_FEATURED = "Apply Job From Job Featured New Homefeed";
const ACTION_VIEW_JOB_DETAILS_NEW_HOMEFEED = "View Job Detail on Job Listing New Homefeed";
const ACTION_VIEW_JOB_DETAILS_FEATURED = "View Job Detail on Job Featured New Homefeed";
const ACTION_VIEW_COMPANY_LISTING = "View Company Infof Job Listing New Homefeed";
const ACTION_VIEW_CONTACT_LISTING = 'show employer contact Job Listing New Homefeed';
const ACTION_VIEW_CONTACT_FEATURED = 'show employer contact Job Featured New Homefeed';
const ACTION_VIEW_EMPLOYER_HOMEFEED = 'show employer New Homefeed';
const ACTION_VIEW_MINI_ICON_JOB = 'View Job From Mobile View New Homefeed';
const ACTION_VIEW_MINI_ICON_COMPANY = 'View Company From Mobile View New Homefeed';
const ACTION_VIEW_MINI_ICON_BLOG = 'View Blog From Mobile View New Homefeed';

const ACTION_LOGIN_WITH_FB_BUTTON = "login with fb btn";
const ACTION_LOGIN_WITH_PHONE_BUTTON = "login with phone btn";
const ACTION_LOGIN_WITH_EMAIL_BUTTON = "login with email btn";
const ACTION_LOGIN_WITH_GMAIL_BUTTON = "login with Gmail btn";
const ACTION_SIGN_UP_WITH_FB_BUTTON = "sign up with fb btn";
const ACTION_SIGN_UP_WITH_PHONE_BUTTON = "sign up with phone btn";
const ACTION_SIGN_UP_WITH_EMAIL_BUTTON = "sign up with email btn";
const ACTION_SIGN_UP_WITH_GMAIL_BUTTON = "sign up with Gmail btn";
const ACTION_REQUEST_TAC = "Request tac btn";
const ACTION_REQUEST_WA_TAC = "Request wa tac btn";
const ACTION_ALREADY_HAVE_TAC = "Already Have TAC";
const ACTION_CONFIRM_TAC = "Confirm tac btn";
const ACTION_SHOW_RESEND_TAC = "Confirm tac btn";
const ACTION_SHOW_BASE_INFO = "Show Base Info Page";
const ACTION_SAVE_BASE_INFO = "Save Base Info";
const ACTION_SHOW_JOB_PREFENCE = "Show Job Preference Page";
const ACTION_SAVE_JOB_PREFENCE = "Save Job Preference";
const ACTION_SHOW_JOB_EXPERIENCE = "Show Job Experience Page";
const ACTION_NO_JOB_EXPERIENCE = "Show Job Experience Page";
const ACTION_SHOW_LAST_EDUCATION = "Show Education Page";
const ACTION_SAVE_LAST_EDUCATION = "Save Education";
const ACTION_SAVE_LAST_EXPERIENCE = "Save Experience";
const ACTION_ADD_NAME = "Add Name";
const ACTION_ADD_EMAIL = "Add Email";
const ACTION_ADD_GENDER = "Add Gender";
const ACTION_ADD_LANGUAGE = "Add Language";
const ACTION_ADD_ADDRESS = "Add Address";
const ACTION_ADD_SALARY = "Add Salary";
const ACTION_ADD_PICTURE = "Add Picture";
const ACTION_ADD_WORKING_EXPERIANCE = "Add Picture";
const ACTION_ADD_BIRTHDAY = "Add DOB";
const ACTION_ADD_JOB_PREFER = "Add Job Prefer";
const ACTION_ADD_EDUCATION = "Add Education";
const ACTION_ADD_RESUME = "Add Resume";
const ACTION_ADD_NATIONALITY = "Add Nationality";
const ACTION_ADD_JOB_TYPE = "Add Job Type";
const ACTION_ADD_WORK_LOCATION = "Add Work Location";
const ACTION_VERIFY_WORK = "Verify Work Experience";

const ACTION_SHARE_JOB_THANK_YOU_PAGE = "Share Job From Thank You Page Recommendation";
const ACTION_APPLY_JOB_THANK_YOU_PAGE = "Apply Job From Thank You Page Recommendation";
const ACTION_PROFILE = "profile";

const ACTION_ADD_SKILL = "Add Skills";
const ACTION_ADD_EXPERIENCE = "Add Experience";

const ACTION_SAVE_FROM_MAIN = "save from main";
const ACTION_SHARE_FROM_MAIN = "share from main";
const ACTION_FROM_MAIN = "from main";

const ACTION_ALERT_CANCEL = "Cancel Alert";
const ACTION_ALERT_SAVE = "Save Alert";
const ACTION_ALERT_OPEN = "Open Alert";
const ACTION_ALERT_CLEAR = "Clear Alert";

const VALUE_EMAIL = "email";
const VALUE_GOOGLE = "google";
const VALUE_YES = "Yes";
const VALUE_NO = "No";
const VALUE_PHONE = "phone";
const VALUE_FACEBOOK = "facebook";
const VALUE_OPT_IN = 1;

const ACTION_NO_PHOTO_SELECTED = "No Photo selected";
const ACTION_FORM_PERSONAL_SUCCESS = "form personal-success";
const ACTION_FORM_PERSONAL_FAILED = "form personal-failed";
const ACTION_NO_RESUME_SELECTED = "No Resume option selected";
const ACTION_APPLY_JOB_RESUME = "apply job resume type";
const ACTION_APPLY_SUCCESS = "apply-success";
const ACTION_APPLY_FAILED = "apply-failed";
const ACTION_LOGOUT = "logout btn";
const ACTION_ONBOARDING_LOGOUT = "logout";
const ACTION_FORGOT_PASSWORD = "Forgot Password";

const ACTION_CLICK = "click";
const ACTION_UPLOAD = "Upload"
const ACTION_LOAD = "Load";
const ACTION_FIRST = "First";
const ACTION_SECOND = "Second";
const ACTION_SHOW = "show"

const GA_LABEL_LIMIT_REACHED = "limit reached";
const GA_LABEL_API_ERROR = "api error";
const GA_LABEL_PROFILE = "Profile";
const GA_LABEL_MY_PAGE = "MyPage";
const GA_LABEL_HOME_PAGE = "Homepage";
const GA_LABEL_ENTER_NAME = "Enter Name";
const GA_LABEL_SAVE_NAME = "Save Name";
const GA_LABEL_SAVE_EMAIL = "Save Email";
const GA_LABEL_SAVE_ADDRS = "Save Address";
const GA_LABEL_SET_ADDRS = "Set Address";
const GA_LABEL_FEMALE = "Female";
const GA_LABEL_MALE = "Male";
const GA_LABEL_SAVE = "Save";
const GA_LABEL_ENTER_SALARY = "Enter Salary";
const GA_LABEL_SAVE_SALARY = "Save Salary";
const GA_LABEL_UPLOAD_PICTURE = "Upload Picture";
const GA_LABEL_SAVE_PICTURE = "Save Picture";
const GA_LABEL_CHOOSE_BIRTHDAY = "Save Birthday";
const GA_LABEL_SAVE_BIRTHDAY = "Save Birthday";
const GA_LABEL_ADD_BIRTHDAY = "Add Birthday";
const GA_LABEL_ADD_FULL_TIME = "Full TIme";
const GA_LABEL_ADD_PART_TIME = "Part Time";
const GA_LABEL_ADD_INTERN = "Intern";
const GA_LABEL_SAVE_EDUCATION = "Save Education";
const GA_LABEL_ADD_EDUCATION = "Add Education";
const GA_LABEL_UPLOAD_RESUME = "Upload Resume";
const GA_LABEL_NO_RESUME = "No Resume";
const GA_LABEL_ADD_SKILL = "Add Skill";
const GA_LABEL_NOT_ADD_SKILL = "Not Add Skill";
const GA_LABEL_MALAYSIAN = "Malaysian";
const GA_LABEL_NOT_MALAYSIAN = "Not Malaysian";
const GA_LABEL_SAVE_NATIONALITY = "Save Nationality";
const GA_LABEL_WORKING = "Working";
const GA_LABEL_NOT_WORKING = "Not Working";
const GA_LABEL_SAVE_EXPERIENCE = "Save Experience";
const GA_LABEL_ADD_JOB_TITLE = "Add Job Title";
const GA_LABEL_ADD_SAVE_TITLE = "Add Save Title";
const GA_LABEL_ADD_WORK_LOCATION = "Add Work Location";
const GA_LABEL_ADD_SAVE_WORK_LOCATION = "Save Work Location";
const GA_LABEL_CLOSE_QUESTION = "Close Question";
const GA_LABEL_SHOW_QUESTION = "Show Question";
const GA_LABEL_ASKPRO_SHOW = "event_askpro_upsel_show";
const GA_LABEL_ASKPRO_CLICK = "event_askrpo_upsel_click_cta";


const GA_LABEL_LOGIN = "Login";

const GA_SEARCH_KEYWORD = 'Search Job By Keyword';
const GA_SEARCH_LOCATION = 'Search Job By Location';
const GA_SEARCH_SALARY = 'Search Job By Min Salary';
const GA_FILTER_TYPE = 'Filter Job By Job Type';
const GA_FILTER_CATEGORY = 'Filter Job By Job Category';
const GA_FILTER_EDUCATION = 'Filter Job By Education';
const GA_FILTER_COUNTRY = 'Filter Job By Country';
const GA_FILTER_FRESH_GRADUATE = 'Find For Fresh Graduate Jobs';
const GA_FILTER_FAST_RESPONSE = 'Find Job with Fast Response';
const GA_FILTER_HOT_JOBS = 'Find Hot Jobs';
const GA_FILTER_URGENT_JOBS = 'Find Urgent Jobs';
const GA_FILTER_DIRECT_CONTACT = 'Find Jobs With Direct Contact';
const GA_FILTER_WALKIN = 'Find Walkin Interview Jobs';

const GA_VIEW_COMPANY = 'View Company Profile';

const ACTION_SAVE_JOB_ALERT_NON_LOGIN = "Save Job Alert Non Login";
const ACTION_ALERT_OPEN_NON_LOGIN = "Open Alert Non Login";
const ACTION_ALERT_EDIT_NON_LOGIN = "Edit Alert Non Login";

const ACTION_REMIND_ME_ONBOARDING = "Remind Me Later";
const EMPTY_NAME_ONBOARDING = "Empty Name";
const LIMIT_NAME_ONBOARDING = "Limit Name";
const EMPTY_PASSWORD_ONBOARDING = "Empty Password";
const EMPTY_EMAIL_ONBOARDING = "Empty Email";
const EMPTY_TITLE_JOB_ALERT_ONBOARDING = "Empty Title Job Alert";
const LIMIT_TITLE_JOB_ALERT_ONBOARDING = "Limit Title Job Alert";
const EMPTY_JOB_TYPE_ONBOARDING = "Empty Job Type";
const EMPTY_SALARY_CUR_ONBOARDING = "Empty Current Salary";
const EMPTY_EXPECTED_SALARY_ONBOARDING = "Empty Expected Salary";
const EMPTY_CITY_ONBOARDING = "Empty City";
const EMPTY_DEGREE_ONBOARDING = "Empty Degree";
const EMPTY_SCHOOL_NAME_ONBOARDING = "Empty Scholl Name";
const EMPTY_GRADE_ONBOARDING = "Empty Grade";
const EMPTY_START_EDU_ONBOARDING = "Empty Start Education";
const EMPTY_END_EDU_ONBOARDING = "Empty End Education";
const EMPTY_TITLE_EXPERIENCE_ONBOARDING = "Empty Title Experience";
const LIMIT_TITLE_EXPERIENCE_ONBOARDING = "Limit Title Experience";
const EMPTY_COMPANY_NAME_ONBOARDING = "Empty Company Name";
const LIMIT_COMPANY_NAME_ONBOARDING = "Limit Company Name";
const EMPTY_START_EXP_ONBOARDING = "Empty Start Experience";
const EMPTY_END_EXP_ONBOARDING = "Empty End Experience";
const ACTION_DONT_HAVE_EXPERIENCE = "Do not have Experience";

const ERROR_FACEBOOK_LOGIN = "Error Login Facebook";
const SUCCESS_FACEBOOK_LOGIN = "Success Login Facebook";
const ERROR_FACEBOOK_REGISTER = "Error Register Facebook";
const SUCCESS_FACEBOOK_REGISTER = "Success Facebook Register";
const ERROR_GOOGLE_LOGIN = "Error Google Login";
const SUCCESS_GOOGLE_LOGIN = "Success Google Login";
const SUCESS_GOOGLE_REGISTER = "Success Google Register";
const ERROR_GOOGLE_REGISTER = "Success Google Register";

const ERROR_UPLOAD_PHOTO = "Error Upload Photo";
const SUCCESS_UPLOAD_PHOTO = "Error Upload Photo";
const NO_PHOTO_PROFILE = "No Photo Profile";
const SUCCESS_UPLOAD_RESUME = "Success Upload Resume";
const ERROR_UPLOAD_RESUME = "Error Upload Resume";
const UPLOAD_RESUME = "Upload Resume";
const CLOSE_REGISTER = "Close Register";
const SUCCESS_FORGOT_PASSWORD = "Success Forgot Password";
const ERROR_FORGOT_PASSWORD = "Error Forgot Password";
const CLOSE_LOGIN = "Close Login";
const OPEN_REGISTER = "Open Register";
const CONFIRM_SUCCESS_TAC_LOGIN = "Success TAC Login";
const CONFIRM_SUCCESS_TAC_REGISTER = "Success TAC Register";
const CONFIRM_ERROR_TAC_LOGIN = "Success TAC Login";
const CONFIRM_ERROR_TAC_REGISTER = "Success TAC Register";
const REQUEST_TAC_SUCESS = "Request TAC Success";
const REQUEST_TAC_ERROR = "Request TAC Error";
const REQUEST_TAC_SUCCESS_CALL = "Request Success TAC Call"
const REQUEST_TAC_ERROR_CALL = "Request Error TAC Call"
const REQUEST_TAC_SUCCESS_SMS = "Request Success TAC SMS"
const REQUEST_TAC_ERROR_SMS = "Request Error TAC SMS"
const ACTION_REGISTER_WITH_PHONE_BUTTON = "Register with phone button";
const CLOSE_JOB_TODAY = "close";
const APPLIED_JOB_TODAY = "applied";
const SHOWN_JOB_TODAY = "shown";
const SKIPPED_JOB_TODAY = "skipped";
const CLOSE_USER_LIMITATION = "Close Pop Up";
const UPDATE_PROFILE = "Update Profile";


const ACTION_SHOW_ANNOUNCEMENT = "Show Announcement";
const ACTION_CLICK_ANNOUNCEMENT = "Click Announcement";
const ACTION_SHOW_HOMESCREEN = "New Homescreen Loaded";
const ACTION_CLICK_THUMBS_UP = "Click Like";
const ACTION_CLICK_THUMBS_DOWN = "Click Dislike";
const ACTION_CLICK_SUBMIT = "Submit Feedback";
const ACTION_CLICK_CLOSE = "CloseFeedback";
const ACTION_CLICK_EMAIL_FAILED = 'Email Failed';

const SC_LOGIN = "login";
const SC_REGISTER = "register";
const SC_PHONE_CLICK = "phone_click";
const SC_PHONE_SUCCESS = "phone_success";
const SC_PHONE_FAILED = "phone_failed";
const SC_FACEBOOK_CLICK = "facebook_click";
const SC_FACEBOOK_SUCCESS = "facebook_success";
const SC_FACEBOOK_FAILED = "facebook_failed";
const SC_GOOGLE_CLICK = "google_click";
const SC_GOOGLE_SUCCESS = "google_success";
const SC_GOOGLE_FAILED = "google_failed";
const SC_EMAIL_CLICK = "email_click";
const SC_EMAIL_SUCCESS = "email_success";
const SC_EMAIL_FAILED = "email_failed";

const SC_ONBOARDING = "onboarding";
const SC_BASIC_INFO_SHOW = "event_onboarding_basic_info_shown";
const SC_BASIC_INFO_SUBMIT = "event_onboarding_basic_info_submit";
const SC_JOB_PREFERENCE_SHOW = "event_onboarding_job_pref_shown";
const SC_JOB_PREFERENCE_SUBMIT = "event_onboarding_job_pref_submit";
const SC_EXPERIENCE_SHOW = "event_onboarding_experience_shown";
const SC_EXPERIENCE_SUBMIT = "event_onboarding_experience_submit";
const SC_EXPERIENCE_SKIP_DIALOG = "event_onboarding_experience_skip_dialog_shown";
const SC_EXPERIENCE_SKIP_DIALOG_YES = "event_onboarding_experience_skip_dialog_yes";
const SC_EXPERIENCE_SKIP_DIALOG_CANCEL = "event_onboarding_experience_skip_dialog_cancel";
const SC_EDUCATION_SHOW = "event_onboarding_education_shown";
const SC_EDUCATION_SUBMIT = "event_onboarding_education_submit";
const SC_EDUCATION_REMIND_ME_LATER = "event_onboarding_education_remind_me_later";
const SC_TOP_MENU = "top_menus";
const SC_CLICK_LOGO = "event_top_menus_click_logo";
const SC_CLICK_JOB = "event_top_menus_click_job";
const SC_CLICK_TOP_COMPANY = "event_top_menus_click_top_companies";
const SC_CLICK_RESUME_BUILDER = "event_top_menus_click_resume_builder";
const SC_CLICK_HELP = "event_top_menus_click_menu_faq";
const SC_CLICK_CONTACT = "event_top_menus_click_contact";
const SC_CLICK_ABOUT = "event_top_menus_click_about";
const SC_CLICK_BLOG = "event_top_menus_click_stories";
const SC_FOR_EMPLOYER = "event_top_menus_click_for_employer";
const SC_CLICK_LOGIN_REGISTER = "event_top_menus_click_login_or_register";
const SC_CLICK_CHANGE_LANGUAGE = "event_top_menus_click_change_language";
const SC_CLICK_CHAT = "event_top_menus_click_chat";
const SC_CLICK_NOTIFICATION = "event_top_menus_click_notification";
const SC_CLICK_VIEW_PROFILE = "event_top_menus_click_view_profile";
const SC_CLICK_SETTING_PRIVACY = "event_top_menus_click_setting_privacy";
const SC_CLICK_LOGOUT = "event_top_menus_click_logout";
const SC_CLICK_MY_PAGE = "event_top_menus_click_mypage";

const SC_PROFILE = "profile";
const SC_INTRO_EDIT = "profile_intro_edit";
const SC_INTRO_SAVE = "profile_intro_edited";
const SC_BASIC_EDIT = "profile_basic_edit";
const SC_BASIC_SAVE = "profile_basic_edited";
const SC_BASIC_ADD_EMAIL = "profile_add_email";
const SC_ADD_EMAIL_SEND = "profile_add_email_send";
const SC_BASIC_VERIFY_EMAIL = "profile_verify_email";
const SC_VERIFY_EMAIL_SEND = "profile_verify_email_send";
const SC_CHANGE_PHONE_EMAIL_CLICK = "profile_change_phone_email_click";
const SC_EXPERIENCE_EDIT = "profile_experience_list_edit";
const SC_EXPERIENCE_SAVE = "profile_experience_list_added";
const SC_EXPERIENCE_ADD = "profile_experience_list_add";
const SC_EXPERIENCE_CHANGE = "profile_experience_change";
const SC_ACTION_CLICK_WORKING = "currently_working";
const SC_EXPERIENCE_LIST_REMOVE = "profile_experience_list_remove";
const SC_EXPERIENCE_CONFIRMATION_REMOVE = "profile_experience_list_removed";
const SC_EXPERIENCE_UPDATE = "profile_experience_list_edited";
const SC_EDUCATION_ADD = "profile_education_add";
const SC_EDUCATION_EDIT = "profile_education_edit";
const SC_EDUCATION_SAVE = "profile_education_added";
const SC_EDUCATION_UPDATE = "profile_education_edited";
const SC_EDUCATION_REMOVE = "profile_education_remove";
const SC_EDUCATION_CONFIRMATION_REMOVE = "profile_education_removed";
const SC_LANGUANGE_EDIT = "profile_language_edit";
const SC_LANGUANGE_SAVE = "profile_language_edited";
const SC_SKILL_ADD = "profile_skills_add";
const SC_SKILLS_SUBMIT_SUCCESS = "profile_skills_submit_success";
const SC_SKILLS_SUBMIT_FAILED = "profile_skills_submit_failed";
const SC_AWARD_LIST_ADD = "profile_award_list_add";
const SC_AWARD_SAVE = "profile_award_list_added";
const SC_AWARD_EDIT = "profile_award_list_edit";
const SC_AWARD_UPDATE = "profile_award_list_edited";
const SC_AWARD_REMOVE = "profile_award_list_remove";
const SC_AWARD_CONFIRMATION_REMOVE = "profile_award_list_removed";
const SC_REFERENCES_ADD = "profile_reference_list_add";
const SC_REFERENCES_SAVE = "profile_reference_list_added";
const SC_REFERENCES_EDIT = "profile_reference_list_edit";
const SC_REFERENCES_DELETE = "profile_reference_list_remove";
const SC_REFERENCES_UPDATE = "profile_reference_list_edited";
const SC_REFERENCES_CONFIRMATION_REMOVE = "profile_reference_list_removed";
const SC_RESUME_ADD = "profile_resume_list_add";
const SC_RESUME_CLOSE = "profile_resume_upload_cancel";
const SC_RESUME_UPLOAD = "profile_resume_upload_submit_success";
const SC_RESUME_SELECT = "profile_resume_upload_click";
const SC_RESUME_REPORT = "profile_resume_report";
const SC_RESUME_TRASH = "profile_resume_list_remove";
const SC_RESUME_GENERATE = "profile_resume_list_generate";
const SC_RESUME_DELETE = "profile_resume_list_remove";
const SC_RESUME_DOWNLOAD_SUCCESS = "profile_resume_list_donwload_success";
const SC_RESUME_DOWNLOAD_FAILED = "profile_resume_list_donwload_failed";
const SC_RESUME_UPLOAD_FAILED = "profile_resume_upload_submit_failed";
const SC_RESUME_LIST_REMOVED = "profile_resume_list_removed";



const PROFILE_DETAILS = "Profile Details";
const SEARCH = "Search";
const SIDE_PAGE = "Side page";
const FRESH_GRADUATE = "Fresh Graduate";
const FAST_RESPONSE = "Fast Response";
const HOT_JOBS = "Hot Jobs";
const URGENT_JOBS = "Urgent Jobs";
const DIRECT_CONTACT = "Direct Contact";
const WIT = "Walk-in Interview";

const PROFILE_INSIGHT_COMPLETING="profile_insight_completing";
const PROFILE_INSIGHT_RESUME="profile_insight_resume";
const PROFILE_INSIGHT_VIEW_PROFILE="profile_insight_view_profile";
const PROFILE_INSIGHT_COMPANY_INTEREST="profile_insight_company_interest";
const PROFILE_SCORING_CLICK="profile_scoring_click";
const PROFILE_SCORING_NOT_NOW="profile_scoring_not_now";
const SETTING_PRIVACY_EDIT="setting_privacy_edit";
const SETTING_PRIVACY_EDITED="setting_privacy_edited";
const SETTING_ALERT_JOB_ADD="setting_alert_job_add";
const SETTING_ALERT_JOB_EDIT="setting_alert_job_edit";
const SETTING_ALERT_JOB_REMOVE="setting_alert_job_remove";
const SETTING_ALERT_JOB_ADDED="setting_alert_job_added";
const SETTING_ALERT_JOB_EDITED="setting_alert_job_edited";
const SETTING_ALERT_JOB_REMOVE_CANCELED="setting_alert_job_remove_canceled";
const SETTING_ALERT_JOB_REMOVED="setting_alert_job_removed";
const SETTING_PHONE_EDIT="setting_phone_edit";
const SETTING_EMAIL_EDIT="setting_email_edit";
const SETTING_CHANGE_PASSWORD="setting_change_password";
const SETTING_PHONE_EDIT_TAC="setting_phone_edit_tac";
const SETTING_PHONE_EDITED="setting_phone_edited";
const SETTING_EMAIL_EDIT_TAC="setting_email_edit_tac";
const SETTING_EMAIL_EDITED="setting_email_edited";
const SETTING_CHANGE_PASSWORD_CONFIRM="setting_change_password_confirm";
const PQ_GENDER_MALE_SUBMIT="pq_gender_male_submit";
const PQ_GENDER_FEMALE_SUBMIT="pq_gender_female_submit";
const PQ_GENDER_CLOSE="pq_gender_close";
const PQ_BIRTHDAY_CLOSE="pq_birthday_close";
const PQ_BIRTHDAY_SUBMIT="pq_birthday_submit";
const PQ_EDUCATION_CLOSE="pq_education_close";
const PQ_EDUCATION_SUBMIT="pq_education_submit";
const PQ_RESUME_CLOSE="pq_resume_close";
const PQ_RESUME_DONT_HAVE="pq_resume_dont_have";
const PQ_RESUME_UPLOAD="pq_resume_upload";
const PQ_LANGUAGE_CLOSE="pq_language_close";
const PQ_LANGUAGE_NO="pq_language_no";
const PQ_LANGUAGE_YES="pq_language_yes";
const PQ_NATIONALITY_NO="pq_nationality_no";
const PQ_NATIONALITY_YES="pq_nationality_yes";
const PQ_NATIONALITY_CLOSE="pq_nationality_close";
const PQ_SALARY_CLOSE="pq_salary_close";
const PQ_SALARY_SUBMIT="pq_salary_submit";
const PQ_ADDRESS_CLOSE="pq_address_close";
const PQ_ADDRESS_SUBMIT="pq_address_submit";
const PQ_SKILL_YES="pq_skill_yes";
const PQ_SKILL_CLOSE="pq_skill_close";
const PQ_SKILL_SUBMIT="pq_skill_submit";
const PQ_WORK_LOCATION_CLOSE="pq_work_location_close";
const PQ_WORK_LOCATION_SUBMIT="pq_work_location_submit";
const EVENT_SEARCH_SCREEN_INPUT_KEYWORD = "event_search_screen_input_keyword";
const EVENT_SEARCH_SCREEN_CLICK_RECENT_SEARCH = "event_search_screen_click_recent_search";
const EVENT_SEARCH_SCREEN_CLICK_RECENT_CLEAR = "event_search_screen_click_recent_clear";
const EVENT_SEACH_SCREEN_CLICK_POPULAR_SEARCH = "event_seach_screen_click_popular_search";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_LOCATION = "event_result_search_screen_filter_by_location";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_SALARY = "event_result_search_screen_filter_by_job_salary";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_TYPE = "event_result_search_screen_filter_by_job_type";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_CATEGORY = "event_result_search_screen_filter_by_category";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_EDUCATION = "event_result_search_screen_filter_by_job_education";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_COUNTRY = "event_result_search_screen_filter_by_country";
const EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE = "event_result_search_screen_filter_by_job_attribute";
const JOB_MAY_LIKE_CLICK = "job_may_like_click";
const FEATURED_EMPLOYERS_VIEW_ALL = "featured_employers_view_all";
const FEATURED_EMPLOYERS_CLICK = "featured_employers_click";
const PQ_WORK_VERIFY_YES = "pq_work_verify_yes";
const PQ_WORK_VERIFY_NO = "pq_work_verify_no";
const PQ_WORK_VERIFY_CLOSE = "pq_work_verify_close";


const CLICK_EDIT_INFORMATION = "Click edit information";
const ERROR_BIRTHDAY = "Error Birthday";
const ERROR_GENDER = "Error Gender";
const ERROR_COUNTRY = "Error Country";
const ERROR_STATE = "Error State";
const ERROR_NATIONALITY = "Error Nationality";
const ERROR_IC = "Error IC";
const ERROR_EMAIL = "Error Email";
const CLICK_NEXT = "Click Next";
const RESUME_CLICK_UPLOAD_NEW = "Resume click upload new";
const RESUME_POP_UP_SELECT_FILE = "Resume pop up - select file";
const RESUME_POP_UP_CLOSE = "Resume pop up - close";
const RESUME_POP_UP_REPORT = "Resume pop up - report";
const RESUME_POP_UP_UPLOAD = "Resume pop up - upload";
const RESUME_POP_UP_SUCCESS = "Resume pop up - success";
const RESUME_POP_UP_FAILED = "Resume pop up - failed";
const RESUME_SELECTED = "Resume selected";
const RESUME_PREVIEW = "Resume preview";
const RESUME_CLICK_ADD_RESUME = "Resume click add resume";
const EXPERIENCE_EDIT = "Experience - Edit";
const EXPERIENCE_DELETE = "Experience - Delete";
const EXPERIENCE_DELETE_CANCEL = "Experience - Delete Cancel";
const EXPERIENCE_DELETED = "Experience - Deleted";
const EXPERIENCE_ADD = "Experience - Add ";
const EXPERIENCE_ERROR_TITLE = "Experience - Error - Title";
const EXPERIENCE_ERROR_COMPANY_NAME = "Experience - Error - Company Name";
const EXPERIENCE_ERROR_SALARY = "Experience - Error - Salary";
const EXPERIENCE_ERROR_INDUSTRY = "Experience - Error - Industry";
const EXPERIENCE_ERROR_FROM = "Experience - Error - From";
const EXPERIENCE_ERROR_TO = "Experience - Error - To";
const EXPERIENCE_SAVED = "Experience - Saved";
const LANGUAGE_ERRO_SKILLS = "Language - Erro - skills";
const AVAILABILITY_NOW = "Availability - Now";
const AVAILABILITY_1_WEEK = "Availability - 1 Week";
const AVAILABILITY_1_MONTH = "Availability - 1 Month";
const AVAILABILITY_2_MONTH = "Availability - 2 Month";
const AVAILABILITY_3_MONTH = "Availability - 3 Month";
const AVAILABILITY_ERROR = "Availability - Error ";
const EXPECTED_SALARY_ERROR = "Expected salary - Error";
const CLICK_BACK = "Click Back";

const SC_APPLY_JOB = "apply_job";
const SC_EVENT_HOME_FEED_JOB_CLICKED = "event_home_feed_job_clicked";
const SC_EVENT_HOME_FEED_COMPANY_CLICKED = "event_home_feed_company_clicked";
const SC_EVENT_HOME_FEED_JOB_SHARED = "event_home_feed_job_shared";
const SC_EVENT_HOME_FEED_JOB_SAVED = "event_home_feed_job_saved";
const SC_EVENT_HOME_FEED_JOB_APPLY = "event_home_feed_job_apply";
const SC_RECOMMENDED_JOB_CLICK = "recommended_job_click";
const SC_RECOMMENDED_JOB_APPLY = "recommended_job_apply";

const SC_JOB_DETAIL_COMPANY = "job_detail_company";
const SC_JOB_DETAIL_SHARE = "job_detail_share";
const SC_JOB_DETAIL_JOB_TYPE_SEARCH = "job_detail_job_type_search";
const SC_JOB_DETAIL_SAVE = "job_detail_save";
const SC_JOB_DETAIL_JOB_CATEGORY_SEARCH = "job_detail_job_category_search";
const SC_JOB_DETAIL_JOB_LOCATION_SEARCH = "job_detail_job_location_search";
const SC_JOB_DETAIL_APPLY = "job_detail_apply";
const SC_JOB_DETAIL_REPORT = "job_detail_report";
const SC_JOB_DETAIL_COMPANY_VIEW_ALL = "job_detail_company_view_all";
const SC_JOB_DETAIL_COMPANY_JOB_CLICK = "job_detail_company_job_click";
const SC_JOB_DETAIL_SKILL_ANALYZE = "job_detail_skill_analyze";

const SC_MYPAGE_SAVED_JOB_REMOVE = "mypage_saved_job_remove";
const SC_MYPAGE_SAVED_JOB_APPLY = "mypage_saved_job_apply";
const SC_MYPAGE_SAVED_JOB_CLICKED = "mypage_saved_job_clicked";
const SC_MYPAGE_SAVED_COMPANY_CLICKED = "mypage_saved_company_clicked";

const SC_MYPAGE_APPLIED_JOB_SHARE = "mypage_applied_job_share";
const SC_MYPAGE_APPLIED_JOB_VIEW = "mypage_applied_job_view";
const SC_MYPAGE_APPLIED_COMPANY_CLICKED = "mypage_applied_company_clicked";
const SC_MYPAGE_APPLIED_JOB_CLICKED = "mypage_applied_job_clicked";

const SC_APPLY_JOB_DETAIL_CLICK = "apply_job_detail_click";
const SC_APPLY_BASIC_INFORMATION_EDIT = "apply_basic_information_edit";
const SC_APPLY_BASIC_INFORMATION_ADD = "apply_basic_information_add";
const SC_APPLY_BASIC_INFORMATION_SUBMIT = "apply_basic_information_submit";
const SC_APPLY_BASIC_INFORMATION_SAVE = "apply_basic_information_save";

const SC_APPLY_RESUME_ADD = "apply_resume_add";
const SC_APPLY_RESUME_PREVIEW = "apply_resume_preview";
const SC_APPLY_EXPERIENCE_ADD = "apply_experience_add";
const SC_APPLY_EXPERIENCE_EDIT = "apply_experience_edit";
const SC_APPLY_EXPERIENCE_ADD_CLOSE = "apply_experience_add_close";
const SC_APPLY_EXPERIENCE_ADD_SUBMIT = "apply_experience_add_submit";
const SC_APPLY_EXPERIENCE_EDIT_CLOSE = "apply_experience_edit_close";
const SC_APPLY_EXPERIENCE_EDIT_SUBMIT = "apply_experience_edit_submit";
const SC_APPLY_EXPERIENCE_EDIT_REMOVE = "apply_experience_edit_remove";
const SC_APPLY_EXPERIENCE_REMOVE = "apply_experience_remove";
const SC_APPLY_EXPERIENCE_REMOVED = "apply_experience_removed";
const SC_APPLY_CONFIRMATION_WEB_BACK = "apply_confirmation_web_back";
const SC_APPLY_CONFIRMATION_WEB_SUCCESS = "apply_confirmation_web_success";

const SC_APPLY_THANK_YOU_LATER = "apply_thank_you_later";
const SC_APPLY_THANK_YOU_SUBMIT = "apply_thank_you_submit";
const SC_APPLY_THANK_YOU_CLOSE = "apply_thank_you_close";
const SC_APPLY_THANK_YOU_MYJOB_CLICK = "apply_thank_you_myjob_click";
const SC_APPLY_THANK_YOU_HOME_CLICK = "apply_thank_you_home_click";
const SC_APPLY_THANK_YOU_JOB_CLICK = "apply_thank_you_job_click";
const SC_APPLY_THANK_YOU_PROFILE_CLICK = "apply_thank_you_profile_click";

const GA_LABEL_JOB_DETAIL = "Job Detail";
const GA_LABEL_COMPANY_PAGE = "Company Page";
const GA_LABEL_FEATURED_JOBS = "Featured Jobs";
const SC_APPLY_JOB_HOMEPAGE = "event_home_feed_job_apply_success";
const SC_APPLY_JOB_JOB_DETAIL = "job_detail_apply_success";
const SC_APPLY_JOB_MY_PAGE = "mypage_saved_job_apply_success";
