$.jobunlockClick= function(jid,sec) {
  if ($('#timer-'+jid).hasClass("timerstarted")) {
          return false;
      }
  else
  {
    var display = document.querySelector('#timer-'+jid);
    var timer = new CountDownTimer(sec,jid); // put second here
    timer.onTick(format).onTick(restart).start(); //start countdown
    $("#timer-"+jid).addClass("timerstarted");
  }           
};


function next(next, e) {

    if (next != 0)
    {
        if (e.keyCode != 8)
            document.getElementById(next).focus();
    }

    var codeCount = '';
    $(".clock").each(function (index) {
        var v = $(this).val();
        v = v.trim();
        if (v != '')
        {
            codeCount++;
        }
    });

    if (codeCount > 3)
        $(".login_confirmphonetac").removeClass('disabled');
    else
        $(".login_confirmphonetac").addClass('disabled');
}


function showtawk() {
    Tawk_API.showWidget();
    Tawk_API.maximize();
}


// --------phone & fb login -----------//

function checkFbLoginState(el) {

    var $btn = $(el);

    var isFbApp = isFacebookApp();
    var isInstagram = isInstagramApp();
    
    $btn.button("loading");
    $("#logintype").val('fblogin');
    disableButton('allReset');

    if(isInstagram || isFbApp) {
        if (typeof(FB) != 'undefined' && FB != null) {
            FB.getLoginStatus(function (loginStatusresponse) {
                console.log('loginStatusresponse');
                console.log(JSON.stringify(loginStatusresponse));
                if (loginStatusresponse.status === 'connected') {
                    fbLogin($btn, loginStatusresponse);
                } else {
                    console.log("user not connected to facebook");
                    FB.login(function (response) {

                        if (response.status === 'connected') {
                            console.log("now user connected to facebook");
                            fbLogin($btn, response);
                        } else {
                            console.log('Please log ' + 'into this webpage.');
                        }
                    }, {
                        scope: 'email'
                    });

                }

            });
        }
    }else{
        FB.login(function (response) {

            if (response.status === 'connected') {
                console.log("now user connected to facebook");
                fbLogin($btn, response);
            } else {
                console.log('Please log ' + 'into this webpage.');
            }
        }, {
            scope: 'email'
        });
    }

};


function fbLogin($btn, response) {
    console.log('Welcome!  Fetching your information.... ');

    var access_token = FB.getAuthResponse()['accessToken'];

    FB.api('/me', function (resp) {
        uid = response.authResponse.userID;
        var userId = response.authResponse.userID;
        var userName = resp.name;
        var userEmail = resp.email;
        $("#fbid").val(userId);
        $("#register_name").val(userName);
        $("#register_email").val(userEmail);

        $("#fbuid").val(userId);
        $("#fbaccesstoken").val(access_token);

        $.post(canvas_url + "api/jobseeker/logincheck", {
            'access_token': access_token,
            'uid': uid,
            'name': userName,
            'email': userEmail,
            'user_type': 'jobseeker',
            'id': 'ea78e',
            'login_type': 'facebook',
            '_token': $('meta[name="csrf-token"]').attr('content')
        }, function (data) {

            if (data.status == 'success') //sucess login
            {
                sendGaEvent('Login', 'fb-success', '0');
                sendGaEvent(GA_CATEGORY_LOGIN, GA_CATEGORY_FACEBOOK, SUCCESS_FACEBOOK_LOGIN);

                $btn.button('reset');
                disableButton('allReset');
                if (data.phone_verified == 'no') // User phone is not verified, so ask them to verify the phone number
                {
                    //verify phone
                    sendSCLoginEvent(SC_REGISTER, SC_FACEBOOK_SUCCESS, VALUE_FACEBOOK);
                    $.verifyphone('verify phone', 'login_modal', 'fblogin');
                }
                else //register sucess
                {
                    sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_FACEBOOK, SUCCESS_FACEBOOK_REGISTER);
                    sendSCLoginEvent(SC_LOGIN, SC_FACEBOOK_SUCCESS, VALUE_FACEBOOK);
                    var pagename = $("#pagename").val();
                    if (pagename == 'applyjob') //log in and stay in this page
                    {
                        location.reload();
                    }
                    else {
                        // Normal Login
                        $.notify({message: notify_msg.success.login}, {type: 'success'});
                        var redirectUrl = GetUrlParms('redirect');

                        setTimeout(function () {
                            if (server != 'localhost' && server != 'ricebowljobs.orientaldaily.com.my')
                                canvas_url = canvas_url.replace("http:", "https:");
                            if (applyjoblink != '') {
                                window.location = applyjoblink;
                            }
                            else if (redirectUrl != '')
                                window.location = canvas_url + redirectUrl;
                            else
                                location.reload();
                        }, timeout_redirect);
                    }
                }
            }
            else {
                sendGaEvent(GA_CATEGORY_LOGIN, GA_CATEGORY_FACEBOOK, ERROR_FACEBOOK_LOGIN,data.msg);
                sendSCLoginEvent(SC_REGISTER, SC_FACEBOOK_FAILED, VALUE_FACEBOOK, ACTION_REGISTER_FAILED);

            }

        }, "json");

    });
}


//popover custom temp
var popovercustom = ['<div class="popover popover-custom">',
'<div class="arrow"></div>',
'<div class="popover-content">',
'</div>',
'</div>'].join('');

var userStatus =  ['inactive', 'ban'];
$(document).ready(function () {

    //init phone
    $("#login_phone").intlTelInput({
      separateDialCode : true,
      initialCountry: "MY",
      preferredCountries: ["MY", "SG", "ID", "CN", "TW", "HK","PH", "IN"],
      onlyCountries: ["MY", "ID", "PH", "VN", "TH", "SG","CN", "IN", "PK", "BD"]
    });

    $("#fb_phone").intlTelInput({
      separateDialCode : true,
      initialCountry: "MY",
      preferredCountries: ["MY", "SG", "ID", "CN", "TW", "HK","PH", "IN"],
      onlyCountries: ["MY", "ID", "PH", "VN", "TH", "SG","CN", "IN", "PK", "BD"]          
    });

    $("#register_phone").intlTelInput({
      separateDialCode : true,
      initialCountry: "MY",
      preferredCountries: ["MY", "SG", "ID", "CN", "TW", "HK","PH", "IN"],
      onlyCountries: ["MY", "ID", "PH", "VN", "TH", "SG","CN", "IN", "PK", "BD"]
    });

    
    // ----------- normal login --------- //
    $('#form_jobseeker_login').validate({
        rules: {
            email_address: {required:true},
            password: {required:true}
        },
        messages: {
            email_address: {required:'This field is required'},
            password: {required:'This field is required'}
        },       
        submitHandler: function() {
            //using default submit function below
            return false;
        }
    });

    $("#form_jobseeker_login").on('submit', function(){
      
      var _this = $(this);
      var submitBtn = _this.find('.btn');
      _this.prev('.alert').hide()
      var email_address = $("#email_address").val();
      var password = $("#password").val();
      var remember_me = $("#cb1").is(':checked');
      var user_type = $("#user_type").val();
      var cp = $("#cp").val();
      $("#logintype").val('normal');

      if ($('#form_jobseeker_login').valid()) {
        disableButton('emailLoginButton');
        submitBtn.button("loading");


        $.post(canvas_url + "api/jobseeker/logincheck",{'email_address': email_address, 'password': password, 'remember_me': remember_me, 'cp': cp, 'id': 'ea78e','login_type':'normal','_token': $('meta[name="csrf-token"]').attr('content')}, function (data) {
                                                                                                                      
          if (data.status == 'success')
          {       
            submitBtn.button("reset");
            $('#login_modal').modal('hide');
            disableButton('allReset');

            if (data.phone_verified=='no') // User phone is not verified, so ask them to verify the phone number
            {
              //Added by Ali
              if (data.msg !== undefined ) {
                ga('send', 'event', 'Login', 'email-failed',data.msg+'-'+email_address);
              } else {
                ga('send', 'event', 'Login', 'email-failed','phone not verified-'+email_address);
              }
                                            
              //verify phone
              $.verifyphone('Verify phone','login_modal','normal');
            }
            else
            {
              ga('send', 'event', 'Login', 'email-success','');
                                            
              var pagename = $("#pagename").val();
              if (pagename=='applyjob') //log in and stay in this page
              {
                location.reload();
              }
              else
              {         
                // Normal Login
                $.notify({message: notify_msg.success.login}, {type: 'success'});
                
                var redirectUrl = GetUrlParms('redirect');
                
                if (server != 'localhost' &&  server != 'ricebowljobs.orientaldaily.com.my'){
                  canvas_url = canvas_url.replace("http:", "https:");
                }
                
                if (applyjoblink!=''){
                  window.location = applyjoblink;
                }else if (redirectUrl!=''){
                  window.location = canvas_url + redirectUrl;
                }else{
                  location.reload();
                }
              }
            }       
          }
          else
          {
            //Added by Ali
            if (data.msg !== undefined ) {
              ga('send', 'event', 'Login', 'email-failed',data.msg+'-'+email_address);
            } else {
              ga('send', 'event', 'Login', 'email-failed',email_address);
            }
              disableButton('emailLoginReset');
              submitBtn.button("reset");

            if (data.not_exists=='yes'){
              document.getElementById('login-alert').innerHTML = notify_msg.error.user_isnotexist;
            } else if (data.user_status && (userStatus.indexOf(data.user_status) != -1)){
                document.getElementById('login-alert').innerHTML = notify_msg.error.login_inactive;
            }else if (data.status =='error' && data.msg=='password not set'){
                document.getElementById('login-alert').innerHTML = notify_msg.error.pass_wrong;
            }
            else if (data.status =='error' && data.msg=='password is wrong'){
                $("#password").parent().addClass("has-error");
                document.getElementById('login-alert').innerHTML = notify_msg.error.pass_wrong;
            }
            else{
                $("#email_address").parent().addClass("has-error");
                $("#password").parent().addClass("has-error");
                document.getElementById('login-alert').innerHTML = notify_msg.error.user_wrong;
            }

            _this.parents('.modal-body').find('.alert').show()

          }
        }, "json").fail(function () {
          submitBtn.button("reset");
          $.notify('There seems to be a problem, please try again.');
        });
      }
    });

    //---------- normal signup --------//
    $('#form_signup_modal').validate({
        rules :{
            register_name : {required :true},
            register_email : {required :true,email:true},
            register_password : {required:true, minlength: 8},
     
        },
        messages : {
            register_name : {
                required:'This field is required',
            },
            register_email : {
              required:'This field is required',
              email:'Please enter correct email address'
            },
            register_password : {
              required:'This field is required',
              minlength:'Minimum 8 charactar'
            }

        },
        submitHandler: function() {
            //using default submit function below
            return false;
        }
    });

    $("#form_signup_modal").on('submit', function(){
        var _this = $(this);
        var submitBtn = _this.find('.btn');
        _this.prev('.alert').hide()

        if ($('#form_signup_modal').valid()) {

            submitBtn.button("loading");

            //verify phone
            $.verifyphone('Verify phone','signup_modal','register');
            
        }
    });

    //on show login/register phone modal
    $("#phone_modal").on("show.bs.modal", function(){
      //reset error form
      $("#login_phone_error").hide();
      $("#login_code_error").hide();
      $("#login-error").hide();
      $("#login_phone").parents('.form-group').removeClass("has-error");

      //init
      $('#m-tacphone').hide();
      $('#m-confirmphone').hide();
      $('#m-verfiyphone').show();
    });

    //on show login modal
    $("#login_modal").on("show.bs.modal", function(){
      is_register = false;
      //reset error form
      $('#form_jobseeker_login').prev('.alert').hide()
      $('#form_jobseeker_login').validate().resetForm();
      $('#form_jobseeker_login').find('.form-group').removeClass('has-error');
      $('#form_jobseeker_login').find('.help-block').remove();
    });

    //on show sign email modal
    $("#signup_email").on("show.bs.modal", function(){
      //reset error form
      $('#form_signup_modal').prev('.alert').hide()
      $('#form_signup_modal').validate().resetForm();
      $('#form_signup_modal').find('.form-group').removeClass('has-error');
      $('#form_signup_modal').find('.help-block').remove();
      $('#form_signup_modal').find('input').val('');
      $('#form_signup_modal').find('button').button('reset');
    });

    $("#signup_modal").on("show.bs.modal", function(){
      is_register = true;
    });


    // --------forgot password -----------//
    $(".submit_jsforgotpass").bind("click", function () {
      var fb_phone = $("#fb_phone").val();
      var email_address = $("#fb_email_address").val();

      var $btn = $(this);
      $("#fb_email_address").parent().removeClass("has-error");
      $("#fb_phone").parent().removeClass("has-error");
      $("#fb_email_address_error").hide();
      $("#fb_phone_error").hide();
      
      if ($(".submit_jsforgotpass").hasClass("disabled")) {
        return false;
      } else {
        if (email_address=='' && fb_phone=='')
        {
          $("#fb_email_address").parent().addClass("has-error");
          $("#fb_email_address_error").html('Please enter email address or phone number');
          $("#fb_email_address_error").show();
          return false;
        }
        else if (email_address != '' && validateEmail(email_address)==false)
        {
          $("#fb_email_address").parent().addClass("has-error");
          $("#fb_email_address_error").html('Please enter valid email address');
          $("#fb_email_address_error").show();
          return false;
        }
        else if (fb_phone != '' && validatePhone(fb_phone)==false)
        {
          $("#fb_phone").parent().addClass("has-error");
          $("#fb_phone_error").html('Please enter valid phone number');
          $("#fb_phone_error").show();
          return false;
        }
        else {
          
          if ($('#timer-1').hasClass("timerstarted")) {
          
            $.notify(
                {title: '', message: 'You can only send request after 1 minute.'},
                {type: "danger"}
            );
            return false;
          }
        
          $("#fb_email_address").parent().removeClass("has-error");

          $btn.button("loading");

          if (fb_phone!='')
          {
            fb_phone = cleanPhone(fb_phone,'jobseekerforgotpass');
            email_address = fb_phone;
            $('#success_text_phone').show();
          }else{
            $('#success_text_email').show();
          }
          

          
          $.post(canvas_url + "api/jobseeker/forgotpassword", {'email_address': email_address,'phone': fb_phone, 'id': 'ea78e', '_token': $('meta[name="csrf-token"]').attr('content')}, function (data) {
            if (data.status == 'success') {
              sendGaEvent(ACTION_FORGOT_PASSWORD, GA_CATEGORY_LOGIN, SUCCESS_FORGOT_PASSWORD);
              $("#fb_email_address").val('');
              $("#fpemail").html(email_address);
              
              $.jobunlockClick(1,60);
              $('#m-forget-password').hide();
              $("#m-forget-password-thanks").fadeIn('fast');


              
              ga('send', 'event', 'Forget Password', 'success','');

            } else {
              sendGaEvent(ACTION_FORGOT_PASSWORD, GA_CATEGORY_LOGIN, ERROR_FORGOT_PASSWORD);
              var aaa = txt_email_not_exist;
              if (typeof data.custommsg != 'undefined')
                aaa = data.custommsg;
              //$.notify(aaa);
              
              $("#fb_email_address").parent().addClass("has-error");
              $("#fb_email_address_error").html(aaa);
              $("#fb_email_address_error").show();
              
              ga('send', 'event', 'Forget Password', 'failed',email_address+' - '+aaa);
          
            }
            $btn.button('reset');
          }, "json");
        }
      }
    });  


    /*
    //fb login
    $(".fbregisternew").bind("click", function () {
            
      var $btn = $(this);
      $btn.button("loading");
      $("#logintype").val('fblogin');
      disableButton('fbLoginButton');
      if (typeof(FB) != 'undefined' && FB != null ) {
		  FB.login(function (response) {
			if (response.status === 'connected') {
			  access_token = FB.getAuthResponse()['accessToken'];
			  FB.api('/me', function (resp) {
				uid = response.authResponse.userID;
				var userId = response.authResponse.userID;
				var userName = resp.name;
				var userEmail = resp.email;
				$("#fbid").val(userId);
				$("#register_name").val(userName);
				$("#register_email").val(userEmail);
				
				$("#fbuid").val(userId);
				$("#fbaccesstoken").val(access_token);
	
				$.post(canvas_url + "api/jobseeker/logincheck", {'access_token': access_token, 'uid': uid, 'name': userName, 'email': userEmail, 'user_type': 'jobseeker', 'id': 'ea78e','login_type':'facebook', '_token': $('meta[name="csrf-token"]').attr('content')}, function (data) {
				
				  if (data.status=='success') //sucess login
				  {
          sendGaEvent('Login', 'fb-success','0');
          sendGaEvent(GA_CATEGORY_LOGIN, GA_CATEGORY_FACEBOOK, SUCCESS_FACEBOOK_LOGIN);          
					
          $btn.button('reset');
          disableButton('allReset');          
					if (data.phone_verified=='no') // User phone is not verified, so ask them to verify the phone number
					{
            //verify phone
            sendSCLoginEvent(SC_REGISTER,SC_FACEBOOK_SUCCESS,VALUE_FACEBOOK);
					  $.verifyphone('verify phone','login_modal','fblogin');
					}
					else //register sucess
					{
            sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_FACEBOOK, SUCCESS_FACEBOOK_REGISTER);                 
            sendSCLoginEvent(SC_LOGIN,SC_FACEBOOK_SUCCESS,VALUE_FACEBOOK);
					  var pagename = $("#pagename").val();
					  if (pagename=='applyjob') //log in and stay in this page
					  {
						location.reload();
					  }
					  else
					  {         
						// Normal Login
						$.notify({message: notify_msg.success.login}, {type: 'success'});
						var redirectUrl = GetUrlParms('redirect');
						
						setTimeout(function () {
						  if (server != 'localhost' &&  server != 'ricebowljobs.orientaldaily.com.my')
							canvas_url = canvas_url.replace("http:", "https:");
						  if (applyjoblink!='')
						  {
							window.location = applyjoblink;
						  }
						  else if (redirectUrl!='')
							window.location = canvas_url + redirectUrl;
						  else
              location.reload();
              sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_FACEBOOK, ERROR_FACEBOOK_REGISTER); 
						}, timeout_redirect);             
					  }
					}           
				  }
				  else
				  {
            sendGaEvent('Login', 'fb-failed',data.msg);
            sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_FACEBOOK, ERROR_FACEBOOK_LOGIN); 
            sendSCLoginEvent(SC_REGISTER,SC_FACEBOOK_FAILED,VALUE_FACEBOOK,ACTION_REGISTER_FAILED);
              
				  }
				  
				}, "json");
				
			  });
			} else {
        sendSCLoginEvent(SC_LOGIN,SC_FACEBOOK_FAILED,VALUE_FACEBOOK,ACTION_REGISTER_FAILED);
        $btn.button('reset');
        $('.fbregisternew').button('reset');
        $('.googleLogin').removeAttr('disabled');
        $('.phone-login').removeAttr('disabled');
        $('.email-login').removeAttr('disabled');
        $('.password-login').removeAttr('disabled'); 
        $('.button-login').removeAttr('disabled'); 
			}
		  }, {
			scope: 'email'
		  });
	  }
    });
    */

    var isPhoneConfirmed = 'no';
    
    
    //request again
    $('.btn-requesttacagain').bind('click', function(){
       if($(this).hasClass('disabled')){
          //$('.btn-requesttacagain').popover('hide');
          return false;
       }
       else{
          //$('.btn-requesttacagain').popover('toggle');
          $.callPhoneVerify();
       }
    });

    //init popover
    $('.btn-requesttacagain').popover({
        trigger : "manual",
        template : popovercustom,
        placement : 'top',
        html : true,
        content : '<div class="xs-p-10"><div class="row">\
        <div class="col-xs-6 text-center b-right">\
        <a class="link link-reset" onclick="$.triggerSendCode();"><i class="fa fa-lg fa-envelope-o"></i><p class="xs-mt-5 xs-mb-0 l-h-1">Send code <br> via sms</p></a>\
        </div>\
        <div class="col-xs-6 text-center">\
        <a class="link link-reset" onclick="$.callPhoneVerify();"><i class="fa fa-lg fa-phone"></i><p class="xs-mt-5 xs-mb-0 l-h-1">Send code <br> via phone</p></a>\
        </div>\
        <div></div>',
    });

    //confirm phone number
    $(".login_confirmphone").bind("click", function () {
      $("#isPhoneConfirmed").val('yes');

        var tacType = $("#tacType").val();
        var dataType = 'tac-'+tacType;

        $('.login_sendphonetac#'+dataType).trigger('click');
    });

    $(".login_confirmphone2").bind("click", function () {
      $("#isPhoneConfirmed").val('yes');
      $(".login_callphonetac").trigger('click');
    });

    //back to verify screen
    $(".login_confirmphoneback").bind("click", function () {
      $('#m-verfiyphone').show();
      $('#m-confirmphone').hide();
      $("#isPhoneConfirmed").val('no');
    });

    //send code 
    $(".login_sendphonetac").bind("click", function () {
      if (isLogin == 0 ) {
        sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_SUCESS);
      } else {
        sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_SUCESS);
      }

      if ($('#timer-111').hasClass("timerstarted")) { 
        if (isLogin == 0 ) {
          sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_ERROR);
        } else {
          sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_ERROR);
        }
        $.notify(
            {title: '', message: 'You can only send request after 1 minute.'},
            {type: "danger"}
        );
        return false;
      }
      var _this = $(this);  
      var login_phone = $("#login_phone").intlTelInput("getNumber");
      var logintype = $("#logintype").val();
      //Added by Ali
      // nurul: keep + in front of phone number to fix JW-88
      // based on https://github.com/googlei18n/libphonenumber/blob/master/FAQ.md#why-wasnt-the-country-code-removed-when-parsing
      // login_phone = login_phone.replace("+",'');
      var countryData = $("#login_phone").intlTelInput("getSelectedCountryData");
      selectedDialCode = countryData['dialCode'];

      //reset
      $('.tac-input').find('input').val('');
      $("#login_phone_error").hide();
      $("#login_phone").parents('.form-group').removeClass("has-error");
      $("#login_code_error").hide();
      
      
      $("#keyinsms").show();
      $("#keyinsms_already").hide();


      var cleanLoginPhone = login_phone.replace("+",'');
      if (login_phone == '' || cleanLoginPhone == selectedDialCode)
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html(txt_otvc.phone_missing);
        $("#login_phone_error").show();
        return false;
      }
      if (!$("#login_phone").intlTelInput("isValidNumber"))
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html(txt_otvc.phone_incorrect);
        $("#login_phone_error").show();
        ga('send', 'event', 'Phone Validation', 'invalid phone number via TelInput',login_phone);
        return false;
      }
      else
      {

          var tacType =  $(this).data("type");

        var platform_id = $("#imp_platform_id").val();    
        if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
        

        //confirm phone number
        var isPhoneConfirmed = $("#isPhoneConfirmed").val();
        if (isPhoneConfirmed=='no')
        {
          $('#m-verfiyphone').hide();
          $('#m-confirmphone').show();
          $('.login_confirmphone2').hide();
          $('.login_confirmphone').show();
          $("#lgconfirmphone").html(formatPhoneNumber(login_phone));

          var tacType =  $(this).data("type");
          $('#tacType').val(tacType);
          return false;
        }else{
          $('#m-verfiyphone').show();
          $('.login_confirmphone2').hide();
          $('.login_confirmphone').show();
          $('#m-confirmphone').hide()
        }

          if(logintype == 'register')
          {
              var jsonData = {
                  phone: cleanLoginPhone,
                  '_token': $('meta[name="csrf-token"]').attr('content'),
                  '_method': "POST"
              };

              var request = $.ajax({
                  url: canvas_url+"api/jobseeker/checkNewRegister",
                  data: jsonData,
                  type: 'POST',
                  crossDomain: true,
                  dataType: 'json'
              });

              request.done(function (data) {
                  if (data.success) {
                      var jsonData = {
                          platformId: platform_id,
                          sender: 'web',
                          type: $('#tacType').val(),
                          recipient: login_phone,
                          country_code: selectedDialCode,
                          '_token': $('meta[name="csrf-token"]').attr('content'),
                          '_method': "GET"
                      };

                      var request = $.ajax({
                          url: canvas_url+"api/otvc/generate",
                          data: jsonData,
                          type: 'GET',
                          crossDomain: true,
                          dataType: 'json'
                      });


                      _this.button("loading");
                      $(".login_callphonetac").addClass('disabled');
                      _this.siblings().addClass('disabled');
                      $("#login_phone").attr("disabled",true);

                      try {
                          var tacSource = $("#tac_source").val();
                          sendGaEvent(tacSource, GA_CATEGORY_TAC, "");
                      }catch (e){
                          console.log(e);
                      }

                      request.done(function (data) {
                          if (isLogin == 0 ) {
                              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_SUCCESS_CALL);
                          } else {
                              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_SUCCESS_CALL);
                          }

                          _this.button("reset");
                          _this.siblings().removeClass('disabled');
                          $(".login_callphonetac").removeClass('disabled');
                          $("#login_phone").attr("disabled",true);

                          if (data.success)
                          {

                              $.jobunlockClick(111,60);
                              $("#generateotvcid").val(data.id);
                              $("#generateotvc").val(data.code);
                              $('#m-tacphone').show();
                              $('#m-verfiyphone').hide();
                              $('#m-confirmphone').hide();
                              $('#timer-111').show();
                              $('#request_txt').show();
                              $('#sms_text').hide();
                              $('#btn_resend').hide();


                              if(tacType == "whatsapp"){
                                  notify(txt_otvc.sent_whatsapp, 'warning');
                              }else if(tacType == "sms"){
                                  notify(txt_otvc.sent_sms, 'warning');
                              }
                              $(".btn-requesttacagain").show().addClass('disabled');

                              sendGaEvent('TAC', 'request tac-success',login_phone);

                          } else
                          {
                              if (data.status=='validation_error')
                              {
                                  sendGaEvent('Phone Validation', 'invalid phone number via api',login_phone);
                                  $("#login-error").html('Please enter correct phone number.');
                                  sendGaEvent('TAC', 'request tac-failed invalid phone',login_phone);
                              }
                              else
                              {
                                  $("#login-error").html(data.error);
                                  sendGaEvent('TAC', 'request tac-failed',login_phone+'-'+data.error);
                              }

                              $("#login-error").show();
                              _this.button("reset");
                              _this.siblings().removeClass('disabled');
                              $("#login_phone").attr("disabled",false);

                          }
                      });

                      request.fail(function (jqXHR, textStatus) {
                          if (isLogin == 0 ) {
                              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_ERROR_CALL);
                          } else {
                              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_ERROR_CALL);
                          }
                          _this.button("reset");
                          _this.siblings().removeClass('disabled');
                          $("#login_phone").attr("disabled",false);

                          console.log(jqXHR);
                          console.log(textStatus);
                          $("#login-error").html('Invalid Phone Number. Please enter correct phone number.');
                          $("#login-error").show();
                          $("#login-footer").show();

                      });
                  }
                  else
                  {
                      $("#login-error").html(data.message);
                      $("#login-error").show();
                      $("#login-footer").show();
                  }

              });

              request.fail(function (jqXHR, textStatus) {
                  if (isLogin == 0 ) {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_ERROR_CALL);
                  } else {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_ERROR_CALL);
                  }
                  _this.button("reset");
                  _this.siblings().removeClass('disabled');
                  $("#login_phone").attr("disabled",false);

                  console.log(jqXHR);
                  console.log(textStatus);
                  $("#login-error").html('Invalid Phone Number. Please enter correct phone number.');
                  $("#login-error").show();
                  $("#login-footer").show();

              });
          }
          else
          {
              var jsonData = {
                  platformId: platform_id,
                  sender: 'web',
                  type: $('#tacType').val(),
                  recipient: login_phone,
                  country_code: selectedDialCode,
                  '_token': $('meta[name="csrf-token"]').attr('content'),
                  '_method': "GET"
              };

              var request = $.ajax({
                  url: canvas_url+"api/otvc/generate",
                  data: jsonData,
                  type: 'GET',
                  crossDomain: true,
                  dataType: 'json'
              });


              _this.button("loading");
              $(".login_callphonetac").addClass('disabled');
              _this.siblings().addClass('disabled');
              $("#login_phone").attr("disabled",true);

              try {
                  var tacSource = $("#tac_source").val();
                  sendGaEvent(tacSource, GA_CATEGORY_TAC, "");
              }catch (e){
                  console.log(e);
              }

              request.done(function (data) {
                  if (isLogin == 0 ) {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_SUCCESS_CALL);
                  } else {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_SUCCESS_CALL);
                  }

                  _this.button("reset");
                  _this.siblings().removeClass('disabled');
                  $(".login_callphonetac").removeClass('disabled');
                  $("#login_phone").attr("disabled",true);

                  if (data.success)
                  {

                      $.jobunlockClick(111,60);
                      $("#generateotvcid").val(data.id);
                      $("#generateotvc").val(data.code);
                      $('#m-tacphone').show();
                      $('#m-verfiyphone').hide();
                      $('#m-confirmphone').hide();
                      $('#timer-111').show();
                      $('#request_txt').show();
                      $('#sms_text').hide();
                      $('#btn_resend').hide();


                      if(tacType == "whatsapp"){
                          notify(txt_otvc.sent_whatsapp, 'warning');
                      }else if(tacType == "sms"){
                          notify(txt_otvc.sent_sms, 'warning');
                      }
                      $(".btn-requesttacagain").show().addClass('disabled');

                      sendGaEvent('TAC', 'request tac-success',login_phone);

                  } else
                  {
                      if (data.status=='validation_error')
                      {
                          sendGaEvent('Phone Validation', 'invalid phone number via api',login_phone);
                          $("#login-error").html('Please enter correct phone number.');
                          sendGaEvent('TAC', 'request tac-failed invalid phone',login_phone);
                      }
                      else
                      {
                          $("#login-error").html(data.error);
                          sendGaEvent('TAC', 'request tac-failed',login_phone+'-'+data.error);
                      }

                      $("#login-error").show();
                      _this.button("reset");
                      _this.siblings().removeClass('disabled');
                      $("#login_phone").attr("disabled",false);

                  }
              });

              request.fail(function (jqXHR, textStatus) {
                  if (isLogin == 0 ) {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_ERROR_CALL);
                  } else {
                      sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_ERROR_CALL);
                  }
                  _this.button("reset");
                  _this.siblings().removeClass('disabled');
                  $("#login_phone").attr("disabled",false);

                  console.log(jqXHR);
                  console.log(textStatus);
                  $("#login-error").html('Invalid Phone Number. Please enter correct phone number.');
                  $("#login-error").show();
                  $("#login-footer").show();

              });
          }
      }
    });

  $(".login_callphonetac").bind("click", function () {
      if ($('#timer-111').hasClass("timerstarted")) {         
        $.notify(
            {title: '', message: 'You can only send request after 1 minute.'},
            {type: "danger"}
        );
        return false;
      }
      
      var _this = $(this);  
      var platform_id = $("#imp_platform_id").val();  
      if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
          
      //reset
      $('.tac-input').find('input').val('');
      $("#login_phone_error").hide();
      $("#login_code_error").hide();
      
      $("#login_phone").parents('.form-group').removeClass("has-error");

      var login_phone = $("#login_phone").intlTelInput("getNumber");
      //Added by Ali
      login_phone = login_phone.replace("+",'');
      var countryData = $("#login_phone").intlTelInput("getSelectedCountryData");
      selectedDialCode = countryData['dialCode'];
            
      if (login_phone == '' || login_phone == selectedDialCode)
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html('Please enter your phone number');
        $("#login_phone_error").show();
        return false;
      }
      if (!$("#login_phone").intlTelInput("isValidNumber"))
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html('Please enter correct phone number');
        $("#login_phone_error").show();
        ga('send', 'event', 'Phone Validation', 'invalid phone number via TelInput',login_phone);
        return false;
      }
      else
      {
        
        var isPhoneConfirmed = $("#isPhoneConfirmed").val();
        if (isPhoneConfirmed=='no')
        {
          $('#m-verfiyphone').hide();
          $('#m-confirmphone').show();
          $('.login_confirmphone').hide();
          $('.login_confirmphone2').show();
          $("#lgconfirmphone").html(formatPhoneNumber(login_phone));
          return false;
        }else{
          $('.login_confirmphone').hide();
          $('.login_confirmphone2').show();
          $('#m-verfiyphone').show();
          $('#m-confirmphone').hide()
        }

        var jsonData = {
          platformId: platform_id,
          sender: 'web',
          type: 'sms',
          recipient: login_phone,
          country_code: selectedDialCode,
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "GET"
        };

        var request = $.ajax({
          url: canvas_url+"api/otvc/phoneVerify",
          data: jsonData,
          type: 'GET',
          crossDomain: true,
          dataType: 'json'
        });

        _this.button("loading");
        $(".login_sendphonetac").addClass('disabled');
        _this.next().addClass('disabled');
        $("#login_phone").attr("disabled",true);

        
        request.done(function (data) {

          _this.button("reset");
          $(".login_sendphonetac").removeClass('disabled');
          _this.next().removeClass('disabled');
          $("#login_phone").attr("disabled",true);

          if (data.success)
          {
            $("#generateotvcid").val(data.id);
            $("#generateotvc").val(data.code);
            $.jobunlockClick(111,60);
            $('#m-tacphone').show();
            $('#m-verfiyphone').hide();
            $('#m-confirmphone').hide();
            $('#timer-111').show();
            $('#request_txt').show();
            $('#sms_text').hide();
            $('#btn_resend').hide();

            $(".btn-requesttacagain").show().addClass('disabled');

            ga('send', 'event', 'TAC', 'request tac-success',login_phone);
          } 
          else
          {
            $("#login-error").html('Something wrong Please Use SMS');
            $("#login-error").show();
            ga('send', 'event', 'TAC-Call', 'Failed-' + data.status, login_phone);
           
            
          }
        });

        request.fail(function (jqXHR, textStatus) {
          _this.button("reset");
          $("#login_code_error").html(textStatus);
          $("#login_code_error").show();
        }); 
      }
    });


    $('#resend-toggle').on('click', function () {
        $(this).hide();
        $('#resend-section').show();
    });

    function formatPhoneNumber(phoneNumberString) {
      var cleaned = phoneNumberString.replace(/[^\d]/g, "");
      var len = cleaned.length;
      var match = cleaned.match(/(\d{2})(\d{3})(\d{4})(\d{4})/);
      if(len == 12)
      {
        var match = cleaned.match(/(\d{2})(\d{3})(\d{3})(\d{4})/);
      }
      if (match) {
        return '+' + match[1] + ' ' + match[2] + ' ' + match[3]+ ' ' + match[4];
      }
      return cleaned;
    }

    
    //already have code
    $(".login_alreadyhavetac").bind("click", function () {

      if($(this).hasClass('disabled')){
        return false;
      }
      $(".btn-requesttacagain").hide();
      if ($('#timer-111').hasClass("timerstarted")) { 
        $(".btn-requesttacagain").show();
      }else{
        $(".btn-requesttacagain").hide();
      }

      //reset
      $('.tac-input').find('input').val('');
      $("#login_phone_error").hide();
      $("#login_code_error").hide();
      
      $("#login_phone").parents('.form-group').removeClass("has-error");

      var login_phone = $("#login_phone").intlTelInput("getNumber");
      //Added by Ali
      login_phone = login_phone.replace("+",'');
      var countryData = $("#login_phone").intlTelInput("getSelectedCountryData");
      selectedDialCode = countryData['dialCode'];
            
      if (login_phone == '' || login_phone == selectedDialCode)
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html(txt_otvc.phone_missing);
        $("#login_phone_error").show();
        return false;
      }
      if (!$("#login_phone").intlTelInput("isValidNumber"))
      {
        $("#login_phone").parents('.form-group').addClass("has-error");
        $("#login_phone_error").html(txt_otvc.phone_incorrect);
        $("#login_phone_error").show();
        ga('send', 'event', 'Phone Validation', 'invalid phone number via TelInput',login_phone);
        return false;
      }
      else
      {

        $("#m-tacphone").show();
        $("#m-verfiyphone").hide();
        $("#m-confirmphone").hide();
        $("#timer_txt").hide();
        $("#request_txt").hide();
        $("#btn_resend").hide();
        $("#keyinsms").hide();
        $("#keyinsms_already").show();
      }
    });

    //confirm code
    $(".login_confirmphonetac").bind("click", function () {
      
      if ($(".login_confirmphonetac").hasClass("disabled")) {
        return false;
      } else {

        var sms_code = '';    
        $(".clock").each(function (index) {
          var v = $(this).val();
                v = v.trim();
                if (v!='')
                {
                  sms_code += v;
                }
        });
        
        $("#login_code").val(sms_code);

        var tacType = $("#tacType").val();
        var login_code = $("#login_code").val();
        $("#login_code_error").hide();
        var _this = $(this);
        
        //  var login_phone = $("#login_phone").val();
        var login_phone = $("#login_phone").intlTelInput("getNumber");
        if (login_code=='')
        {
          $("#login_code_error").html('Please enter sms code');
          $("#login_code_error").show();
          return false;
        }
        else
        {
          //    var selectedDialCode = $("#m-verfiyphone .selected-dial-code").text();
          //    if (selectedDialCode=='') 
          //      selectedDialCode = '60';
          //    else selectedDialCode = selectedDialCode.replace("+",'');
          
          var platform_id = $("#imp_platform_id").val();  
          if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
          
          //    login_phone = cleanPhone(login_phone,'newlogin');
          //    login_phone = login_phone.replace('<font><font>60</font></font>','60');
          //    
          //Added by Ali
          var countryData = $("#login_phone").intlTelInput("getSelectedCountryData");
          selectedDialCode = countryData['dialCode'];
          // login_phone = login_phone.replace("+",'');

          var logintype = $("#logintype").val();
          
          var jsonData = {
            platformId: platform_id,
            sender: 'web',
            type: tacType,
            country_code: selectedDialCode,
            recipient: login_phone,
            code: login_code,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
          };

          var request = $.ajax({
            url: canvas_url+"api/otvc/verify",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
          });

          _this.button("loading");

          request.done(function (data) {

            _this.button("reset");

            if (data.success)
            {
                sendGaEvent('TAC', 'confirm tac-success','0');
                sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , CONFIRM_SUCCESS_TAC_LOGIN);
                var otvcIdEl = $("#generateotvcid");

                if(otvcIdEl.val() == 0){
                    otvcIdEl.val(data.id);
                }
                $.updateUserVerified();

                // ga('send', 'pageview', 'register_success');
                sendPageView('register_success', '/register_success', 'register_success');
                sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , CONFIRM_SUCCESS_TAC_REGISTER);

            } 
            else
            {
              sendGaEvent('TAC', 'confirm tac-failed',logintype+' - '+login_phone+' - '+login_code+' - '+data.error);
              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , CONFIRM_ERROR_TAC_REGISTER);
              //ga('send', 'event', 'confirm tac', 'fail','0');
              //ga('send', 'event', 'failed tac', logintype+' - '+login_phone+' - '+login_code,data.status);
              $("#login_code_error").html(data.error);
              $("#login_code_error").show();
              $("#footer-cs").show();
            }
          });

          request.fail(function (jqXHR, textStatus) {
            sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , CONFIRM_ERROR_TAC_LOGIN);
            _this.button("reset");
            ga('send', 'event', 'TAC', 'confirm tac-failed', logintype+' - '+login_phone+' - '+login_code,textStatus);
            //ga('send', 'event', 'failed tac error', textStatus,'0');
            $("#login_code_error").html(textStatus);
            $("#login_code_error").show();
            $("#footer-cs").show();
          });

        }

      }      
    });


    $('body').on('click', '.show-live-chat', function (e) {
        console.log("show tawk to")
        showtawk();
    });

    $.verifyphone = function(title,goback,action){

      //open phone modal and hide other modal
      $('.modal').modal('hide');
      $('#phone_modal').data('bs.modal',null); // fixing for statix backdrop
      $("#phone_modal").modal({backdrop: "static", keyboard: "false"});

      //change title
      $('#phone_modal').find('.modal-title').html('\
        <a href="#'+goback+'" data-dismiss="modal" data-toggle="modal" class="close-custom"><i class="fa fa-chevron-left"></i></a> '+title+'\
      ');
      
      //reset
      $('#m-tacphone').hide();  
      $('#m-verfiyphone').show(); 
      $('#m-confirmphone').hide();
      $("#login_phone").val('');
      $('.tac-input').find('input').val('');
      $('.login_sendphonetac').button("reset");
      $('.login_alreadyhavetac').removeClass('disabled');
      $("#login_phone").attr("disabled",false);
      $("#isPhoneConfirmed").val('no');

      $('#tac_source').val(goback);


      //set type of login
      if(action == 'register'){

        $("#logintype").val('register');

      }else if(action == 'normal'){

        $("#logintype").val('normal');

      }else if(action == 'googleLogin'){

        $("#logintype").val('googleLogin');

      }else if(action == 'fblogin'){

        $("#logintype").val('fblogin');

      }else if(action == 'byphone'){

        $("#logintype").val('byphone');
      }  
    }

    $.emailoginphoneerror = function (){
      var email_address = $("#email_address").val();
      ga('send', 'event', 'Login Others', 'email-to-phone',email_address);
      $.verifyphone('Login via phone','login_modal','byphone');
    }

    $.triggerSendCode = function(type){


      if ($('#timer-111').hasClass("timerstarted")) {
         
        $.notify(
            {title: '', message: 'Please wait until the timer finish.'},
            {type: "danger"}
        );
        return false;
      }
      else
      {
        var login_phone = $("#login_phone").intlTelInput("getNumber");
        var logintype = $("#logintype").val();
        $.jobunlockClick(111,60);
        $('.btn-requesttacagain').popover('hide');
        $("#m-verfiyphone").hide();
        $("#m-tacphone").show();
        $("#timer-111").show();
        $("#isPhoneConfirmed").val('no');
        $('#btn_resend').hide();


        $('#tacType').val(type);
        login_phone = login_phone.replace("+",'');      
        var platform_id = $("#imp_platform_id").val();    
        if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
        var selectedDialCode = $("#m-verfiyphone .selected-dial-code").text();
          if (selectedDialCode=='') 
            selectedDialCode = '60';
          else selectedDialCode = selectedDialCode.replace("+",'');

        //confirm phone number
        var jsonData = {
          platformId: platform_id,
          sender: 'web',
          type: type,
          recipient: login_phone,
          country_code: selectedDialCode,
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "GET"
        };

        var request = $.ajax({
          url: canvas_url+"api/otvc/generate",
          data: jsonData,
          type: 'GET',
          crossDomain: true,
          dataType: 'json'
        });

         request.done(function (data) {

          if (data.success)
          {
              if(type == "whatsapp"){
                  notify(txt_otvc.sent_whatsapp, 'warning');
              }else if(type == "sms"){
                  notify(txt_otvc.sent_sms, 'warning');
              }
            $('#request_txt').show();
            ga('send', 'event', 'TAC', 'request tac-repeat with sms',login_phone);

            if (isLogin == 0 ) {
              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_SUCCESS_SMS);
            } else {
              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_SUCCESS_SMS);
            }
          } 
          else
          {
            if (data.status=='validation_error')
            {
              sendGaEvent('Phone Validation', 'invalid phone number via api',login_phone);
              sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_TAC, REQUEST_TAC_ERROR_SMS);
              $("#login_code_error").html('Please enter correct phone number.');
              $("#login_code_error").show();
              ga('send', 'event', 'TAC', 'request tac-failed invalid phone',login_phone);
            }
            else
            {
              $("#login_code_error").html(data.error.replace('SMS','TAC'));
              $("#login_code_error").show();
              $("#footer-cs").show();
              $("#timer-111").hide();
              $('#timer-111').removeClass("timerstarted");
              $("#request_txt").hide();
              $("#btn_resend").show();
              ga('send', 'event', 'TAC', 'request tac-failed',login_phone+'-'+data.error);
            }
            
          }
        });

        request.fail(function (jqXHR, textStatus) {
          if (isLogin == 0 ) {
            sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_LOGIN , REQUEST_TAC_ERROR_SMS);
          } else {
            sendGaEvent(ACTION_CONFIRM_TAC, GA_CATEGORY_REGISTER , REQUEST_TAC_ERROR_SMS);
          }
          $("#login-error2").html('Invalid Phone Number. Please enter correct phone number.');
          $("#login-error2").show();
          
        });
      }
    }

    var is_register = false;
    $.updateUserVerified = function ()
    {
      var logintype = $("#logintype").val();
      var platform_id = $("#imp_platform_id").val();  
      if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
      
      if (logintype=='byphone')
      {
        console.log(logintype);
        var _this = $(".login_confirmphonetac");
        var generateotvcid = $("#generateotvcid").val();
        var selectedDialCode = $("#m-verfiyphone .selected-dial-code").text();
          if (selectedDialCode=='') 
            selectedDialCode = '60';
          else selectedDialCode = selectedDialCode.replace("+",'');
          //    var login_phone = $("#login_phone").val();
          //    login_phone = cleanPhone(login_phone,'newlogin');
                    //Added by Ali
            var login_phone = $("#login_phone").intlTelInput("getNumber");
        login_phone = login_phone.replace("+",'');
        
        var jsonData = {
          platform_id: platform_id,
          country_code: selectedDialCode,
          phone: login_phone,
          id: generateotvcid,
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "POST"
        };
      
        var request = $.ajax({
          url: canvas_url+"api/mobile/jobseeker/phonelogin",
          data: jsonData,
          type: 'POST',
          crossDomain: true,
          dataType: 'json'
        });
      
        _this.button("loading");
      
        request.done(function (data) {
      
          _this.button("reset");
      
          if (data.success)
          {
            ga('send', 'event', 'Login', 'phone-success','');
            sendSCLoginEvent(SC_LOGIN,SC_PHONE_SUCCESS,VALUE_PHONE);


            $.notify({message: notify_msg.success.login}, {type: 'success'});
            
            var redirectUrl = GetUrlParms('redirect');
            
            if (server != 'localhost' &&  server != 'ricebowljobs.orientaldaily.com.my'){
              canvas_url = canvas_url.replace("http:", "https:");
            }
            
            if (applyjoblink!=''){
              window.location = applyjoblink;
            }else if (redirectUrl!=''){
              window.location = canvas_url + redirectUrl;
            }else if (is_register){
              window.location = "/jobseeker/profile";
            }else{
              location.reload();
            }

              if (typeof data.msg != 'undefined' && data.msg == 'new user created'){
                ga('send', 'event', GA_CATEGORY_USER_REGISTER, ACTION_REGISTER_SUCCESS, VALUE_EMAIL)
            }

          } 
          else
          {
            sendSCLoginEvent(SC_LOGIN,SC_PHONE_FAILED,VALUE_PHONE,ACTION_REGISTER_FAILED);
            ga('send', 'event', 'Login', 'phone-failed',login_phone+' - '+data.error);
            $("#login_code_error").html('Something wrong: '+data.error);
            $("#login_code_error").show();
          }
        });
      
        request.fail(function (jqXHR, textStatus) {
          _this.button("reset");

            var  st   = jqXHR.responseJSON.status;

            if (st && (userStatus.indexOf(st) != -1)){
                document.getElementById('login_code_error').innerHTML = notify_msg.error.login_inactive;
            }else{
                $("#login_code_error").html(textStatus);
            }

          $("#login_code_error").show();
          $("#footer-cs").show();
        });
      }else if(logintype=='register'){

        var _this = $(".login_confirmphonetac");

        var phone = $("#login_phone").intlTelInput("getNumber");
        phone = phone.replace("+",'');

        var netCoreId = '';
        if(platform_id == 1)
        {
          netCoreId = NC_ACTIVITY_RB_ADD_CONTACT;
        }
        else if(platform_id == 2)
        {
          netCoreId = NC_ACTIVITY_MK_ADD_CONTACT ;
        }

        var name = $("#register_name").val();
        var email = $("#register_email").val();
        var password = $("#register_password").val();
        var fbid = '0';
        var user_type = 'jobseeker';
        
        _this.button('loading');

        $.post(canvas_url + "api/jobseeker/register", {'name': name, 'email': email, 'password': password, 'phone': phone, 'fbid': fbid, 'id': 'ea78e', '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
            
            if (data.status == 'success') {

                $('#signup_modal').modal('hide');
                _this.button('reset');

                  if(platform_id ==1 || platform_id ==2)
                  {
                    var payload =  {
                        'NAME': name,
                        'EMAIL':email,
                        'MOBILE':phone,
                        'AGE':'0',
                        'CITY':'Kuala Lumpur'
                    };
                    $.netcoreTracking(netCoreId, payload, true);
                    
                      try {
                          var token = data.token;
                          console.log("token1" + token);
                          token = $.decodeToken(token);

                          console.log("token2" + token);
                          $.netcoreAddContact(platform_id, token.user.user_id, email, phone, name);
                      }catch (e) {
                          console.log(e)
                      }
                  }


                if($('#page-home-harikerjaya').length < 0){
                  swal({
                    title: 'Hi, Welcome',
                    text: email,
                    type: 'success',
                    width: 450,
                    showCancelButton : true,
                    confirmButtonText: 'Create profile',
                    cancelButtonText: 'Continue',
                  }).then(function () {
                      window.location = canvas_url + "jobseeker/mypage";
                  },function (dismiss) {
                      if (dismiss === 'cancel') {
                          location.reload();
                      }
                      
                  });
                }else{
                  if(is_register){
                    window.location = "/jobseeker/profile";
                  }else{
                    window.location = applyjoblink;
                  }
                }

                sendSCLoginEvent(SC_REGISTER,SC_PHONE_SUCCESS,VALUE_PHONE);
                ga('send', 'event', GA_CATEGORY_USER_REGISTER, ACTION_REGISTER_SUCCESS, VALUE_EMAIL);

            }else{

              _this.button('reset');
              $("#login_code_error").html(data.msg);
              $("#login_code_error").show();
              ga('send', 'event', GA_CATEGORY_USER_REGISTER, ACTION_REGISTER_FAILED, VALUE_EMAIL);
              sendSCLoginEvent(SC_REGISTER,SC_PHONE_FAILED,VALUE_PHONE,ACTION_REGISTER_FAILED);


            }

        }, "json").fail(function () {
            _this.button('reset');
            $.notify('There seems to be a problem, please try again.');
            ga('send', 'event', GA_CATEGORY_USER_REGISTER, ACTION_REGISTER_FAILED, VALUE_EMAIL)
        });


      }
      else
      {
        // Fb or Emailpass login - So update user phone and login user    
        var _this = $(".login_confirmphonetac");
        var generateotvcid = $("#generateotvcid").val();
        var selectedDialCode = $("#m-verfiyphone .selected-dial-code").text();
          if (selectedDialCode=='') 
            selectedDialCode = '60';
          else selectedDialCode = selectedDialCode.replace("+",'');
          //    var login_phone     = $("#login_phone").val();
                    //Added by Ali
            var login_phone = $("#login_phone").intlTelInput("getNumber");
        login_phone = login_phone.replace("+",'');
        var email_address     = $("#email_address").val();
        var password      = $("#password").val();
        var fbuid         = $("#fbuid").val();
        var fbaccesstoken     = $("#fbaccesstoken").val();
        
        //    login_phone = cleanPhone(login_phone,'newlogin');
        
        var jsonData = {
          platform_id: platform_id,
          country_code: selectedDialCode,
          phone: login_phone,
          email_address: email_address,
          password: password,
          uid: fbuid,
          access_token: fbaccesstoken,
          login_type: logintype,
          'id': 'ea78e',
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "POST"
        };
      
        var request = $.ajax({
          url: canvas_url+"api/jobseeker/verifiedlogin",
          data: jsonData,
          type: 'POST',
          crossDomain: true,
          dataType: 'json'
        });
      
        _this.button("loading");
      
        request.done(function (data) {
      
          _this.button("reset");
      
          if (data.status=='success')
          {
            sendSCLoginEvent(SC_LOGIN,SC_EMAIL_SUCCESS,VALUE_EMAIL);
            ga('send', 'event', 'Login', 'afterverify-success','0');
            $.notify({message: notify_msg.success.login}, {type: 'success'});


            var redirectUrl = GetUrlParms('redirect');
            
            if (server != 'localhost' &&  server != 'ricebowljobs.orientaldaily.com.my'){
              canvas_url = canvas_url.replace("http:", "https:");
            }
            
            if (applyjoblink!=''){
              window.location = applyjoblink;
            }else if (redirectUrl!=''){
              window.location = canvas_url + redirectUrl;
            }else if(is_register){
              window.location = "/jobseeker/profile";
            }else{
              window.location = canvas_url + "jobseeker/mypage";
            }

              if (typeof data.msg != 'undefined' && data.msg == 'new user created'){
                ga('send', 'event', GA_CATEGORY_USER_REGISTER, ACTION_REGISTER_SUCCESS, VALUE_FACEBOOK)
             }
             

          } 
          else
          {
            ga('send', 'event', 'Login', 'afterverify-failed',logintype+'-'+login_phone+'-'+data.msg);
            sendSCLoginEvent(SC_LOGIN,SC_EMAIL_FAILED,VALUE_EMAIL,ACTION_REGISTER_FAILED);
            sendGaEvent(ACTION_CLICK_EMAIL_FAILED, GA_CATEGORY_REGISTER, data.msg)
            $("#login_code_error").html('Something wrong: '+data.error);
            $("#login_code_error").show();
            
          }
        });
      
        request.fail(function (jqXHR, textStatus) {
          _this.button("reset");
          $("#login_code_error").html(textStatus);
          $("#login_code_error").show();
        });
      
      }
    }
    
    $.callPhoneVerify = function ()
    {
      if ($('#timer-111').hasClass("timerstarted")) {
                  
        $.notify(
            {title: '', message: 'Please wait until the timer finish.'},
            {type: "danger"}
        );
        return false;
      }
      else
      {
       
        //hide popover

        var _this = $(".login_confirmphonetac");
        var oid = $("#generateotvcid").val();
        var login_phone = $("#login_phone").intlTelInput("getNumber");
        login_phone = login_phone.replace("+",'');
        var platform_id = $("#imp_platform_id").val();    
        if (typeof platform_id=='undefined' || platform_id==null || platform_id=='' || platform_id==0) platform_id = 1;
        var selectedDialCode = $("#m-verfiyphone .selected-dial-code").text();
        if (selectedDialCode==''){
          selectedDialCode = '60';
        }
        else {
          selectedDialCode = selectedDialCode.replace("+",''); 
        }

        ga('send', 'event', 'TAC', 'request tac-repeat with call',login_phone);

          //confirm phone number
        var jsonData = {
          platformId: platform_id,
          sender: 'web',
          type: 'sms',
          recipient: login_phone,
          country_code: selectedDialCode,
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "GET"
        };

        var request = $.ajax({
          url: canvas_url+"api/otvc/phoneVerify",
          data: jsonData,
          type: 'GET',
          crossDomain: true,
          dataType: 'json'
        });
         request.done(function (data) {

          if (data.success)
          {

            $('.btn-requesttacagain').popover('hide');
            $("#login_code_error").hide();
             $.jobunlockClick(111,60);
            $("#m-verfiyphone").hide();
            $("#m-tacphone").show();
            $("#timer-111").show();
            $("#isPhoneConfirmed").val('no');
            $('#btn_resend').hide();        
            $('#request_txt').show();

              ga('send', 'event', 'TAC-Call', 'Successful', login_phone);
                 // ga('send', 'event', 'TAC', 'send code via phone call success',login_phone);
              $("#login_code_error").html('Calling your number '+login_phone+', please wait for our call.');
              $("#login_code_error").show();
              
              setTimeout(function () {
                $("#login_code_error").hide();
              }, 3000);   
          } 
          else
          {
            if (data.status=='validation_error')
            {
              ga('send', 'event', 'Phone Validation', 'invalid phone number via api',login_phone);
              $("#login_code_error").html('Please enter correct phone number.');
              $("#login_code_error").show();
              ga('send', 'event', 'TAC', 'request tac-failed invalid phone',login_phone);
            }
            else
            {
              $("#login_code_error").html(data.msg);
              $("#login_code_error").show();
              $("#timer-111").hide();
              $('#timer-111').removeClass("timerstarted");
              $("#request_txt").hide();
              $("#btn_resend").show();
              ga('send', 'event', 'TAC', 'request tac-failed',login_phone+'-'+data.msg);
            }
            
          }
        });

        request.fail(function (jqXHR, textStatus) {
          _this.button("reset");
          $("#login_code_error").html(textStatus);
          $("#login_code_error").show();
        }); 
      }  
    }

});

var googleUser = {};
var google_client_id = google_client_id;
var startApp = function() {
  gapi.load('auth2', function(){
  auth2 = gapi.auth2.init({
    client_id: google_client_id,
    cookiepolicy: 'single_host_origin',
  });
  attachSignin(document.getElementById('loginGoogleBtn'));
  attachSignin(document.getElementById('signupGoogleBtn'));
  });
};

function attachSignin(element) {

  auth2.attachClickHandler(element, {},
      function(googleUser) {
          var _this = $(this);
          var submitBtn = _this.find('.btn');
          _this.prev('.alert').hide()
          var profile = googleUser.getBasicProfile();
          var email = profile.getEmail();
          var userId = profile.getId();
          var name = profile.getName();
          var profileImage = profile.getImageUrl();
          var token = {};
          token['Paa'] = profileImage;
          token['ig'] = name;
          token['Eaa'] = userId;

          //var token = googleUser.getAuthResponse().id_token;

          $("#logintype").val('googlelogin');

          $("#email_address").val(email);
          $("#fbaccesstoken").val(JSON.stringify(token));

          var jsonData = {
              'access_token': token,
              'uid': userId,
              'name' : name,
              'email' : email,
              'user_type': 'jobseeker',
              'profileImage' : profileImage,
              'id': 'ea78e',
              'login_type':'google',
              '_token': $('meta[name="csrf-token"]').attr('content'),
              '_method': "POST"
            };

          
          var request = $.ajax({
              url: canvas_url + "api/jobseeker/logincheck",
              data: jsonData,
              type: 'POST',
              crossDomain: true,
              dataType: 'json'
          });

          sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_FACEBOOK, SUCCESS_FACEBOOK_REGISTER);                 


        request.done(function (data) {
          submitBtn.button("reset");
          disableButton('allReset');
          $('#login_modal').modal('hide');

            if (data.phone_verified=='no') //register
            {
              if (data.msg !== undefined ) { 
                sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_GOOGLE, ERROR_GOOGLE_REGISTER,data.msg+'-'+email);
              } else {
                sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_GOOGLE, ERROR_GOOGLE_REGISTER,data.msg+'-'+email);
              }
                                            
              $.verifyphone('Verify phone','login_modal','googleLogin');
              sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_GOOGLE, SUCESS_GOOGLE_REGISTER);                 
              sendSCLoginEvent(SC_REGISTER,SC_GOOGLE_SUCCESS,VALUE_GOOGLE);
            }
            else // login
            {
              sendGaEvent('Login', 'email-success','');
              sendGaEvent(GA_CATEGORY_LOGIN, GA_CATEGORY_GOOGLE, SUCCESS_GOOGLE_LOGIN);                 
                                            
              var pagename = $("#pagename").val();
              if (pagename=='applyjob') 
              {
                location.reload();
              }
              else
              {
                  sendGaEvent(GA_CATEGORY_LOGIN, GA_CATEGORY_GOOGLE, SUCCESS_GOOGLE_LOGIN);
                  sendSCLoginEvent(SC_LOGIN,SC_GOOGLE_SUCCESS,VALUE_GOOGLE);
                // Normal Login
                $.notify({message: notify_msg.success.login}, {type: 'success'});
                
                var redirectUrl = GetUrlParms('redirect');
                
                if (server != 'localhost' &&  server != 'ricebowljobs.orientaldaily.com.my'){
                  canvas_url = canvas_url.replace("http:", "https:");
                }
                
                if (applyjoblink!=''){
                  window.location = applyjoblink;
                }else if (redirectUrl!=''){
                  window.location = canvas_url + redirectUrl;
                }else{
                  location.reload();
                }
              }
            }  
        });

        request.fail(function (jqXHR, textStatus) {
            sendGaEvent(GA_CATEGORY_REGISTER, GA_CATEGORY_GOOGLE, ERROR_GOOGLE_LOGIN,textStatus);
          sendSCLoginEvent(SC_REGISTER,SC_GOOGLE_FAILED,VALUE_GOOGLE,ACTION_REGISTER_FAILED);
          disableButton('googleLoginReset');
          $.notify(txt_got_error, {type: 'danger'});
          return false;
        });
      },
      function(error) {
        disableButton('googleLoginReset');
          console.log(JSON.stringify(error, undefined, 2));
      });

}

$.showRegisterModal = function (){
  $('.modal').modal('hide');
  $('#signup_modal').modal('show');
}

$.facebookLogin= function (){
  $(".fbregisternew").click();
}


$.googleLogin= function (){
  attachSignin(document.getElementById('loginGoogleBtn'));
}



$(".toggle-password").click(function() {

  $(this).toggleClass("fa-eye fa-eye-slash");
  var input = $($(this).attr("toggle"));
  if (input.attr("type") == "password") {
    input.attr("type", "text");
  } else {
    input.attr("type", "password");
  }
});












