/*Define Messages Functions*/

function fbUpdateConversationMute(action, conversationKey, userId) {
    if (typeof (action) === 'undefined' || typeof (conversationKey) === 'undefined' || typeof (userId) === 'undefined') {
        return;
    }
    if (action === 'mute') {
        firebase.database().ref('userConversations/' + userId + '/' + conversationKey).update({'mute': true}).catch(function (error) {
            console.log(error.message);
        });
    }
    if (action === 'unmute') {
        firebase.database().ref('userConversations/' + userId + '/' + conversationKey).update({'mute': false}).catch(function (error) {
            console.log(error.message);
        });
    }
}

function fbChangeToDelivered(userId, conversationKey) {
    if (typeof (userId) === 'undefined' || typeof (conversationKey) === 'undefined') {
        return;
    }
    if (/^\d+$/.test(userId)) {
        userId = parseInt(userId);
    }
    firebase.database().ref('conversationMessages').orderByChild('conversationKey').equalTo(conversationKey).once('value').then(function (messagesSnapshot) {
        if (messagesSnapshot.val() !== null) {
            messagesSnapshot.forEach(function (messageSnapshot) {
                var messageData = messageSnapshot.val();
                if (messageData.receiver === userId) {
                    var messageKey = messageSnapshot.key.trim();
                    if (messageData.message === '-user-is-typing-...-') {
                        //delete typing message
                        firebase.database().ref('conversationMessages/' + messageKey).remove().catch(function (error) {
                            console.log(error.message);
                        });
                    } else {
                        if (messageData.status === 'new') {
                            //change message status to delivered
                            firebase.database().ref('conversationMessages/' + messageKey).update({'status': 'delivered'}).catch(function (error) {
                                console.log(error.message);
                            });
                        }
                    }
                }
            });
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function fbUpdateConversationLock(userID, conversationKey) {
    if (/^\d+$/.test(userID)) {
        userID = parseInt(userID);
    }
    firebase.database().ref('userConversations/' + userID + '/' + conversationKey).on('child_changed', function (lockSnap) {
        var changedChildKey = lockSnap.key.trim();
        var changedChildValue = lockSnap.val();
        if (changedChildKey === 'lock') {
            if (changedChildValue === false && fbUnlockedConversations.findIndex(conversationKey) === -1) {
                fbUnlockedConversations.push(conversationKey);
            }
        }
    }), function (error) {
        console.log(error.message);
    };
}

function fbTextAreaKeyDown(e, fbUserId, source) {
    if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        //if user press Only Enter we send message
        fbSendMessage(fbUserId, source);
    } else {
        if (fbUserIsTypingLock === false) {
            var receiver, conversationKey = '';
            if (source === 'popup') {
                receiver = $("#fb-conversation-receiver").val();
                conversationKey = $("#fb-conversation-key").val();
            }
            if (source === 'inline') {
                receiver = $("#fb-conversation-receiver-inline").val();
                conversationKey = $("#fb-conversation-key-inline").val();
            }
            if (receiver !== '' && conversationKey !== '') {
                var updatedAt = {
                    'updatedAt': firebase.database.ServerValue.TIMESTAMP
                };
                firebase.database().ref('usersTyping/' + conversationKey + '/' + fbUserId).update(updatedAt).catch(function (error) {
                    console.log(error.message);
                });
            }
            fbUserIsTypingLock = true;
            setTimeout(function () {
                fbUserIsTypingLock = false;
            }, 5000);
        }
    }
}

function fbShowAttachment(attachment, message, place) {
    message = decodeURIComponent(message);
    var result = '';
    if (!attachment || !message || !place) {
        return message;
    }
    switch (attachment) {
        case 'image':
            if (place === 'conversation') {
                result = '<i class="mdi mdi-image"></i> Photo';
            }
            if (place === 'chat') {
                result = '<img src="' + message + '" class="img-thumbnail" style="display: block;max-width:230px;max-height:95px;width: auto;height: auto;cursor:pointer;" onclick="showImage(\'' + message + '\');">';
            }
            break;
        case 'audio':
            if (place === 'conversation') {
                result = '<i class="mdi mdi-microphone"></i> Audio';
            }
            if (place === 'chat') {
                result = '<audio controls><source src="' + message + '" type="audio/mpeg">Your browser does not support the audio tag.</audio>';
            }
            break;
        case 'video':
            if (place === 'conversation') {
                result = '<i class="mdi mdi-video"></i> Video';
            }
            if (place === 'chat') {
                result = '<video width="320" height="240" controls><source src="' + message + '" type="video/mp4">Your browser does not support the video tag.</video>';
            }
            break;
        default:
            return message;
            break;
    }
    return result;
}

/*Define Notifications Functions*/

function fbReadNotification(key, fbUserId, nKey) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    //get site URL
    var site = window.location.hostname;
    site = site.split('.').join('_');
    //change a notification read to true, means read
    firebase.database().ref('notifications/' + fbUserId + '/' + key).update({'read': true}).catch(function (error) {
        console.log(error.message);
    });
    //change its status
    firebase.database().ref('notificationsStatus/' + site + '/' + key).set(true).catch(function (error) {
        console.log(error.message);
    });
    //save number of clicks for this notification with this key
    trackEvent({event: 'click', key: 'Read Notification', value: nKey});
}

function fbReadAllNotifications() {
    if( (typeof firebase.auth().currentUser === 'undefined') || (firebase.auth().currentUser  === null)){
        return;
    }
    var fbUserId = firebase.auth().currentUser.uid;
    //get site URL
    var site = window.location.hostname;
    site = site.split('.').join('_');
    //change all user notifications read to true
    firebase.database().ref('notifications/' + fbUserId).orderByChild('read').equalTo(false).once('value').then(function (notificationsSnap) {
        notificationsSnap.forEach(function (notificationSnap) {
            var notificationKey = notificationSnap.key.trim();
            firebase.database().ref('notifications/' + fbUserId + '/' + notificationKey).update({'read': true}).catch(function (error) {
                console.log(error.message);
            });
            //change its status
            firebase.database().ref('notificationsStatus/' + site + '/' + notificationKey).set(true).catch(function (error) {
                console.log(error.message);
            });
        });
    }).catch(function (error) {
        console.log(error.message);
    });
}

/*Define General Functions*/

function fbSignOut() {
    //if user has a connection, remove it
    if (connectionRef !== null) {
        connectionRef.remove();
    }
    //delete all cookies
    $.removeCookie('_fbTokenTimeStamp', {path: '/'});
    $.removeCookie('_fbToken', {path: '/'});
    $.removeCookie('_fbGuestId', {path: '/'});
    //sign out user from firebase
    firebase.auth().signOut().then(function () {
    }, function (error) {
        console.log(error.message);
    });
}

function fbEscapeHtml(s) {
    var div = document.createElement('div');
    div.appendChild(document.createTextNode(s));
    return div.innerHTML;
}


function fbConvertTimeStamp(ts, serverTs, outputType) {
//convert given date to different format
    outputType = (typeof outputType !== 'undefined') ? outputType : 'normal';
    var arrMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    var arrMonthsFull = ['January', 'February', 'March', 'April', 'May', 'Jun', 'July', 'August', 'September', 'October', 'November', 'December'];
    var objectDate = new Date(parseInt(ts));
    var odDay = objectDate.getDate();
    var odMonth = objectDate.getMonth();
    var odYear = objectDate.getFullYear();
    var odHour = objectDate.getHours();
    var odMinute = objectDate.getMinutes();
    var serverDate = new Date(parseInt(serverTs));
    var sdDay = serverDate.getDate();
    var sdMonth = serverDate.getMonth();
    var sdYear = serverDate.getFullYear();
    var result = '';
    if (typeof (txt_today) === 'undefined') {
        var txt_today = 'Today';
    }
    if (typeof (txt_yesterday) === 'undefined') {
        var txt_yesterday = 'Yesterday';
    }
    switch (outputType) {
        case 'normal':
            if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay) {
                result = txt_today + ' ' + (odHour > 9 ? odHour : '0' + odHour) + ':' + (odMinute > 9 ? odMinute : '0' + odMinute);
            } else if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay - 1) {
                result = txt_yesterday + ' ' + (odHour > 9 ? odHour : '0' + odHour) + ':' + (odMinute > 9 ? odMinute : '0' + odMinute);
            } else if (odYear === sdYear) {
                result = odDay + ' ' + arrMonths[odMonth] + ' ' + (odHour > 9 ? odHour : '0' + odHour) + ':' + (odMinute > 9 ? odMinute : '0' + odMinute);
            } else {
                result = objectDate.toLocaleString();
            }
            break;
        case 'date':
            if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay) {
                result = txt_today;
            } else if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay - 1) {
                result = txt_yesterday;
            } else if (odYear === sdYear) {
                result = odDay + ' ' + arrMonthsFull[odMonth];
            } else {
                result = odDay + ' ' + arrMonthsFull[odMonth] + ' ' + odYear;
            }
            break;
        case 'mini':
            if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay) {
                result = (odHour > 9 ? odHour : '0' + odHour) + ':' + (odMinute > 9 ? odMinute : '0' + odMinute);
            } else if (odYear === sdYear && odMonth === sdMonth && odDay === sdDay - 1) {
                result = 'Yesterday';
            } else if (odYear === sdYear) {
                result = odDay + ' ' + arrMonths[odMonth];
            } else {
                result = odDay + ' ' + arrMonths[odMonth] + ' ' + odYear;
            }
            break;
        case 'time':
            result = (odHour > 9 ? odHour : '0' + odHour) + ':' + (odMinute > 9 ? odMinute : '0' + odMinute);
            break;
    }
	
	if (result=='Invalid Date') result = 'N/A';
	
    return result;
}

var fbVis = (function () {
    //check the tab is active or hidden
    var stateKey, eventKey, keys = {
        hidden: "visibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
        msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return function (c) {
        if (c)
            document.addEventListener(eventKey, c);
        return !document[stateKey];
    };
})();

//function censorText(text) {
//    var result = false;
//    var scan = new Censoring();
//    scan.enableFilters(['phone_number', 'email_address', 'url']);
//    scan.prepare(text);
//    if (scan.test()) {
//        result = true;
//    }
//    return result;
//}

function fbCheckAvatarFile(avatar) {
    var result = decodeURIComponent(avatar);
    if (avatar === '') {
        result = 'https://s3-ap-southeast-1.amazonaws.com/brightan-assets/images/others/v1/no-avatar.png';
    }
    return encodeURIComponent(result);
}

function fbIsInArray(theArray, theElement) {
    var result = false;
    theArray.forEach(function (element) {
        if (element === theElement) {
            result = true;
        }
    });
    return result;
}

function fbGetUUID(userID) {
    var key = 'fbUUID-' + userID;
    if (localStorage.getItem(key)) {
        var uuid = localStorage.getItem(key);
    } else {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        localStorage.setItem(key, uuid);
    }
    return uuid;
}

function fbGetExtraData(extraData, ConversationType, source) {
    
    try{
        var parsedData = JSON.parse(extraData);
        switch (ConversationType) {
            case "apply-job":
                var result = {title: parsedData.title, id: parsedData.id};
                break;
            case "recommended-applicant":
                var result = {title: parsedData.title, id: parsedData.id};
                break;
            case "people-search":
                if (source.toLowerCase() === 'employer') {
                    var result = {title: 'People Search', id: parsedData.id};
                } else {
                    var result = {title: parsedData.title, id: parsedData.id};
                }
                break;
        }
        return result;
    }catch(ex){
        console.log(ex);
        if(window.Raven && Raven.isSetup()){
            Raven.captureMessage('Error in fbGetExtraData', {
              level: 'error' 
            });
            Raven.captureException(ex);
        }
        var result = {title: "", id: ""};
        return result;
    }
}