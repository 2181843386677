/////---COUNTDOWN TIMER/////
function CountDownTimer(duration, jid, granularity) {
  this.duration = duration;
  this.jid = jid;
  this.granularity = granularity || 1000;
  this.tickFtns = [];
  this.running = false;
}

function restart() {

  if (this.expired()) {
    //this.remove();
    //alert('expired') // on expired
      var pagename = $("#pagename").val();
    if (pagename=='jobmanager')
    {
      $("#timer-"+this.jid).parents('.job-promo').remove();
      $.post(canvas_url + "api/employer/job/promoexpired/"+jid, {'jid': jid,'_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {            
      }, "json");
    }
    else
    {
      $("#timer-"+this.jid).removeClass("timerstarted");
      if ($("#timer-"+this.jid).hasClass("logintimer"))
      {
        // Login Popup
        $("#timer-"+this.jid).hide();
        //$(".login_sendphonetac").removeClass('disabled');
        //$(".login_sendphonetac").show();
        //$("#login_phone").attr("disabled",false);
        //$("#logintimermsg").hide();
        //$("#loginrequesttacmsg").show();
        $(".btn-requesttacagain").removeClass('disabled');
        $("#request_txt").hide();
        $('#btn_resend').show();
        $('#footer-cs').show();



          $("#wa_resend_txt").hide();
          $("#wa_resend_btn").show();


        //$(".btnrequesttacphonecall").removeClass('disabled');
        //$(".btnrequesttacagain").removeClass('btn-default');
        //$(".btnrequesttacphonecall").removeClass('btn-default');
        //$(".btnrequesttacagain").addClass('btn-primary');
        //$(".btnrequesttacphonecall").addClass('btn-info');
        
      }
    }
    
      /*var pagename = $("#pagename").val();
    if (pagename=='invitefriend' || pagename=='register')
    {
      //$("#timer-"+this.jid).remove();
      $("#timer-"+this.jid).removeClass("timerstarted");
    }
    else
    {
      $("#timer-"+this.jid).parents('.job-promo').remove();
      $.post(canvas_url + "api/employer/job/promoexpired/"+jid, {'jid': jid,'_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {            
      }, "json");
    }*/
  }
}

function format(hours, minutes, seconds) {
  var display = document.querySelector('#timer-'+this.jid);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  display.textContent = hours + ":" + minutes + ":" + seconds;
}
      
CountDownTimer.prototype.start = function() {
  if (this.running) {
    return;
  }
  this.running = true;
  var start = Date.now(),
      that = this,
      diff, obj, jid;

  (function timer() {
    diff = that.duration - (((Date.now() - start) / 1000) | 0);

    if (diff > 0) {
      setTimeout(timer, that.granularity);
    } else {
      diff = 0;
      that.running = false;
    }

    obj = CountDownTimer.parse(diff,jid);
    that.tickFtns.forEach(function(ftn) {
      ftn.call(this, obj.hours, obj.minutes, obj.seconds);
    }, that);
  }());
};

CountDownTimer.prototype.onTick = function(ftn) {
  if (typeof ftn === 'function') {
    this.tickFtns.push(ftn);
  }
  return this;
};

CountDownTimer.prototype.expired = function() {
  return !this.running;
};

CountDownTimer.parse = function(seconds) {
  return {
    'hours': (seconds / 3600) | 0,
    'minutes': (seconds % 3600 / 60) | 0,
    'seconds': (seconds % 60) | 0
  };
};