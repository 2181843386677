var list_job = "";

function OnKeyPressJobSearch(e) {
    if (window.event) {
        e = window.event;
    }
    if ((e.keyCode == 13)) {
        $.SetJobSearch();
        setStoreSearch();
    }
}

function OnClickJobSearch(e) {
    $.SetJobSearch();
    setStoreSearch();
}

function SetGetLocalStorage(method,key,value)
{
    //Since some browsers do not support localStorage, so will put or get the values , only after validating localStorage
    try {
        if (method=='set')
        {
            localStorage.setItem(key, value);
        }
        else if (method=='get')
        {
            localStorage.getItem(key);
        }

        return true;
    }
    catch(e) {
        //console.log(e);
        return false;
    }
}


function saveBrowsingHistory(){
    var currentLocation = window.location.search;
    SetGetLocalStorage('set', storageKey.browseHistory, currentLocation);
}


function getVideo(o, callback) {

    var data = [];
    var defaults = {
        id: 0,
        type: 'all',
        count: 6,
        start: 1,
        random: true,
        '_token': $('meta[name="csrf-token"]').attr('content'),
        '_method': 'GET'
    };
    if (typeof o == 'undefined')
        o = defaults;
    else
        o = $.extend({}, defaults, o);
    $.get(canvas_url + "api/get-video", o, function (d) {
        if (d.status == 'success')
            data = d.data;
        if (typeof callback == "function")
            callback(data);
    }, "json");
}

//joblist view
function changeListView(el,action){

    var _btn = el;
    var _action = action;
    var _list = $('.job-list-wrap')

    if (_action == 'compact') {
        _list.addClass('minimize');
        $(_btn).addClass('active');
        $(_btn).prev().removeClass('active');
        $(".promo-blog-container").css("display","none");
        $(".promo-video-container").css("display","none");
        $(".job-ger-container").hide();
        $(".job-recommend-container").hide();
        $(".sidebar-blog").show();
        $(".sidebar-video").show();
        $(".sidebar-jobger").show();
        $(".noskill-container1").hide();
        $.cookie('joblist_compact_view', 'yes', { expires: 7, path: '/' });
        $.cookie('joblist_detail_view', '', { expires: 7, path: '/' });


    }else if(_action == 'detail'){
        _list.removeClass('minimize');
        $(_btn).addClass('active');
        $(_btn).next().removeClass('active');
        $(".promo-blog-container").css("display","block");
        $(".promo-video-container").css("display","block");
        $(".job-ger-container").hide();
        $(".job-recommend-container").show();
        $(".sidebar-blog").hide();
        $(".sidebar-video").hide();
        $(".sidebar-jobger").hide();
        $(".noskill-container1").show();
        $.cookie('joblist_detail_view', 'yes', { expires: 7, path: '/' });
        $.cookie('joblist_compact_view', '', { expires: 7, path: '/' });
    }

    sendGaEvent(CHANGE_LIST_VIEW, GA_CATEGORY_CLICK, _action);
}

function FilterString(string)
{
    if (string!='' && typeof string!='undefined' && string!=null)
    {
        string = string.replace(" ", "-");
        string = string.replace(".", "-");
        string = string.replace(/[&\/\\#,@+()$~%.'":*?<>{}]/g, '');
        string = string.replace(/ /g, '-');
    }
    return string;
}

function alertMeState() {
    if($('#search_keyword').val() && $('#search_location_2').val()) {
        $('.alert-me').show();
        alertShown();
    } else {
        $('.alert-me').hide();
    }
}

function alertShown() {
    if(!$.cookie('jobAlert')) {
        openAlert();
        $.cookie('jobAlert', 'yes', { expires: 365*100, path: '/' });
    }
}

function clearAllSearchFilters()
{
    var txt_base_education = $("#txt_base_education").val();
    sendGaEvent(ACTION_ALERT_CLEAR, GA_CATEGORY_ALERT, '');
    $("#search_keyword").val('');
    $("#search_location_2").val('');
    $("#search_salary").val('');
    $("#jobsearch_country_name").val('');
    $("#jobsearch_region_name").val('');
    $("#search_country").val('');
    $("#tag_name").val('');
    $(".joblisting_jobtype").each(function (index) {
        $(this).prop("checked",false);
    });

    $("#joblisting_category_id").val('0');
    $(".joblisting_category").each(function (index) {
        $(this).prop("checked",false);
    });

    $(".joblisting_education").each(function (index) {
        $(this).prop("checked",false);
    });
    $('input[name=joblisting_freshgraduate]:checked').prop("checked",false);
    $('input[name=joblisting_fastresponse]:checked').prop("checked",false);
    $('input[name=joblisting_hotjobs]:checked').prop("checked",false);
    $('input[name=joblisting_urgentjobs]:checked').prop("checked",false);
    $('input[name=joblisting_direct_contact]:checked').prop("checked",false);
    $('input[name=joblisting_walkin_interview]:checked').prop("checked",false);
    $('.text-education').html('Top 20<br />' +txt_base_education);

    $("#searchfilters").html('');
    var stateObj = { foo: "bar" };
    var filtertags = '';

    history.replaceState(stateObj, "Search Jobs", canvas_url);
    $.SetJobSearch();

}

function clearSearchFilter(type,val)
{
    $("#filter-"+type+"-"+val).remove();
    var txt_base_education = $("#txt_base_education").val();

    if (type=='keyword')
    {
        $("#search_keyword").val('');
    }

    if (type=='location')
    {
        $("#search_location_2").val('');
    }

    if (type=='salary')
    {
        $("#search_salary").val('');
    }

    if (type=='countrySearch')
    {
        $("#search_country").val('');
    }

    if (type=='jobtype')
    {
        $(".joblisting_jobtype").each(function (index) {
            var v = $(this).attr("data-id");
            if ($(this).is(':checked'))
            {
                if (v==val)
                {
                    $(this).prop("checked",false);
                }
            }
        });
    }

    if (type=='category')
    {
        var joblisting_category_id = $("#joblisting_category_id").val();
        if (val==joblisting_category_id)
        {
            $("#joblisting_category_id").val('0');
        }

        $(".joblisting_category").each(function (index) {
            var v = $(this).attr("data-id");
            if ($(this).is(':checked'))
            {
                if (v==val)
                {
                    $(this).prop("checked",false);
                }
            }
        });
    }

    if (type=='education')
    {
        $(".joblisting_education").each(function (index) {
            var v = $(this).attr("data-id");
            if ($(this).is(':checked'))
            {
                if (v==val)
                {
                    $(this).prop("checked",false);
                }
            }
        });

        $('.text-education').html('Top 20<br />' +txt_base_education);        
    }

    if (type=='freshgraduate')
    {
        $('input[name=joblisting_freshgraduate]:checked').prop("checked",false);
    }

    if (type=='fastresponse')
    {
        $('input[name=joblisting_fastresponse]:checked').prop("checked",false);
    }

    if (type=='hotjobs')
    {
        $('input[name=joblisting_hotjobs]:checked').prop("checked",false);
    }

    if (type=='urgentjobs')
    {
        $('input[name=joblisting_urgentjobs]:checked').prop("checked",false);
    }

    if (type=='direct_contact')
    {
        $('input[name=joblisting_direct_contact]:checked').prop("checked",false);
    }

    if (type == 'iswalkin') {
        $('input[name=joblisting_walkin_interview]:checked').prop("checked", false);
    }

    if (type=='country')
    {
        $("#jobsearch_country_name").val('');
    }

    if (type=='region')
    {
        $("#jobsearch_region_name").val('');
    }

    if (type=='tagsearch')
    {
        $("#tag_name").val('');
    }

    if (type=='catsearch')
    {
        $("#joblisting_category").val('0');
        $("#joblisting_category_name").val('');
    }

    alertMeState();

    $.SetJobSearch();

}

function viewJobPublicContact(view_type,job_id,event){

    if (isLogin=='0') // User not login
    {
        showLoginModal(event);
    }
    else
    {
        $.post(canvas_url + "api/job/addjobinterested", {'job_id': job_id,'view_type':view_type, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
            if (data.status == 'success')
            {
                if (data.warning_msg!='')
                {
                    notify(data.warning_msg, "warning");
                }

                if (view_type=='phone')
                {
                    //$("#job_publicphone"+job_id).html(data.data.phone);
                    $("#showpubliccontact-"+job_id).html('<a href="tel:'+data.data.phone+'"><i class="fa fa-phone"></i> '+data.data.phone+'</a>');
                }
                else if (view_type=='email')
                {
                    //$("#job_publicemail"+job_id).html(data.data.email);
                    $("#showpubliccontact-"+job_id).html('<a href="mailto:'+data.data.email+'?subject=I\'m interested in this position"><i class="fa fa-envelope"></i> '+data.data.email+'</a>');
                }
                else if (view_type=='whatsapp')
                {
                    //window.open("https://api.whatsapp.com/send?phone="+data.data.whatsapp+"&text="+data.data.whatsapp_msg,'_blank');
                    $("#showpubliccontact-"+job_id).html('<a href="https://api.whatsapp.com/send?phone='+data.data.whatsapp+'&text='+data.data.whatsapp_msg+'" target="_blank" class=""><i class="fa fa-whatsapp"></i> '+data.data.whatsapp+'</a>');
                }

            } else {

                notify(data.msg, "warning");
                setTimeout(function () {
                    fbSignOut();
                    window.location = canvas_url + "jobseeker/logout";
                }, 5000);
                return false;
            }
        }, "json");
    }
}

function closeAlert(){
    sendGaEvent(ACTION_ALERT_CANCEL, GA_CATEGORY_ALERT, '');
    $('.alert-container').fadeToggle('fast', function () {
        $('.alert-content').hide();
    });
}

function openAlert(){
    sendGaEvent(ACTION_ALERT_OPEN, GA_CATEGORY_ALERT, '');
    $('.alert-container').fadeToggle('fast', function () {
        $('.alert-content').fadeToggle();
    });
}

function alertNonLogin(){
    sendGaEvent(ACTION_ALERT_OPEN_NON_LOGIN, GA_CATEGORY_ALERT, '');
    $(".add_more_alert").trigger("click");
    $('.alert-container').fadeToggle('fast', function () {
        $('.alert-content').fadeToggle();
        if ($('.alert-re-submit-email').is(':visible')|| $('.alert-submit-email').is(':visible')) {
            $('#set-job').hide();
            $('#editjobalert').hide();
            $('#create-job-alert').hide();
            $('#alertContainer').hide();
            $('#content-edit').hide();
        }
        
    });
}


function alertMe() {
    if($('.jobalert_email').val() == undefined) {
        $('#login_modal').modal('toggle');
        return false;
        
    } else if($('.jobalert_email').val() == ''){
        $('.alert-main-content').hide();
        $('.alert-no-email').show();
        if(!$('#jobalert_email').val()) {
            return false;
        }
    }
    sendGaEvent(ACTION_ALERT_SAVE, GA_CATEGORY_ALERT, '');

    var payload = {
        keywords : $('#search_keyword').val(),
        prefer_locations : $('#search_location_2').val(),
        title : 'Job Alert',
        referer : 'search_result',
        _token: $('meta[name="csrf-token"]').attr('content'),
    }

    payload['jobalert_email'] = $('.jobalert_email').val();
    if($('#jobalert_email').val() != '' && $('#jobalert_email').val() != undefined) {
        payload['jobseeker_email'] = $('#jobalert_email').val();
    }

    $.ajax({
        url: canvas_url + 'api/jobseeker/save-job-alert',
        dataType: 'json',
        data:payload,
        type:'POST',
        success: function (data) {
            sendGaEvent(ACTION_ALERT_SAVE, GA_CATEGORY_ALERT, ACTION_SAVE_JOB_ALERT_NON_LOGIN);
            if(data.status == 'success') {
                $('.alert-content__title.new-feature').hide();
                $('.alert-main-content').hide();
                $(".alert-email-verification").html(data.msg).show();
                $('.alert-content__title.thank-you').show();
                $('.alert-no-email').hide();

                if(data.header == "400"){
                    $('.alert-email-verification').show();
                }else{
                    $('.alert-checked').show();
                    setTimeout(function(){
                        $('.alert-checked').hide();
                        closeAlert();
                    }, 2000);
                }
            }
            else
            {
                if(data.status == 'error' && data.msg != 'Invalid parameters.')
                {
                    $("#account_login_email_error").html(data.msg);
                    $("#account_login_email_error").show();
                }
                else
                {
                    $("#account_login_email_error").html(data.msg);
                    $("#account_login_email_error").hide();
                }
                
            }
        }
    });

}
var choosenIndex = 0;

function toggleJobAlert() {
    $('#create-job-alert').toggle();
    $('#content-edit').toggle();
    $('#alertContainer').toggle();
    $('.alert-content__title.alertContainer').toggle();
}

var isEditJobAlert = false;

const alertArray = [];

function rerenderAlert() {
    $('.alert_div').html('');
    var txt_alert = $("#txt_alert").val();

    alertArray.forEach(function (item, index) {
        var html = ''
        if (alertArray.length === 1) {
            html = '<div class="bg-grey xs-p-20 xs-mb-10" style="border-radius: 10px;">\
            <span style="text-transform: capitalize;">' + item.title + txt_alert + item.location + '\
            <a class="glyphicon glyphicon-pencil jobalertedit" style="color: #1ea6ff; border: 0px; width: 0px; float: right; margin-right: 25px;" data-index="' + index + '"></a></span> \
            </div>';
        } else {
            html =  '<div class="bg-grey xs-p-20 xs-mb-10" style="border-radius: 10px;">\
            <span style="text-transform: capitalize;">' + item.title + txt_alert + item.location + '\
            <a class="glyphicon glyphicon-pencil jobalertedit" style="color: #1ea6ff; border: 0px; width: 0px; float: right; margin-right: 25px;" data-index="' + index + '"></a></span> \
            <a class="glyphicon glyphicon-trash deleteAlert" style="color: #D33759; border: 0px; width: 0px; float: right; margin-right: -20px;" data-index="' + index + '"></a></span> \
        </div>';
        }
        sendGaEvent(GA_CATEGORY_VIEW, GA_CATEGORY_ALERT, ACTION_ALERT_EDIT_NON_LOGIN);
        $('.alert_div').append(html);
    });

    $('.jobalertedit').on('click', function() {
        toggleJobAlert();
        isEditJobAlert = true;
        choosenIndex = $(this).data('index');
        const job = alertArray[choosenIndex];
        var inputTitle = $('#jobalert_title');
        var inputLocation = $('#jobalert_location');
        var inputSalary = $('#expected_salary');
        var inputCurrency = $('#expected_salary_cur');
        inputTitle.val(job.title);
        inputLocation.val(job.location);
        inputSalary.val(job.salary);
        inputCurrency.val(job.currency);
        inputCurrency.attr('selected', 'selected');
        
    });

    $('.deleteAlert').on('click', function() {
        const index = $(this).data('index');
        deleteAlert(index);
    });

    sendGaEvent(GA_CATEGORY_VIEW, GA_CATEGORY_ALERT, ACTION_ALERT_OPEN_NON_LOGIN);
    if (alertArray.length === 3) {
        $(".add_more_alert").hide();
    } else {
        $(".add_more_alert").show();
    }
}

var suggestion_stuff = {};
suggestion_stuff.caret = -1;
suggestion_stuff.pressed_enter = false;
suggestion_stuff.char_count = 0;
suggestion_stuff.min_count = 3; //api call every X number of characters

$('.suggestion-alert').keyup(function (e) {

    if (e.keyCode == 9 || $(this).val() == '') {
        $("#" + $(this).attr('data-target')).hide();
        return false;
    }

    var _this = $(this)

    //types: job, company, area, education
    var id = $(this).attr('id'); //company, job-title
    var type = $(this).attr('data-type'); //company, job-title
    if (type == undefined || type == '')
        type = 'all';
    var target = $(this).attr('data-target'); //string
    var suggestion = $("#" + target); //dom element
    var url = '';
    console.log(id);
    $('#' + target.replace('-suggestion', '-confirm')).val('no');

    // add this to wait until user stop typing and then send to search engine
    clearTimeout(suggestion_stuff.timeout);

    // Make a new timeout set to go off in 500ms
    $('#' + target.replace('-suggestion', '-confirm')).val('no');
    if (suggestion_stuff.char_count < suggestion_stuff.min_count)
        suggestion_stuff.char_count++;
    //if($(this).val().length > 2) {
    if (suggestion_stuff.char_count >= suggestion_stuff.min_count) {
        suggestion_stuff.char_count = 0;
        $.ajax({
            //url: "http://maukerja-search-app-prod.elasticbeanstalk.com/suggestions/"+type+"/find?text=" + $(this).val(),
            url: "https://search.maukerja.my/suggestions/" + type + "/find?text=" + $(this).val(),
            dataType: 'json',
            success: function (data) {
                var html = '<ul data-target="' + target + '" data-field="#' + id + '" style="margin-top: 0px; margin-bottom: 0px; max-height: 200px; overflow-y: scroll; border-radius: 10px; background: #fff; padding: 0px; list-style: none; padding-left: 17px;">';
                var icon = '';
                var area_check = '';
                var vid = 0;
                if (type == 'area')
                {
                    area_check = 'setJobCity';
                    vid = $(this).attr("data-id");
                }
                for (var i = 0; i < data.length; i++) {
                    html += '<li class="dynamic-suggestion-job ' + area_check + '" data-text="' + data[i] + '" data-id="' + id + '"><a href="#" style="border: 0px; display: inline; color: #555555;"><i class="fa fa-map-marker"></i> ' + data[i] + '</a></li>';
                }

                html += '</ul>';
                suggestion.html(html);
                suggestion.show();
                $(".setJobCity").click(function () {
                    var v = $(this).attr("data-text");
                    var i = $(this).attr("data-id");
                    i = i.replace("city-", "");
                    if ($('.peoplesearchpage').length > 0)
                    {
                    } else
                        $.getCityInfo(i, v);
                });
            }
        });
    }/* else {
        suggestion_stuff.char_count++;
        }*/
    if ($(this).val().length < 1) {
        suggestion_stuff.char_count = 0;
        suggestion.hide();
    }
});


function deleteAlert(index) {
    alertArray.splice(index, 1);
    rerenderAlert();
}

$('.save-job').click(function() {
    var inputTitle = $('#jobalert_title');
    var inputLocation = $('#jobalert_location');
    var inputSalary = $('#expected_salary');
    var inputCurrency = $('#expected_salary_cur');
    const job = {
        title: inputTitle.val(),
        location: inputLocation.val(),
        salary: inputSalary.val(),
        currency: inputCurrency.val()
    };
    if (!isJobInputFilled(job)) {
        $('#error-fill-title').show();
        return;
    } else {
        $('#error-fill-title').hide();
    }
    if (isEditJobAlert) {
        alertArray[choosenIndex] = job;
        choosenIndex = 0;
        isEditJobAlert = false;
    } else {
        alertArray.push(job);
    }
    inputTitle.val('');
    inputLocation.val('');
    inputSalary.val('');
    inputCurrency.val('');
    toggleJobAlert();
    rerenderAlert();
});

function isJobInputFilled(job) {
    if (job.title == '' || job.location == '') return false;
    return true;
}

// add job alert

$(".add_more_alert").click(function () {
    var qPage = window.location.search;
    qPage = qPage.replace("-", " ");
    qPage = new URLSearchParams(qPage);
    var qLoc = qPage.get('loc')

    if( qLoc.indexOf('-')){
        qLoc = qLoc.replace("-", " ");
      }

    if (alertArray.length > 2) {
        valert({
            element: $('#city-1'), // create div or using swap
            title: 'Max 3 location allowed'
        });
        return false
    } else if (alertArray.length == 0) {
        const job = {
            title: qPage.get('q'),
            location: qLoc,
            salary: qPage.get('sal'),
            currency: "RM",
        };
        alertArray.push(job);
    } else {
        var inputCurrency = $('#expected_salary_cur');
        inputCurrency.val("RM");
        toggleJobAlert();
        return;
    }

    rerenderAlert();
});

// validate email

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

//   $('.email_completeness').on('click', function () {
//     var id = $(this).attr('data-id')
//     var page = $(this).attr('data-page')

//     swal({
//       title:'Your Job Alert Has Activated',
//       type: 'success',
//       width: 450,
//       confirmButtonText: 'close',
//       confirmButtonClass: 'btn btn-primary ml-10p w-100p-xs',
//       buttonsStyling: false
//     })
//   }

// click alert 

$(document).ready(function () {
    function activateJob() {
        var params = window.location.search;
        params = new URLSearchParams(params);
        if (params.has('activateJob') && params.get('activateJob') == 'true') {
            // show alert success activate
            swal({
                title:'Your Job Alert Has Activated',
                type: 'success',
                width: 450,
                confirmButtonText: 'close',
                confirmButtonClass: 'btn btn-primary ml-10p w-100p-xs',
                buttonsStyling: false
            });
        }
    }
    activateJob();

    $( '.email-alert' ).on('click', function() {
        $('#new-alert').show();
        $('.alert-no-email.user-not-login').show();
        $('#account_login_email_error').hide();
        $('#alertContainer').hide();
        $('.alert-content__title.alertContainer').hide();
        $('.alert-submit-email').hide();
    });

    $( '.non-user-submit' ).on('click', function() {
        const email = $('#jobalert_email').val();
        if (!validateEmail(email)) {
            $("#account_login_email_error").show();
            return;
        } else {
            $("#account_login_email_error").hide();
        }

        //function to check email user register or not
            var payload = {
                email: email,
                jobAlerts: alertArray.map(function (item)  {
                    return {
                        keyword: item.title,
                        location: item.location,
                        salary: item.salary,
                        currency: item.currency,
                    };
                }),
                '_token': $('meta[name="csrf-token"]').attr('content'),
                '_method': "POST"
    
            };

            var request = $.ajax({
                url: canvas_url+'api/job-alert/save',
                data: payload,
                type: 'POST',
                crossDomain: true,
                dataType: 'json'
            });

            request.done(function(data) {
                if (data.success === true) {
                    $('#new-alert').show();
                    $('.alert-submit-email').show();
                    $('.alert-re-submit-email').hide();
                    $('.re-submit-again').hide();
                    $('.alert-no-email.user-not-login').hide();
                    $('#alertContainer').hide();
                    $('.alert-content__title.alertContainer').hide();
                } else if (data.status === "exist" && data.success === false) {
                    $('#new-alert').show();
                    $('.re-submit-again').show();
                    $('.alert-re-submit-email').hide();
                    $('.alert-submit-email').hide();
                    $('.alert-no-email.user-not-login').hide();
                    $('#alertContainer').hide();
                    $('.alert-content__title.alertContainer').hide();    
                } else if (data.status === "error" && data.success === false) {
                    $('#new-alert').show();
                    $('.alert-re-submit-email').show();
                    $('.re-submit-again').hide();
                    $('.alert-submit-email').hide();
                    $('.alert-no-email.user-not-login').hide();
                    $('#alertContainer').hide();
                    $('.alert-content__title.alertContainer').hide();
                }
            });

            request.fail(function (jqXHR, textStatus, errorThrown) {

                showErrorNotification(jqXHR.status)
            });

    });

    
    $( '.submit-new-email' ).on('click', function() {
        const email = $('#jobalert_email').val();
        $('#new-alert').show();
        $('.alert-submit-email').show();
        $('#input-email').html(email);
        $('.alert-no-email.user-not-login').hide();
        $('#alertContainer').hide();
        $('.alert-content__title.alertContainer').hide();
    });

    // function closeAlert2(){
    //     sendGaEvent(ACTION_ALERT_CANCEL, GA_CATEGORY_ALERT, '');
    //     $('.alert-container').fadeToggle('fast', function () {
    //         $('.alert-content').hide();
    //     });
    // }

    var suggestion_stuff = {};
    var suggestion = $("#suggestion");
    suggestion_stuff.caret = -1;
    suggestion_stuff.pressed_enter = false;
    suggestion_stuff.char_count = 0;
    suggestion_stuff.min_count = 2; // api call every X number of characters
    suggestion_stuff.max_count = 35; // maximum character
    // Init a timeout variable to be used below
    suggestion_stuff.timeout = null;
    // suggestion_stuff.timeout_milisecond = 500;
    suggestion_stuff.timeout_milisecond = 150;

    $('#search_keyword').keyup(function (e) {

        //console.log('ku');
        //pressing tab or shift
        if (e.keyCode == 9 || e.keyCode == 16) {
            $("#search-area-suggestion").hide();
        }

        //pressing left or right
        else if (e.keyCode == 37 || e.keyCode == 39) {
            //do nothing
        }

        //pressing enter
        else if (e.keyCode == 13) {
            suggestion_stuff.pressed_enter = true;

            $("#suggestion").hide().removeClass('suggestion-active');
            $('#search_keyword').blur();
            OnKeyPressJobSearch(e);
        }

        //pressing up
        else if (e.keyCode == 38) {
            e.preventDefault();
            var ul = $('#suggestion ul').children();
            if (suggestion_stuff.caret > 0)
                suggestion_stuff.caret--;
            else
                suggestion_stuff.caret = ul.length - 1;
            ul.css('background-color', 'initial');
            $(ul[suggestion_stuff.caret]).css('background-color', '#eee');
            $('#search_keyword').val($(ul[suggestion_stuff.caret]).text().replace(txt_employer, '').replace(txt_job_title, '').trim());
            return false;
        }

        //pressing down
        else if (e.keyCode == 40) {
            e.preventDefault();
            var ul = $('#suggestion ul').children();
            if (suggestion_stuff.caret < (ul.length - 1))
                suggestion_stuff.caret++;
            else
                suggestion_stuff.caret = 0;
            ul.css('background-color', 'initial');
            $(ul[suggestion_stuff.caret]).css('background-color', '#eee');
            $('#search_keyword').val($(ul[suggestion_stuff.caret]).text().replace(txt_employer, '').replace(txt_job_title, '').trim());
            return false;
        }

        //pressing letters
        else {

            // add this to wait until user stop typing and then send to search engine
            clearTimeout(suggestion_stuff.timeout);

            // Make a new timeout set to go off in 500ms
            suggestion_stuff.timeout = setTimeout(function () {
                suggestion_stuff.pressed_enter = false;
                // var suggestion = $("#suggestion");

                suggestion_stuff.char_count = $("#search_keyword").val().length;

                //if($('#search_keyword').val().length > 1) {
                if (suggestion_stuff.char_count >= suggestion_stuff.min_count && suggestion_stuff.char_count < suggestion_stuff.max_count) {
                    //$("#search-bar").addClass('active')
                    $.ajax({
                        url: canvas_url + "api/job/suggest?param=" + $('#search_keyword').val(),
                        dataType: 'json',
                        success: function (data) {
                            //$("#search-bar").addClass('active')
                            if (suggestion_stuff.pressed_enter == false) {

                                var html = '<ul>';
                                for (var i = 0; i < data.job.length; i++) {
                                    html += '<li class="suggestion-job" data-text="' + data.job[i].text + '"><a href="#" class="text-dark"> <i class="fa fa-briefcase"></i> ' + data.job[i].text + '<span class="hide pull-right text-grey">' + txt_job_title + '</span></a></li>';
                                    ///job/'+data.job[i].payload.jobId+'-'+data.job[i].text.replace(/ /g, '-')+'
                                }
                                for (var i = 0; i < data.company.length; i++) {
                                    //html += '<li><a href="/company/'+data.company[i].text.replace(/ /g, '-')+'"><i class="mdi mdi-city"></i> '+data.company[i].text+'</a></li>';
                                    //html += '<li><a href="/company/' + data.company[i].id+'-'+data.company[i].slug + '" class="text-dark"> <i class="fa fa-building"></i> ' + data.company[i].text + '<span class="hide pull-right text-grey">' + txt_employer + '</span></a></li>';
                                    html += '<li class="suggestion-job" data-text="' + data.company[i].text + '"><a href="#" class="text-dark"> <i class="fa fa-building"></i> ' + data.company[i].text + '<span class="hide pull-right text-grey">' + txt_employer + '</span></a></li>';
                                }
                                html += '</ul>';
                                suggestion.html(html);
                                suggestion.show().addClass('suggestion-active');
                                popSearchState(false);
                                var suggestion_jobs = document.querySelectorAll('.suggestion-job');
                                setStoreSearch(suggestion_jobs);
                            }
                        }
                    });
                } else {
                    //suggestion_stuff.char_count++;
                    suggestion_stuff.caret = -1;
                    //suggestion.hide();

                    //return false;
                }
            }, suggestion_stuff.timeout_milisecond);

            if ($('#search_keyword').val().length < 1) {
                suggestion.hide().removeClass('suggestion-active');
                suggestion_stuff.char_count = 0;
                popSearchState(true);
            }
        }
    });

    $('body').on('click', '.suggestion-job', function (e) {
        e.preventDefault();
        var suggestion = $("#suggestion");
        $('#search_keyword').val($(this).attr('data-text'));
        suggestion.hide().removeClass('suggestion-active');
        $.SetJobSearch();
        //$.SearchJob();
    });

    $('#search_location_2').keyup(function (e) {
        //pressing enter
        if (e.keyCode == 13) {
            suggestion_stuff.pressed_enter = true;

            $("#suggestion")
                .hide()
                .removeClass("sugestion-active");
            $('#search_keyword').blur();
            $('#search_location_2').blur();
            $("#search-area-suggestion").hide();
            OnKeyPressJobSearch(e);
        }

        //pressing tab
        else if (e.keyCode == 9) {
            $("#suggestion")
                .hide()
                .removeClass("sugestion-active");
        }
    });


    $('#search_salary').keyup(function (e) {
        //pressing enter
        if (e.keyCode == 13) {
            $('#search_keyword').blur();
            $('#search_location_2').blur();
            $('#search_salary').blur();
            OnKeyPressJobSearch(e);
        }

    });

    $('.suggestion-thing').keyup(function (e) {

        if (e.keyCode == 9 || $(this).val() == '') {
            $("#" + $(this).attr('data-target')).hide();
            return false;
        }

        var _this = $(this)

        //types: job, company, area, education
        var id = $(this).attr('id'); //company, job-title
        var type = $(this).attr('data-type'); //company, job-title
        if (type == undefined || type == '')
            type = 'all';
        var target = $(this).attr('data-target'); //string
        var suggestion = $("#" + target); //dom element
        var url = '';

        $('#' + target.replace('-suggestion', '-confirm')).val('no');

        // add this to wait until user stop typing and then send to search engine
        clearTimeout(suggestion_stuff.timeout);

        // Make a new timeout set to go off in 500ms
        suggestion_stuff.timeout = setTimeout(function () {
            suggestion_stuff.char_count = _this.val().length;
            //if($(this).val().length > 2) {
            if (suggestion_stuff.char_count >= suggestion_stuff.min_count && suggestion_stuff.char_count < suggestion_stuff.max_count) {
                $.ajax({
                    url: canvas_url +  "api/suggestions/find",
                    data: { "type" : type , "text" : _this.val(), '_token': $('meta[name="csrf-token"]').attr('content'), },
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        var html = '<ul data-target="' + target + '" data-field="#' + id + '">';
                        var icon = '';
                        if (type == 'job')
                            icon = 'fa-briefcase';
                        else if (type == 'company')
                            icon = 'fa-building';
                        else if (type == 'area')
                            icon = 'fa-map-marker';
                        else if (type == 'education')
                            icon = 'fa-book';
                        else if (type == 'uni')
                            icon = 'fa-graduation-cap';

                        for (var i = 0; i < data.length; i++) {
                            html += '<li class="dynamic-suggestion-job" data-text="' + data[i] + '"><a href="#" class="text-dark"><i class="fa ' + icon + '"></i> ' + data[i] + '</a></li>';
                        }
                        html += '</ul>';
                        suggestion.html(html);
                        suggestion
                            .show()
                            .addClass("sugestion-active");;
                    }
                });
            }
        }, suggestion_stuff.timeout_milisecond);
        suggestion_stuff.char_count = _this.val().length;

        if ($(this).val().length < 1) {
            suggestion_stuff.char_count = 0;
            suggestion
                .hide()
                .removeClass("sugestion-active");
        }
    });

    $('body').on('click', '.dynamic-suggestion-job', function (e) {
        e.preventDefault();
        var field = $(this).parent().attr('data-field'); //string
        var target = $(this).parent().attr('data-target'); //string
        var suggestion = $("#" + target); //dom element
        $('#' + target.replace('-suggestion', '-confirm')).val('yes');
        $(field).val($(this).attr('data-text'));
        suggestion
            .hide()
            .removeClass("sugestion-active");;


    });

    $(document).mouseup(function (e) {
        var suggestion = $(".input-suggestion");
        if (!suggestion.is(e.target) // if the target of the click isn't the suggestion...
            && suggestion.has(e.target).length === 0) // ... nor a descendant of the suggestion
        {
            suggestion.hide().removeClass('suggestion-active');
        }
    });

    function sendNewSuggestion() {
        var suggestions = $('.suggestion-thing');
        $.each(suggestions, function (k, v) {
            if ($($('.suggestion-thing')[k]).val() != '') {

                if ($('#' + $($('.suggestion-thing')[k]).attr('data-target').replace('-suggestion', '-confirm')).val() == 'no') {
                    $.ajax({
                        url: canvas_url + "/propose-salary-info",
                        method: 'post',
                        dataType: 'json',
                        data: {
                            '_token': $('meta[name="csrf-token"]').attr('content'),
                            type: $($('.suggestion-thing')[k]).attr('data-type'),
                            text: $($('.suggestion-thing')[k]).val()
                        }
                    });
                }

            }
        });
    }

    $.SetSearchSalary = function(val)
    {
        $('#search_salary').val(val);
        $.SetJobSearch();
    }

    $.SetSearchCountry = function(el, val)
    {
        try{
            console.log(el)
            $('#search_country').val(val);
            $(el).closest(".dropdown").removeClass('open');
            $.SetJobSearch();


        }catch (e){
            console.log(e)
        }

    }

    $.getParentCategory = function(cat_id)
    {
        var allCategories = new Array();
        var parentCategory = 0;
        var c = 0;
        $(".filtercategory_counts").each(function (index) {
            var childs = $(this).attr('data-childs');
            var category_id  = $(this).attr('data-id');
            if (childs!='' && typeof childs!='undefined')
            {
                childs = childs.split(',');

                if (jQuery.inArray(cat_id, childs) !== -1)
                {
                    parentCategory = category_id;
                }
            }
        });

        return parentCategory;
    }

    function setSearchSearchLink(data)
    {
        console.log(data);
        $(".joblisting_sortby_head").html(txt_relevance);
        $(".joblisting_sortby_options li").removeClass('active');
        $("#joblisting_sortby_relevance_desc").addClass('active');
        $("#sort_type").val('relevance');
        $("#sort_order").val('desc');
        if (data.search_keyword)
            $("#search_keyword").val(data.search_keyword);

        if (data.location)
            $("#search_location_2").val(data.location);

        if (data.min_salary)
            $("#search_salary").val(data.min_salary);

        if (data.country)
            $("#search_country").val(data.country);

        if (data.job_type)
        {
            var valsArray = data.job_type.split(',');
            $(".joblisting_jobtype").each(function (index) {
                var id = $(this).val();
                if (jQuery.inArray(id, valsArray) !== -1)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (data.category_id)
        {
            $(".joblisting_category").each(function (index) {
                var id = $(this).attr("data-id");
                if (data.category_id==id)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (data.education)
        {
            $(".joblisting_education").each(function (index) {
                var id = $(this).val();
                if (data.education==id)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (data.fast_response)
            $('input[name=joblisting_fastresponse]').prop("checked",true);

        if (data.fresh_graduate)
            $('input[name=joblisting_freshgraduate]').prop("checked",true);

        if (data.hotjob)
            $('input[name=joblisting_hotjobs]').prop("checked",true);

        if (data.urgentjob)
            $('input[name=joblisting_urgentjobs]').prop("checked",true);

        if (data.directcontact)
            $('input[name=joblisting_direct_contact]').prop("checked",true);

        if (data.walkin_interview)
            $('input[name=joblisting_walkin_interview]').prop("checked", true);

        if(data.sidebar_title != null  && data.sidebar_title != ''){
            var sidebar = ' <div class="b-r-4 xs-mb-20 d-inline-block p-relative w-100p text-left" style="line-height: 20px;padding-top: 30px;">\
                <span class="text-bold xs-mb-0 text-medium">'+data.sidebar_title+'</span>\
                    <p>'+nl2br(data.sidebar_content)+'</p>\
            </div>';
            $("#seo-sidebar").html(sidebar)
        }

        if(data.footer_title != null &&  data.footer_title != ''){
            var footer = '<div class="b-r-4 xs-mb-20 d-inline-block p-relative w-100p text-left" style="line-height: 20px;padding-top: 30px;">\
                <h1 class="text-bold xs-mb-0 text-medium">'+data.footer_title+'</h1>\
                <p>'+nl2br(data.footer_content)+'</p>\
            </div>';
            $("#seo-footer").html(footer);
        }


        $.getJobsListing(0);
    }

    function nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    $.setSearchFilterCount = function (data)
    {
        // Initializing
        $("#joblisting_filter_count_jobtype_1").html('0');
        $("#joblisting_filter_count_jobtype_2").html('0');
        $("#joblisting_filter_count_jobtype_3").html('0');
        $("#joblisting_filter_count_education_1").html('0');
        $("#joblisting_filter_count_education_2").html('0');
        $("#joblisting_filter_count_education_3").html('0');
        $("#joblisting_filter_count_education_4").html('0');
        $("#joblisting_filter_count_education_5").html('0');
        $(".filtercategory_counts").each(function (index) {
            $(this).html('0');
        });

        if (data.status=='success')
        {
            var filters = data.filters;

            if (typeof filters.jobType!='undefined' && filters.jobType!=null)
            {
                var jobtype_filters = filters.jobType;
                for (var i = 0; i<jobtype_filters.length;i++)
                {
                    if (jobtype_filters[i].label=='Full Time')
                    {
                        $("#joblisting_filter_count_jobtype_1").html(jobtype_filters[i].count);
                    }

                    if (jobtype_filters[i].label=='Part Time')
                    {
                        $("#joblisting_filter_count_jobtype_2").html(jobtype_filters[i].count);
                    }

                    if (jobtype_filters[i].label=='Internship')
                    {
                        $("#joblisting_filter_count_jobtype_3").html(jobtype_filters[i].count);
                    }
                }
            }

            if (typeof filters.education!='undefined' && filters.education!=null)
            {
                var education_filters = filters.education;
                for (var i = 0; i<education_filters.length;i++)
                {
                    if (education_filters[i].label=="Primary School")
                    {
                        $("#joblisting_filter_count_education_1").html(education_filters[i].count);
                    }

                    if (education_filters[i].label=="Secondary/SPM/'O' Level")
                    {
                        $("#joblisting_filter_count_education_2").html(education_filters[i].count);
                    }

                    if (education_filters[i].label=='Diploma')
                    {
                        $("#joblisting_filter_count_education_3").html(education_filters[i].count);
                    }

                    if (education_filters[i].label=="Bachelor's Degree")
                    {
                        $("#joblisting_filter_count_education_4").html(education_filters[i].count);
                    }

                    if (education_filters[i].label=="Master/PHD")
                    {
                        $("#joblisting_filter_count_education_5").html(education_filters[i].count);
                    }
                }
            }

            if (typeof filters.categories!='undefined' && filters.categories!=null)
            {
                var jobcategory_filters = filters.categories;
                for (var i = 0; i<jobcategory_filters.length;i++)
                {
                    var cc_id = jobcategory_filters[i].id;
                    var pp = $.getParentCategory(cc_id);
                    var currentCount = $("#joblisting_filter_count_category_"+pp).html();
                    currentCount = parseInt(currentCount);
                    currentCount = currentCount+jobcategory_filters[i].count;
                    $("#joblisting_filter_count_category_"+pp).html(currentCount);

                }
            }
        }
    }

    $.SetJobSearch = function()
    {
        if (issearchlink)
        {
            issearchlink = false;
            setSearchSearchLink(searchseolinkdata);
            return false;
        }


        var stateObj = { foo: "bar" };
        var filtertags = '';

        var current_url = cUrl.href;
        var newurl = current_url.split('#')[0];
        var newurlss = '';
        var platform_id = $("#imp_platform_id").val();

        if(window.location.hash) {
            var hash = window.location.hash.slice(1);
            var qKeyword = hash.split('&cat=');
            qKeyword     = qKeyword[0].replace("q=","");
            qKeyword     = decodeURIComponent(qKeyword);
            if (qKeyword!='')
            {
                qKeyword = qKeyword.replace("-"," ");
                $("#search_keyword").val(qKeyword);
            }

            var qLoc = hash.split('&loc=');
            if (typeof qLoc!='undefined' && typeof qLoc[1]!='undefined')
            {
                qLoc     = qLoc[1].split('&sal=');
                qLoc     = decodeURIComponent(qLoc[0]);
                if (qLoc!='')
                {
                    $("#search_location_2").val(qLoc);
                }
            }

        }

        if (cUrl.pathname == '/en/' || cUrl.pathname == '/zh/' || cUrl.pathname == '/ms/' || cUrl.pathname == '/en' || cUrl.pathname == '/zh' || cUrl.pathname == '/ms')
        {
            if (newurl.indexOf("/en") != -1)
            {
                newurlss = 'en';
            }
            else if (newurl.indexOf("/zh") != -1)
            {
                newurlss = 'zh';
            }
            else if (newurl.indexOf("/ms") != -1)
            {
                newurlss = 'ms';
            }
        }

        var keyword  = $("#search_keyword").val();
        var location  = $("#search_location_2").val();
        var salary  = $("#search_salary").val();
        var countryFilter  = $("#search_country").val();
        var salaryCurrency = $("#search_salary_currency").val();
        if(typeof salaryCurrency === 'undefined'){
            salaryCurrency = "RM";
        }
        var original_seotitle = $("#original_seotitle").val();

        if (keyword!='' && keyword!=null && platform_id != 5)
        {
            filtertags += '<span class="label label-button label-info  label-normal label-outline xs-mr-15" id="filter-keyword-0"><a class="close" onclick="clearSearchFilter(\'keyword\',\'0\');">&times;</a>'+keyword+'</span>';

            //localStorage.setItem('searchKeyword', keyword);
            SetGetLocalStorage('set', 'searchKeyword', keyword);
            sendGaEvent(GA_SEARCH_KEYWORD, GA_CATEGORY_CLICK, keyword);
            $('#pagetitle').html(keyword+' - '+original_seotitle);


            jsonObj = [];
            item = {}
            item["keyword"] = keyword;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_SEARCH_SCREEN_INPUT_KEYWORD,jsonObj);

            keyword = FilterString(keyword);
        }
        else
        {
            //localStorage.setItem('searchKeyword', '');
            SetGetLocalStorage('set','searchKeyword','');
        }

        if (location!='' && location!=null && platform_id != 5)
        {
            filtertags += '<span class="label label-button label-default  label-normal label-outline xs-mr-15" id="filter-location-0"><a class="close" onclick="clearSearchFilter(\'location\',\'0\');">&times;</a>'+location+'</span>';
            //localStorage.setItem('searchLocation', location);
            SetGetLocalStorage('set','searchLocation',location);
            sendGaEvent(GA_SEARCH_LOCATION, GA_CATEGORY_CLICK, location);

            jsonObj = [];
            item = {}
            item["location"] = location;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_LOCATION,jsonObj);

            if (keyword!='' && keyword!=null)
            {
                $('#pagetitle').html(keyword+' jobs in '+location+' - '+original_seotitle);
            }
            else
            {
                $('#pagetitle').html(location+' jobs - '+original_seotitle);
            }

            location = FilterString(location);
        }
        else
        {
            //	localStorage.setItem('searchLocation', '');
            SetGetLocalStorage('set','searchLocation','');
        }

        var job_type = new Array();
        var i = 0;
        $(".joblisting_jobtype").each(function (index) {
            if ($(this).is(':checked'))
            {
                var v = $(this).attr("value");
                var id = $(this).attr("data-id");
                var text = $(this).attr("data-value");
                filtertags += '<span class="label label-button label-primary  label-normal label-outline xs-mr-15" id="filter-jobtype-'+id+'"><a class="close" onclick="clearSearchFilter(\'jobtype\',\''+id+'\');">&times;</a>'+text+'</span>';
                sendGaEvent(GA_FILTER_TYPE, GA_CATEGORY_CLICK, text);
                jsonObj = [];
                item = {}
                item["job_type"] = text;
                jsonObj.push(item);
    
                sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_TYPE,jsonObj);

                job_type[i] = id+'-'+FilterString(v);
                i++;
            }
        });

        var job_category = new Array();
        var i = 0;
        var joblisting_category_id = $("#joblisting_category_id").val();
        $(".joblisting_category").each(function (index) {
            var id = $(this).attr("data-id");

            if (joblisting_category_id==id) $(this).prop('checked',true);

            if ($(this).is(':checked'))
            {
                var v = $(this).attr("value");
                jsonObj = [];
                item = {}
                item["category"] = v;
                jsonObj.push(item);
    
                sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_CATEGORY,jsonObj);

                filtertags += '<span class="label label-button label-info label-normal label-outline xs-mr-15" id="filter-category-'+id+'"><a class="close" onclick="clearSearchFilter(\'category\',\''+id+'\');">&times;</a>'+v+'</span>';
                job_category[i] = id+'-'+FilterString(v);
                i++;
            }
        });

        var job_education = new Array();
        var i = 0;
        $(".joblisting_education").each(function (index) {
            if ($(this).is(':checked'))
            {
                var v = $(this).attr("value");
                var id = $(this).attr("data-id");
                var text = $(this).attr("data-value");
                filtertags += '<span class="label label-button label-success  label-normal label-outline xs-mr-15" id="filter-education-'+id+'"><a class="close" onclick="clearSearchFilter(\'education\',\''+id+'\');">&times;</a>'+text+'</span>';
                sendGaEvent(GA_FILTER_CATEGORY, GA_CATEGORY_CLICK, text);
                jsonObj = [];
                item = {}
                item["education"] = text;
                jsonObj.push(item);
    
                sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_EDUCATION,jsonObj);
                job_education[i] = id+'-'+FilterString(v);
                i++;
            }
        });

        var freshgraduate = $('input[name=joblisting_freshgraduate]:checked').is(':checked');
        var fastresponse = $('input[name=joblisting_fastresponse]:checked').is(':checked');
        var hotjobs = $('input[name=joblisting_hotjobs]:checked').is(':checked');
        var urgentjobs = $('input[name=joblisting_urgentjobs]:checked').is(':checked');
        var direct_contact = $('input[name=joblisting_direct_contact]:checked').is(':checked');
        var iswalkin = $('input[name=joblisting_walkin_interview]:checked').is(':checked');

        var jobsearch_country_name = decodeURIComponent($("#jobsearch_country_name").val());
        var jobsearch_region_name = decodeURIComponent($("#jobsearch_region_name").val());
        var joblisting_category_name = decodeURIComponent($("#joblisting_category_name").val());
        var tag_name = decodeURIComponent($("#tag_name").val());
        var searchEnabled = 'no';

        var qPage = 'q=' + encodeURIComponent(keyword);
        alertMeState();

        if (keyword!='' && keyword!=null)
        {
            searchEnabled = 'yes';
        }

        if (location!='' && location!=null)
        {
            qPage += "&loc=" + encodeURIComponent(location);
            searchEnabled = 'yes';
        }

        if (salary!='' && salary!=null)
        {
            filtertags += '<span class="label label-button label-danger  label-normal label-outline xs-mr-15" id="filter-salary-0"><a class="close" onclick="clearSearchFilter(\'salary\',\'0\');">&times;</a>' + salaryCurrency + ' '+salary+'</span>';
            qPage += "&sal=" + encodeURIComponent(salary);
            //localStorage.setItem('searchSalary', salary);
            sendGaEvent(GA_SEARCH_SALARY, GA_CATEGORY_CLICK, salary);
            
            jsonObj = [];
            item = {}
            item["salary"] = salary;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_SALARY,jsonObj);


            SetGetLocalStorage('set','searchSalary',salary);
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchSalary', '');
            SetGetLocalStorage('set','searchSalary','');
        }


        if (countryFilter)
        {
            filtertags += '<span class="label label-button label-danger  label-normal label-outline xs-mr-15" id="filter-country-0"><a class="close" onclick="clearSearchFilter(\'countrySearch\',\'0\');">&times;</a>'  + countryFilter +'</span>';
            qPage += "&country=" + encodeURIComponent(countryFilter);
            //localStorage.setItem('searchSalary', salary);
            sendGaEvent(GA_FILTER_COUNTRY, GA_CATEGORY_CLICK, countryFilter);
            jsonObj = [];
            item = {}
            item["country"] = countryFilter;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_COUNTRY,jsonObj);
            SetGetLocalStorage('set','searchCountry',countryFilter);
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchSalary', '');
            SetGetLocalStorage('set','searchCountry','');
        }



        // set currency
        //localStorage.setItem('searchSalaryCurrency', salaryCurrency);
        SetGetLocalStorage('set','searchSalaryCurrency',salaryCurrency);

        if (job_category.length>0)
        {
            qPage += "&cat=" + encodeURIComponent(job_category.join(';'));
            //localStorage.setItem('searchCategory', encodeURIComponent(job_category.join(';')));
            SetGetLocalStorage('set','searchCategory',encodeURIComponent(job_category.join(';')));
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchCategory', '');
            SetGetLocalStorage('set','searchCategory','');
        }

        if (job_type.length>0)
        {
            qPage += "&jtype=" + encodeURIComponent(job_type.join(';'));
            //localStorage.setItem('searchJobTypes', encodeURIComponent(job_type.join(';')));
            SetGetLocalStorage('set','searchJobTypes',encodeURIComponent(job_type.join(';')));
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchJobTypes', '');
            SetGetLocalStorage('set','searchJobTypes','');
        }

        if (job_education.length>0)
        {
            qPage += "&edu=" + encodeURIComponent(job_education.join(';'));
            //localStorage.setItem('searchJobEducation', encodeURIComponent(job_education.join(';')));
            SetGetLocalStorage('set','searchJobEducation',encodeURIComponent(job_education.join(';')));
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchJobEducation', '');
            SetGetLocalStorage('set','searchJobEducation','');
        }

        if (freshgraduate)
        {
            filtertags += '<span class="label label-button label-warning  label-normal label-outline xs-mr-15" id="filter-freshgraduate-0"><a class="close" onclick="clearSearchFilter(\'freshgraduate\',\'0\');">&times;</a>'+txt_exp_status_val3+'</span>';
            qPage += "&freshgraduate=true";
            sendGaEvent(GA_FILTER_FRESH_GRADUATE, GA_CATEGORY_CLICK, 'true');
            jsonObj = [];
            item = {}
            item["attribute"] = FRESH_GRADUATE;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            
            //localStorage.setItem('searchFreshGraduate', 'true');
            SetGetLocalStorage('set','searchFreshGraduate','true');
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchFreshGraduate', 'false');
            SetGetLocalStorage('set','searchFreshGraduate','false');
        }

        if (fastresponse)
        {
            filtertags += '<span class="label label-button label-warning  label-normal label-outline xs-mr-15" id="filter-fastresponse-0"><a class="close" onclick="clearSearchFilter(\'fastresponse\',\'0\');">&times;</a>'+txt_badge+'</span>';
            qPage += "&fastresponse=true";
            //localStorage.setItem('searchFastResponse', 'true');
            sendGaEvent(GA_FILTER_FAST_RESPONSE, GA_CATEGORY_CLICK, 'true');
            jsonObj = [];
            item = {}
            item["attribute"] = FAST_RESPONSE;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            SetGetLocalStorage('set','searchFastResponse','true');
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchFastResponse', 'false');
            SetGetLocalStorage('set','searchFastResponse','false');
        }

        if (hotjobs)
        {
            filtertags += '<span class="label label-button label-warning  label-normal label-outline xs-mr-15" id="filter-hotjobs-0"><a class="close" onclick="clearSearchFilter(\'hotjobs\',\'0\');">&times;</a>'+txt_recommendation_trend+'</span>';
            qPage += "&hotjobs=true";
            SetGetLocalStorage('set','searchHotJobs','true');
            jsonObj = [];
            item = {}
            item["attribute"] = HOT_JOBS;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            sendGaEvent(GA_FILTER_HOT_JOBS, GA_CATEGORY_CLICK, 'true');
            //localStorage.setItem('searchHotJobs', 'true');
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchHotJobs', 'false');
            SetGetLocalStorage('set','searchHotJobs','false');
        }

        if (urgentjobs)
        {
            filtertags += '<span class="label label-button label-danger  label-normal label-outline xs-mr-15" id="filter-urgentjobs-0"><a class="close" onclick="clearSearchFilter(\'urgentjobs\',\'0\');">&times;</a>'+txt_urgentjobs+'</span>';
            qPage += "&urgentjobs=true";
            SetGetLocalStorage('set','searchUrgentJobs','true');
            jsonObj = [];
            item = {}
            item["attribute"] = URGENT_JOBS;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            sendGaEvent(GA_FILTER_URGENT_JOBS, GA_CATEGORY_CLICK, 'true');
            //localStorage.setItem('searchUrgentJobs', 'true');
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchUrgentJobs', 'false');
            SetGetLocalStorage('set','searchUrgentJobs','false');
        }

        if (direct_contact)
        {
            filtertags += '<span class="label label-button label-warning  label-normal label-outline xs-mr-15" id="filter-direct_contact-0"><a class="close" onclick="clearSearchFilter(\'direct_contact\',\'0\');">&times;</a>'+txt_direct_contact+'</span>';
            qPage += "&direct_contact=true";
            SetGetLocalStorage('set','searchDirectContact','true');
            sendGaEvent(GA_FILTER_DIRECT_CONTACT, GA_CATEGORY_CLICK, 'true');
            jsonObj = [];
            item = {}
            item["attribute"] = DIRECT_CONTACT;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            //localStorage.setItem('searchUrgentJobs', 'true');
            searchEnabled = 'yes';
        }
        else
        {
            //localStorage.setItem('searchUrgentJobs', 'false');
            SetGetLocalStorage('set','searchDirectContact','false');
        }

        if (iswalkin)
        {
            filtertags += '<span class="label label-button label-warning label-normal label-outline xs-mr-15" id="filter-walkin_interview-0"><a class="close" onclick="clearSearchFilter(\'iswalkin\',\'0\');">&times;</a>'+txt_walkin_interview+'</span>';
            qPage += "&iswalkin=true";
            sendGaEvent(GA_FILTER_WALKIN, GA_CATEGORY_CLICK, 'true');
            jsonObj = [];
            item = {}
            item["attribute"] = WIT;
            jsonObj.push(item);

            sendSCLoginEvent(SEARCH,EVENT_RESULT_SEARCH_SCREEN_FILTER_BY_JOB_ATTRIBUTE,jsonObj);
            SetGetLocalStorage('set','searchWalkinInterview','true');
            searchEnabled = 'yes';
        }
        else
        {
            SetGetLocalStorage('set','searchWalkinInterview','false');
        }

        if (jobsearch_country_name!='')
        {
            filtertags += '<span class="label label-button label-info  label-normal label-outline xs-mr-15" id="filter-country-0"><a class="close" onclick="clearSearchFilter(\'country\',\'0\');">&times;</a>'+jobsearch_country_name+'</span>';
        }

        if (jobsearch_region_name!='')
        {
            filtertags += '<span class="label label-button label-info  label-normal label-outline xs-mr-15" id="filter-region-0"><a class="close" onclick="clearSearchFilter(\'region\',\'0\');">&times;</a>'+jobsearch_region_name+'</span>';
        }

        if (joblisting_category_name!='')
        {
            filtertags += '<span class="label label-button label-info  label-normal label-outline xs-mr-15" id="filter-catsearch-0"><a class="close" onclick="clearSearchFilter(\'catsearch\',\'0\');">&times;</a>'+joblisting_category_name+'</span>';
        }

        if (tag_name!='')
        {
            filtertags += '<span class="label label-button label-info  label-normal label-outline xs-mr-15" id="filter-tag-0"><a class="close" onclick="clearSearchFilter(\'tagsearch\',\'0\');">&times;</a>'+tag_name+'</span>';
        }

        var canvasurl =canvas_url;

        if (newurlss!='') canvasurl = canvasurl+newurlss+'/';

        var ref = GetUrlParms('ref');
        var viewcpa = GetUrlParms('viewcpa');
        var page = GetUrlParms('page');

        if (searchEnabled=='no')
        {
            //history.replaceState(stateObj, "Search Jobs", canvasurl);
            //localStorage.setItem('qPage', '');
            SetGetLocalStorage('set','qPage','');
            $('.clear_all_filter').hide();
        }
        else
        {
            //localStorage.setItem('qPage', qPage);
            //localStorage.setItem('searchFilterTags', filtertags);
            SetGetLocalStorage('set','qPage',qPage);
            SetGetLocalStorage('set','searchFilterTags',filtertags);
            //	$.setJobListingSorting('relevance','desc','Relevance');
            $(".joblisting_sortby_head").html(txt_relevance);
            $(".joblisting_sortby_options li").removeClass('active');
            $("#joblisting_sortby_relevance_desc").addClass('active');
            $("#sort_type").val('relevance');
            $("#sort_order").val('desc');

            var searchUrl = canvasurl+"?"+qPage;
            if (ref!='') searchUrl += '&ref='+ref;
            if (viewcpa!='') searchUrl += '&viewcpa=yes';
            //if (page!='') canvasurl += '&page='+page;
            //else
            $("#page").val('0');
            history.replaceState(stateObj, "Search Jobs", searchUrl);
        }

        var pagename = $("#pagename").val();

        if (pagename=='joblisting') {
            if (filtertags!='')
            {
                $("#searchfilters").html(filtertags);
                $("#search-filters").removeClass('hide');
            }
            else
            {
                $("#search-filters").addClass('hide');
            }

            $.getJobsListing(0);
        }
        else
        {
            window.location = canvasurl+"?"+qPage;
        }

        //load related function
        scroll_X('filter-tag-scroll');
        $('body').scrollTop(0);

        if (searchEnabled=='no')
        {
            $('.clear_all_filter').hide();
        }else{
            $('.clear_all_filter').show();
        }
    }

    $.setSearchFilters = function()
    {
        var search_keyword 	= GetUrlParms('q');
        var loc 			= GetUrlParms('loc');
        var sal 			= GetUrlParms('sal');
        var countrySearch 	= GetUrlParms('country');
        var cat 			= GetUrlParms('cat');
        var jtype 			= GetUrlParms('jtype');
        var edu 			= GetUrlParms('edu');
        var freshgraduate	= GetUrlParms('freshgraduate');
        var fastresponse 	= GetUrlParms('fastresponse');
        var hotjobs 		= GetUrlParms('hotjobs');
        var urgentjobs 		= GetUrlParms('urgentjobs');
        var direct_contact  = GetUrlParms('direct_contact');
        var iswalkin  	        = GetUrlParms('iswalkin');

        if (typeof search_keyword!='undefined' && search_keyword!='' && search_keyword!=null)
        {
            search_keyword = search_keyword.replace(/-/g, ' ');
            search_keyword     = decodeURIComponent(search_keyword);
            $("#search_keyword").val(search_keyword);
        }

        if (typeof loc!='undefined' && loc!='' && loc!=null)
        {
            loc = loc.replace(/-/g, ' ');
            loc     = decodeURIComponent(loc);
            $("#search_location_2").val(loc);
        }

        if (typeof sal!='undefined' && sal!='' && sal!=null)
        {
            $("#search_salary").val(sal);
        }

        if (countrySearch)
        {
            countrySearch     = decodeURI(countrySearch);
            $("#search_country").val(countrySearch);
        }

        if (typeof jtype!='undefined' && jtype!='' && jtype!=null)
        {
            var valsArray = new Array();
            var j = 0;
            var vals = decodeURIComponent(jtype);
            vals     = vals.split(';');
            for (var i=0;i<vals.length;i++)
            {
                var val = vals[i].split('-');
                valsArray[j] = val[0];
                j++;
            }

            $(".joblisting_jobtype").each(function (index) {
                var id = $(this).attr("data-id");
                if (jQuery.inArray(id, valsArray) !== -1)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (typeof cat!='undefined' && cat!='' && cat!=null)
        {
            var valsArray = new Array();
            var j = 0;
            var vals = decodeURIComponent(cat);
            vals     = vals.split(';');
            for (var i=0;i<vals.length;i++)
            {
                var val = vals[i].split('-');
                valsArray[j] = val[0];
                j++;
            }

            $(".joblisting_category").each(function (index) {
                var id = $(this).attr("data-id");
                if (jQuery.inArray(id, valsArray) !== -1)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (typeof edu!='undefined' && edu!='' && edu!=null)
        {
            var valsArray = new Array();
            var j = 0;
            var vals = decodeURIComponent(edu);
            vals     = vals.split(';');
            for (var i=0;i<vals.length;i++)
            {
                var val = vals[i].split('-');
                valsArray[j] = val[0];
                j++;
            }

            $(".joblisting_education").each(function (index) {
                var id = $(this).attr("data-id");
                if (jQuery.inArray(id, valsArray) !== -1)
                {
                    $(this).prop('checked',true);
                }
            });
        }

        if (typeof freshgraduate!='undefined' && freshgraduate=='true')
        {
            $('input[name=joblisting_freshgraduate]').prop("checked",true);
        }

        if (typeof fastresponse!='undefined' && fastresponse=='true')
        {
            $('input[name=joblisting_fastresponse]').prop("checked",true);
        }

        if (typeof hotjobs!='undefined' && hotjobs=='true')
        {
            $('input[name=joblisting_hotjobs]').prop("checked",true);
        }

        if (typeof urgentjobs!='undefined' && urgentjobs=='true')
        {
            $('input[name=joblisting_urgentjobs]').prop("checked",true);
        }

        if (typeof direct_contact!='undefined' && direct_contact=='true')
        {
            $('input[name=joblisting_direct_contact]').prop("checked",true);
        }

        if (typeof iswalkin!= 'undefined' && iswalkin == 'true')
        {
            $('input[name=joblisting_walkin_interview]').prop("checked",true);
        }

        $.SetJobSearch();
    }

    $.setSearchFiltersJobDetails = function()
    {
        if (SetGetLocalStorage('get','qPage'))
        {
            var qPage = localStorage.getItem("qPage");
            if (qPage!='')
            {
                var search_keyword 	= localStorage.getItem('searchKeyword');
                var loc 			= localStorage.getItem('searchLocation');
                var sal 			= localStorage.getItem('searchSalary');
                var salCurrency 	= localStorage.getItem('searchSalaryCurrency');
                var cat 			= localStorage.getItem('searchCategory');
                var jtype 			= localStorage.getItem('searchJobTypes');
                var edu 			= localStorage.getItem('searchJobEducation');
                var freshgraduate	= localStorage.getItem('searchFreshGraduate');
                var fastresponse 	= localStorage.getItem('searchFastResponse');
                var hotjobs 		= localStorage.getItem('searchHotJobs');
                var urgentjobs 		= localStorage.getItem('searchUrgentJobs');
                var direct_contact  = localStorage.getItem('searchDirectContact');
                var iswalkin       = localStorage.getItem('searchWalkinInterview');
                var searchFilterTags = localStorage.getItem('searchFilterTags');

                if (typeof search_keyword!='undefined' && search_keyword!='' && search_keyword!=null)
                {
                    search_keyword = search_keyword.replace(/-/g, ' ');
                    $("#search_keyword").val(search_keyword);
                }

                if (typeof loc!='undefined' && loc!='' && loc!=null)
                {
                    loc = loc.replace(/-/g, ' ');
                    $("#search_location_2").val(loc);
                }

                if (typeof sal!='undefined' && sal!='' && sal!=null)
                {
                    $("#search_salary").val(sal);
                }

                // salary currency
                $("#search_salary_currency").val(salCurrency);

                if (typeof jtype!='undefined' && jtype!='' && jtype!=null)
                {
                    var valsArray = new Array();
                    var j = 0;
                    var vals = decodeURIComponent(jtype);
                    vals     = vals.split(';');
                    for (var i=0;i<vals.length;i++)
                    {
                        var val = vals[i].split('-');
                        valsArray[j] = val[0];
                        j++;
                    }

                    $(".joblisting_jobtype").each(function (index) {
                        var id = $(this).attr("data-id");
                        if (jQuery.inArray(id, valsArray) !== -1)
                        {
                            $(this).prop('checked',true);
                        }
                    });
                }

                if (typeof cat!='undefined' && cat!='' && cat!=null)
                {
                    var valsArray = new Array();
                    var j = 0;
                    var vals = decodeURIComponent(cat);
                    vals     = vals.split(';');
                    for (var i=0;i<vals.length;i++)
                    {
                        var val = vals[i].split('-');
                        valsArray[j] = val[0];
                        j++;
                    }

                    $(".joblisting_category").each(function (index) {
                        var id = $(this).attr("data-id");
                        if (jQuery.inArray(id, valsArray) !== -1)
                        {
                            $(this).prop('checked',true);
                        }
                    });
                }

                if (typeof edu!='undefined' && edu!='' && edu!=null)
                {
                    var valsArray = new Array();
                    var j = 0;
                    var vals = decodeURIComponent(edu);
                    vals     = vals.split(';');
                    for (var i=0;i<vals.length;i++)
                    {
                        var val = vals[i].split('-');
                        valsArray[j] = val[0];
                        j++;
                    }

                    $(".joblisting_education").each(function (index) {
                        var id = $(this).attr("data-id");
                        if (jQuery.inArray(id, valsArray) !== -1)
                        {
                            $(this).prop('checked',true);
                        }
                    });
                }

                if (typeof freshgraduate!='undefined' && freshgraduate=='true')
                {
                    $('input[name=joblisting_freshgraduate]').prop("checked",true);
                }

                if (typeof freshgraduate!='undefined' && fastresponse=='true')
                {
                    $('input[name=joblisting_fastresponse]').prop("checked",true);
                }

                if (typeof hotjobs!='undefined' && hotjobs=='true')
                {
                    $('input[name=joblisting_hotjobs]').prop("checked",true);
                }

                if (typeof urgentjobs!='undefined' && urgentjobs=='true')
                {
                    $('input[name=joblisting_urgentjobs]').prop("checked",true);
                }

                if (typeof direct_contact!='undefined' && direct_contact=='true')
                {
                    $('input[name=joblisting_direct_contact]').prop("checked",true);
                }

                if (typeof iswalkin!='undefined' && iswalkin == 'true')
                {
                    $('input[name=joblisting_walkin_interview]').prop("checked",true);
                }

                if (searchFilterTags!='')
                {
                    $("#searchfilters").html(searchFilterTags);
                    $("#search-filters").removeClass('hide');
                }

            }
        }
    }

    $('.loginapply').click(function (e) {
        applyjoblink = $(this).attr("data-link");
        $(this).addClass("selected");
        showLoginModal(e);
    });

    $.setJobListingSorting = function (sorty_by,orderby,label)
    {
        $(".joblisting_sortby_head").html(label);
        $(".joblisting_sortby_options li").removeClass('active');
        $("#joblisting_sortby_"+sorty_by+"_"+orderby).addClass('active');
        $("#sort_type").val(sorty_by);
        $("#sort_order").val(orderby);
        $.getJobsListing(0);
    }

    $.getJobsListing = function (page) {

        var no_experience = $("#txt_noexperiance").val();
        var no_experience2 = $("#txt_noexperiance2").val();
        var txt_mypage = $("#txt_mypage").val();
        var txt_header1 = $("#txt_header1").val();
        var txt_header2 = $("#txt_header2").val();
        var keyword  = $("#search_keyword").val();
        var salary  = $("#search_salary").val();
        var brand_id = $("#brand_id").val();
        var salaryCurrency  = $("#search_salary_currency").val();
        if(typeof salaryCurrency === 'undefined'){
            salaryCurrency = "RM";
        }

        if (salary!='' && salary!='0') salary = parseInt(salary);


        var countrySearch = $("#search_country").val();

        var job_type = new Array();
        var i = 0;
        $(".joblisting_jobtype").each(function (index) {
            if ($(this).is(':checked'))
            {
                job_type[i] = $(this).attr("value");
                i++;
            }
        });

        var job_category = new Array();
        var i = 0;
        $(".joblisting_category").each(function (index) {
            if ($(this).is(':checked'))
            {
                job_category[i] = $(this).attr("data-id");
                i++;
            }
        });

        var job_education = new Array();
        var i = 0;
        $(".joblisting_education").each(function (index) {
            if ($(this).is(':checked'))
            {
                job_education[i] = $(this).attr("data-id");
                i++;
            }
        });

        var freshgraduate = $('input[name=joblisting_freshgraduate]:checked').is(':checked');
        var fastresponse = $('input[name=joblisting_fastresponse]:checked').is(':checked');
        var hotjobs = $('input[name=joblisting_hotjobs]:checked').is(':checked');
        var urgentjobs = $('input[name=joblisting_urgentjobs]:checked').is(':checked');
        var direct_contact = $('input[name=joblisting_direct_contact]:checked').is(':checked');
        var iswalkin = $('input[name=joblisting_walkin_interview]:checked').is(':checked');

        var company_id = $("#company_id").val();
        var tag_name = $("#tag_name").val();
        var pagename = $("#pagename").val();

        var search_location = '';//decodeURIComponent($("#search_location").val());
        var search_location_2 = decodeURIComponent($("#search_location_2").val());
        var search_region     = decodeURIComponent($("#search_location").val());
        var jobsearch_country_name = decodeURIComponent($("#jobsearch_country_name").val());
        var jobsearch_region_name = decodeURIComponent($("#jobsearch_region_name").val());
        var joblisting_category_name = decodeURIComponent($("#joblisting_category_name").val());
        var joblisting_category = decodeURIComponent($("#joblisting_category").val());
        var platform_id = $("#imp_platform_id").val();

        var resultDiv = '';
        var sort_by = $("#sort_type").val();
        var orderby = $("#sort_order").val();

        var current_page = parseInt($("#page").val());
        if (current_page > 1)
            page = current_page;
        var limit = 30;
        var minimizeClass = '';
        if (pagename=='companypage' || pagename=='jobseekerdashboard')
        {
            limit = 12;
            minimizeClass = 'minimize';
        }

        var start_limit = (page - 1) * limit;
        start_limit = (start_limit < 0) ? 0 : start_limit;

        var language = getCookie('userLanguage');
        var _isLogin  = typeof isLogin  !== 'undefined' ? isLogin :  0;
        var jsonData = {
            keyword: keyword,
            company_id: company_id,
            tag_name: tag_name,
            job_type: job_type,
            brandId: brand_id,
            search_location: search_location_2,
            search_region:search_region,
            search_education: job_education,
            search_salary: salary,
            search_salary_currency: salaryCurrency,
            search_country: countrySearch,
            sort_by: sort_by,
            search_category: job_category,
            freshgraduate:freshgraduate,
            fastresponse:fastresponse,
            hotjobs:hotjobs,
            urgentjobs:urgentjobs,
            direct_contact: direct_contact,
            iswalkin: iswalkin,
            orderby: orderby,
            start: start_limit,
            noOfRecords: limit,
            pagename: pagename,
            language: language,
            jobsearch_country_name:jobsearch_country_name,
            jobsearch_region_name:jobsearch_region_name,
            joblisting_category:joblisting_category,
            ref:getQueryParameter('ref'),
            'isLogin': _isLogin,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/job/listing",
            data: jsonData,
            cache: true,
            localCache: true,
            cacheKey: 'jobs',
            type: 'GET',
            crossDomain: true,
            dataType: 'json',
            cacheTTL: 1,
            isCacheValid: function () {  // optional.
                return true;
            }
        });

        $('#spinner').show();

        NProgress.start();
        $("#jobListing").hide();
        $(".paging-div").hide();
        $("#empty-job").hide();
        $('#engine-tech-issue-wrapper').hide();
        $("#Paging").show();
        request.done(function (data) {
            NProgress.done();
            $('#spinner').hide();

            $.setSearchFilterCount(data);

            if (data.status == 'success') {
                var count = data.totalRecords;
                $(".totalrec").html(count);
                $(".totalrec")[0].innerHTML = count;
                var totalNonFeatured = data.totalNonFeatured;
                var job = data.data;
                var recommendationJob = job.filter(function(jobs) {
                    return jobs.is_recommended == "yes";
                });

                job = job.filter(function(newjob) {
                    return newjob.is_recommended == "no";
                });
                var current = job.length;
                var statusBar = '';
                var clogo = '';
                var joburl = '';
                var applylink = '';
                var source = '';
                var save_btn = '';
                var applied_btn = '';
                var share_btn = '';
                var newurl = cUrl.href;
                var companyURL = '';
                var jobgroupcpa = '';
                var public_emails = '';
                var public_phones = '';
                var whatsapp = '';
                var user_can_apply = '';

                for (var i = 0; i < current; i++) {

                    const jobId = job[i].job_id;

                    if (job[i].logo != null && job[i].logo != '') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].logo + '" alt="' + job[i].company_name + ' Logo" class="lazy">';
                    } else {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].category_pic + '" alt="' + job[i].category_name + ' Category" class="lazy">';
                    }

                    if (job[i].company_privacy == 'private') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg" alt="Private Company" class="lazy">';
                    }


                    if (job[i].seo_link == null || typeof job[i].seo_link == undefined) {
                        joburl = canvas_url + 'job/' + jobId + '-' + FilterURL(job[i].job_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        joburl = canvas_url + 'job/' + jobId + '-' + job[i].seo_link + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (job[i].seo_title != null || typeof job[i].seo_title != undefined) {
                        if (newurl.indexOf("/en") != -1) {
                            joburl = canvas_url + 'en/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else if (newurl.indexOf("/zh") != -1) {
                            joburl = canvas_url + 'zh/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else if (newurl.indexOf("/ms") != -1) {
                            joburl = canvas_url + 'ms/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else
                            joburl = canvas_url + 'job/' + jobId + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (!job[i].company_slug) {
                        job[i].company_slug = FilterURL(job[i].company_name);
                    }

                    if (job[i].brand_id) {
                        urlComp = 'brand/' + job[i].brand_id+'-'+job[i].brand_url+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        urlComp = 'company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (newurl.indexOf("/en") != -1)
                    {
                        applylink = canvas_url + 'en/job/apply-job?job_id=' + jobId;
                        companyURL = canvas_url + 'en/' + urlComp;
                        firstLink = canvas_url + 'en/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/zh") != -1)
                    {
                        applylink = canvas_url + 'zh/job/apply-job?job_id=' + jobId;
                        companyURL = canvas_url + 'zh/' + urlComp;
                        firstLink = canvas_url + 'zh/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/ms") != -1)
                    {
                        applylink = canvas_url + 'ms/job/apply-job?job_id=' + jobId;
                        companyURL = companyURL = canvas_url + 'ms/' + urlComp;
                        firstLink = canvas_url + 'ms/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        applylink = canvas_url + 'job/apply-job?job_id=' + jobId;
                        companyURL = companyURL = canvas_url + urlComp;
                        firstLink = canvas_url + 'jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    }
                    if (job[i].company_privacy == 'private') {
                        companyURL = '#';
                    }

                    if (job[i].apply_direct != null && job[i].apply_direct == 'yes')
                    {
                        applylink = job[i].apply_link + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (job[i].job_saved == 'false')
                    {
                        save_btn = '<a class="btn btn-default  save_job_normal" data-id="' + jobId + '" id="savejobn-' + jobId + '" onclick="sendGaEvent(\'' + ACTION_SAVE_JOB_FROM_JOB_LIST  + '\', \''+ GA_CATEGORY_CLICK + '\', \'' + jobId + '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SAVED\,\'' + jobId + '\' ,\'\job\'\);"><i class="fa fa-heart"></i> ' + txt_save + '</a> ';
                    } else
                    {
                        save_btn = '<a class="btn btn-default  btn-job-saved disabled" data-id="' + jobId + '"><i class="fa fa-heart"></i> ' + txt_job_saved + '</a>';
                    }

                    if (job[i].job_applied == 'false') {

                        if (isLogin=='0') // User not login
                        {
                            applied_btn = '<a class="btn btn-default pointer loginapply text-primary important" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + job[i].job_id + '\' , \'\job\'\);"  data-id="'+jobId+'" data-link="'+applylink+'"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                        }else{
                            user_can_apply = job[i].user_can_apply;
                            if(user_can_apply){
                                applied_btn
                                    = '<a href="' + applylink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' +  ACTION_APPLY_JOB_FROM_LIST + '\', \'' + GA_CATEGORY_CLICK  + '\' , \'' + jobId + '\');saveBrowsingHistory(); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                            }
                            else if(!user_can_apply)
                            {
                                applied_btn
                                    = '<a href="' + joburl + '" class="important btn btn-default "><span style="color:#f5a623 ;"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</span></a>';
                            }
                            else
                            {
                                applied_btn
                                    = '<a href="' + applylink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' +  ACTION_APPLY_JOB_FROM_LIST + '\', \'' + GA_CATEGORY_CLICK  + '\' , \'' + jobId + '\');saveBrowsingHistory(); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                            }

                        }

                        if(job[i].deeplink != "" ){
                            user_can_apply = job[i].user_can_apply;
                            if(user_can_apply){
                                applied_btn = '<a href="' + job[i].deeplink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' + ACTION_APPLY_DOWNLOAD_APP_JOB_LISTING + '\', \'' + GA_CATEGORY_CLICK + '\' , \'' + jobId + '\');saveBrowsingHistory();"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>'
                            }
                            else if(!user_can_apply)
                            {
                                applied_btn
                                    = '<a href="' + joburl + '" class="important btn btn-default "><span style="color:#f5a623 ;"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</span></a>';
                            }
                            else
                            {
                                applied_btn = '<a href="' + job[i].deeplink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' + ACTION_APPLY_DOWNLOAD_APP_JOB_LISTING + '\', \'' + GA_CATEGORY_CLICK + '\' , \'' + jobId + '\');saveBrowsingHistory();"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>'
                            }
                        }

                    } else {
                        //applied_btn = '<a target="_blank" class="btn btn-default disabled"><i class="fa fa-pencil-square-o"></i> ' + txt_applied + '</a>';
                        applied_btn = '<a target="_blank" class="btn btn-default" href="' + canvas_url + 'jobseeker/timeline/' + job[i].hashedAppliedId + '" ><i class="fa fa-eye"></i> ' + txt_view_progress + '</a>';
                    }

                    share_btn = '<a class="btn btn-default pointer fb-share-button" data-id="' + jobId + '" data-url="' + joburl + '" onclick="sendGaEvent(\'' + ACTION_SHARE_FROM_JOB_LIST + '\', \'' + GA_CATEGORY_CLICK + '\', \'' + jobId +'\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SHARED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-share-alt"></i> '+ txt_share_button+'</a>';

                    if (job[i].area != '') {
                        job[i].area = job[i].area + ' ,';
                    }
                    if (job[i].final_salary == 'RM' || job[i].final_salary == 'RM1-RM1' || job[i].final_salary == 'RMRM1' || job[i].final_salary == '')
                        job[i].final_salary = txt_negotiable;
                    if (job[i].final_salary == 0)
                        job[i].final_salary = txt_negotiable;

                    //featured
                    var featured = '';
                    if (job[i].is_featured == '1') {
                        featured = '<span class="xs-mt-10 label label-sm label-danger label-circle xs-mr-5" data-toggle="tooltip" title="Urgent Job"><i class="fa fa-star-o"></i></span>';
                    }

                    //fast response
                    var fastresponse = '';
                    if (job[i].has_badge == 'yes'){
                        fastresponse = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Fast Response"><i class="mk mk-flash"></i></span>';
                    }

                    var jobLocation = job[i].job_location;

                    var jobBranch = '';
                    if(job[i].job_branch && job[i].job_branch.length > 0)
                    {
                        jobBranch = '<br /><span class="d-inline-block xs-mr-10"><img src="//i.imgur.com/bGLMryW.png" style="max-width: 100%; min-height: 12px;margin-left: -3px;margin-right: 3px"> ' + job[i].job_branch.replace('|',' &#9679; ')  + '</span>';
                    }

                    // walkin interview
                    var walkin_interview = '';
                    var walkin_interview_title = (job[i].is_walkin == 1) ? '[Walk In Interview] ' : '';
                    if(job[i].walkin_date != '')
                    var walkinDate = moment(job[i].walkin_date).format("DD MMMM YYYY");

                    var walkin_interview_date = (job[i].is_walkin == 1) ? '<span class="d-inline-block text-nowrap xs-mr-10"><i class="fa fa-calendar-o"></i> ' + walkinDate + '</span>' : '';
                    if (job[i].is_walkin == 1) {
                        walkin_interview = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Walk-in Interview"><i class="fa fa-building-o"></i></span>';
                        applied_btn = '<a class="btn btn-info" href="'+joburl+'#jobWalkin" target="_blank"><i class="fa fa-building-o"></i> Walk-in Interview</a>';
                    }

                    //fresh edu
                    var freshjob = '';
                    if (job[i].accept_fresh_graduate == 'yes') {
                        freshjob = '<span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5" data-toggle="tooltip" title="Fresh Graduate"><i class="mk mk-graduate"></i></span>';
                    }

                    //hot job
                    var hotjob = '';
                    if (job[i].is_hotjob == 'yes') {
                        hotjob = '<span class="xs-mt-10 label label-sm label-warning label-circle xs-mr-5" data-toggle="tooltip" title="Hot Job"><i class="mk mk-hotjobs"></i></span>';
                    }

                    public_emails = '';
                    public_phones = '';
                    whatsapp	  = '';

                    if (job[i].public_emails != '') {
                        public_emails = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'email\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-default label-circle xs-mr-5 pull-right"><i class="fa fa-envelope"></i></span></a>';
                    }

                    if (job[i].public_phones != '') {
                        public_phones = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'phone\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5 pull-right"><i class="fa fa-phone"></i></span></a>';
                    }

                    if (job[i].whatsapp != '') {
                        whatsapp = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'whatsapp\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5 pull-right"><i class="fa fa-whatsapp"></i></span></a>';
                    }


                    var cleanJobLocation = '';
                    if (job[i].job_location.indexOf('|') === -1) {
                        cleanJobLocation = job[i].job_location;
                    } else {
                        cleanJobLocation = job[i].job_location.substring(0,job[i].job_location.indexOf('|')-1);
                    }

                    var requredLanguages = '';
                    if (job[i].speaking_language) {
                        requredLanguages1 = '<span class="d-inline-block text-nowrap xs-mr-10" data-toggle="tooltip" title="' + txt_required_langs + ': ' + job[i].speaking_language + '"><i class="fa fa-language w-15"></i> '
                        requredLanguages1 = requredLanguages1.replace('Chinese', 'Mandarin');
                        requredLanguages2 = job[i].speaking_language + '</span>';
                        requredLanguages2 = requredLanguages2.replace('English', 'EN');
                        requredLanguages2 = requredLanguages2.replace('Bahasa Malaysia', 'BM');
                        requredLanguages2 = requredLanguages2.replace('Chinese', 'ZH');
                        requredLanguages = requredLanguages1 + requredLanguages2;
                        requredLanguages = requredLanguages.replace(/,/g, ', ');
                    }

                    jobgroupcpa = '';
                    var viewcpa = GetUrlParms('viewcpa');
                    if (typeof job[i]['group_cpa']!='undefined' && viewcpa!='')
                    {
                        jobgroupcpa = '<span class="d-inline-block text-nowrap xs-mr-10" ><i class="fa fa-money w-15"></i> RM '+job[i]['group_cpa']+' CPA </span>';
                    }


                    var reportBtn = '<li><a href="#" class="btn btn-default report-job-btn" data-type="job" data-id="' + jobId + '" id="report-job-' + jobId +'" >'+ txt_report_job.navigation + '</a></li> ';

                    if (job[i].reported == true) {
                        reportBtn = '<li><a  class="btn btn-default reported-job-btn disabled text-grey" data-id="' + jobId + '" >' + txt_report_job.reported + '</a></li>';
                    }


                    var moreBtn = '<div class="action dropup dropdown-menu-right"> ' +
                        '<a class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-lg fa-ellipsis-h text-grey"></i></a>' +
                        '<ul class="dropdown-menu dropdown-menu-right"> ' +
                        '<li><a class="btn btn-default text-dark" target="_blank" href="' + joburl + '"> '+ txt_view_job + '</a></li> \
                                                '+ reportBtn +
                        '</ul>' +
                        '</div>';

                    var aLinkToContact = '';
                    if (job[i].public_emails != '' || job[i].public_phones != '' || job[i].whatsapp != '') {
                        var uhref = joburl+'#employerContact';
                        // aLinkToContact = '<a id="reqViewContact" onclick="viewEmployerContact('+job[i].job_id+');sendGaEvent(\'' + ACTION_CLICK +  '\', \'' + GA_CATEGORY_SHOW_CONTACT  + '\', \'' + job[i].job_id+ '\');" href="'+uhref+'" target="_blank" data-url="'+joburl+'"><span class="xs-mr-5">View Employer Contact</span></a>';
                        aLinkToContact = '';
                    } else {}


                    if(job[i].is_recommended == 'yes')
                    {
                        hRefUrl = 'href="' + joburl + '?ref=profileRecommendHome" onclick="sendGaEvent(\'' + ACTION_PROFILE + '\', \''+ GA_CATEGORY_RECOMMENDATIONS +  '\', \'' +job[i].job_id+'\');"';
                    }

                    if (pagename=='joblisting'){
                        if(!data.searchEnabled){
                            if(i==2 && _isLogin !=0) 
                            {
                                resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' job-recommend-container">\
                                                    <h5 class="slick-title text-bold">'+txt_header1+'   <i class="fa fa-play" style="color:red;"></i></h5>\
                                            </div>';
                                if(recommendationJob.length !=0)
                                {

                                    resultDiv += loadRecommendJobFromList(recommendationJob,minimizeClass,data.ref,current_page);


                                }
                                else
                                {
                                    resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' noskill-container1">\
                                                    <div class="job-list b b-1 b-default b-r-4 p-relative bg-white box-shadow xs-mb-20 b-r-0-xs b-left-0-xs b-right-0-xs">\
                                                        <div class="xs-p-20 pointer" >\
                                                                <table align="center">\
                                                                <tbody>\
                                                                    <tr>\
                                                                        <td rowspan="3"><img src="https://maukerja.s3-ap-southeast-1.amazonaws.com/logo-standing.png" alt="mkexp" style="width: 68px; height: 98px; object-fit: contain;"></td>\
                                                                    </tr>\
                                                                    <tr>\
                                                                        <td align="center"><p>'+no_experience+'<br />'+no_experience2+'</p></td>\
                                                                    </tr>\
                                                                    <tr>\
                                                                        <td align="center"><a href="'+canvas_url+'jobseeker/mypage" data-toggle="modal" class="btn btn-primary btn-outline sm-ml-10 xs-ml-0 sm-mt-0 xs-mt-10 w-320-xs w-100p-xs">'+txt_mypage+'</a></td>\
                                                                    </tr>\
                                                                </tbody>\
                                                            </table>\
                                                        </div>\
                                                    </div>\
                                                </div>';   

                                }
                                
                            }
                        }

                        if(i==6)
                        {
                             resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' job-ger-container" style="display:none">\
                                            <h5 class="slick-title text-bold">'+txt_header1+'  <i class="fa fa-play" style="color:red;"></i></h5>\
                                            <div class="col-md-12 job-list-wrap '+minimizeClass+'"> <div class="xs-p-20 pointer" ><div id="recom-job" class="recomjob-slide"></div></div></div></div>';
                        }

                        if(i==7 && platform_id == 5)
                        {
                            resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' promo-adsense">\
                                            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>\
                                            <ins class="adsbygoogle"\
                                                 style="display:block"\
                                                 data-ad-client="ca-pub-9472008767195963"\
                                                 data-ad-slot="4077035753"\
                                                 data-ad-format="auto"\
                                                 data-full-width-responsive="true"></ins>\
                                            <script>\
                                                 (adsbygoogle = window.adsbygoogle || []).push({});\
                                            </script>\
                                            </div>';
                        }

                        if(i==10 && platform_id != 5) 
                        {
                            resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' promo-blog-container">\
                                            <h5 class="slick-title text-bold">'+txt_header2+'  <i class="fa fa-play" style="color:red;"></i></h5>\
                                            <div class="col-md-12 job-list-wrap '+minimizeClass+'"> <div style="padding-left: 0px; padding-bottom: 20px;margin-left:10px;margin-right: -10px;" ><div id="promo-blog" class="blog-slide"></div></div></div></div>';
                        }
                       
                        if(i==14 && platform_id != 5) 
                        {
                            resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+' promo-video-container">\
                                            <h5 class="slick-title text-bold">'+txt_header2+'   <i class="fa fa-play" style="color:red;"></i></h5>\
                                            <div class="col-md-12 job-list-wrap '+minimizeClass+'"> <div style="padding-left: 0px; padding-bottom: 20px;margin-left:10px;margin-right: -10px;" ><div id="promo-video" class="video-slide"></div></div></div></div>';
                            
                        }

                    }
                    
                    var benefitJobLi = [];
                    var benefitJob = null;
                    var benefit = '';
                    
                    if (language === 'en') {
                        benefitJob = job[i].benefit_en;
                    } else if (language === 'ms' && job[i].benefit_ms !== '') {
                        benefitJob = job[i].benefit_ms;
                    } else if (language === 'zh' && job[i].benefit_zh !== '') {
                        benefitJob = job[i].benefit_zh;
                    } else {
                        // default to en
                        benefitJob = job[i].benefit_en;
                    }

                    try {
                        benefitJobLi = $(benefitJob).find('li');

                        if (benefitJobLi.length > 0) {
                            var index;
                            for (index = 0; index < benefitJobLi.length; ++index) {
                                benefit += benefitJobLi[index].textContent + ' | ';
                            }
                        } else {
                            benefit = benefitJob;
                        }

                    }catch (ex){
                        console.log(ex);
                    }
                    

                    // benefit= benefit.replace(/<(?:.|\n)*?>/gm, '');

                    try {
                        benefit = benefit.replace(/<(?:.|\n)*?>/gm, '');

                        if(job[i].benefit_tags != '')
                        {
                            benefit = job[i].benefit_tags + benefit;
                        }

                    }catch (ex){
                        console.log(ex);
                    }

                    if(!job[i].is_expired) {
                            resultDiv += '<div class="col-md-12 job-list-wrap ' + minimizeClass + '" id="'+job[i].job_id+'"><div class="job-list b b-1 b-default b-r-4 p-relative bg-white box-shadow xs-mb-20 b-r-0-xs b-left-0-xs b-right-0-xs ' + ((job[i].highlighted == 'yes') ? 'highlighted' : '') + ' ' + ((job[i].is_featured == '1') ? 'featured' : '') + ' ">\
                            <div class="xs-p-20 pointer" >\
                                <div class="xs-pr-15 pull-left" onClick="openURL(\'' + joburl + '\', \'_blank\', event); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_CLICKED\,\'' + job[i].job_id + '\', \'\job\'\);" >\
                                    <a target="_blank" class="img-thumb img-center" href="' + joburl + '">' + clogo + '</a>\
                                </div>\
                                <div class="job-list-title">\
                                    <h4 class=" xs-mt-0 xs-mb-0 text-capitalize text-bold"><a class="text-dark" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_CLICKED\,\'' + job[i].job_id + '\' , \'\job\'\);" target="_blank" href="' + joburl + '"> ' + walkin_interview_title + '' + job[i].title + '</a></h4>\
                                    <p class=" text-capitalize xs-mb-0"><a class="text-dark" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_COMPANY_CLICKED\,\'' + job[i].company_id + '\' , \'\company\'\);" target="_blank" href="' + companyURL + '">' + job[i].company_name + '</a></p>\
                                    <div class="text-left xs-mt-5 text-ellipsis">\
                                        ' + aLinkToContact + '\
                                    </div>\
                                </div>\
                                <div class="job-list-info xs-mt-10" onClick="openURL(\'' + joburl + '\', \'_blank\', event)" >\
                                    <p class="xs-mb-0">\
                                        <span class="d-inline-block text-nowrap xs-mr-10 pointer text-primary"><i class="fa fa-usd w-15"></i>  ' + job[i].final_salary + '' + job[i].commission + '</span>\
                                        <span class="d-inline-block text-nowrap xs-mr-10"><i class="fa fa-briefcase w-15"></i>  ' + job[i].job_type + '</span>\
                                        ' + requredLanguages + '\
                                        ' + jobgroupcpa + '\
                                    </p>\
                                    <p class="xs-mb-0">\
                                        <span class="d-inline-block xs-mr-10"><i class="fa fa-map-marker w-15"></i> ' + jobLocation + '</span>\
                                        ' + jobBranch + '\
                                        ' + walkin_interview_date + '</p>\
                                </div>\
                                <div class="job-list-extra xs-mt-10" onClick="openURL(\'' + joburl + '\', \'_blank\', event)" >\
                                    <p class="xs-mb-0 text-truncate text-grey">' + benefit + '</p>\
                                </div>\
                                <div class="job-tag l-h-0 text-ellipsis">' + walkin_interview + '' + freshjob + '' + hotjob + '' + fastresponse + '' + featured + '<span class="xs-mr-5 text-grey pull-right" style="position:relative;top:25px;">' + job[i].created_at_human + '</span>' + '<span id="showpubliccontact-' + job[i].job_id + '" class="xs-mt-20 xs-mr-20 pull-right"></span></div>\
                            </div>\
                            <div class="job-list-action">\
                                <span>\
                                    ' + share_btn + '\
                                </span>\
                                <span>\
                                    ' + save_btn + '\
                                </span>\
                                <span>\
                                    ' + applied_btn + '\
                                </span>\
                            </div>\
                        </div></div>';
                    }
                }
                $("#jobListing").html(resultDiv);
                $('body').scrollTop(0);



                 $('.job-list-wrap').on('scrollSpy:enter', function() {
                     var id = $(this).attr('id')
                                $.viewImpressionsTracking('impressions',id, 'oldlisting' )

                            });


                            $('.job-list-wrap').scrollSpy();

                if(pagename=='companypage'){
                    $("#jobListing").parent().parent().show();
                }
                $("#jobListing").show();
                $('[data-toggle="tooltip"]').tooltip();
                $("#n_count_results").val(count);

                if (pagename=='joblisting'){
                    //set job view base on cookies
                    if($.cookie('joblist_compact_view') == 'yes') {
                        changeListView('#job-view-compact','compact');
                    }else{
                        changeListView('#job-view-detail','detail');
                    }
                    loadBlog(current_page);
                    loadVideo(current_page);
                    $.loadRecommendedJobsList();
                }


                document.getElementById("Paging").innerHTML = Paging('JobNewListing', start_limit, page, totalNonFeatured, limit);
                if (count > limit)
                    $("#Paging").show();

                $('.loginapply').click(function (e) {
                    applyjoblink = $(this).attr("data-link");
                    $(this).addClass("selected");
                    showLoginModal(e);
                });

                $(".fb-share-button").bind("click", function () {
                    var id = $(this).attr('data-id');
                    var url = $(this).attr('data-url');
                    FB.ui({
                        method: 'share',
                        href: url, //'http://54.169.110.239/job/464-social-media-customer-service-specialist',
                    }, function (response) {});

                    sendSCEvent(scEvents.share_job);
                });

                $(".save_job_normal").bind("click", function (event) {
                    
					if (isLogin=='0') // User not login
					{
						showLoginModal(event);
					}
					else
					{
						var id = $(this).attr('data-id');
						if ($(".save_job_normal").hasClass("disabled")) {
							return false;
						} else
						{
							$.post(canvas_url + "api/job/saved", {'job_id': id, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
								if (data.status == 'success')
								{
									$("#savejobn-" + id).removeClass("save_job_normal");
									$("#savejobn-" + id).addClass("btn-job-saved");
									$("#savejobn-" + id).addClass("disabled");
									$("#savejobn-" + id).html('<i class="fa fa-heart"></i> ' + txt_job_saved + '');

									swal({
				                      title: 'Well Done',
				                      text: txt_job_saved_list,
				                      type: 'success',
				                      width: 450,
				                      confirmButtonText: 'Ok, Thanks',
				                      confirmButtonClass: 'btn btn-primary',
				                      buttonsStyling: false
				                    });


                                    var found = job.find(function(element) {
                                        return element.job_id == id;
                                    });

                                    if(found) {
                                        var _payload = {
                                            'JOBTITLE': found.job_title,
                                            'COMPANYNAME': found.company_name,
                                            'JOBTYPE': found.job_type,
                                            'JOBCATEGORY': found.category_name,
                                            'JOBCITY': found.job_location,
                                            'JOBSTATE': found.region,
                                            'SALARYRANGE': found.final_salary,
                                            "JOBEXPIRYDATE": found.expiry_date,
                                            'LANGUAGE': nc_lang
                                        };
                                        $.netcoreTracking(NC_ACTIVITY_SAVE_JOB, _payload)
                                    }


                                } else {
									$(".save_job_normal").addClass("disabled");
									notify(data.msg, "warning");
								}
	
							}, "json");
						}
					}
                });
            } else {
                $("#jobListing").hide();
                $("#total_counter").hide();
                $("#Paging").hide();
                $(".totalrec").html("0");
                if (pagename=='jobseekerdashboard')
                {
                    $("#jobListing").html('<div class="col-md-12 job-list-wrap text-center">\
					 		<div class="sm-p-0 xs-p-20"><p>No Job Found Matching Your Criteria. Please add/modify your job alerts to get better result.</p>\
					 		<a href="#jobseeker_alert_dashboard" data-toggle="modal" class="btn btn-primary btn-outline sm-ml-10 xs-ml-0 sm-mt-0 xs-mt-10 w-320-xs w-100p-xs"> <i class="fa fa-pencil xs-mr-5"></i> Edit Match Requirement</a>\
					 		</div></div>');
                    $("#jobListing").show();

                }
                else if(pagename=='companypage'){
                    $("#jobListing").parent().parent().hide();
                }
                else
                {
                    if ($(guided_search_stuff.active_filters).children().length == 0 && $("#search_keyword").val() != '*' && $("#search_keyword").val() != '')
                    //$('#add-filter').hide();
                        $('#add-filter').addClass('disabled');

                    $("#n_count_results").val(0);
                    setTimeout(function () {
                        var nc = parseInt($("#n_count_results").val());
                        var fc = parseInt($("#f_count_results").val());
                        if (nc == 0 && fc == 0) {
                            $("#Paging").hide();
                            if (data.msg == 'engine is down') {
                                $('#engine-tech-issue-wrapper').html('<img id="engine-tech-issue" src="' + canvas_url + 'assets/img/engine-tech-issue-' + $('#engine-tech-issue-wrapper').attr('data-platform') + '.png" style="width:100%;" alt="We are experiencing technical difficulties. Please try again later..." >');
                                $('#engine-tech-issue-wrapper').show();
                                $("#empty-job").hide();
                            } else {
                                $('#engine-tech-issue-wrapper').hide();
                                $("#empty-job").show();
                                $.getRecommendedJobsAdditional();
                            }
                        } else if ((keyword != '' || search_location_2 != '') &&nc == 0) {
                            $("#Paging").hide();
                            if (data.msg == 'engine is down') {
                                $('#engine-tech-issue-wrapper').html('<img id="engine-tech-issue" src="' + canvas_url + 'assets/img/engine-tech-issue-' + $('#engine-tech-issue-wrapper').attr('data-platform') + '.png" style="width:100%;" alt="We are experiencing technical difficulties. Please try again later..." >');
                                $('#engine-tech-issue-wrapper').show();
                                $("#empty-job").hide();
                            } else {
                                $('#engine-tech-issue-wrapper').hide();
                                $("#empty-job").show();
                                $.getRecommendedJobsAdditional();
                            }
                        } else {
                            $("#empty-job").hide();
                            $("#Paging").show();
                        }
                    }, 1000);
                }
            }
            //$('.suggestion-thing-content').hide();
            //$('.location-tooltip').tooltip();
        });

        request.fail(function (jqXHR, textStatus) {
            $('#spinner').hide();
            NProgress.done();
            $("#jobListing").hide();
            $("#total_counter").hide();
            $("#Paging").hide();
            $(".totalrec").html("0");
            //if ($(guided_search_stuff.active_filters).children().length == 0)
            //$('#add-filter').hide();
            $('#add-filter').addClass('disabled');
            //$("#filter_sorting").addClass("hide");
            $("#n_count_results").val(0);
            setTimeout(function () {
                var nc = parseInt($("#n_count_results").val());
                var fc = parseInt($("#f_count_results").val());
                if (nc == 0 && fc == 0) {
                    $("#empty-job").show();
                    $("#Paging").hide();
                } else if (keyword != '' && nc == 0) {
                    $("#empty-job").show();
                    $.getRecommendedJobsAdditional();
                    $("#Paging").hide();
                } else {
                    $("#empty-job").hide();
                    $("#Paging").show();
                }
            }, 1000);
        });
    }


    $(".joblisting_jobtype, .joblisting_category, .joblisting_education, .joblisting_freshgraduate, .joblisting_fastresponse, .joblisting_hotjobs, .joblisting_urgentjobs,.joblisting_direct_contact, .joblisting_walkin_interview").bind("click", function () {
        $.SetJobSearch();
    });

    $.getFeaturedEmployers = function()
    {
        var resultDiv = '';
        var jsonData = {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url+"api/featuredemployers",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        //$('#spinner').show();
        $("#featuredemployer_div").hide();

        request.done(function (data) {

            //$('#spinner').hide();

            if (data.status == 'success')
            {
                var company = data.data;
                var count   = company.length;
                var companyURL = '';
                for (var i=0;i<count;i++)
                {
                    companyURL = canvas_url + 'company/' + company[i].emp_profile_id+'-'+company[i].seo_link+ (data.ref === '' ? '' : '?ref=' + data.ref);

                    if (company[i].logo=='' || company[i].logo==null)
                    {
                        company[i].logo = 'https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg';
                    }

                    jsonObj = [];
                    item = {}
                    item["company_id"] = company[i].emp_profile_id;
                    jsonObj.push(item);


                    resultDiv+='<a href="'+companyURL+'" onClick="sendSCLoginEvent(\''+SIDE_PAGE+ '\',\'' +FEATURED_EMPLOYERS_CLICK+ '\',\'' +jsonObj+ '\');" target="_blank"><div class="input-group-custom group xs-pt-10 xs-pb-10 b-top">\
    								<div class="group xs-pr-10">\
    									<div class="img-thumb img-center">\
    										<img src="'+company[i].logo+'" class="img-responsive">\
    									</div>\
    								</div>\
    								<div class="group auto">\
    									<p class="text-dark text-bold xs-mb-0 text-truncate">'+company[i].company_name+'</p>\
    									<p class="text-grey">'+company[i].job_count+' Job Available</p>\
    								</div>\
    							</div></a>';
                }

                $("#featuredemployers").html(resultDiv);
                $("#featuredemployer_div").show();
            } else
            {
                $("#featuredemployer_div").hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {

            $("#featuredemployer_div").hide();
        });
    }

    $.getSimilarEmployers = function()
    {
        var company_id = $("#company_id").val();
        var resultDiv = '';
        var jsonData = {
            'company_id':company_id,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url+"api/similaremployers",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        //$('#spinner').show();

        request.done(function (data) {

            //$('#spinner').hide();

            if (data.status == 'success')
            {
                var company = data.data;
                var count   = company.length;
                var companyURL = '';
                for (var i=0;i<count;i++)
                {
                    companyURL = canvas_url + 'company/' + company[i].emp_profile_id+'-'+company[i].seo_link+ (data.ref === '' ? '' : '?ref=' + data.ref);

                    if (company[i].logo=='' || company[i].logo==null)
                    {
                        company[i].logo = 'https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg';
                    }

                    resultDiv+='<a href="'+companyURL+'" target="_blank"><div class="input-group-custom group xs-pt-10 xs-pb-10 b-top">\
    								<div class="group xs-pr-10">\
    									<div class="img-thumb img-center">\
    										<img src="'+company[i].logo+'" class="img-responsive">\
    									</div>\
    								</div>\
    								<div class="group auto">\
    									<p class="text-dark text-bold xs-mb-0 text-truncate">'+company[i].company_name+'</p>\
    									<p class="text-grey">'+company[i].job_count+' Job Available</p>\
    								</div>\
    							</div></a>';
                }

                $("#similaremployers").html(resultDiv);
                $("#similaremployer_div").show();
            } else
            {
                $("#similaremployer_div").hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {

            $("#similaremployer_div").hide();
        });
    }

    $.getAllFeaturedEmployers = function()
    {
        var page = parseInt($("#page").val());
        var limit = 18;
        var start_limit = (page - 1) * limit;
        start_limit = (start_limit < 0) ? 0 : start_limit;

        var resultDiv = '';
        var jsonData = {
            start: start_limit,
            noOfRecords: limit,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url+"api/allfeaturedemployers",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        //$('#spinner').show();

        request.done(function (data) {

            $('#spinner').hide();

            if (data.status == 'success')
            {
                var company = data.data;
                var count   = company.length;
                var total   = data.total;
                var companyURL = '';
                for (var i=0;i<count;i++)
                {
                    companyURL = canvas_url + 'company/' + company[i].emp_profile_id+'-'+company[i].seo_link+ (data.ref === '' ? '' : '?ref=' + data.ref);

                    if (company[i].logo=='' || company[i].logo==null)
                    {
                        company[i].logo = 'https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg';
                    }

                    resultDiv += '<div class="col-md-4 col-sm-6 col-xs-12 xs-pl-10 xs-pr-10 xs-mb-20 text-center">\
                                <a class="no-link b b-1 b-default xs-p-20 b-r-4 d-block " href="' + companyURL + '">\
                                    <div class="img-thumb img-center xs-mb-10" style="margin:0 auto;margin-bottom:10px;">\
                                        <img class="img-responsive center-block" src="' + company[i].logo + '">\
                                    </div>\
                                    <p class="text-bold xs-mb-0 text-ellipsis">' + company[i].company_name + '</p>\
                                    <p class="text-grey xs-mb-0">' + company[i].job_count + ' '+txt_jobs_available+'</p>\
									<p class="xs-mb-0 link link-underline">'+txt_view_jobs+'</p>\
                                </a>\
                            </div>';
                }

                $("#featuredemployers").append(resultDiv);
                if (count<total)
                    $('#show-more').show();
                else
                    $('#show-more').hide();
            } else
            {
                $("#featuredemployer_div").hide();
                $('#show-more').hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {

            $("#featuredemployer_div").hide();
            $('#show-more').hide();
        });
    }

    $.getFeaturedJobsListing = function (page) {

        var listing_type  = $("#listing_type").val();
        var listing_value  = $("#listing_value").val();
        var listing_value_id  = $("#listing_value_id").val();
        var category_id = $("#category-change").val();
        var priority = $("#priority-change").val();
        var pagename = $("#pagename").val();
        var resultDiv = '';
        var sort_by = $("#sort_type").val();
        var orderby = $("#sort_order").val();
        var platform_id = $("#imp_platform_id").val();
        var themeColor = "#25A67F";
        if(platform_id == 2 || platform_id == 6)
        {
            themeColor = "#ed3554";
        }
        else if(platform_id == 5)
        {
            themeColor = "#00c0cf";
        }
        else
        {
            themeColor = "#25A67F";   
        }


        var current_page = parseInt($("#page").val());
        if (current_page > 1)
            page = current_page;
        var limit = 30;
        var minimizeClass = 'minimize';

        var start_limit = (page - 1) * limit;
        start_limit = (start_limit < 0) ? 0 : start_limit;

        var jsonData = {
            listing_type: listing_type,
            listing_value: listing_value,
            listing_value_id: listing_value_id,
            category_id : category_id ,
            priority : priority ,
            platform_id : platform_id,
            orderby: orderby,
            start: start_limit,
            noOfRecords: limit,
            pagename: pagename,
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/job/featuredlisting",
            data: jsonData,
            cache: true,
            localCache: true,
            cacheKey: 'jobs',
            type: 'GET',
            crossDomain: true,
            dataType: 'json',
            cacheTTL: 1,
            async:false,
            isCacheValid: function () {  // optional.
                return true;
            }
        });

        $('#spinner').show();
        NProgress.start();
        $("#jobListing").hide();
        $(".paging-div").hide();
        $("#empty-job").hide();
        $('#engine-tech-issue-wrapper').hide();
        $("#Paging").show();
        request.done(function (data) {
            NProgress.done();
            $('#spinner').hide();

            if (data.status == 'success') {
                var count = data.totalRecords;
                $(".totalrec").html(count);
                $(".totalrec")[0].innerHTML = count;
                var totalNonFeatured = data.totalNonFeatured;
                var job = data.data;
                var current = job.length;
                var statusBar = '';
                var clogo = '';
                var csrc = '';
                var joburl = '';
                var applylink = '';
                var source = '';
                var save_btn = '';
                var applied_btn = '';
                var share_btn = '';
                var newurl = cUrl.href;
                var companyURL = '';
                var jobgroupcpa = '';
                var bgimage = '';
                for (var i = 0; i < current; i++) {

                    if (job[i].logo != null && job[i].logo != '') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].logo + '" alt="' + job[i].company_name + ' Logo" class="img-responsive lazy">';
                        csrc = job[i].logo;
                    } else {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].category_pic + '" alt="' + job[i].category_name + ' Category" class="img-responsive lazy">';
                        csrc = job[i].category_pic;
                    }

                    csrc = job[i].company_cover;

                    if (job[i].company_privacy == 'private') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg" alt="Private Company" class="img-responsive lazy">';
                        csrc = 'https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg';
                    }

                    if (job[i].seo_link == null || typeof job[i].seo_link == undefined) {
                        joburl = canvas_url + 'job/' + job[i].job_id + '-' + FilterURL(job[i].job_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        joburl = canvas_url + 'job/' + job[i].job_id + '-' + job[i].seo_link + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (job[i].seo_title != null || typeof job[i].seo_title != undefined) {
                        if (newurl.indexOf("/en") != -1) {
                            joburl = canvas_url + 'en/job/' + job[i].job_id + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else if (newurl.indexOf("/zh") != -1) {
                            joburl = canvas_url + 'zh/job/' + job[i].job_id + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else if (newurl.indexOf("/ms") != -1) {
                            joburl = canvas_url + 'ms/job/' + job[i].job_id + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                        } else
                            joburl = canvas_url + 'job/' + job[i].job_id + '-' + FilterURL(job[i].seo_title) + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (!job[i].company_slug) {
                        job[i].company_slug = FilterURL(job[i].company_name);
                    }

                    if (newurl.indexOf("/en") != -1)
                    {
                        applylink = canvas_url + 'en/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'en/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'en/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/zh") != -1)
                    {
                        applylink = canvas_url + 'zh/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'zh/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'zh/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/ms") != -1)
                    {
                        applylink = canvas_url + 'ms/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'ms/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'ms/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        applylink = canvas_url + 'job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    }
                    if (job[i].company_privacy == 'private') {
                        companyURL = '#';
                    }
                    //source = '';

                    if (job[i].apply_direct != null && job[i].apply_direct == 'yes')
                    {
                        applylink = job[i].apply_link + (data.ref === '' ? '' : '?ref=' + data.ref);
                        //source = '<a class="right link-success-o comp" href="' + applylink + '" target="_blank"> ' + job[i].source + '</a> <i class="mdi mdi-radiobox-marked"></i>';
                    }


                    if (job[i].job_saved == 'false')
                    {
                        save_btn = '<a class="btn btn-default  save_job_normal" data-id="' + job[i].job_id + '" id="savejobn-' + job[i].job_id + '" style="margin-left:-3px;border: 0;font-weight: bold;font-size: 12px;"  onclick="sendGaEvent(\'' + ACTION_SAVE_FROM_MAIN + '\',\'' + GA_CATEGORY_SAVE_JOB + '\',\'' + job[i].job_id + '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SAVED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-heart-o"></i></a> ';
                    } else
                    {
                        save_btn = '<a class="btn btn-default  btn-job-saved disabled" style="margin-left:-3px;font-weight: bold;font-size: 12px;border: 0;"  data-id="' + job[i].job_id + '"><i class="fa fa-heart-o"></i></a>';
                    }

                    if (job[i].job_applied == 'false') {

                        if (isLogin=='0') // User not login
                        {
                            applied_btn = '<a class="btn btn-default pointer loginapply text-primary important" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + job[i].job_id + '\' , \'\job\'\);"  data-id="'+job[i].job_id+'" style="margin-right:20px;font-weight: bold;font-size: 12px;float: right;width: 100px;height: 20px;border-radius: 10px;background-color:'+themeColor+';border-color:'+themeColor+';margin-bottom:10px;" data-link="'+applylink+'"><p style="margin-top:-5px;color:#ffffff;">' + txt_apply_button + '</p></a>';
                        }else{

                            applied_btn = '<a href="' + applylink + '" target="_blank" class="text-primary important btn btn-default " style="margin-bottom:10px;margin-right:20px;font-weight: bold;font-size: 12px;float: right;width: 100px;height: 20px;border-radius: 10px;background-color:'+themeColor+';border-color:'+themeColor+';" onclick="sendGaEvent(\'' +  ACTION_FROM_MAIN + '\', \'' + GA_CATEGORY_APPLY_JOB + '\', \'' + job[i].job_id + '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);""><p style="margin-top:-5px;color:#ffffff;">' + txt_apply_button + '</p></a>';

                        }

                    } else {
                        //applied_btn = '<a target="_blank" class="btn btn-default disabled"><i class="fa fa-pencil-square-o"></i> ' + txt_applied + '</a>';
                        applied_btn = '<a target="_blank" style="margin-bottom:10px;margin-right:20px;font-weight: bold;font-size: 12px;float: right;width: 120px;height: 20px;border-radius: 10px;background-color:'+themeColor+';border-color:'+themeColor+';"  class="btn btn-default" href="' + canvas_url + 'jobseeker/timeline/' + job[i].hashedAppliedId + '" ><p style="margin-top:-5px;margin-left:-5px;color:#ffffff;"><i class="fa fa-eye"></i> ' + txt_view_progress + '</p></a>';
                    }

                    share_btn = '<a class="btn btn-default pointer fb-share-button" style="margin-left:5px;border: 0;font-weight: bold;font-size: 12px;"  data-id="' + job[i].job_id + '" data-url="' + joburl + '" onclick="sendGaEvent(\'' +  ACTION_SHARE_FROM_MAIN + '\', \'' + GA_CATEGORY_SHARE_JOB + '\', \'' + job[i].job_id + '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SHARED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-share-alt"></i></a>';

                    if (job[i].area != '') {
                        job[i].area = job[i].area + ' ,';
                    }
                    if (job[i].final_salary == 'RM' || job[i].final_salary == 'RM1-RM1' || job[i].final_salary == 'RMRM1' || job[i].final_salary == '')
                        job[i].final_salary = txt_negotiable;
                    if (job[i].final_salary == 0)
                        job[i].final_salary = txt_negotiable;

                    //featured
                    var featured = '';
                    if (job[i].is_featured == '1') {
                        featured = '<span class="xs-mt-10 label label-sm label-danger label-circle xs-mr-5" data-toggle="tooltip" title="Urgent Job"><i class="fa fa-star-o"></i></span>';
                    }

                    //fast response
                    var fastresponse = '';
                    if (job[i].has_badge == 'yes'){
                        fastresponse = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Fast Response"><i class="mk mk-flash"></i></span>';
                    }

                    //fresh edu
                    var freshjob = '';
                    if (job[i].accept_fresh_graduate == 'yes') {
                        freshjob = '<span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5" data-toggle="tooltip" title="Fresh Graduate"><i class="mk mk-graduate"></i></span>';
                    }

                    //hot job
                    var hotjob = '';
                    if (job[i].is_hotjob == 'yes') {
                        hotjob = '<span class="xs-mt-10 label label-sm label-warning label-circle xs-mr-5" data-toggle="tooltip" title="Hot Job"><i class="mk mk-hotjobs"></i></span>';
                    }

                    var cleanJobLocation = '';
                    if (job[i].job_location.indexOf('|') === -1) {
                        cleanJobLocation = job[i].job_location;
                    } else {
                        cleanJobLocation = job[i].job_location.substring(0,job[i].job_location.indexOf('|')-1);
                    }

                    var requredLanguages = '';
                    if (job[i].speaking_language) {
                        requredLanguages1 = '<span class="d-inline-block text-nowrap xs-mr-10" data-toggle="tooltip" title="' + txt_required_langs + ': ' + job[i].speaking_language + '"><i class="fa fa-language w-15"></i> '
                        requredLanguages1 = requredLanguages1.replace('Chinese', 'Mandarin');
                        requredLanguages2 = job[i].speaking_language + '</span>';
                        requredLanguages2 = requredLanguages2.replace('English', 'EN');
                        requredLanguages2 = requredLanguages2.replace('Bahasa Malaysia', 'BM');
                        requredLanguages2 = requredLanguages2.replace('Chinese', 'ZH');
                        requredLanguages = requredLanguages1 + requredLanguages2;
                        requredLanguages = requredLanguages.replace(/,/g, ', ');
                    }

                    jobgroupcpa = '';
                    var viewcpa = GetUrlParms('viewcpa');
                    if (typeof job[i]['group_cpa']!='undefined' && viewcpa!='')
                    {
                        jobgroupcpa = '<span class="d-inline-block text-nowrap xs-mr-10" ><i class="fa fa-money w-15"></i> RM '+job[i]['group_cpa']+' CPA </span>';
                    }

                    if (platform_id=='1') bgimage = 'rb.png';
                    else if (platform_id=='2') bgimage = 'mk.png';
                    else if (platform_id=='5') bgimage = 'int.png';

                    resultDiv += '<div class="col-md-4 col-sm-6 col-xs-12 minimize-fluid job-list-wrap pointer" onClick="openURL(\''+joburl+'\', \'_blank\', event)" >\
                                	<div class="job-list b b-1 b-default b-r-4 p-relative o-flow-hidden box-shadow xs-mb-20" style="background: #ffffff;">\
								 		<div class="p-relative">\
                                            <div class="bg-image bg-image-fixed bg-image-center" style="background-image: url('+csrc+');"></div>\
                                            <div class="bg-overlay"></div>\
											<div class="xs-pl-20 xs-pr-20">\
											<div class="p-relative" style="height: 100px;">\
												<div class="p-absolute p-bottom p-left w-100p" style="margin-bottom: -20px">\
													<div class="img-thumb img-center pull-left xs-mr-10">\
														' + clogo + '\
													</div>\
												</div>\
											</div>\
											</div>\
										</div>\
										<div class="xs-p-20 xs-pt-40 bg-white">\
											<p class="xs-mb-0 text-bold xs-mt-0 text-responsive">' + job[i].title + '</p>\
											<p class="xs-mb-0 text-grey">' + job[i].company_name + '</p>\
											<p class="xs-mb-0 xs-mt-10">\
												<span class="d-inline-block text-nowrap xs-mr-10 pointer text-primary"><i class="fa fa-usd w-15"></i> ' + job[i].final_salary + '' + job[i].commission + '</span>\
											</p>\
											<p class="xs-mb-0"><i class="fa fa-map-marker w-15"></i> ' + job[i].job_location + '</p>\
										</div>\
                                        <div style="background: #ffffff;margin-bottom:10px;">\
                                            '+share_btn+'\
                                            '+save_btn+'\
                                        <span>\
                                            '+ applied_btn +'\
                                        </span>\
                                    </div>\
									</div>\
                            	</div>\
								 ';

                }

                $("#jobListing").html(resultDiv);
                $("#jobListing").show();
                $('[data-toggle="tooltip"]').tooltip();
                $("#filterCategory").show();


                document.getElementById("Paging").innerHTML = Paging('FeaturedJobListing', start_limit, page, totalNonFeatured, limit);
                if (count > limit)
                    $("#Paging").show();

                $('.loginapply').click(function (e) {
                    applyjoblink = $(this).attr("data-link");
                    $(this).addClass("selected");
                    showLoginModal(e);
                });

                $(".fb-share-button").bind("click", function () {
                    var id = $(this).attr('data-id');
                    var url = $(this).attr('data-url');
                    FB.ui({
                        method: 'share',
                        href: url, //'http://54.169.110.239/job/464-social-media-customer-service-specialist',
                    }, function (response) {});

                    sendSCEvent(scEvents.share_job);
                });

                $(".save_job_normal").bind("click", function (event) {

                    if (isLogin=='0') // User not login
                    {
                        showLoginModal(event);
                    }
                    else
                    {
                        var id = $(this).attr('data-id');
                        if ($(".save_job_normal").hasClass("disabled")) {
                            return false;
                        } else
                        {
                            $.post(canvas_url + "api/job/saved", {'job_id': id, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                                if (data.status == 'success')
                                {
                                    $("#savejobn-" + id).removeClass("save_job_normal");
                                    $("#savejobn-" + id).addClass("btn-job-saved");
                                    $("#savejobn-" + id).addClass("disabled");
                                    $("#savejobn-" + id).html('<i class="fa fa-heart"></i> ' + txt_job_saved + '');

                                    swal({
                                        title: 'Well Done',
                                        text: txt_job_saved_list,
                                        type: 'success',
                                        width: 450,
                                        confirmButtonText: 'Ok, Thanks',
                                        confirmButtonClass: 'btn btn-primary',
                                        buttonsStyling: false
                                    });
                                } else {
                                    $(".save_job_normal").addClass("disabled");
                                    notify(data.msg, "warning");
                                }

                            }, "json");
                        }
                    }
                });
            } else {
                $("#filterCategory").show();
                $("#jobListing").hide();
                $(".totalrec").html("0");
                $("#empty-job").show();
                $("#Paging").hide();
            }
        });

        request.fail(function (jqXHR, textStatus) {
            $('#spinner').hide();
            NProgress.done();
            $("#filterCategory").hide();
            $("#jobListing").hide();
            $(".totalrec").html("0");
            $("#empty-job").show();
            $("#Paging").hide();
        });
    }

    $.getRecommendedJobsAdditional = function ()
    {
        var pagename = $("#pagename").val();
        var job_id = $("#job_id").val();
        var minimizeClass = 'minimize';
        var resultDiv = '';
        var jsonData = {
            'pagename': pagename,
            'job_id': job_id,
            'limit':12,
            'is_additional':'yes',
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': "GET"
        };

        var request = $.ajax({
            url: canvas_url + "api/recommendedjobs",
            data: jsonData,
            type: 'GET',
            crossDomain: true,
            dataType: 'json'
        });

        $("#recommendjobListing").hide();
        $(".recommendjobListing-div").addClass('hide');
        request.done(function (data) {

            if (data.status === 'success')
            {
                var job = data.data;
                var count = job.length;
                var statusBar = '';
                var clogo = '';
                var joburl = '';
                var applylink = '';
                var source = '';
                var save_btn = '';
                var applied_btn = '';
                var share_btn = '';
                var newurl = cUrl.href;
                var companyURL = '';
                var jobgroupcpa = '';
                for (var i = 0; i < count; i++)
                {
                    job[i].title = job[i].job_title;
                    job[i].final_salary = job[i].salary;
                    job[i].job_location = job[i].location;

                    if (job[i].logo != null && job[i].logo != '') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].logo + '" alt="' + job[i].company_name + ' Logo" class="img-responsive lazy">';
                    } else {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="""' + job[i].category_pic + '" alt="' + job[i].category_name + ' Category" class="img-responsive lazy">';
                    }

                    if (job[i].company_privacy == 'private') {
                        clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg" alt="Private Company" class="img-responsive">';
                    }

                    joburl = canvas_url + 'job/' + job[i].job_id + '-' + FilterURL(job[i].title) + (data.ref === '' ? '' : '?ref=' + data.ref);

                    if (!job[i].company_slug) {
                        job[i].company_slug = FilterURL(job[i].company_name);
                    }

                    if (newurl.indexOf("/en") != -1)
                    {
                        applylink = canvas_url + 'en/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'en/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'en/jobs/' + makeUrlFriendly(job[i].job_title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/zh") != -1)
                    {
                        applylink = canvas_url + 'zh/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'zh/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'zh/jobs/' + makeUrlFriendly(job[i].job_title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else if (newurl.indexOf("/ms") != -1)
                    {
                        applylink = canvas_url + 'ms/job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'ms/company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'ms/jobs/' + makeUrlFriendly(job[i].job_title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    } else {
                        applylink = canvas_url + 'job/apply-job?job_id=' + job[i].job_id;
                        companyURL = canvas_url + 'company/' + job[i].company_id+'-'+job[i].company_slug+ (data.ref === '' ? '' : '?ref=' + data.ref);
                        firstLink = canvas_url + 'jobs/' + makeUrlFriendly(job[i].job_title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    if (job[i].company_privacy == 'private') {
                        companyURL = '#';
                    }

                    if (job[i].apply_direct != null && job[i].apply_direct == 'yes')
                    {
                        applylink = job[i].apply_link + (data.ref === '' ? '' : '?ref=' + data.ref);
                    }

                    job[i].job_saved = 'false';
                    job[i].job_applied = 'false';

                    if (job[i].job_saved == 'false')
                    {
                        save_btn = '<a class="btn btn-default  save_job_normal" data-id="' + job[i].job_id + '" id="savejobn-' + job[i].job_id + '" onclick="sendGaEvent(\'' + ACTION_SAVE_FROM_MAIN + '\', \'' + GA_CATEGORY_SAVE_JOB +  '\', \'' + job[i].job_id +  '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SAVED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-heart"></i> ' + txt_save + '</a> ';
                    } else
                    {
                        save_btn = '<a class="btn btn-default  btn-job-saved disabled" data-id="' + job[i].job_id + '"><i class="fa fa-heart"></i> ' + txt_job_saved + '</a>';
                    }

                    if (job[i].job_applied == 'false') {

                        if (isLogin=='0') // User not login
                        {
                            applied_btn = '<a class="btn btn-default pointer loginapply text-primary important" data-id="'+job[i].job_id+'" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + job[i].job_id + '\' , \'\job\'\);" data-link="'+applylink+'"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                        }else{

                            applied_btn = '<a href="' + applylink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' + ACTION_CLICK + '\', \'' + GA_CATEGORY_APPLY_JOB +  '\', \'' + job[i].job_id +  '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);""><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';

                        }

                    } else {
                        //applied_btn = '<a target="_blank" class="btn btn-default disabled"><i class="fa fa-pencil-square-o"></i> ' + txt_applied + '</a>';
                        applied_btn = '<a target="_blank" class="btn btn-default" href="' + canvas_url + 'jobseeker/timeline/' + job[i].hashedAppliedId + '" ><i class="fa fa-eye"></i> ' + txt_view_progress + '</a>';
                    }

                    share_btn = '<a class="btn btn-default pointer fb-share-button" data-id="' + job[i].job_id + '" data-url="' + joburl + '" onclick="sendGaEvent(\'' + ACTION_SHARE_FROM_MAIN + '\', \'' + GA_CATEGORY_SHARE_JOB +  '\', \'' + job[i].job_id +  '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SHARED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-share-alt"></i> '+txt_share_button+'</a>';

                    if (job[i].area != '') {
                        job[i].area = job[i].area + ' ,';
                    }
                    if (job[i].final_salary == 'RM' || job[i].final_salary == 'RM1-RM1' || job[i].final_salary == 'RMRM1' || job[i].final_salary == '')
                        job[i].final_salary = txt_negotiable;
                    if (job[i].final_salary == 0)
                        job[i].final_salary = txt_negotiable;

                    //featured
                    var featured = '';
                    if (job[i].is_featured == '1') {
                        featured = '<span class="xs-mt-10 label label-sm label-danger label-circle xs-mr-5" data-toggle="tooltip" title="Urgent Job"><i class="fa fa-star-o"></i></span>';
                    }

                    //fast response
                    var fastresponse = '';
                    if (job[i].has_badge == 'yes'){
                        fastresponse = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Fast Response"><i class="mk mk-flash"></i></span>';
                    }

                    //fresh edu
                    var freshjob = '';
                    if (job[i].accept_fresh_graduate == 'yes') {
                        freshjob = '<span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5" data-toggle="tooltip" title="Fresh Graduate"><i class="mk mk-graduate"></i></span>';
                    }

                    //hot job
                    var hotjob = '';
                    if (job[i].is_hotjob == 'yes') {
                        hotjob = '<span class="xs-mt-10 label label-sm label-warning label-circle xs-mr-5" data-toggle="tooltip" title="Hot Job"><i class="mk mk-hotjobs"></i></span>';
                    }

                    var cleanJobLocation = '';
                    if (job[i].location.indexOf('|') === -1) {
                        cleanJobLocation = job[i].location;
                    } else {
                        cleanJobLocation = job[i].location.substring(0,job[i].location.indexOf('|')-1);
                    }

                    var requredLanguages = '';
                    if (job[i].speaking_language) {
                        requredLanguages1 = '<span class="d-inline-block text-nowrap xs-mr-10" data-toggle="tooltip" title="' + txt_required_langs + ': ' + job[i].speaking_language + '"><i class="fa fa-language w-15"></i> '
                        requredLanguages1 = requredLanguages1.replace('Chinese', 'Mandarin');
                        requredLanguages2 = job[i].speaking_language + '</span>';
                        requredLanguages2 = requredLanguages2.replace('English', 'EN');
                        requredLanguages2 = requredLanguages2.replace('Bahasa Malaysia', 'BM');
                        requredLanguages2 = requredLanguages2.replace('Chinese', 'ZH');
                        requredLanguages = requredLanguages1 + requredLanguages2;
                        requredLanguages = requredLanguages.replace(/,/g, ', ');
                    }

                    jobgroupcpa = '';
                    var viewcpa = GetUrlParms('viewcpa');
                    if (typeof job[i]['group_cpa']!='undefined' && viewcpa!='')
                    {
                        jobgroupcpa = '<span class="d-inline-block text-nowrap xs-mr-10" ><i class="fa fa-money w-15"></i> RM '+job[i]['group_cpa']+' CPA </span>';
                    }

                    var jobBranch = '';
                    if(job[i].job_branch && job[i].job_branch.length > 0)
                    {
                        jobBranch = '<br /><span class="d-inline-block xs-mr-10"><img src="//i.imgur.com/bGLMryW.png" style="max-width: 100%; min-height: 12px;margin-left: -3px;margin-right: 3px"> ' + job[i].job_branch.replace('|',' &#9679; ')  + '</span>';
                    }

                    var language = getCookie('userLanguage');
                    var benefitJobLi = [];
                    var benefitJob = null;
                    var benefit = '';

                    if (language === 'en') {
                        benefitJob = job[i].benefit_en;
                    } else if (language === 'ms' && job[i].benefit_ms !== '') {
                        benefitJob = job[i].benefit_ms;
                    } else if (language === 'zh' && job[i].benefit_zh !== '') {
                        benefitJob = job[i].benefit_zh;
                    } else {
                        // default to en
                        benefitJob = job[i].benefit_en;
                    }

                    try {
                        benefitJobLi = $(benefitJob).find('li');

                        if (benefitJobLi.length > 0) {
                            var index;
                            for (index = 0; index < benefitJobLi.length; ++index) {
                                benefit += benefitJobLi[index].textContent + ' | ';
                            }
                        } else {
                            benefit = benefitJob;
                        }
                    }catch (ex){
                        console.log(ex);
                    }
                // benefit= benefit.replace(/<(?:.|\n)*?>/gm, '');

                    try {
                        benefit = benefit.replace(/<(?:.|\n)*?>/gm, '');

                    }catch (ex){
                        console.log(ex);
                    }

                    resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+'"><div class="job-list b b-1 b-default b-r-4 p-relative bg-white box-shadow xs-mb-20 b-r-0-xs b-left-0-xs b-right-0-xs '+((job[i].highlighted == 'yes' && job[i].is_featured != '1') ? 'highlighted' : '')+' '+((job[i].is_featured == '1') ? 'featured' : '')+' ">\
                                    <div class="xs-p-20 pointer" onClick="openURL(\''+joburl+'\', \'_blank\', event)" >\
                                        <div class="xs-pr-15 pull-left">\
                                            <a target="_blank" class="img-thumb img-center" href="' + joburl + '">' + clogo + '</a>\
                                        </div>\
                                        <div class="job-list-title">\
                                            <h4 class=" xs-mt-0 xs-mb-0 text-capitalize text-bold"><a class="text-dark" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_CLICKED\,\'' + job[i].job_id + '\' ,\'\job\'\);" target="_blank" href="' + joburl + '"> ' + job[i].title + '</a></h4>\
                                            <p class=" text-capitalize xs-mb-0"><a class="text-dark" onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_COMPANY_CLICKED\,\'' + job[i].company_id + '\' , \'\company\'\);" target="_blank" href="' + companyURL + '">' + job[i].company_name + '</a></p>\
                                            <div class="text-left xs-mt-5 text-ellipsis">\
                                                '+ (job[i].is_featured == '0' ? '<span class="xs-mr-5 text-grey"> ' + job[i].created_at_human + '</span>' : '') +'\
                                            </div>\
                                        </div>\
                                        <div class="job-list-info xs-mt-10">\
                                            <p class="xs-mb-0">\
                                                <span class="d-inline-block text-nowrap xs-mr-10 pointer text-primary"><i class="fa fa-usd w-15"></i>  ' + job[i].final_salary + '</span>\
                                                <span class="d-inline-block text-nowrap xs-mr-10"><i class="fa fa-briefcase w-15"></i>  ' + job[i].job_type + '</span>\
                                                '+requredLanguages+'\
                                                '+jobgroupcpa+'\
                                            </p>\
                                            <p class="xs-mb-0"><span class="d-inline-block xs-mr-10"><i class="fa fa-map-marker w-15"></i> ' + job[i].job_location + '</span>\
                                            '+jobBranch+'\
                                            </p>\
                                        </div>\
                                        <div class="job-list-extra xs-mt-10">\
                                            <p class="xs-mb-0 text-truncate text-grey">' + benefit + '</p>\
                                        </div>\
                                        <div class="job-tag l-h-0 text-ellipsis">'+freshjob+''+hotjob+''+fastresponse+''+featured+'</div>\
                                    </div>\
                                    <div class="job-list-action o-flow-hidden">\
                                        <span>\
                                            '+share_btn+'\
                                        </span>\
                                        <span>\
                                            '+save_btn+'\
                                        </span>\
                                        <span>\
                                            '+ applied_btn +'\
                                        </span>\
                                    </div>\
                                  </div></div>';
                }

                $("#recommendjobListing").html(resultDiv);
                $("#recommendjobListing").show();
                $(".recommendjobListing-div").removeClass('hide');

                $('.loginapply').click(function (e) {
                    applyjoblink = $(this).attr("data-link");
                    $(this).addClass("selected");
                    showLoginModal(e);
                });

                $(".fb-share-button").bind("click", function () {
                    var id = $(this).attr('data-id');
                    var url = $(this).attr('data-url');
                    FB.ui({
                        method: 'share',
                        href: url, //'http://54.169.110.239/job/464-social-media-customer-service-specialist',
                    }, function (response) {});

                    sendSCEvent(scEvents.share_job);

                });

                $(".save_job_normal").bind("click", function (event) {

                    if (isLogin=='0') // User not login
                    {
                        showLoginModal(event);
                    }
                    else
                    {
                        var id = $(this).attr('data-id');
                        if ($(".save_job_normal").hasClass("disabled")) {
                            return false;
                        } else
                        {
                            $.post(canvas_url + "api/job/saved", {'job_id': id, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                                if (data.status == 'success')
                                {
                                    $("#savejobn-" + id).removeClass("save_job_normal");
                                    $("#savejobn-" + id).addClass("btn-job-saved");
                                    $("#savejobn-" + id).addClass("disabled");
                                    $("#savejobn-" + id).html('<i class="fa fa-heart"></i> ' + txt_job_saved + '');

                                    swal({
                                        title: 'Well Done',
                                        text: txt_job_saved_list,
                                        type: 'success',
                                        width: 450,
                                        confirmButtonText: 'Ok, Thanks',
                                        confirmButtonClass: 'btn btn-primary',
                                        buttonsStyling: false
                                    });
                                } else {
                                    $(".save_job_normal").addClass("disabled");
                                    notify(data.msg, "warning");
                                }

                            }, "json");
                        }
                    }
                });


            } else
            {
                $("#recommendjobListing").hide();
                $(".recommendjobListing-div").addClass('hide');
            }
        });

        request.fail(function (jqXHR, textStatus) {
            $("#recommendjobListing").hide();
            $(".recommendjobListing-div").addClass('hide');
        });
    };

    $.loadRecommendedJobsList = function () {
        $.getRecommendedJobsList( function (result) {
            if(result && result.data) {
                var jobs = result.data
                var title = result.title
                var count = jobs.length;
                var r_html = '';
                var d_html = '';

                r_html += '<div class="container-job-similar">';
                for (var i = 0; i < count; i++) {
                    var jobtitle = jobs[i].job_title.substring(0, 20);
                    var company = jobs[i].company_name.substring(0, 15);

                    if (jobtitle.length > 20) {
                        jobtitle = jobtitle + "..";
                    }
                    if (company.length > 15) {
                        company = company + "..";
                    }

                    jsonObj = [];
                    item = {}
                    item["job_id"] = jobs[i].job_id;
                    jsonObj.push(item);
                    
                    // mobile view
                    r_html += '<a class="cjs xs-mb-5 xs-mt-5 recommend" id="oldjobdetails-' + jobs[i].job_id + '" href="' + jobs[i].joburl + '" target="_blank" onclick="sendGaEvent(\'' + pagename + '\', \'' + GA_CATEGORY_RECOMMENDATIONS +  '\', \'' + result.type +  '\');sendSCLoginEvent(\''+SIDE_PAGE+ '\',\'' +JOB_MAY_LIKE_CLICK+ '\',\'' +jsonObj+ '\');">\
                                        <div class="input-group-custom slider-mobile group xs-pr-10">\
                                            <div class="cjs__image group xs-pr-10"><div class="img-thumb img-center"><img src="' + jobs[i].logo + '"></div></div>\
                                            <div class="group auto cjs__desc">\
                                                <h5 class="text-capitalize text-dark text-bold xs-mb-0 xs-mt-0 text-truncate">' + jobtitle + '</h5>\
                                                <p class="text-grey text-small xs-mb-0 text-truncate">' + company + '</p>\
                                                <p class="text-primary text-small xs-mb-0 text-ellipsis"><i class="fa fa-usd"></i> ' + jobs[i].salary + '</p>\
                                                <p class="text-grey text-small xs-mb-0 text-ellipsis"><i class="fa fa-map-marker"></i> ' + jobs[i].location + '</p>\
                                            </div>\
                                        </div>\
                                    </a>';


                    // dekstop view
                    d_html += '<a class="recommend" id="oldjobdetails-' + jobs[i].job_id + '" href="' + jobs[i].joburl + '" target="_blank" onclick="sendGaEvent(\'' + pagename + '\', \'' + GA_CATEGORY_RECOMMENDATIONS +  '\', \'' + result.type +  '\');sendSCLoginEvent(\''+SIDE_PAGE+ '\',\'' +JOB_MAY_LIKE_CLICK+ '\',\'' +jsonObj+ '\');">\
                                                        <div class="xs-pt-10 xs-pb-10 b-top">\
                                    <p class="text-bold text-dark xs-mb-0">' + jobs[i].job_title + '</p>\
                                    <p class="text-grey xs-mb-0 text-ellipsis">' + jobs[i].company_name + '</p>\
                                    <p class="xs-mb-0 text-primary"><i class="fa fa-usd"></i> ' + jobs[i].salary + '</p>\
                                    <p class="xs-mb-0 text-grey"><i class="fa fa-map-marker"></i> ' + jobs[i].location + '</p>\
                                </div></a>';
                }

                r_html += '</div>';


                $("#recommendedjobsList").html(r_html);
                $("#recommendjobsList_div .recommendedjobsList_head").html(txt_recommendation_person);
                $("#recommendjobsList_div").show();
                $("#recommendedjobs").html(d_html);
                $("#recommendedjobs_div .recommendedjobs_head").html(txt_recommendation_person);
                $("#recommendedjobs_div").addClass('hidden-sm hidden-xs')
                $("#recommendedjobs_div").show();


                inView('.recommend')
                    .on('enter', function (el) {
                        var id = el.id
                        if(id) {
                            id = id.split("-");
                            $.viewImpressionsTracking('impressions',id[1], id[0] )
                        }
                    });

            }else{
                $("#recommendjobsList_div").removeClass('visible-sm visible-xs');
                $("#recommendjobsList_div").hide();
                $("#recommendedjobs_div").hide();
            }

        });
    }

    function loadRecommendJobFromList(jobList,minimizeClass,ref,page)
    {
        var current = jobList.length;
        var limit = 4;
        var newurl = cUrl.href;
        var resultDiv = '';
        var start = 0;
        var offset = page*5; 
        
        if(page == 0 || page == 1)
        {
            start = 0;
        }
        else
        {
            start = (page-1) * limit;
        }
        var newLimit = start + limit;

        var job = jobList.slice(start, newLimit);

        if(job.length > 0)
        {
            for (var i = 0; i < job.length; i++) {
                if(typeof job[i] === 'undefined' ){
                    continue;
                }

                const jobId = job[i].job_id;

                if (job[i].logo != null && job[i].logo != '') {
                    clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].logo + '" alt="' + job[i].company_name + ' Logo" class="lazy">';
                } else {
                    clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="' + job[i].category_pic + '" alt="' + job[i].category_name + ' Category" class="lazy">';
                }

                if (job[i].company_privacy == 'private') {
                    clogo = '<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="https://files.ajobthing.com/brightan-assets/images/others/v1/private-company.jpg" alt="Private Company" class="lazy">';
                }


                if (job[i].seo_link == null || typeof job[i].seo_link == undefined) {
                    joburl = canvas_url + 'job/' + jobId + '-' + FilterURL(job[i].job_title) + (ref === '' ? '' : '?ref=' + ref);
                } else {
                    joburl = canvas_url + 'job/' + jobId + '-' + job[i].seo_link + (ref === '' ? '' : '?ref=' + ref);
                }

                if (job[i].seo_title != null || typeof job[i].seo_title != undefined) {
                    if (newurl.indexOf("/en") != -1) {
                        joburl = canvas_url + 'en/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (ref === '' ? '' : '?ref=' + ref);
                    } else if (newurl.indexOf("/zh") != -1) {
                        joburl = canvas_url + 'zh/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (ref === '' ? '' : '?ref=' + ref);
                    } else if (newurl.indexOf("/ms") != -1) {
                        joburl = canvas_url + 'ms/job/' + jobId + '-' + FilterURL(job[i].seo_title) + (ref === '' ? '' : '?ref=' + ref);
                    } else
                        joburl = canvas_url + 'job/' + jobId + '-' + FilterURL(job[i].seo_title) + (ref === '' ? '' : '?ref=' + ref);
                }

                if (!job[i].company_slug) {
                    job[i].company_slug = FilterURL(job[i].company_name);
                }

                if (job[i].brand_id) {
                    urlComp = 'brand/' + job[i].brand_id+'-'+job[i].brand_url+ (ref === '' ? '' : '?ref=' + ref);
                } else {
                    urlComp = 'company/' + job[i].company_id+'-'+job[i].company_slug+ (ref === '' ? '' : '?ref=' + ref);
                }

                if (newurl.indexOf("/en") != -1)
                {
                    applylink = canvas_url + 'en/job/apply-job?job_id=' + jobId;
                    companyURL = canvas_url + 'en/' + urlComp;
                    firstLink = canvas_url + 'en/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (ref === '' ? '' : '?ref=' + ref);
                } else if (newurl.indexOf("/zh") != -1)
                {
                    applylink = canvas_url + 'zh/job/apply-job?job_id=' + jobId;
                    companyURL = canvas_url + 'zh/' + urlComp;
                    firstLink = canvas_url + 'zh/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (ref === '' ? '' : '?ref=' + ref);
                } else if (newurl.indexOf("/ms") != -1)
                {
                    applylink = canvas_url + 'ms/job/apply-job?job_id=' + jobId;
                    companyURL = companyURL = canvas_url + 'ms/' + urlComp;
                    firstLink = canvas_url + 'ms/jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (ref === '' ? '' : '?ref=' + ref);
                } else {
                    applylink = canvas_url + 'job/apply-job?job_id=' + jobId;
                    companyURL = companyURL = canvas_url + urlComp;
                    firstLink = canvas_url + 'jobs/' + makeUrlFriendly(job[i].title) + '-jobs-in-' + makeUrlFriendly(job[i].job_location, 'location')+ (ref === '' ? '' : '?ref=' + ref);
                }
                if (job[i].company_privacy == 'private') {
                    companyURL = '#';
                }

                if (job[i].apply_direct != null && job[i].apply_direct == 'yes')
                {
                    applylink = job[i].apply_link + (ref === '' ? '' : '?ref=' + ref);
                }

                if (job[i].job_saved == 'false')
                {
                    save_btn = '<a class="btn btn-default  save_job_normal" data-id="' + jobId + '" id="savejobn-' + jobId + '" onclick="sendGaEvent(\'' + ACTION_SAVE_JOB_FROM_JOB_LIST  + '\', \''+ GA_CATEGORY_CLICK  + '\', \'' + jobId + '\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SAVED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-heart"></i> ' + txt_save + '</a> ';
                } else
                {
                    save_btn = '<a class="btn btn-default  btn-job-saved disabled" data-id="' + jobId + '"><i class="fa fa-heart"></i> ' + txt_job_saved + '</a>';
                }

                if (job[i].job_applied == 'false') {

                    if (isLogin=='0') // User not login
                    {
                        applied_btn = '<a class="btn btn-default pointer loginapply text-primary important"  onclick="sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + job[i].job_id + '\' , \'\job\'\);"  data-id="'+jobId+'" data-link="'+applylink+'"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                    }else{
                        applied_btn
                            = '<a href="' + applylink + '" target="_blank" class="text-primary important btn btn-default " onclick="sendGaEvent(\'' + ACTION_APPLY_JOB_FROM_LIST + '\', \'' + GA_CATEGORY_CLICK  + '\' , \'' + jobId + '\');saveBrowsingHistory(); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_APPLY\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-pencil-square-o"></i> ' + txt_apply_button + '</a>';
                    }

                } else {
                    //applied_btn = '<a target="_blank" class="btn btn-default disabled"><i class="fa fa-pencil-square-o"></i> ' + txt_applied + '</a>';
                    applied_btn = '<a target="_blank" class="btn btn-default" href="' + canvas_url + 'jobseeker/timeline/' + job[i].hashedAppliedId + '" ><i class="fa fa-eye"></i> ' + txt_view_progress + '</a>';
                }

                share_btn = '<a class="btn btn-default pointer fb-share-button" data-id="' + jobId + '" data-url="' + joburl + '" onclick="sendGaEvent( \'' + ACTION_SHARE_FROM_JOB_LIST  + '\', \'' + GA_CATEGORY_CLICK + '\', \'' + jobId +'\'); sendJobIdSocialCredit(\SC_EVENT_HOME_FEED_JOB_SHARED\,\'' + jobId + '\' , \'\job\'\);"><i class="fa fa-share-alt"></i> '+ txt_share_button+'</a>';

                if (job[i].area != '') {
                    job[i].area = job[i].area + ' ,';
                }
                if (job[i].final_salary == 'RM' || job[i].final_salary == 'RM1-RM1' || job[i].final_salary == 'RMRM1' || job[i].final_salary == '')
                    job[i].final_salary = txt_negotiable;
                if (job[i].final_salary == 0)
                    job[i].final_salary = txt_negotiable;

                //featured
                var featured = '';
                if (job[i].is_featured == '1') {
                    featured = '<span class="xs-mt-10 label label-sm label-danger label-circle xs-mr-5" data-toggle="tooltip" title="Urgent Job"><i class="fa fa-star-o"></i></span>';
                }

                //fast response
                var fastresponse = '';
                if (job[i].has_badge == 'yes'){
                    fastresponse = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Fast Response"><i class="mk mk-flash"></i></span>';
                }

                var jobLocation = job[i].job_location;

                // walkin interview
                var walkin_interview = '';
                var walkin_interview_title = (job[i].is_walkin == 1) ? '[Walk In Interview] ' : '';
                if(job[i].walkin_date != '')
                var walkinDate = moment(job[i].walkin_date).format("DD MMMM YYYY");

                var walkin_interview_date = (job[i].is_walkin == 1) ? '<span class="d-inline-block text-nowrap xs-mr-10"><i class="fa fa-calendar-o"></i> ' + walkinDate + '</span>' : '';
                if (job[i].is_walkin == 1) {
                    walkin_interview = '<span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5" data-toggle="tooltip" title="Walk-in Interview"><i class="fa fa-building-o"></i></span>';
                    applied_btn = '<a class="btn btn-info" href="'+joburl+'#jobWalkin" target="_blank"><i class="fa fa-building-o"></i> Walk-in Interview</a>';
                }

                //fresh edu
                var freshjob = '';
                if (job[i].accept_fresh_graduate == 'yes') {
                    freshjob = '<span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5" data-toggle="tooltip" title="Fresh Graduate"><i class="mk mk-graduate"></i></span>';
                }

                //hot job
                var hotjob = '';
                if (job[i].is_hotjob == 'yes') {
                    hotjob = '<span class="xs-mt-10 label label-sm label-warning label-circle xs-mr-5" data-toggle="tooltip" title="Hot Job"><i class="mk mk-hotjobs"></i></span>';
                }

                public_emails = '';
                public_phones = '';
                whatsapp      = '';

                if (job[i].public_emails != '') {
                    public_emails = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'email\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-default label-circle xs-mr-5 pull-right"><i class="fa fa-envelope"></i></span></a>';
                }

                if (job[i].public_phones != '') {
                    public_phones = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'phone\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-info label-circle xs-mr-5 pull-right"><i class="fa fa-phone"></i></span></a>';
                }

                if (job[i].whatsapp != '') {
                    whatsapp = '<a style="cursor:pointer;" onclick="viewJobPublicContact(\'whatsapp\','+job[i].job_id+',event);"><span class="xs-mt-10 label label-sm label-success label-circle xs-mr-5 pull-right"><i class="fa fa-whatsapp"></i></span></a>';
                }


                var cleanJobLocation = '';
                if (job[i].job_location.indexOf('|') === -1) {
                    cleanJobLocation = job[i].job_location;
                } else {
                    cleanJobLocation = job[i].job_location.substring(0,job[i].job_location.indexOf('|')-1);
                }

                var requredLanguages = '';
                if (job[i].speaking_language) {
                    requredLanguages1 = '<span class="d-inline-block text-nowrap xs-mr-10" data-toggle="tooltip" title="' + txt_required_langs + ': ' + job[i].speaking_language + '"><i class="fa fa-language w-15"></i> '
                    requredLanguages1 = requredLanguages1.replace('Chinese', 'Mandarin');
                    requredLanguages2 = job[i].speaking_language + '</span>';
                    requredLanguages2 = requredLanguages2.replace('English', 'EN');
                    requredLanguages2 = requredLanguages2.replace('Bahasa Malaysia', 'BM');
                    requredLanguages2 = requredLanguages2.replace('Chinese', 'ZH');
                    requredLanguages = requredLanguages1 + requredLanguages2;
                    requredLanguages = requredLanguages.replace(/,/g, ', ');
                }

                jobgroupcpa = '';
                var viewcpa = GetUrlParms('viewcpa');
                if (typeof job[i]['group_cpa']!='undefined' && viewcpa!='')
                {
                    jobgroupcpa = '<span class="d-inline-block text-nowrap xs-mr-10" ><i class="fa fa-money w-15"></i> RM '+job[i]['group_cpa']+' CPA </span>';
                }


                var reportBtn = '<li><a href="#" class="btn btn-default report-job-btn" data-type="job" data-id="' + jobId + '" id="report-job-' + jobId +'" >'+ txt_report_job.navigation + '</a></li> ';

                if (job[i].reported == true) {
                    reportBtn = '<li><a  class="btn btn-default reported-job-btn disabled text-grey" data-id="' + jobId + '" >' + txt_report_job.reported + '</a></li>';
                }


                var moreBtn = '<div class="action dropup dropdown-menu-right"> ' +
                    '<a class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-lg fa-ellipsis-h text-grey"></i></a>' +
                    '<ul class="dropdown-menu dropdown-menu-right"> ' +
                    '<li><a class="btn btn-default text-dark" target="_blank" href="' + joburl + '"> '+ txt_view_job + '</a></li> \
                                            '+ reportBtn +
                    '</ul>' +
                    '</div>';

                var aLinkToContact = '';
                if (job[i].public_emails != '' || job[i].public_phones != '' || job[i].whatsapp != '') {
                    var uhref = joburl+'#employerContact';
                    // aLinkToContact = '<a id="reqViewContact" onclick="viewEmployerContact('+job[i].job_id+');sendGaEvent(\'' + ACTION_CLICK + '\', \'' + GA_CATEGORY_SHOW_CONTACT +  '\', \'' + job[i].job_id +  '\');"  href="'+uhref+'" target="_blank" data-url="'+joburl+'"><span class="xs-mr-5">View Employer Contact</span></a>';
                    aLinkToContact = '';
                } else {}

                var language = getCookie('userLanguage');
                var benefitJobLi = [];
                var benefitJob = null;
                var benefit = '';

                if (language === 'en') {
                    benefitJob = job[i].benefit_en;
                } else if (language === 'ms' && job[i].benefit_ms !== '') {
                    benefitJob = job[i].benefit_ms;
                } else if (language === 'zh' && job[i].benefit_zh !== '') {
                    benefitJob = job[i].benefit_zh;
                } else {
                    // default to en
                    benefitJob = job[i].benefit_en;
                }

                try {
                    benefitJobLi = $(benefitJob).find('li');

                    if (benefitJobLi.length > 0) {
                        var index;
                        for (index = 0; index < benefitJobLi.length; ++index) {
                            benefit += benefitJobLi[index].textContent + ' | ';
                        }
                    } else {
                        benefit = benefitJob;
                    }
                }catch (ex){
                    console.log(ex);
                }

                // benefit= benefit.replace(/<(?:.|\n)*?>/gm, '');

                try {
                    benefit = benefit.replace(/<(?:.|\n)*?>/gm, '');
                }catch (ex){
                    console.log(ex);
                }


                resultDiv += '<div class="col-md-12 job-list-wrap '+minimizeClass+'"><div class="job-list b b-1 b-default b-r-4 p-relative bg-white box-shadow xs-mb-20 b-r-0-xs b-left-0-xs b-right-0-xs '+((job[i].highlighted == 'yes') ? 'highlighted' : '')+' '+((job[i].is_featured == '1') ? 'featured' : '')+' ">\
                    <div class="xs-p-20 pointer" >\
                        <div class="xs-pr-15 pull-left" onClick="openURL(\''+joburl+'\', \'_blank\', event)" >\
                            <a target="_blank" class="img-thumb img-center" href="' + joburl + '">' + clogo + '</a>\
                        </div>\
                        <div class="job-list-title">\
                            <h4 class=" xs-mt-0 xs-mb-0 text-capitalize text-bold"><a class="text-dark" target="_blank" href="' + joburl + '?ref=profileRecommendHome" onclick="sendGaEvent(\'' + ACTION_PROFILE + '\', \'' + GA_CATEGORY_RECOMMENDATIONS+  '\', \'' + job[i].job_id +  '\');"> '+walkin_interview_title+''+ job[i].title + '</a></h4>\
                            <p class=" text-capitalize xs-mb-0"><a class="text-dark" target="_blank" href="' + companyURL + '">' +job[i].company_name + '</a></p>\
                            <div class="text-left xs-mt-5 text-ellipsis">\
                                '+aLinkToContact+'\
                            </div>\
                        </div>\
                        <div class="job-list-info xs-mt-10" onClick="openURL(\''+joburl+'\', \'_blank\', event)" >\
                            <p class="xs-mb-0">\
                                <span class="d-inline-block text-nowrap xs-mr-10 pointer text-primary"><i class="fa fa-usd w-15"></i>  ' + job[i].final_salary + '</span>\
                                <span class="d-inline-block text-nowrap xs-mr-10"><i class="fa fa-briefcase w-15"></i>  ' + job[i].job_type + '</span>\
                                '+requredLanguages+'\
                                '+jobgroupcpa+'\
                            </p>\
                            <p class="xs-mb-0">\
                                <span class="d-inline-block xs-mr-10"><i class="fa fa-map-marker w-15"></i> ' + jobLocation + '</span>\
                                '+walkin_interview_date+'</p>\
                        </div>\
                        <div class="job-list-extra xs-mt-10" onClick="openURL(\''+joburl+'\', \'_blank\', event)" >\
                            <p class="xs-mb-0 text-truncate text-grey">' + benefit + '</p>\
                        </div>\
                        <div class="job-tag l-h-0 text-ellipsis">'+walkin_interview+''+freshjob+''+hotjob+''+fastresponse+''+featured+'<span class="xs-mr-5 text-grey pull-right" style="position:relative;top:25px;">'+ job[i].created_at_human +'</span>'+'<span id="showpubliccontact-'+job[i].job_id+'" class="xs-mt-20 xs-mr-20 pull-right"></span></div>\
                    </div>\
                    <div class="job-list-action">\
                        <span>\
                            '+share_btn+'\
                        </span>\
                        <span>\
                            '+save_btn+'\
                        </span>\
                        <span>\
                            '+ applied_btn +'\
                        </span>\
                    </div>\
                </div></div>';

            }
        }
        else
        {
            $(".job-recommend-container").hide();
        }
        return resultDiv;
    }


    function sliderInitBlog(){
        $('.blog-slide').slick({
            centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2,
              variableWidth: true,
              nextArrow: ' <a class="carousel-control right" style="background-image: none;  width: 31px;height: 32px;border-radius: 15.5px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);background-color: #ffffff;top: 75px;" data-slide="next">\
              <i class="glyphicon glyphicon-chevron-right" style="width: 7.4px;height: 12px;font-size: 20px;color:#ed3554;  left: 5px;"></i></a>',
              prevArrow: '<a class="carousel-control left" data-slide="prev">\
              <i class="glyphicon glyphicon-chevron-left" style="width: 7.4px;margin-left: -20px;height: 12px;font-size: 20px;color:#ed3554;background: #ffffff;  width: 31px;height: 32px;border-radius: 15.5px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);top: 85px;"></i></a>',
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                  }
                }
              ]
        });
    }

    function sliderInitVideo(){
        $('.video-slide').slick({
            centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2,
              variableWidth: true,
              nextArrow: ' <a class="carousel-control right" style="background-image: none;  width: 31px;height: 32px;border-radius: 15.5px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);background-color: #ffffff;top: 75px;" data-slide="next">\
              <i class="glyphicon glyphicon-chevron-right" style="width: 7.4px;height: 12px;font-size: 20px;color:#ed3554;  left: 5px;"></i></a>',
              prevArrow: '<a class="carousel-control left" data-slide="prev">\
              <i class="glyphicon glyphicon-chevron-left" style="width: 7.4px;margin-left: -20px;height: 12px;font-size: 20px;color:#ed3554;background: #ffffff;  width: 31px;height: 32px;border-radius: 15.5px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);top: 85px;"></i></a>',
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                  }
                }
              ]
        });
    }


    function loadVideo(current_page)
    {
        var resultDiv = '';
        var v_html = ''; 
        var jsonData = {
          current_page : current_page,
          count : 5,
          type: 'job-listing',
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "GET"
        };

        var request = $.ajax({
          url: canvas_url + "api/get-video-joblisting",
          data: jsonData,
          type: 'GET',
          crossDomain: true,
          dataType: 'json'
        });                       
        request.done(function (data) {
            if(data.status == 'error')
            {
                $(".promo-video-container").css("display","none");
            }
            else
            {
                var videos = data.data;
                var count = videos.length;
           
                for (var i = 0; i < count; i++) {
                    v_html += ' <div class="slick-content">\
                                    <a>\
                                    <div data-toggle="modal" data-target="#promo-video-modal" onClick="\$(\'#promo-video-modal .modal-video\').html(video_stuff[\'video_' + i + '\']); sendGaEvent(\'' + ACTION_CLICK + '\', \'' + GA_CATEGORY_SHOW_VIDEO +  '\', \'' + GA_LABEL_HOME_PAGE +  '\');">\
                                        <img style="width: 277px;height: 105px;border-radius: 5px; background-size: cover; background-repeat: no-repeat;background-position: 50% 50%;background-image: url(' + videos[i].thumbnail.replace('http://', 'https://') + ');"><br />\
                                            <div class="card" style="margin-left : 10px;margin-bottom:16px;margin-right : 12px ">\
                                                <p class="xs-mb-0 text-bold text-dark text-ellipsis text-capitalize">'+videos[i].title+'</p>\
                                                <p class="xs-mb-0 text-truncate text-dark">'+videos[i].description+'</p></td>\
                                            </div>\
                                    </div>\
                                    </a>\
                                </div>';
                    video_stuff['video_' + i] = '<iframe id="video-iframe-' + i + '" class="embed-responsive-item" src="https://www.youtube.com/embed/' + videos[i].y_id + '?showinfo=0&rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>';
                }
                resultDiv = v_html;
                $('#promo-video').append(resultDiv);
                $('[data-toggle="tooltip"]').tooltip();
                sliderInitVideo();
                $('#promo-video-modal').on('hidden.bs.modal', function () {
                    $('#promo-video-modal .modal-video').html('');
                });
            }
            
        });
        request.fail(function (xhr) {
            $(".sidebar-video").hide();
        });
    }



    function loadBlog(current_page)
    {
        var resultDiv = '';
        var v_html = '';                        
        var jsonData = {
          current_page : current_page,
          '_token': $('meta[name="csrf-token"]').attr('content'),
          '_method': "GET"
        };

        var request = $.ajax({
          url: canvas_url + "api/get-blog",
          data: jsonData,
          type: 'GET',
          crossDomain: true,
          dataType: 'json'
        });
        var platform_id = $("#imp_platform_id").val();
        var url = 'https://blog.ricebowl.my';
        if (platform_id == 2)
            url = 'https://blog.maukerja.my';
        if (platform_id == 6)
            url = 'https://blog.maukerja.id';
        if (platform_id == 7)
            url = 'https://blog.pocketmoneys.com';


        request.done(function (data) {
         var blogs = data.data;

         var count = blogs.length;
           
            for (var i = 0; i < count; i++) {
                var image = new Image();
                var blog_image = url+'/data/blog/images/'+ blogs[i]['cover_photo'].replace('-big', '-medium');
                image.src = blog_image;
                
                v_html += '<div class="slick-content">\
                                <a href="'+url+blogs[i]['seo_link']+'?ref=profileRecommendHome" onclick="sendGaEvent( \'' + ACTION_CLICK  + '\', \'' + GA_CATEGORY_SHOW_BLOG + '\', \'' + GA_LABEL_HOME_PAGE +'\');" target="_blank">\
                                    <img style="width: 277px;height: 105px;border-radius: 5px; background-size: cover; background-repeat: no-repeat;background-position: 50% 50%;background-image: url(' + blog_image + ');"><br />\
                                    <div class="card" style="margin-left : 10px;margin-right : 12px ">\
                                        <p class="xs-mb-0 text-bold text-dark text-ellipsis text-capitalize">'+blogs[i].title+'</p>\
                                        <p class="xs-mb-0 text-truncate text-dark">'+blogs[i].summary+'</p></td>\
                                    </div>\
                                </a>\
                            </div>';

                  
            }
            resultDiv = v_html;
            $('#promo-blog').append(resultDiv);
            $('[data-toggle="tooltip"]').tooltip();
            sliderInitBlog();
        });
    }

    $(".save_job_detail").bind("click", function () {
        if (isLogin=='0') // User not login
        {
            showLoginModal(event);
        }
        else
        {
            var jid = $(this).attr("data-id");
            if ($(".save_job").hasClass("disabled")) {
                return false;
            } else {

                $.post(canvas_url + "api/job/saved", {'job_id': jid, '_token': $('meta[name="csrf-token"]').attr('content'), '_method': "POST"}, function (data) {
                    if (data.status == 'success') {
                        $(".save_job_detail").addClass("disabled");
                        $(".save_job_detail").addClass("savedjob");
                        $(".save_job_detail").html('<i class="fa fa-heart"></i> ' + txt_apply_saved + '');


                        swal({
                            title: txt_done_label,
                            text: txt_job_saved_success,
                            type: 'success',
                            width: 450,
                            confirmButtonText: 'Ok, Thanks',
                            confirmButtonClass: 'btn btn-primary',
                            buttonsStyling: false
                        });

                        $.netcoreTracking(NC_ACTIVITY_SAVE_JOB, nc_payload)
                    } else {
                        $(".save_job").addClass("disabled");
                        notify(data.msg, "warning");
                    }
                }, "json");
            }
            //notify('Job Saved Successfully', 'warning');
        }

    });

    var pagename = $("#pagename").val();
    if (pagename=='joblisting')
    {
        setCookie("ApplyJob",GA_LABEL_HOME_PAGE,1);
        $.getFeaturedEmployers();
        var searchquery = GetUrlParms('q');
        if (typeof searchquery!='undefined')
        {
            $.setSearchFilters();
        }
    }

    if (pagename == 'applyjob')
    {
        //setCookie("ApplyJob",GA_CATEGORY_APPLY_JOB,1);
    }

    if(pagename == 'jobseekerdashboard')
    {
        setCookie("ApplyJob",GA_LABEL_MY_PAGE,1);
    }

    if (pagename=='jobdetail')
    {
        setCookie("ApplyJob",GA_LABEL_JOB_DETAIL,1);
        $.setSearchFiltersJobDetails();
    }

    if (pagename=='companypage')
    {
        setCookie("ApplyJob",GA_LABEL_COMPANY_PAGE,1);
        $.getSimilarEmployers();
        $.getRecommendedJobs();
    }

    if (pagename=='featuredjobs')
    {
        setCookie("ApplyJob",GA_LABEL_FEATURED_JOBS,1);
        $.getFeaturedJobsListing(0);
    }
});
