NC_ACTIVITY_REGISTER = 101;
NC_ACTIVITY_UPDATE_PROFILE_INTRO = 102;
NC_ACTIVITY_UPDATE_BASIC_INFO = 103;
NC_ACTIVITY_UPDATE_EXPERIENCE = 104;
NC_ACTIVITY_UPDATE_EDUCATION = 105;
NC_ACTIVITY_UPDATE_LANGUAGE = 106;
NC_ACTIVITY_APPLY_JOB = 107;
NC_ACTIVITY_APPLY_JOB_SUCCESS = "Maukerja_Job_Application";
NC_ACTIVITY_SEARCH_JOB = 109;
NC_ACTIVITY_MK_ADD_CONTACT = 108;
NC_ACTIVITY_RB_ADD_CONTACT = 110;
NC_ACTIVITY_SAVE_JOB = "Maukerja_Save_Job";

NC_ACTIVITY_ID = "17";

NC_SS_KEY = "sessionUID";
