//navigation
function mobile_menu(action){
    var _btn = $('#main-menu-btn');
    if(action == 'toggle'){
        $('#main-menu').collapse('toggle');
        _btn.toggleClass('active');   
    }else if( action == 'close'){
        $('#main-menu').collapse('hide');
        _btn.removeClass('active');
    }else if( action == 'show'){
        $('#main-menu').collapse('show');
        _btn.addClass('active');
    }    
}

function mobile_search(action){
    var _btn = $('#search-menu-btn');
    if(action == 'toggle'){
        if($('#header').length < 1){
            window.location = canvas_url + "/";
        }else{
            $('#search-menu').collapse('toggle');
            _btn.toggleClass('active');
        }
    }else if( action == 'close'){
        $('#search-menu').collapse('hide');
        _btn.removeClass('active');
    }else if( action == 'show'){
        if($('#header').length < 1){
            window.location = canvas_url + "/";
        }else{
            $('#search-menu').collapse('show');
            _btn.addClass('active');
        }
    }
}

function getAllNotification(){
    //combination notification for mobile
    var totalM = $('#unread-messages').html();
    var totalN = $('#unread-notifications').html();

    if (totalM == ''){totalM = 0;}
    if (totalN == ''){totalN = 0;}

    var totalA = (parseInt(totalN) + parseInt(totalM));
    if(totalA > 0){
       $('#unread-all').html(totalA); 
    }
}

function navigation(){

    //job all page
    if ($('.job-listing-page').length > 0) {
        $('#nav_job_all').addClass('active');
        $('#nav_job_all_mobile').addClass('active');
    }

    //dashboard page
    if ($('.job-dashboard-page').length > 0){
        $('#nav_mypage').addClass('active');
        $('#nav_mypage_mobile').addClass('active');
        var query = location.href.split('#');
        if (typeof query[1] != 'undefined') // Hashes defined
        {   
            $('#tab-dashboard a[href="#'+query[1]+'"]').tab('show');
        }
    }

    //profile page
    if ($(".jobseeker-profile-page").length>0){
        $('#nav_profile').addClass('active');
        $('#nav_user').addClass('active');

        var query = location.href.split('#');
        if (typeof query[1] != 'undefined') // Hashes defined
        {   

            $('.modal').modal('hide');
            $('#'+query[1]).modal('show');
            
        }else{
            //showPromotion() //check promotion if profile complete more than 90
        }
    }

    //resume page
    if ($(".jobseeker-resumes-page").length>0){
        $('#nav_resume').addClass('active');
        $('#nav_user').addClass('active');
    }

    //setting page
    if ($(".jobseeker-setting-page").length>0){
        $('#nav_setting').addClass('active');
        $('#nav_user').addClass('active');
    }

    //Notifications Page
    if ($(".job-notification-page").length>0){
        $('#nav_notifications').addClass('active');
    }

    //Messages Page
    if ($(".job-chat-page").length>0){
        $('#nav_messages').addClass('active');
    }

    var s = $("#navigation");
    var pos = s.position();                    
    $(window).scroll(function() {
        var windowpos = $(window).scrollTop();
        if (windowpos > pos.top) {
            s.addClass("fixed");
        } else {
            s.removeClass("fixed"); 
        }
    });

}

$(document).ready(function () {
    navigation();

    //mobile menu & search hack
    $('#main-menu, #search-menu').on('show.bs.collapse', function(){
        $(this).css({'top': $('#navigation > .navigation-group').height()});
        $(this).find('> div:first-child').css({
            'height':$(window).height() - $('#navigation > .navigation-group').height(),
            'overflow-y':'scroll'
        });
        document.body.style.overflow = 'hidden';
    });
    $('#main-menu, #search-menu').on('hidden.bs.collapse', function(){
        $(this).find('> div:first-child').css({
            'height': 'auto',
            'overflow-y':''
        });
        
        if($('#header').length < 1){
            document.body.style.overflow = '';
        }else{
            if($('#main-menu').attr('aria-expanded') == 'false' && $('#search-menu').attr('aria-expanded') == 'false'){
                document.body.style.overflow = '';
            }
        }           
    });

    //dropdown chat and notification
    if ($(window).width() < 992) {
        $('.dropdown-notification , .dropdown-chat').removeAttr('data-toggle');
    }else{
        $('.dropdown-notification , .dropdown-chat').attr('data-toggle','dropdown');
    }

    
});

$(window).resize(function () {
    //dropdown chat and notification
    if ($(window).width() < 992) {
        $('.dropdown-notification , .dropdown-chat').removeAttr('data-toggle');
    }else{
        $('.dropdown-notification , .dropdown-chat').attr('data-toggle','dropdown');
    }
});



