/**
 * Created by Ali on 30/06/2016.
 */

// assign animation to notifications and messages icons on hover and remove it after animation finished
// $("#notification-bell").hover(function () {
//     $(this).addClass('animated swing');
// });
// $("#notification-bell").bind("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function () {
//     $(this).removeClass('animated swing');
// });
// $("#message-envelope").hover(function () {
//     $(this).addClass('animated tada');
// });
// $("#message-envelope").bind("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function () {
//     $(this).removeClass('animated tada');
// });

//Define global variables

var thename = $('.user-name').text();	
var fbUnlockedConversations = [];
var notificationsHtml = [];
var fbUPListeners = [];
var fbNMListener = false;
var fbMCListener = false;
var fbSMListener = false;
var fbSMCListener = false;
var fbNNListener = false;
var fbNCListener = false;
var fbCCListener = false;
var fbUTListeners = [];
var fbLoginAttempts = 0;
var fbCurrentUserAvatar = '';
var serverCurrentTime = 0;
var fbUserIsTypingLock = false;
var bsNotifyObject = null;
var fbIsConversationsLoaded = false;
var connectionRef = null;

var timeTemplate = '<div class="text-center"><div class="xs-p-10 xs-pl-15 xs-pr-15 alert alert-warning text-center d-inline-block">%date%</div></div>';
var leftMessage = '<div class="lv-item media" style="clear: both;">' +
        '<div class="lv-avatar pull-left"><img src="%logo%" alt=""></div>' +
        '<div class="media-body">' +
        '<div class="ms-item text-break">%message%</div>' +
        '<small class="ms-date">%time%</small>' +
        '</div></div>';
var rightMessage = '<div class="lv-item media right" style="clear: both;">' +
        '<div class="lv-avatar pull-right"><img src="%logo%" alt=""></div>' +
        '<div class="media-body">' +
        '<div class="ms-item text-break">%message%</div>' +
        '<small class="ms-date">%time% %status%</small>' +
        '</div></div>';
var botLeftMessage = '<div class="message-bot lv-item media" style="clear: both;">' +
        '<div class="lv-avatar pull-left"><img src="%logo%" alt=""></div>' +
        '<div class="media-body">' +
        '<div class="ms-item text-break">%message%</div>' +
        '<small class="ms-date">%time%</small>' +
        '</div></div>';
//initiate Firebase and call other related functions
function fbInitiate(token, key, domain, url, bucket, guestId, currentTime, site, username, avatar, userId) {
    //assign value to global variables
    fbCurrentUserAvatar = decodeURIComponent(fbCheckAvatarFile(encodeURIComponent(avatar)));
    serverCurrentTime = currentTime * 1000;
    //variables
    var config = {
        apiKey: key,
        authDomain: domain,
        databaseURL: url,
        storageBucket: bucket
    };
    //initialize Firebase
    firebase.initializeApp(config);
    //check actual user id with the current firebase user id, if not same we signout the current firebase user
    if (firebase.auth().currentUser && !firebase.auth().currentUser.isAnonymous && userId !== 0 && firebase.auth().currentUser.uid !== userId) {
        firebase.auth().signOut().then(function () {
        }, function (error) {
            console.log(error.message);
        });
    }
    //Check to see if user is signed in or not in Firebase
    firebase.auth().onAuthStateChanged(function (currentUser) {
        if (currentUser) {
            //User Signed In Firebase, Get User Id from Firebase
            var fbUserId = currentUser.uid;
            //check to see if user is a guest
            var isAnonymous = currentUser.isAnonymous;
            if (isAnonymous && token !== '' && guestId !== '') {
                //convert guest user to system logged in user
                firebase.database().ref('notifications/' + fbUserId).once('value').then(function (anonymousNotificationsSnap) {
                    if (anonymousNotificationsSnap.val() !== null) {
                        //If there are any notification, save all in array then send back to server to save for logged in user
                        var arrAnonymousNotifications = [];
                        anonymousNotificationsSnap.forEach(function (anonymousNotificationSnap) {
                            var anonymousNotification = anonymousNotificationSnap.val();
                            var arrAnonymousNotification = {
                                read: (!anonymousNotification.read ? false : anonymousNotification.read),
                                url: (!anonymousNotification.url ? '' : anonymousNotification.url),
                                icon: (!anonymousNotification.icon ? '' : anonymousNotification.icon),
                                titleEn: (!anonymousNotification.titleEn ? '' : anonymousNotification.titleEn),
                                messageEn: (!anonymousNotification.messageEn ? '' : anonymousNotification.messageEn),
                                titleOther: (!anonymousNotification.titleOther ? '' : anonymousNotification.titleOther),
                                messageOther: (!anonymousNotification.messageOther ? '' : anonymousNotification.messageOther),
                                htmlEn: (!anonymousNotification.htmlEn ? '' : anonymousNotification.htmlEn),
                                htmlOther: (!anonymousNotification.htmlOther ? '' : anonymousNotification.htmlOther),
                                timestamp: (!anonymousNotification.timestamp ? 0 : anonymousNotification.timestamp),
                                key: (!anonymousNotification.key ? '' : anonymousNotification.key)
                            };
                            arrAnonymousNotifications.push(arrAnonymousNotification);
                        });
                        //delete all notifications of guest user
                        firebase.database().ref('notifications/' + fbUserId).remove();
                        //send notifications to server
                        var jsonData = {
                            'notifications': arrAnonymousNotifications,
                            '_token': $('meta[name="csrf-token"]').attr('content'),
                            '_method': "GET"
                        };
                        var request = $.ajax({
                            url: canvas_url + "api/firebase/save-notifications",
                            data: jsonData,
                            type: 'GET',
                            crossDomain: true,
                            dataType: 'json'
                        });
                        request.fail(function (jqXHR, textStatus) {
                            console.log(jqXHR);
                            console.log(textStatus);
                        });
                    }
                    //make guestid empty in session to prevent asking for new token again
                    guestId = '';
                    $.cookie('_fbGuestId', guestId, {expires: 30, path: '/'});
                    //remove anonymous user
                    currentUser.delete().then(function () {
                    }, function (error) {
                        console.log(error.message);
                    });
                }).catch(function (error) {
                    console.log(error.message);
                });
            } else if (isAnonymous && token === '' && guestId === '') {
                //Save guestId in cookie
                guestId = fbUserId;
                $.cookie('_fbGuestId', guestId, {expires: 30, path: '/'});
            } else if (!isAnonymous && token === '') {
                var presenceRef = firebase.database().ref('usersPresence/' + currentUser.uid);
                presenceRef.update({'timeStamp': firebase.database.ServerValue.TIMESTAMP}).catch(function (error) {
                    console.log(error.message);
                });
                firebase.auth().signOut().then(function () {
                }, function (error) {
                    console.log(error.message);
                });
            }
            if (!isAnonymous) {
                //Delete old presence data: connections and status
                firebase.database().ref('usersPresence/' + currentUser.uid + '/connections').remove();
                firebase.database().ref('usersPresence/' + currentUser.uid + '/status').remove();
                //Update user presence status
                connectionRef = firebase.database().ref('userConnections/' + currentUser.uid + '/' + fbGetUUID(currentUser.uid));
                var userPresenceRef = firebase.database().ref('usersPresence/' + currentUser.uid);
                firebase.database().ref('.info/connected').on('value', function (presenceSnap) {
                    if (presenceSnap.val() === true) {
                        //hide the connection lost if it is visible
                        if (!$("#connection-lost").hasClass('hide')) {
                            $("#connection-lost").addClass('hide');
                        }
                        userPresenceRef.onDisconnect().update({'timeStamp': firebase.database.ServerValue.TIMESTAMP}).catch(function (error) {
                            console.log(error.message);
                        });
                        connectionRef.onDisconnect().remove();
                        //update user data 
                        if (!site) {
                            var site = window.location.hostname;
                            site = site.split('.').join('_');
                        }
                        userPresenceRef.update({'platform': site, 'name': username, 'avatar': fbCurrentUserAvatar}).catch(function (error) {
                            console.log(error.message);
                        });
                        connectionRef.update({'clientType': 'web'}).catch(function (error) {
                            console.log(error.message);
                        });
                        firebase.database().ref('userConnections/' + currentUser.uid).update({'updatedAt': firebase.database.ServerValue.TIMESTAMP}).catch(function (error) {
                            console.log(error.message);
                        });
                    } else {
                        //show the connection lost if internet connection lost
                        if ($("#connection-lost").hasClass('hide')) {
                            $("#connection-lost").removeClass('hide');
                        }
                    }
                }, function (error) {
                    console.log(error.message);
                });
            }

            // ***** Call NOTIFICATIONS Functions

            //get number of unread notifications
            totalUnreadNotifications(fbUserId);
            //get latest 5 notifications
            latestFiveNotifications(fbUserId);
            //get page notifications
            getPageNotifications(fbUserId, isAnonymous);
            //starts listening to new notifications
            listenForNewNotifications(fbUserId);
            //starts listening to any changes to notifications, here i use only check notification read status
            listenForNotificationChange(fbUserId);
            //If user is in his/her notifications, this will get all notifications
            if ($('#alljobseekernotifications').length) {
                getAllNotifications(fbUserId);
            }

            //get notifications for mypage
            if ($('.job-dashboard-page').length) {
                getMyPageNotifications(fbUserId);
            }

        } else {
            if (fbLoginAttempts < 2) {
                if (token === '') {
                    //login As anonymous, user is NOT logged in
                    firebase.auth().signInAnonymously().catch(function (error) {
                        console.log(error.message);
                    });
                } else {
                    //login With token, user is logged in
                    firebase.auth().signInWithCustomToken(token).catch(function (error) {
                        console.log(error.message);
                    });
                }
                fbLoginAttempts++;
            } else {
                console.log('maximum login attempt.');
            }
        }
    }, function (error) {
        console.log(error.message);
    });
}

/*Define Notifications Functions*/

function totalUnreadNotifications(fbUserId) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    firebase.database().ref('notifications/' + fbUserId).orderByChild('read').equalTo(false).once('value').then(function (unreadSnap) {
        if (unreadSnap.val() !== null) {
            //update unread counter
            var unreads = unreadSnap.numChildren();
            if (unreads !== 0) {
                $('#unread-notifications').html(unreads);
                $('#unread-notifications').removeClass('hide');
                getAllNotification() //update notification in menu
                //animate the bill
                //$('#notification-bell').addClass('animated swing');
            }
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function latestFiveNotifications(fbUserId) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    firebase.database().ref('notifications/' + fbUserId).orderByChild('timestamp').limitToLast(5).once('value').then(function (latestNotificationsSnap) {
        if (latestNotificationsSnap.val() !== null) {
            var menuResult = '';
            latestNotificationsSnap.forEach(function (latestNotificationSnap) {
                //get data based on user selected language and compose result
                var latestNotificationKey = latestNotificationSnap.key.trim();
                var latestNotification = latestNotificationSnap.val();
                var myTime = fbConvertTimeStamp(latestNotification.timestamp, serverCurrentTime);
                var myUrl = latestNotification.url;
                var myIcon = latestNotification.icon;
                var myKey = (!latestNotification.key ? '' : latestNotification.key);
                if (selectedLanguage === 'en') {
                    var myTitle = latestNotification.titleEn;
                    var myMessage = latestNotification.messageEn;
                    if (latestNotification.htmlEn === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (latestNotification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    } else {
                        var htmlContent = {key: latestNotificationKey, content: latestNotification.htmlEn};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (latestNotification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    }
                } else {
                    var myTitle = latestNotification.titleOther;
                    var myMessage = latestNotification.messageOther;
                    if (latestNotification.htmlOther === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (latestNotification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    } else {
                        var htmlContent = {key: latestNotificationKey, content: latestNotification.htmlOther};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (latestNotification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    }
                }

                menuResult = '\
                <a class="dropdown-item b-bottom d-block link link-reset ' + myRead + ' " id="' + latestNotificationKey + '" ' + myHref + ' ' + myOnClick + '>\
                    <div class="xs-p-15 xs-pt-10 xs-pb-10">\
                        <div class="img-thumb img-thumb-sm img-center pull-left xs-mr-10">\
                            <img src="' + myIcon + '" class="img-responsive center-block">\
                        </div>\
                        <div>\
                            <span class="pull-right text-grey">' + myTime + '</span>\
                            <p class="text-bold xs-mb-0 text-ellipsis text-dark xs-pr-30">' + myTitle + '</p>\
                            <p class="text-ellipsis xs-mb-0 text-dark" >' + myMessage + '</p>\
                        </div>\
                    </div>\
                </a>' + menuResult;
                //update timestamp for old bad data
                if (typeof latestNotification.timestamp !== 'number') {
                    var postData = {
                        'timestamp': Number(latestNotification.timestamp)
                    };
                    firebase.database().ref('notifications/' + fbUserId + '/' + latestNotificationKey).update(postData).catch(function (error) {
                        console.log(error.message);
                    });
                }
            });
            //show notifications in menu
            $('#fb-notifications').html(menuResult);
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function getMyPageNotifications(fbUserId) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    firebase.database().ref('notifications/' + fbUserId).orderByChild('timestamp').limitToLast(5).once('value').then(function (latestNotificationsSnap) {
        if (latestNotificationsSnap.val() !== null) {
            var menuResult = '';
            latestNotificationsSnap.forEach(function (latestNotificationSnap) {
                //get data based on user selected language and compose result
                var latestNotificationKey = latestNotificationSnap.key.trim();
                var latestNotification = latestNotificationSnap.val();
                var myTime = fbConvertTimeStamp(latestNotification.timestamp, serverCurrentTime);
                var myUrl = latestNotification.url;
                var myIcon = latestNotification.icon;
                var myKey = (!latestNotification.key ? '' : latestNotification.key);
                if (selectedLanguage === 'en') {
                    var myTitle = latestNotification.titleEn;
                    var myMessage = latestNotification.messageEn;
                    if (latestNotification.htmlEn === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (latestNotification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    } else {
                        var htmlContent = {key: latestNotificationKey, content: latestNotification.htmlEn};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (latestNotification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    }
                } else {
                    var myTitle = latestNotification.titleOther;
                    var myMessage = latestNotification.messageOther;
                    if (latestNotification.htmlOther === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (latestNotification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    } else {
                        var htmlContent = {key: latestNotificationKey, content: latestNotification.htmlOther};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (latestNotification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + latestNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = ' not-read';
                        }
                    }
                }

                menuResult = '\
                                <li class="xs-pb-10">\
                                    <p class="text-grey xs-mb-0 text-small">' + myTime + '</p>\
                                    <p class="xs-mb-0">\
                                        <a class=" ' + myRead + ' " ' + myHref + ' ' + myOnClick + '>\
                                        ' + myMessage + '\
                                        </a>\
                                    </p>\
                                </li>' + menuResult;
                //update timestamp for old bad data
                if (typeof latestNotification.timestamp !== 'number') {
                    var postData = {
                        'timestamp': Number(latestNotification.timestamp)
                    };
                    firebase.database().ref('notifications/' + fbUserId + '/' + latestNotificationKey).update(postData).catch(function (error) {
                        console.log(error.message);
                    });
                }
            });
            //show notifications in menu
            $('.timeline-list').html(menuResult);
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function getPageNotifications(fbUserId, isAnonymous) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    var pageNotificationURL = window.location.hostname + window.location.pathname;
    var site = window.location.hostname;
    site = site.split('.').join('_');
    if (isAnonymous) {
        var refPageNotifications = 'pageNotifications/guest/';
    } else {
        var refPageNotifications = 'pageNotifications/user/';
    }
    firebase.database().ref(refPageNotifications).orderByChild('page').equalTo(pageNotificationURL).once('value').then(function (pageNotificationsSnap) {
        if (pageNotificationsSnap.val() !== null) {
            pageNotificationsSnap.forEach(function (pageNotificationSnap) {
                var pnKey = pageNotificationSnap.key.trim();
                var pnData = pageNotificationSnap.val();
                firebase.database().ref('pageNotificationStatus/' + fbUserId).orderByKey().equalTo(pnKey).once('value').then(function (pnsSnap) {
                    if (pnsSnap.val() === null) {
                        //save page notification to the user notifications
                        if (pnData.htmlEn === undefined || pnData.htmlOther === undefined) {
                            var postData = {
                                'titleEn': pnData.titleEn,
                                'messageEn': pnData.messageEn,
                                'titleOther': pnData.titleOther,
                                'messageOther': pnData.messageOther,
                                'icon': pnData.icon,
                                'url': pnData.url,
                                'read': false,
                                'timestamp': firebase.database.ServerValue.TIMESTAMP,
                                'key': 'page-notification'
                            };
                        } else {
                            var postData = {
                                'titleEn': pnData.titleEn,
                                'messageEn': pnData.messageEn,
                                'titleOther': pnData.titleOther,
                                'messageOther': pnData.messageOther,
                                'icon': pnData.icon,
                                'url': pnData.url,
                                'read': false,
                                'timestamp': firebase.database.ServerValue.TIMESTAMP,
                                'htmlEn': pnData.htmlEn,
                                'htmlOther': pnData.htmlOther,
                                'key': 'page-notification'
                            };
                        }
                        var newMessageRef = firebase.database().ref('notifications/' + fbUserId).push();
                        var key = newMessageRef.key;
                        newMessageRef.set(postData);
                        //save notification status
                        firebase.database().ref('notificationsStatus/' + site + '/' + key).set(false).catch(function (error) {
                            console.log(error.message);
                        });
                        //save the page notification key for next time check this user got this notification before or not
                        firebase.database().ref('pageNotificationStatus/' + fbUserId + '/' + pnKey).set(true).catch(function (error) {
                            console.log(error.message);
                        });
                    }
                }).catch(function (error) {
                    console.log(error.message);
                });
            });
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function listenForNewNotifications(fbUserId) {
    if (!fbNNListener) {
        fbNNListener = true;
        if (/^\d+$/.test(fbUserId)) {
            fbUserId = parseInt(fbUserId);
        }
        firebase.database().ref('notifications/' + fbUserId).orderByChild('timestamp').startAt(serverCurrentTime).on('child_added', function (newNotificationSnap) {
            //update number of unread notifications
            var notifications = parseInt($('#unread-notifications').html());
            notifications++;
            $('#unread-notifications').html(notifications);
            if ($('#unread-notifications').hasClass('hide')) {
                $('#unread-notifications').removeClass('hide');
            }
            //animate the bell
            //$('#notification-bell').addClass('animated swing');
            //get data based on user selected language and compose result
            var newNotificationKey = newNotificationSnap.key.trim();
            var newNotification = newNotificationSnap.val();
            var myTime = fbConvertTimeStamp(newNotification.timestamp, serverCurrentTime, 'normal');
            var myUrl = newNotification.url;
            var myIcon = newNotification.icon;
            var myKey = (!newNotification.key ? '' : newNotification.key);
            if (selectedLanguage === 'en') {
                var myTitle = newNotification.titleEn;
                var myMessage = newNotification.messageEn;
                if (newNotification.htmlEn === undefined) {
                    var myHref = 'href="' + myUrl + '"';
                    var myOnClick = 'onclick="fbReadNotification(\'' + newNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                } else {
                    var htmlContent = {key: newNotificationKey, content: newNotification.htmlEn};
                    notificationsHtml.push(htmlContent);
                    var myHref = '';
                    var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + newNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                }
            } else {
                var myTitle = newNotification.titleOther;
                var myMessage = newNotification.messageOther;
                if (newNotification.htmlOther === undefined) {
                    var myHref = 'href="' + myUrl + '"';
                    var myOnClick = 'onclick="fbReadNotification(\'' + newNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                } else {
                    var htmlContent = {key: newNotificationKey, content: newNotification.htmlOther};
                    notificationsHtml.push(htmlContent);
                    var myHref = '';
                    var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + newNotificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                }
            }
            var menuResult = '\
                <a class="dropdown-item b-bottom d-block link link-reset not-read" id="' + newNotificationKey + '" ' + myHref + ' ' + myOnClick + '>\
                    <div class="xs-p-15 xs-pt-10 xs-pb-10">\
                        <div class="img-thumb img-thumb-sm img-center pull-left xs-mr-10">\
                            <img src="' + myIcon + '" class="img-responsive center-block">\
                        </div>\
                        <div>\
                            <span class="pull-right text-grey">' + myTime + '</span>\
                            <p class="text-bold xs-mb-0 text-ellipsis text-dark xs-pr-30">' + myTitle + '</p>\
                            <p class="text-ellipsis xs-mb-0 text-dark" >' + myMessage + '</p>\
                        </div>\
                    </div>\
                </a>';
            var notifyMessage = '<a target="_blank" ' + myHref + ' ' + myOnClick + ' ><div><div style="float:left;margin-right: 10px;"><img style="width: 40px;height: 40px;border-radius: 50%;" src="' + myIcon + '" alt=""></div><div style="float: left;max-width: 350px;"><h4>' + myTitle + '</h4><p>' + myMessage + '</p></div></div></a>';
            //show new notification in menu
            var currentContent = $('#fb-notifications').html();
            if (typeof currentContent !== 'undefined' && currentContent.search('<a') === -1) {
                $('#fb-notifications').html(menuResult);
            } else {
                $('#fb-notifications').prepend(menuResult);
            }
            //If user is in the View All notifications page, add in the page list also
            if ($('#alljobseekernotifications').length) {
                var pageResult = '\
                    <a class="dropdown-item b-bottom d-block link link-reset not-read" id="vap-' + newNotificationKey + '" ' + myHref + ' ' + myOnClick + '>\
                        <div class="xs-p-20 xs-pt-10 xs-pb-10">\
                            <div class="img-thumb img-thumb-sm img-center pull-left xs-mr-10">\
                                <img src="' + myIcon + '" class="img-responsive center-block">\
                            </div>\
                            <div>\
                                <span class="pull-right text-grey">' + myTime + '</span>\
                                <p class="text-bold xs-mb-0 text-ellipsis text-dark xs-pr-30">' + myTitle + '</p>\
                                <p class="text-ellipsis xs-mb-0 text-dark" >' + myMessage + '</p>\
                            </div>\
                        </div>\
                    </a>';
                var currentContent = $('#alljobseekernotifications').html();
                if (typeof currentContent !== 'undefined' && currentContent.search('<a') === -1) {
                    $('#alljobseekernotifications').html(pageResult);
                } else {
                    $('#alljobseekernotifications').prepend(pageResult);
                }
            }
            //Show notification in message box
            bsNotifyObject = $.notify({
                message: notifyMessage
            }, {
                type: 'info',
                mouse_over: 'pause',
                delay: 10000,
                placement: {
                    from: 'bottom',
                    align: 'right'
                },
                animate: {
                    enter: 'animated slideInRight',
                    exit: 'animated fadeOutDown'
                }
            });
            //Play notification sound
            document.getElementById("notificationAudio").play();
        }, function (error) {
            console.log(error.message);
        });
    }
}

function listenForNotificationChange(fbUserId) {
    if (!fbNCListener) {
        fbNCListener = true;
        if (/^\d+$/.test(fbUserId)) {
            fbUserId = parseInt(fbUserId);
        }
        firebase.database().ref('notifications/' + fbUserId).on('child_changed', function (readNotificationSnap) {
            var readNotificationKey = readNotificationSnap.key.trim();
            var readNotification = readNotificationSnap.val();
            if (readNotification.read === true) {
                //update number of unread notifications
                var notifications = parseInt($('#unread-notifications').html());
                notifications--;
                if (notifications <= 0) {
                    $('#unread-notifications').html(0);
                    $('#unread-notifications').addClass('hide');
                } else {
                    $('#unread-notifications').html(notifications);
                }
                //animate the bell
                //$('#notification-bell').addClass('animated swing');
                //update selected notification class
                $('#' + readNotificationKey).removeClass('not-read');
                //If user is in the View All notifications page, change the status in the page list also
                if ($('#alljobseekernotifications').length) {
                    $('#vap-' + readNotificationKey).children('.not-read').removeClass('not-read');
                }
            }
        }, function (error) {
            console.log(error.message);
        });
    }
}

function getAllNotifications(fbUserId) {
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
    firebase.database().ref('notifications/' + fbUserId).orderByChild('timestamp').once('value').then(function (notificationsSnap) {
        if (notificationsSnap.val() !== null) {
            var pageResult = '';
            notificationsSnap.forEach(function (notificationSnap) {
                //get data based on user selected language and compose result
                var notificationKey = notificationSnap.key.trim();
                var notification = notificationSnap.val();
                var myTime = fbConvertTimeStamp(notification.timestamp, serverCurrentTime, 'normal');
                var myUrl = notification.url;
                var myIcon = notification.icon;
                var myKey = notification.key;
                if (selectedLanguage === 'en') {
                    var myTitle = notification.titleEn;
                    var myMessage = notification.messageEn;
                    if (notification.htmlEn === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (notification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + notificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = 'not-read';
                        }
                    } else {
                        var htmlContent = {key: notificationKey, content: notification.htmlEn};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (notification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + notificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + notificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = 'not-read';
                        }
                    }
                } else {
                    var myTitle = notification.titleOther;
                    var myMessage = notification.messageOther;
                    if (notification.htmlOther === undefined) {
                        var myHref = 'href="' + myUrl + '"';
                        if (notification.read) {
                            var myOnClick = '';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="fbReadNotification(\'' + notificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = 'not-read';
                        }
                    } else {
                        var htmlContent = {key: notificationKey, content: notification.htmlOther};
                        notificationsHtml.push(htmlContent);
                        var myHref = '';
                        if (notification.read) {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + notificationKey + '\',\'\',\'' + myKey + '\');"';
                            var myRead = '';
                        } else {
                            var myOnClick = 'onclick="openNotification(\'' + myTitle + '\',\'' + notificationKey + '\',\'' + fbUserId + '\',\'' + myKey + '\');"';
                            var myRead = 'not-read';
                        }
                    }
                }
                pageResult = '\
                    <a class="dropdown-item b-bottom d-block link link-reset ' + myRead + '" id="vap-' + notificationKey + '" ' + myHref + ' ' + myOnClick + '>\
                        <div class="xs-p-20 xs-pt-10 xs-pb-10">\
                            <div class="img-thumb img-thumb-sm img-center pull-left xs-mr-10">\
                                <img src="' + myIcon + '" class="img-responsive center-block">\
                            </div>\
                            <div>\
                                <span class="pull-right text-grey">' + myTime + '</span>\
                                <p class="text-bold xs-mb-0 text-ellipsis text-dark xs-pr-30">' + myTitle + '</p>\
                                <p class="text-ellipsis xs-mb-0 text-dark" >' + myMessage + '</p>\
                            </div>\
                        </div>\
                    </a>' + pageResult;
            });
            $('#alljobseekernotifications').html(pageResult);
        } else {
            $('#alljobseekernotifications').html('<div class="xs-pl-20 xs-pr-20 xs-pt-40 xs-pb-40 text-center text-grey b-bottom" style="border-bottom-right-radius: 4px;border-bottom-left-radius: 4px;"><i class="fa fa-bell-o"></i> ' + txt_no_notification + '</div>');
        }
    }).catch(function (error) {
        console.log(error.message);
    });
}

function openNotification(title, key, fbUserId, nKey) {
    if (bsNotifyObject !== null) {
        bsNotifyObject.close();
    }
    if (/^\d+$/.test(fbUserId)) {
        fbUserId = parseInt(fbUserId);
    }
//if notification is unread, change its status
    if (fbUserId !== '' && fbUserId !== 0) {
        fbReadNotification(key, fbUserId, nKey);
    }
//find the correct html for selected notification
    for (var i = 0, len = notificationsHtml.length; i < len; i++) {
        if (notificationsHtml[i].key === key) {
            var content = notificationsHtml[i].content;
        }
    }
//update dialog title and content and show it
    $("#notification-dialog .modal-header > h4").html(title);
    $("#notification-dialog .modal-body").html(content);
    $("#notification-dialog").modal('toggle');
}

/*Define General Functions*/

function fbUserPresence(userID) {
    if (fbIsInArray(fbUPListeners, userID) === false) {
        fbUPListeners.push(userID);
        if (/^\d+$/.test(userID)) {
            userID = parseInt(userID);
        }
        var ref = firebase.database().ref('userConnections/' + userID);
        //connection added
        ref.on('child_added', function (userStatusSnap) {
            ref.once('value').then(function (connectionsSnap) {
                if (connectionsSnap.numChildren() > 1) {
                    //online
                    $('.fbulot-' + userID).addClass('hide');
                    $('.fbus-' + userID).removeClass('fbUserStatusOffline');
                    $('.fbus-' + userID).addClass('fbUserStatusOnline');
                } else {
                    //offline
                    $('.fbulot-' + userID).removeClass('hide');
                    $('.fbus-' + userID).removeClass('fbUserStatusOnline');
                    $('.fbus-' + userID).addClass('fbUserStatusOffline');
                }
            });
        }, function (error) {
            console.log(error.message);
        });
        //connection removed
        ref.on('child_removed', function (userStatusSnap) {
            ref.once('value').then(function (connectionsSnap) {
                if (connectionsSnap.numChildren() > 1) {
                    //online
                    $('.fbulot-' + userID).addClass('hide');
                    $('.fbus-' + userID).removeClass('fbUserStatusOffline');
                    $('.fbus-' + userID).addClass('fbUserStatusOnline');
                } else {
                    //offline
                    $('.fbulot-' + userID).removeClass('hide');
                    $('.fbus-' + userID).removeClass('fbUserStatusOnline');
                    $('.fbus-' + userID).addClass('fbUserStatusOffline');
                }
            });
        }, function (error) {
            console.log(error.message);
        });
        //last time user was online
        firebase.database().ref('usersPresence/' + userID).on('child_changed', function (userStatusSnap) {
            var changedChildKey = userStatusSnap.key.trim();
            var changedChildValue = userStatusSnap.val();
            if (changedChildKey === 'timeStamp') {
                $('.fbulot-' + userID).html('Last seen ' + fbConvertTimeStamp(changedChildValue, serverCurrentTime, 'normal'));
            }
        }), function (error) {
            console.log(error.message);
        };
    }
}

function showDesktopNotification(conversationKey, senderName, senderLogo, senderID, senderTimeStamp, jobTitle, jobID, fbUserId, message, conversationType) {
    senderLogo = decodeURIComponent(senderLogo);
    //show desktop notification on opera/firefox/chrome
    if (!Notification) {
        console.log('Desktop notifications not available in your browser. Try Chromium.');
        return;
    }
//    var site = window.location.hostname;
    var notificationIcon = senderLogo;
//    if (site.indexOf('ricebowl') !== -1) {
//        notificationIcon = 'https://s3-ap-southeast-1.amazonaws.com/brightan-assets/images/ricebowl/others/v1/chrome-notification-logo.png';
//    }
//    if (site.indexOf('maukerja') !== -1) {
//        notificationIcon = 'https://s3-ap-southeast-1.amazonaws.com/brightan-assets/images/maukerja/others/v1/chrome-notification-logo.png';
//    }
//    if (site.indexOf('internsheeps') !== -1) {
//        notificationIcon = 'https://s3-ap-southeast-1.amazonaws.com/brightan-assets/images/internsheeps/others/v1/chrome-notification-logo.png';
//    }
    var notificationBody = senderName + ' - ' + jobTitle + '\n' + message;
    if (Notification.permission !== "granted")
        Notification.requestPermission();
    else {
        var notification = new Notification('New Message', {
            icon: notificationIcon,
            body: notificationBody
        });
        notification.onclick = function () {
            window.focus();
            if ($('.fb-conversation-key').val() !== conversationKey) {
                openConversation(conversationKey, senderName, senderLogo, senderID, senderTimeStamp, jobTitle, jobID, fbUserId, conversationType);
            }
        };
    }
}

function fbResetConversationSearch() {
    if ($("#conversations_search").length) {
        if ($("#conversations_search").val() !== '') {
            $("#conversations_search").val('');
            $("#all-conversations-list").children("div").show();
        }
    }
}

function fbGenViewLink(jobTitle, jobID, conversationType, linkPlace) {
    var result, viewTitle, viewLink, viewText = '';
    if (jobID === 0 || conversationType === '') {
        return result;
    }
    switch (conversationType) {
        case "apply-job":
            viewTitle = txt_view_job;
            viewLink = "job/";
            break;
        case "recommended-applicant":
            viewTitle = txt_view_job;
            viewLink = "job/";
            break;
        case "people-search":
            viewTitle = txt_view_company;
            viewLink = "company/";
            break;
    }
    if (linkPlace === 'popup') {
        viewText = ' ' + viewTitle;
    }
    var viewJobLink = jobTitle.toLowerCase();
    viewJobLink = viewJobLink.replace(/\ \/ /g, '-');
    viewJobLink = viewJobLink.replace(/\---/g, '-');
    viewJobLink = viewJobLink.replace(/\--/g, '-');
    viewJobLink = viewJobLink.replace(/\ /g, '-');
    result = '<li><a href="' + canvas_url + viewLink + jobID + '-' + viewJobLink + '" target="_blank" title="' + viewTitle + '"><i class="fa fa-eye"></i>' + viewText + '</a></li>';
    return result;
}